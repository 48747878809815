import {
    Box,
    Divider,
    Paper,
    Skeleton,
    Tab,
    Tabs,
    Toolbar,
} from "@mui/material";
import React from "react";
import PostSkeleton from "../../../components/PostSkeleton";

const HomeSkeleton = () => {
    return (
        <>
            <Toolbar />
            <Skeleton
                animation="wave"
                width="100%"
                height="50px"
                style={{ margin: "auto", padding: "15px" }}
            />
            <Divider />

            <Box>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs variant="scrollable" value={0}>
                        <Tab
                            label={
                                <Skeleton
                                    animation="wave"
                                    height={20}
                                    width="100%"
                                />
                            }
                            sx={{
                                fontSize: "15px",
                                fontWeight: "700",
                                color: "#ABABAB",
                            }}
                        />
                        <Tab
                            label={
                                <Skeleton
                                    animation="wave"
                                    height={20}
                                    width="100%"
                                />
                            }
                            sx={{
                                fontSize: "15px",
                                fontWeight: "700",
                                color: "#ABABAB",
                            }}
                        />
                        <Tab
                            label={
                                <Skeleton
                                    animation="wave"
                                    height={20}
                                    width="100%"
                                />
                            }
                            sx={{
                                fontSize: "15px",
                                fontWeight: "700",
                                color: "#ABABAB",
                            }}
                        />
                        <Tab
                            label={
                                <Skeleton
                                    animation="wave"
                                    height={20}
                                    width="100%"
                                />
                            }
                            sx={{
                                fontSize: "15px",
                                fontWeight: "700",
                                color: "#ABABAB",
                            }}
                        />
                    </Tabs>
                </Box>
                <PostSkeleton />
                <PostSkeleton />
                <PostSkeleton />
            </Box>
        </>
    );
};

export default HomeSkeleton;
