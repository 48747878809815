import React from "react";

import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";

const ChooseEthnicity = ({ ethnicity, setEthnicity }) => {
  // to handle ethnicity select change
  const handleEthnicityChange = (event) => {
    setEthnicity(event.target.value);
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Typography
        sx={{ fontSize: 15, marginTop: "20px", marginBottom: "25px" }}
      >
        Select your Ethnicity
      </Typography>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">select one</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label="select a type"
          value={ethnicity}
          onChange={handleEthnicityChange}
        >
          <MenuItem value={"American Indian or Alaskan Native"}>
            American Indian or Alaskan Native
          </MenuItem>
          <MenuItem value={"Asian / Pacific Islander"}>
            Asian / Pacific Islander
          </MenuItem>
          <MenuItem value={"Black or African American"}>
            Black or African American
          </MenuItem>
          <MenuItem value={"Hispanic"}>Hispanic</MenuItem>
          <MenuItem value={"White / Caucasian"}>White / Caucasian</MenuItem>
          <MenuItem value={"Multiple ethnicity / Other"}>
            Multiple ethnicity / Other
          </MenuItem>
          <MenuItem value={"Prefer not to say"}>Prefer not to say</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};
export default ChooseEthnicity;
