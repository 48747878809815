import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/auth/authSlice";
import { composeWithDevTools } from "@redux-devtools/extension";
import layersReducer from "../features/layers/layersSlice";
import postsReducer from "../features/posts/postSlice";
import userProfileSlice from "../features/userprofile/userProfileSlice";
import reportSlice from "../features/report/reportSlice";
import notificationsSlice from "../features/notifications/notificationsSlice";
import messageSlice from "../features/messages/messageSlice";
import surveysSlice from "../features/surveys/surveysSlice";

import { loadingBarReducer } from "react-redux-loading-bar";

export const store = configureStore(
  {
    reducer: {
      loadingBar: loadingBarReducer,
      auth: authReducer,
      layers: layersReducer,
      posts: postsReducer,
      userProfile: userProfileSlice,
      report: reportSlice,
      notifications: notificationsSlice,
      messages: messageSlice,
      surveys: surveysSlice,
    },
  },
  composeWithDevTools()
);
