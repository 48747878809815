import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import surveysService from "./surveysService";

const initialState = {
  survey: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const surveysSlice = createSlice({
  name: "surveys",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSurvey.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSurvey.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.survey = action.payload.survey;
      })
      .addCase(getSurvey.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(surveyResponse.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(surveyResponse.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.survey = null;
      })
      .addCase(surveyResponse.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

// Get a survey from db
export const getSurvey = createAsyncThunk(
  "/surveys",
  async (userId, thunkAPI) => {
    console.log("userId in thunk", userId);
    try {
      const token = thunkAPI.getState().auth.token;
      return await surveysService.getSurvey(userId, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Create new layer
export const surveyResponse = createAsyncThunk(
  "/surveyResponses",
  async (surveyData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token;
      const result = await surveysService.surveyResponse(surveyData, token);
      return result;
    } catch (error) {
      const message =
        error.response && error.response.data && error.response.data.error
          ? error.response.data.error
          : "";
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const { reset } = surveysSlice.actions;
export default surveysSlice.reducer;
