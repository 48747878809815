import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import notificationsService from "./notificationsService";

const initialState = {
    notifications: [],
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
};

export const getNotifications = createAsyncThunk(
    "auth/getNotifications",
    async (_, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.token;

            const response = await notificationsService.getNotifications({
                token,
            });

            return response;
        } catch (error) {
            const message =
                error.response &&
                error.response.data &&
                error.response.data.error
                    ? error.response.data.error
                    : "";
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const createNotification = createAsyncThunk(
    "auth/createNotification",
    async (notificationData, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.token;

            const response = await notificationsService.createNotification(
                notificationData,
                token
            );

            return response;
        } catch (error) {
            const message =
                error.response &&
                error.response.data &&
                error.response.data.error
                    ? error.response.data.error
                    : "";
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const notificationsSlice = createSlice({
    name: "notifications",
    initialState,
    reducers: {
        resetAll: (state) => {
            state.isError = false;
            state.isSuccess = false;
            state.isLoading = false;
            state.message = "";
            state.notifications = false;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getNotifications.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getNotifications.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.notifications = action.payload.notifications;
            })
            .addCase(getNotifications.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
            .addCase(createNotification.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(createNotification.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.notifications = [...state.notifications, action.payload];
            })
            .addCase(createNotification.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            });
    },
});
export const { resetAll } = notificationsSlice.actions;
export default notificationsSlice.reducer;
