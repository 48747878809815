import { Avatar, Box, Typography } from "@mui/material";
import React from "react";

import stratosLong from "../../Assets/stratos_long_3.png";

import "./Footer2.css";

const product = ["Overview", "Features", "Solutions", "New", "Releases"];

const company = [
  "About us",
  "Careers",
  "Press",
  "News",
  "Media kit",
  "Contact",
];

const resources = ["Blog", "Events", "Help centre", "Tutorials", "Support"];

const social = ["Twitter", "LinkedIn", "Facebook"];

const legal = [
  "Terms",
  "Privacy",
  "Cookies",
  "Licenses",
  "Settings",
  "Contact",
];

const Footer2 = () => {
  return (
    <Box className="footer-container">
      <Box className="footer-container_links">
        <Avatar
          alt="stratos logo"
          src={stratosLong}
          sx={{
            display: { xs: "none", md: "flex" },
            width: "300px",
            height: "60px",
          }}
          imgProps={{
            style: { objectFit: "contain" },
          }}
        />
        {/* <Box
          sx={{
            // maxWidth: "600px",
            minWidth: "200px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "flex-start",
            textAlign: "center",
            verticalAlign: "middle",
            alignSelf: "center",
          }}
        > */}
        {/* <Box className="footer-links_section">
          <a href="/Product">Product</a>
        </Box> */}

        <Box className="footer-links_section">
          <a href="/about">About</a>
        </Box>

        <Box className="footer-links_section">
          <a href="/investors">For Investors</a>
        </Box>

        <Box className="footer-links_section">
          <a href="/FAQ">FAQ</a>
        </Box>
        {/* </Box> */}
      </Box>

      <Box className="footer-logo">
        <Typography variant="h3">
          © 2023 Stratos. All rights reserved.
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer2;
