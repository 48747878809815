import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import layersService from "./layersService";

const initialState = {
  layers: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const layersSlice = createSlice({
  name: "layers",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLayers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getLayers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.layers = action.payload.layers;
      })
      .addCase(getLayers.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(createLayer.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createLayer.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.layers.push(action.payload.layer.name);
      })
      .addCase(createLayer.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

// Get layers from db
export const getLayers = createAsyncThunk(
  "layers/getAll",
  async (_, thunkAPI) => {
    try {
      return await layersService.getLayers();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Create new layer
export const createLayer = createAsyncThunk(
  "layers/create",
  async (layerData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token;
      const result = await layersService.createLayer(layerData, token);
      console.log("result", result);
      return result;
    } catch (error) {
      const message =
        error.response && error.response.data && error.response.data.error
          ? error.response.data.error
          : "";
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const { reset } = layersSlice.actions;
export default layersSlice.reducer;
