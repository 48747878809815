import { Box, Typography } from "@mui/material";
import React from "react";
import ContactUs from "./ContactUs";

const Contact = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        paddingX: { xs: 3, sm: 3, md: 7 },
        background: "linear-gradient( #FFFFFF, #52B1BB)",
        paddingBottom: 10,
        alignItems: "center",
      }}
    >
      <Typography
        variant="h4"
        sx={{
          fontWeight: "700",
          fontSize: "50px",
          margin: 3,
          textAlign: "center",
        }}
      >
        We drive change
      </Typography>

      <Typography
        variant="h5"
        sx={{
          fontWeight: "500",
          color: "grey",
          padding: 2,
          paddingLeft: 0,
          width: { xs: "90%", sm: "90%", md: "60%" },
          margin: 3,
        }}
      >
        By connecting professionals from top companies worldwide, Stratos serves
        as a powerful community tool driving impactful change and promoting
        progress towards a more inclusive and diverse society.
      </Typography>

      <Box
        sx={{
          background: "linear-gradient(90deg, #52B1BB 0%, #008997 96.22%)",
          width: "80%",
          display: { xs: "none", sm: "none", md: "flex" },
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "flex-start",
          paddingX: 2,
          borderRadius: "10px",
          marginY: 1,
          padding: 2,
        }}
      >
        <ContactUs iconColor={"#fff"} buttonColor={"#000"} />
      </Box>

      {/*Mobile*/}

      <Box
        sx={{
          background: "linear-gradient(90deg, #52B1BB 0%, #008997 96.22%)",
          marginRight: "7%",
          marginLeft: "3%",
          display: { xs: "flex", sm: "flex", md: "none" },
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "flex-start",
          paddingX: { xs: -3, sm: -3, md: -7 },
          borderRadius: "10px",
          marginBpttom: 1,
          padding: 2,
          marginTop: -1,
        }}
      >
        <ContactUs iconColor={"#fff"} buttonColor={"#000"} />
      </Box>
    </Box>
  );
};

export default Contact;
