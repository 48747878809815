import { Box, Paper, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import logo from "../../Assets/logo.png";
import welcome from "../../Assets/welcome.png";
import { LoginMixPanel, TrackRegister } from "../../MixPanel";
import MobileMessage from "../../components/MobileMessage";
import Spinner from "../../components/Spinner";
import {
  reset,
  signInWithGoogle,
  updateUserPersonal,
} from "../../features/auth/authSlice";
import "./Welcome.css";

function Welcome() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [mobile, setMobile] = useState(false);
  const { isSuccess, isError, isLoading, user, message } = useSelector(
    (state) => state.auth
  );
  const [showErrorAlert, setShowErrorAlert] = useState(false);

  // get ref link from url if it exists
  const params = new URLSearchParams(location.search);

  const handleCredentialResponse = (res) => {
    console.log(res.credential);
    const refCode = params.get("ref");

    //navigate("/loginauth", {
    //    state: { googleToken: res.credential, refCode },
    //});
    dispatch(signInWithGoogle({ googleToken: res.credential, refCode }));
  };
  useEffect(() => {
    // isNewUser is false,  login
    if (isSuccess) {
      if (user && !user?.isNewUser) {
        LoginMixPanel(user.email);
        const lastActive = new Date().toISOString();
        const newUserData = {
          lastActive,
        };
        console.log("update activity", newUserData);
        dispatch(updateUserPersonal({ newUserData }));
        if (user.birthDate === "" || user.city === "") {
          dispatch(reset());
          navigate("/HomeScreen", {
            state: { showOnboarding: true },
          });
        } else {
          dispatch(reset());
          navigate("/HomeScreen", {
            state: { showOnboarding: false },
          });
        }
      } else if (user) {
        // isNewUser is true, registered
        TrackRegister(
          user.name,
          user.email,
          user.jobTitle,
          user.currentCompany
        );
        dispatch(reset());
        navigate("/loginauth", {
          state: { showOnboarding: true },
        });
      }
    }

    if (isError) {
      setShowErrorAlert(true);
    }
  }, [isSuccess, isError]);

  useEffect(() => {
    /*global google */
    google.accounts.id.initialize({
      client_id:
        "261842456718-oon2niaduaiumkfq6g0srtea82umgg3v.apps.googleusercontent.com",
      callback: handleCredentialResponse,
    });
    const containerWidth =
      document.getElementById("GoogleSignInButton").parentElement.clientWidth;
    const buttonWidth = Math.round(containerWidth * 0.7);
    google.accounts.id.renderButton(
      document.getElementById("GoogleSignInButton"),
      {
        theme: "outline",
        size: "large",
        shape: "rectangular",
        width: buttonWidth.toString(),
      }
    );
    google.accounts.id.prompt();
  }, []);

  const handleClickSignUp = () => {
    navigate("/SignUp");
  };

  const handleClickLogin = () => {
    navigate("/login");
  };

  const [windowWidth, setWindowWidth] = useState(
    Math.min(window.screen.width, window.innerWidth)
  );

  const handleResize = () => {
    setWindowWidth(Math.min(window.screen.width, window.innerWidth));
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize, false);

    return () => {
      window.removeEventListener("resize", handleResize, false);
    };
  }, []);

  useEffect(() => {
    if (windowWidth < 500) {
      // setMobile(true);
    } else {
      setMobile(false);
    }
  }, [windowWidth]);

  if (isLoading) {
    return <Spinner />;
  }

  return mobile ? (
    <MobileMessage />
  ) : (
    <>
      {showErrorAlert && (
        <Dialog
          open={showErrorAlert}
          onClose={() => {
            console.log("alert close");
            dispatch(reset());
            setShowErrorAlert(false);
            navigate("/Welcome");
          }}
        >
          <DialogTitle>{"Error"}</DialogTitle>
          <DialogContent>
            <DialogContentText>{"Error Details: " + message}</DialogContentText>
          </DialogContent>
        </Dialog>
      )}
      <Helmet>
        <title>Welcome - Stratos</title>
      </Helmet>
      <Box className="welcome-container">
        <img src={logo} alt="logo" className="logo" />
        <Paper className="welcome">
          <img src={welcome} alt="welcome" className="welcomeImg" />
          <Typography variant="h1"> Community & Networking </Typography> <br />
          <Typography
            variant="h2"
            sx={{
              marginBottom: 2,
            }}
          >
            {" "}
            A new way for professionals <br /> to network with each other
          </Typography>
          {/*<Button
                    variant="outlined"
                    onClick={() => navigate("/loginauth")}
                    sx={{
                        marginTop: 2,
                        color: "black",
                        border: "1px solid rgba(158, 158, 158, 0.3) ",
                        padding: "8px 50px",
                    }}
                >
                    <img
                        src={googleLogo}
                        alt="google logo"
                        height={"20px"}
                        width={"20px"}
                        style={{ marginRight: "5px" }}
                    />{" "}
                    SIGN IN WITH GOOGLE
                </Button>*/}
          <div id="GoogleSignInButton"></div>
          {/*<Button
                        variant="outlined"
                        onClick={() => navigate("/loginauth")}
                        sx={{
                            marginTop: 2,
                            color: "black",
                            border: "1px solid rgba(158, 158, 158, 0.3)",
                            padding: "8px 50px",
                        }}
                    >
                        <img
                            src={linkedinLogo}
                            alt="google logo"
                            height={"20px"}
                            width={"20px"}
                            style={{ marginRight: "5px" }}
                        />{" "}
                        SIGN IN WITH LINKEDIN
                    </Button>*/}
          <Button
            variant="contained"
            onClick={handleClickSignUp} // -> this allows sign up [STRAT-22]
            // onClick={() => navigate("/loginauth")} // -> this takes you to blank page if domain not added to oauth [STRAT-23]
            sx={{ marginTop: 2, padding: "8px 50px", width: "80%" }}
          >
            Create Account
          </Button>
          <Typography mt={2} mb={2} fontWeight="300" fontSize={16}>
            Have an account?{" "}
            <span
              style={{
                textDecoration: "none",
                border: "none",
                color: "#52B1BB",
                fontSize: "14px",
                cursor: "pointer",
              }}
              onClick={handleClickLogin}
            >
              Login
            </span>
          </Typography>
        </Paper>
      </Box>
    </>
  );
}

export default Welcome;
