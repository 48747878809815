import { Box, Paper, Tab, Tabs } from '@mui/material'
import React, { useState } from 'react'
import MyPipeline from './MyPipeline/MyPipeline';
import './Pipeline.css'


//Pipeline-tabs panel
function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
        {children}
          </Box>
        )}
      </div>
    );
}
  
//tab-panel ally props
function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
}
  

const Pipeline = () =>
{
    const [tab, setTab] = useState(0);

  const handleChange = (event, newValue) => {
    setTab(newValue);
    };
    

  return (

          <Box className='pipeline-container'>
      <Tabs
        orientation="vertical"
        value={tab}
        onChange={handleChange}
        aria-label="Pipeline tabs"
        className='pipeline-tabs_tabs-container'

      >
        <Tab className='tab' label="My Pipeline &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; (12)"  {...a11yProps(0)} />
        <Tab className='tab' label="All Applicants &emsp; &emsp; &emsp; &emsp; &nbsp; (05) " {...a11yProps(1)} />
        <Tab className='tab' label="Archived &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; (20)" {...a11yProps(2)} />
        <Tab className='tab' label="Responded &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; (15)" {...a11yProps(3)} />
        <Tab className='tab' label="Messaged &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &nbsp; (05)" {...a11yProps(4)} />
      </Tabs>
      <TabPanel className='tab-panel_container' value={tab} index={0}>
       <MyPipeline />
      </TabPanel>
      <TabPanel className='tab-panel_container' value={tab} index={1}>
        All Applicants
      </TabPanel>
      <TabPanel className='tab-panel_container' value={tab} index={2}>
        Archived
      </TabPanel>
      <TabPanel className='tab-panel_container' value={tab} index={3}>
        Responded
      </TabPanel>
      <TabPanel className='tab-panel_container' value={tab} index={4}>
        Messaged
      </TabPanel>
    </Box>
  )
}

export default Pipeline