import BlockIcon from "@mui/icons-material/Block";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import NoAccountsIcon from "@mui/icons-material/NoAccounts";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Popover,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import DOMPurify from "dompurify";
import { Parser } from "html-to-react";
import React, { useEffect, useRef, useState } from "react";
import LinesEllipsis from "react-lines-ellipsis";
import responsiveHOC from "react-lines-ellipsis/lib/responsiveHOC";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CommentsIcon from "../../../Assets/commentIcon.svg";
import { convertDate } from "../../../Assets/utilFunctions";
import {
  TrackLike,
  TrackUnLike,
  TrackUserProfileClick,
} from "../../../MixPanel";
import PostSkeleton from "../../../components/PostSkeleton";
import { updateUserLayer } from "../../../features/auth/authSlice";
import { createChat } from "../../../features/messages/messageSlice";
import {
  deletePost,
  getPostComments,
  likePost,
  resetComments,
  unLikePost,
} from "../../../features/posts/postSlice";
import {
  blockUser,
  reportPost,
  reportPostBlockUser,
} from "../../../features/report/reportSlice";
import CommentsDialogBox from "./CommentsDialogBox";

// for popover mousing
const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
  },
  popoverContent: {
    pointerEvents: "auto",
  },
}));

const DisplayPostCard = ({ post, setCurrentLayerName }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const sanitizedContent = DOMPurify.sanitize(post.content);
  const ResponsiveLinesEllipsis = responsiveHOC()(LinesEllipsis);
  const [expanded, setExpanded] = useState(false);
  const handleClick = () => {
    setExpanded(!expanded);
  };
  const parser = new Parser();
  const reactComponent = parser.parse(sanitizedContent);

  const { user } = useSelector((state) => state.auth);
  const { isLikeLoading } = useSelector((state) => state.posts);

  const [openCommentsDialog, setOpenCommentsDialog] = useState(false);
  const [like, setLike] = useState(false);
  const [likeCount, setLikeCount] = useState(0);
  const currentDate = new Date().toISOString();

  const [openReportMenu, setOpenReportMenu] = useState(null);
  const open = Boolean(openReportMenu);
  const handleReportMenuClick = (event) => {
    setOpenReportMenu(event.currentTarget);
  };
  const handleReportMenuClose = () => {
    setOpenReportMenu(null);
  };

  useEffect(() => {
    setLike(post.likedByCurrentUser ? post.likedByCurrentUser : false);
    setLikeCount(post.likesCount);
  }, []);

  const handleLikeButton = (event) => {
    const likeData = { postId: post.id, layer: post.layer };

    if (post.likedByCurrentUser) {
      // unlinke the post
      setLike(false);
      setLikeCount(likeCount - 1);
      dispatch(unLikePost(likeData));
      TrackUnLike(
        user.email,
        post.id,
        post.author.name,
        post.author.id,
        user.name,
        user.userId,
        post.layer,
        post.title,
        sanitizedContent
      );
    } else {
      // like the post
      const notificationData = {
        actionType: "Like",
        content: sanitizedContent,
        postId: post.id,
        layer: post.layer,
        userToNotify: post.author.id,
      };
      setLike(true);
      setLikeCount(likeCount + 1);
      dispatch(likePost({ likeData, notificationData }));
      TrackLike(
        user.email,
        post.id,
        post.author.name,
        post.author.id,
        user.name,
        user.userId,
        post.layer,
        post.title,
        sanitizedContent
      );
    }
  };

  const handleCommentDialog = (event) => {
    const commentData = { postId: post.id, layer: post.layer };
    dispatch(getPostComments(commentData));
    setOpenCommentsDialog(true);
  };

  const handleCommentDialogClose = () => {
    dispatch(resetComments());
    setOpenCommentsDialog(false);
  };

  const handleIconClick = () => {
    TrackUserProfileClick(
      user.email,
      user.userId,
      post.author.name,
      post.author.id,
      post.author.jobTitle,
      post.author.currentCompany
    );
    // this would get current info on a given user but takes too long to load when hovering
    //dispatch(getUserProfile({ id: post.author.id }));
    navigate(
      `/HomeScreen/Profile/${post.author.id}`,
      { state: { userId: user.userId } },
      { preventScrollReset: true }
    );
  };

  const [rprtMenu, setRprtMenu] = useState(false);
  const [reason, setReason] = useState("");

  const [onlyReport, setOnlyReport] = useState(true);

  const reportMenu = () => {
    setRprtMenu(true);
  };
  const closeRprtMenu = () => {
    setRprtMenu(false);
  };

  const handleReportButton = () => {
    setOnlyReport(true);
    dispatch(
      reportPost({
        reportedPost: post.id,
        reportingReason: reason,
        reportedPostLayer: post.layer,
        reportedPostAuthorId: post.author.id,
        reportedPostAuthorName: post.author.name,
      })
    );
  };

  const handleBlockUserButton = () => {
    dispatch(
      blockUser({
        blockedUser: post.author.id,
        blockedEmail: post.author.name,
      })
    );
  };

  const handleBlockReportButton = () => {
    setOnlyReport(false);
    reportMenu();
  };

  const handleReportAndBlock = () => {
    console.log("report and block");
    dispatch(
      reportPostBlockUser({
        reportedPost: post.id,
        reportingReason: reason,
        reportedPostLayer: post.layer,
        reportedPostAuthorId: post.author.id,
        reportedPostAuthorName: post.author.name,
        blockedUser: post.author.id,
        blockedEmail: post.author.name,
      })
    );
  };

  const handleDeletePostButton = () => {
    // delete post
    dispatch(
      deletePost({
        postId: post.id,
        layer: post.layer,
      })
    );
  };

  const [openLayer, setOpenLayer] = useState(false);
  const openLayerD = () => {
    setOpenLayer(true);
  };

  const closeLayerD = () => {
    setOpenLayer(false);
  };

  const Layer = () => {
    return (
      <Dialog open={openLayer} close={closeLayerD}>
        <DialogTitle>Join {post.layer} Layer?</DialogTitle>
        <DialogActions>
          <Button onClick={closeLayerD}>Cancel</Button>
          <Button onClick={addLayer} variant="contained">
            Join Now
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const addLayer = () => {
    closeLayerD();
    const userLayers = [...user.layers, post.layer];
    dispatch(updateUserLayer(userLayers));
  };

  // popover handling
  const [openedPopover, setOpenedPopover] = useState(false);
  const popoverAnchor = useRef(null);
  const popoverEnter = ({ currentTarget }) => {
    setOpenedPopover(true);
  };
  const popoverLeave = ({ currentTarget }) => {
    setOpenedPopover(false);
  };
  const classes = useStyles();

  // to take user to messaging
  const handleMessageClick = (userId) => {
    dispatch(createChat(userId));
    navigate("/HomeScreen/Messages", {
      state: { chatUser: userId },
    });
  };

  return post ? (
    <Paper elevation={0} sx={{ borderRadius: "15px" }}>
      <Layer />
      <Dialog open={rprtMenu} onClose={closeRprtMenu}>
        <DialogTitle>Reporting Reason</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To report this post you need to provide a valid reason. We will
            review the post and inform concerned user.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Reason"
            type="text"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            fullWidth
            variant="standard"
            multiline
            inputProps={{
              style: {
                height: "20px",
                fontSize: "14px",
              },
            }}
          />
        </DialogContent>
        <DialogActions>
          {!onlyReport ? (
            <Tooltip title={"Report Post and Block this User"}>
              <Button onClick={handleReportAndBlock}>Report and Block</Button>
            </Tooltip>
          ) : (
            <Button onClick={handleReportButton}>Report</Button>
          )}
          <Button onClick={closeRprtMenu}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <Card sx={{ width: "100%", borderRadius: "15px" }}>
        <CardHeader
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            padding: "0px",
            paddingLeft: "16px",
            paddingRight: "16px",
            paddingTop: "16px",
          }}
          avatar={
            <div>
              <img
                src={
                  post.author.profileImage
                    ? post.author.profileImage
                    : "https://firebasestorage.googleapis.com/v0/b/stratosv1.appspot.com/o/default-user-profile.png?alt=media"
                }
                style={{
                  width: "35px",
                  height: "35px",
                  borderRadius: "100%",
                  margin: "0px",
                  cursor: "pointer",
                }}
                onClick={handleIconClick}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src =
                    "https://firebasestorage.googleapis.com/v0/b/stratosv1.appspot.com/o/default-user-profile.png?alt=media";
                }}
                ref={popoverAnchor}
                aria-owns="mouse-over-popover"
                aria-haspopup="true"
                onMouseEnter={popoverEnter}
                onMouseLeave={popoverLeave}
              />
              <Popover
                id="mouse-over-popover"
                className={classes.popover}
                classes={{
                  paper: classes.popoverContent,
                }}
                open={openedPopover}
                anchorEl={popoverAnchor.current}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                PaperProps={{
                  onMouseEnter: popoverEnter,
                  onMouseLeave: popoverLeave,
                }}
              >
                <Paper //elevation={2}
                  sx={{
                    pointerEvents: "auto",
                    borderRadius: "20px",
                    // width: { xs: 200, sm: 200, md: 300 },
                    // height: { xs: 150, sm: 150, md: 250 },
                    display: "flex",
                    flexDirection: "column",
                    padding: 2,
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    verticalAlign: "middle",
                    maxWidth: 400,
                    minWidth: 200,
                  }}
                >
                  <Avatar
                    alt={post.author.firstName || post.author.name}
                    src={
                      post.author.profileImage
                        ? post.author.profileImage
                        : "https://firebasestorage.googleapis.com/v0/b/stratosv1.appspot.com/o/default-user-profile.png?alt=media"
                    }
                    sx={{
                      cursor: "pointer",
                      alignItems: "center",
                      justifyContent: "center",
                      verticalAlign: "center",
                      width: { xs: 30, sm: 30, md: 50 },
                      height: { xs: 30, sm: 30, md: 50 },
                      m: 1,
                    }}
                    onClick={handleIconClick}
                  />
                  <Typography
                    sx={{
                      cursor: "pointer",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      marginTop: 1,
                      fontWeight: 700,
                      fontSize: { xs: "13px", sm: "15px", md: "17px" },
                      color: "#52B1BB",
                    }}
                    onClick={handleIconClick}
                  >
                    {post.author.firstName || post.author.name}
                  </Typography>
                  <Typography
                    color="#BDBCBC"
                    sx={{
                      fontSize: { xs: "11px", sm: "13px", md: "15px" },
                      marginBottom: 2,
                    }}
                  >
                    {post.author.jobTitle && post.author.currentCompany
                      ? post.author.jobTitle + " @" + post.author.currentCompany
                      : ""}
                  </Typography>
                  <Divider flexItem variant="middle" />
                  <Box
                    sx={{
                      m: 0.5,
                      display: "flex",
                      flexDirection: "row",
                      // width: "100%",
                      flexWrap: "wrap",
                      marginTop: 2,
                      // padding: 0.5,
                      // marginBottom: 3,
                    }}
                  >
                    {post.author?.interests &&
                      post.author.interests.slice(0, 4).map((interest) => (
                        <Typography
                          key={interest}
                          color="#16171B"
                          sx={{
                            fontSize: "12px",
                            fontWeight: 400,
                            marginRight: 1.5,
                            // padding: 0.25,
                            // paddingRight:1,
                            // marginBottom: 2,
                            // overflow: "hidden",
                            // whiteSpace: "nowrap",
                          }}
                        >
                          {interest}
                        </Typography>
                      ))}
                  </Box>
                  <Button
                    sx={{
                      fontWeight: 400,
                      borderRadius: "27px",
                      color: "white",
                      backgroundColor: "#52B1BB",
                      textTransform: "none",
                      marginBottom: 2,
                      marginTop: 2,
                    }}
                    onClick={() => handleMessageClick(post.author.userId)}
                  >
                    Send Message
                  </Button>
                </Paper>
              </Popover>
            </div>
          }
          title={post.author.firstName || post.author.name}
          subheader={
            <>
              {convertDate(post.createdAt ? post.createdAt : currentDate)}
              <Typography
                component="button"
                sx={{
                  fontWeight: 100,
                  fontSize: "10px",
                  borderRadius: "11px",
                  background: "#ffffff",
                  color: "#52B1BB",
                  border: "none",
                  margin: 1,
                  border: "1px solid #52B1BB",
                  paddingX: 2,
                  paddingY: 0,
                  paddingTop: 0.1,
                }}
                onClick={() => {
                  if (
                    !user.layers?.includes(post.layer) &&
                    post.layer !== "All"
                  ) {
                    setOpenLayer(true);
                  } else {
                    // open that layer
                    const temp = post.layer.trim().toUpperCase();
                    console.log(temp);
                    setCurrentLayerName(temp);
                  }
                }}
              >
                {post.layer}
              </Typography>
            </>
          }
        />
        <CardContent
          sx={{
            paddingLeft: "64px",
            paddingRight: "64px",
            paddingTop: "10px",
            paddingBottom: "0px",
          }}
        >
          <Typography
            variant="h5"
            sx={{ fontWeight: 700, fontSize: "15px" }}
            color="black"
          >
            {post.title ? post.title : "Post Title"}{" "}
          </Typography>

          {!expanded ? (
            <Typography
              variant="body2"
              color="#16171B"
              sx={{
                fontSize: "14px",
                whiteSpace: "pre-wrap",
                fontWeight: 400,
              }}
            >
              <ResponsiveLinesEllipsis
                text={sanitizedContent}
                maxLine="3"
                ellipsis={
                  <Button
                    onClick={() => setExpanded(true)}
                    sx={{
                      textTransform: "none",
                      paddingLeft: 0,
                    }}
                  >
                    ...read more
                  </Button>
                }
                trimRight
                basedOn="words"
                // component="span" -> makes posts bigger/less collapsed
              />
            </Typography>
          ) : (
            <Box>
              <Typography
                variant="body2"
                color="#16171B"
                sx={{
                  fontSize: "14px",
                  whiteSpace: "pre-wrap",
                  fontWeight: 400,
                }}
                //dangerouslySetInnerHTML={{
                //    __html: sanitizedContent
                //        ? sanitizedContent
                //        : "Post Content",
                //}}
              >
                {reactComponent}
              </Typography>
              <Button
                onClick={() => setExpanded(false)}
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <ExpandLessIcon />
              </Button>
            </Box>
          )}
        </CardContent>
        <CardActions
          disableSpacing
          sx={{
            display: "flex",
            justifyContent: "space-between",
            paddingLeft: "58px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              disabled={isLikeLoading}
              startIcon={like ? <ThumbUpIcon /> : <ThumbUpOutlinedIcon />}
              onClick={handleLikeButton}
            >
              {likeCount}
            </Button>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "18px",
                backgroundColor: "#52B1BB",
                paddingX: 1,
                paddingY: 0,
                color: "white",
              }}
              onClick={handleCommentDialog}
            >
              {" "}
              <Avatar
                src={CommentsIcon}
                sx={{
                  width: "13px",
                  height: "13px",
                  borderRadius: 0,
                  margin: 0,
                  marginRight: 0.5,
                  cursor: "pointer",
                }}
              />
              <Typography fontSize={"13px"}> {post.commentsCount}</Typography>
            </Box>
          </Box>
          <>
            <IconButton aria-label="settings" onClick={handleReportMenuClick}>
              <MoreVertIcon />
            </IconButton>

            <Menu
              anchorEl={openReportMenu}
              open={open}
              onClose={handleReportMenuClose}
            >
              {post.author.id === user.userId && (
                <MenuItem
                  onClick={handleDeletePostButton}
                  style={{
                    color: "#005257",
                    fontSize: "14px",
                  }}
                >
                  <DeleteIcon
                    style={{
                      transform: "rotateY(180deg)",
                      marginRight: "4px",
                      fontSize: "16px",
                    }}
                  />
                  Delete Post
                </MenuItem>
              )}
              {post.author.id !== user.userId && (
                <MenuItem
                  onClick={() => {
                    setOnlyReport(true);
                    reportMenu();
                  }}
                  style={{
                    color: "#005257",
                    fontSize: "14px",
                  }}
                >
                  <BlockIcon
                    style={{
                      transform: "rotateY(180deg)",
                      marginRight: "4px",
                      fontSize: "16px",
                    }}
                  />
                  Report Post
                </MenuItem>
              )}
              {post.author.id !== user.userId && (
                <MenuItem
                  onClick={handleBlockUserButton}
                  style={{
                    color: "#005257",
                    fontSize: "14px",
                  }}
                >
                  <PersonOffIcon
                    style={{
                      marginRight: "4px",
                      fontSize: "16px",
                    }}
                  />
                  Block User
                </MenuItem>
              )}
              {post.author.id !== user.userId && (
                <MenuItem
                  onClick={handleBlockReportButton}
                  style={{
                    color: "#005257",
                    fontSize: "14px",
                  }}
                >
                  <NoAccountsIcon
                    style={{
                      marginRight: "4px",
                      fontSize: "16px",
                    }}
                  />
                  Report & Block
                </MenuItem>
              )}
            </Menu>
          </>
        </CardActions>
      </Card>
      {openCommentsDialog && (
        <CommentsDialogBox
          post={post}
          openCommentsDialog={openCommentsDialog}
          handleCommentDialogClose={handleCommentDialogClose}
        />
      )}
    </Paper>
  ) : (
    <PostSkeleton />
  );
};

export default DisplayPostCard;
