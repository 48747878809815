import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { editComments, editPosts, hideUserPosts } from "../posts/postSlice";
import reportService from "./reportService";

//get user from local storage
// const user = JSON.parse(localStorage.getItem("user"));

const initialState = {
    blockedUsers: [],
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
};

//report post

export const reportPost = createAsyncThunk(
    "auth/reportPost",
    async (reportedPost, thunkAPI) => {
        console.log("Report Post: ", reportedPost);
        try {
            const token = thunkAPI.getState().auth.token;

            const response = await reportService.reportPost(
                reportedPost,
                token
            );
            thunkAPI.dispatch(
                editPosts({
                    layerName: response.reportedPostLayer,
                    postId: response.reportedPost,
                })
            );
            return response;
        } catch (error) {
            const message =
                error.response &&
                error.response.data &&
                error.response.data.error
                    ? error.response.data.error
                    : "";
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const reportPostBlockUser = createAsyncThunk(
    "auth/reportPostBlockUser",
    async (reportBlock, thunkAPI) => {
        console.log("Report block: ", reportBlock);
        try {
            const token = thunkAPI.getState().auth.token;

            const {
                reportedPost,
                reportingReason,
                reportedPostLayer,
                reportedPostAuthorId,
                reportedPostAuthorName,
                blockedUser,
                blockedEmail,
            } = reportBlock;

            const response = await reportService.reportPost(
                {
                    reportedPost,
                    reportingReason,
                    reportedPostLayer,
                    reportedPostAuthorId,
                    reportedPostAuthorName,
                },
                token
            );
            thunkAPI.dispatch(
                editPosts({
                    layerName: response.reportedPostLayer,
                    postId: response.reportedPost,
                })
            );

            const res = await reportService.blockUser(
                { blockedUser, blockedEmail },
                token
            );

            thunkAPI.dispatch(hideUserPosts({ author: res.blockedUser }));
            return res;
        } catch (error) {
            const message =
                error.response &&
                error.response.data &&
                error.response.data.error
                    ? error.response.data.error
                    : "";
            return thunkAPI.rejectWithValue(message);
        }
    }
);

//block user

export const blockUser = createAsyncThunk(
    "auth/blockUser",
    async (blockedUser, thunkAPI) => {
        console.log("Report Post: ", blockedUser);
        try {
            const token = thunkAPI.getState().auth.token;

            const response = await reportService.blockUser(blockedUser, token);
            thunkAPI.dispatch(hideUserPosts({ author: response.blockedUser }));
            return response;
        } catch (error) {
            const message =
                error.response &&
                error.response.data &&
                error.response.data.error
                    ? error.response.data.error
                    : "";
            return thunkAPI.rejectWithValue(message);
        }
    }
);

//report user

export const reportUser = createAsyncThunk(
    "auth/reportUser",
    async (reportedUser, thunkAPI) => {
        console.log("Report User: ", reportedUser);
        try {
            const token = thunkAPI.getState().auth.token;

            const response = await reportService.reportUser(
                reportedUser,
                token
            );
            thunkAPI.dispatch(hideUserPosts({ author: response.reportedUser }));
            return response;
        } catch (error) {
            const message =
                error.response &&
                error.response.data &&
                error.response.data.error
                    ? error.response.data.error
                    : "";
            return thunkAPI.rejectWithValue(message);
        }
    }
);

// get blocked users list
export const getBlockedUsers = createAsyncThunk(
    "auth/getBlockedUsers",
    async (_, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.token;
            return await reportService.getBlockedUsers({ token });
        } catch (error) {
            const message =
                error.response &&
                error.response.data &&
                error.response.data.error
                    ? error.response.data.error
                    : "";
            return thunkAPI.rejectWithValue(message);
        }
    }
);

// report comment
export const reportComment = createAsyncThunk(
    "auth/reportComment",
    async (reportedComment, thunkAPI) => {
        console.log("Report Comment: ", reportedComment);
        try {
            const token = thunkAPI.getState().auth.token;

            const response = await reportService.reportComment(
                reportedComment,
                token
            );
            thunkAPI.dispatch(
                editComments({
                    commentId: response.reportedComment,
                })
            );
            return response;
        } catch (error) {
            const message =
                error.response &&
                error.response.data &&
                error.response.data.error
                    ? error.response.data.error
                    : "";
            return thunkAPI.rejectWithValue(message);
        }
    }
);

// report comment and block user
export const reportCommentBlockUser = createAsyncThunk(
    "auth/reportCommentBlockUser",
    async (reportBlock, thunkAPI) => {
        try {
            const {
                reportedPost,
                reportedComment,
                reportingReason,
                reportedPostLayer,
                reportedCommentAuthorId,
                reportedCommentAuthorName,
                blockedUser,
                blockedEmail,
            } = reportBlock;

            console.log("reportBlock", reportBlock);

            const token = thunkAPI.getState().auth.token;

            const response = await reportService.reportComment(
                {
                    reportedPost,
                    reportedComment,
                    reportingReason,
                    reportedPostLayer,
                    reportedCommentAuthorId,
                    reportedCommentAuthorName,
                },
                token
            );
            thunkAPI.dispatch(
                editComments({
                    commentId: response.reportedComment,
                })
            );
            const res = await reportService.blockUser(
                { blockedUser, blockedEmail },
                token
            );

            thunkAPI.dispatch(hideUserPosts({ author: res.blockedUser }));
            return res;
        } catch (error) {
            const message =
                error.response &&
                error.response.data &&
                error.response.data.error
                    ? error.response.data.error
                    : "";
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const reportSlice = createSlice({
    name: "report",
    initialState,
    reducers: {
        resetAll: (state) => {
            state.isError = false;
            state.isSuccess = false;
            state.isLoading = false;
            state.message = "";
            state.blockedUsers = [];
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(reportPost.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(reportPost.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
            })
            .addCase(reportPost.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
            .addCase(reportUser.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(reportUser.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.blockedUsers = Array.from(
                    new Set([
                        ...state.blockedUsers,
                        action.payload.reportedUser,
                    ])
                );
            })
            .addCase(reportUser.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
            .addCase(blockUser.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
            .addCase(blockUser.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(blockUser.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.blockedUsers = Array.from(
                    new Set([...state.blockedUsers, action.payload.blockedUser])
                );
            })
            .addCase(reportPostBlockUser.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
            .addCase(reportPostBlockUser.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(reportPostBlockUser.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.blockedUsers = Array.from(
                    new Set([...state.blockedUsers, action.payload.blockedUser])
                );
            })
            .addCase(getBlockedUsers.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
            .addCase(getBlockedUsers.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getBlockedUsers.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.blockedUsers = action.payload.blockedList;
            })
            .addCase(reportComment.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
            .addCase(reportComment.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(reportComment.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
            })
            .addCase(reportCommentBlockUser.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
            .addCase(reportCommentBlockUser.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(reportCommentBlockUser.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.blockedUsers = Array.from(
                    new Set([...state.blockedUsers, action.payload.blockedUser])
                );
            });
    },
});
export const { resetAll } = reportSlice.actions;
export default reportSlice.reducer;
