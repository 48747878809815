import { Typography, Box, CardMedia } from "@mui/material";
import React from "react";
import LayerImage from "../../Assets/onboarding1.jpg";
const WhatAreLayers = () => {
    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
        >
            <Typography sx={{ fontSize: 20 }}>
                Layers are locations where you can discuss different topics.
            </Typography>
            <CardMedia
                component="img"
                src={LayerImage}
                sx={{ marginTop: 4, height: "200px", width: "300px" }}
                alt="layerImage"
            />
        </Box>
    );
};
export default WhatAreLayers;
