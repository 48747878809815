import { ArrowBack } from "@mui/icons-material";
import {
    Box,
    Button,
    IconButton,
    Input,
    Paper,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../../../Assets/logo.png";
import jwt_decode from "jwt-decode";
import {
    setIsNewUserFalse,
    signInWithGoogle,
} from "../../../features/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";

const OAuthLogin = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    const { user } = useSelector((state) => state.auth);

    useEffect(() => {
        if (!user.isNewUser) {
            navigate("/HomeScreen", {
                state: { showOnboarding: true },
            });
        }
    }, [user.isNewUser]);

    return (
        <Box className="signup2-container">
            <img src={logo} alt="logo" />
            <Paper
                sx={{
                    padding: "50px",
                    width: "30%",
                    "@media (max-width: 450px)": {
                        width: "60%",
                    },
                }}
            >
                <Stack
                    display={"flex"}
                    direction={"row"}
                    justifyContent="space-between"
                    justifySelf={"flex-start"}
                    width={{ sm: "70%", xs: "100%" }}
                    ml={{ sm: "0", xs: "-20px" }}
                >
                    <IconButton onClick={() => navigate(-1)}>
                        <ArrowBack color="primary" />
                    </IconButton>
                    <Typography>Create an Account</Typography>
                </Stack>
                <Stack
                    direction={"column"}
                    width={"100%"}
                    alignItems={"center"}
                    spacing={2}
                    mt={4}
                >
                    <TextField
                        type="name"
                        fullWidth
                        placeholder="Name"
                        inputProps={{
                            style: {
                                fontSize: "15px",
                                padding: 10,
                            },
                        }}
                        value={user.name}
                        disabled
                    />

                    <TextField
                        type="email"
                        fullWidth
                        placeholder="Email"
                        inputProps={{
                            style: {
                                fontSize: "15px",
                                padding: 10,
                            },
                        }}
                        value={user.email}
                        disabled
                    />

                    <Button
                        variant="contained"
                        fullWidth
                        sx={{
                            marginTop: 2,
                            padding: "8px 50px",
                            width: "100%",
                        }}
                        onClick={() => {
                            dispatch(setIsNewUserFalse());
                        }}
                    >
                        Agree & Join
                    </Button>

                    <Typography mt={2} mb={2} fontWeight="300" fontSize={16}>
                        Have an Account?{" "}
                        <span
                            style={{
                                textDecoration: "none",
                                border: "none",
                                color: "#52B1BB",
                                fontSize: "14px",
                                cursor: "pointer",
                            }}
                        >
                            Login
                        </span>
                    </Typography>
                </Stack>
                <Typography
                    marginTop={3}
                    fontSize={16}
                    fontWeight={400}
                    textAlign={"center"}
                >
                    By clicking Agree & Join, you agree to the{" "}
                    <span style={{ color: "#52B1BB", fontWeight: "bolder" }}>
                        Stratos Community Guideline
                    </span>{" "}
                </Typography>
            </Paper>
        </Box>
    );
};

export default OAuthLogin;
