import React from "react";

import { Box, Button, Typography } from "@mui/material";

import { cities, ergs, layersToPrompts } from "../../Pages/constants";

const ChooseYourLayers = ({ userLayers, setUserLayers }) => {
  // compiling current layers from constants
  let updatedLayersToPrompts = {};
  for (const layer in layersToPrompts) {
    if (layer !== "ERG" && layer !== "City") {
      updatedLayersToPrompts[layer] = layersToPrompts[layer];
    }
  }
  // compiling all erg-based layers
  ergs.forEach((erg) => {
    if (erg in layersToPrompts) {
      updatedLayersToPrompts[erg] = layersToPrompts["ERG"].concat(
        layersToPrompts[erg]
      );
    } else {
      updatedLayersToPrompts[erg] = layersToPrompts["ERG"];
    }
  });
  // compiling all city-based layers
  cities.forEach((city) => {
    if (city in layersToPrompts) {
      updatedLayersToPrompts[city] = layersToPrompts["City"].concat(
        layersToPrompts[city]
      );
    } else {
      updatedLayersToPrompts[city] = layersToPrompts["City"];
    }
  });
  const layers = Object.keys(updatedLayersToPrompts);

  const handleLayerToggle = (layer) => {
    if (userLayers.includes(layer)) {
      setUserLayers(userLayers.filter((item) => item !== layer));
    } else {
      setUserLayers([...userLayers, layer]);
    }
  };

  const isSelected = (layer) => {
    return userLayers.includes(layer) ? "contained" : "outlined";
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Typography sx={{ fontSize: 20 }}>
        Layers are locations where you can discuss different topics.
      </Typography>
      <Box sx={{ display: "flex", flexWrap: "wrap", width: "70%" }}>
        {layers.map(
          (layer) =>
            layer !== "All" && (
              <Button
                sx={{
                  margin: 0.5,
                  textTransform: "none",
                  borderRadius: "12px",
                  fontWeight: 400,
                  fontSize: 15,
                  paddingX: 2,
                  paddingY: 0.5,
                }}
                key={layer}
                variant={isSelected(layer)}
                onClick={() => handleLayerToggle(layer)}
              >
                {layer}
              </Button>
            )
        )}
      </Box>

      {/* <Typography sx={{ color: "#C4C4C4" }}>
        select at least 1 to continue
      </Typography> */}
    </Box>
  );
};
export default ChooseYourLayers;
