import React from 'react';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import {useLocation} from 'react-router-dom';




function Card({title,subtitle,description,roletype,positionhierarchy }) {
    const navigate = useNavigate();
    const location = useLocation();
    return (
        <Button className={"MyCustomButton"}  onClick={() => navigate("/JobDetails", {state: location.state})}>
        <div id="job_card">
        <div id="titlediv">
        <div id="JCLOGO">
            {/* {props.img} */}
            {/* <img src="https://www.freepnglogos.com/uploads/google-logo-png/google-logo-png-suite-everything-you-need-know-about-google-newest-0.png" width="68" alt="google logo png suite everything you need know about google newest"/> */}
        </div>   
        { <h1 id="title">{title}</h1>}
        {/* <Button> */}
        <BookmarkBorderIcon id="savebookmark"/>
        {/* </Button> */}
        </div>
        <h2 id="company">{subtitle}</h2>
        <h3 id="experience">{description}</h3>
                <hr id="hr_job" />
                
        {roletype && <div id="jobcard_roletypes">
            <h3 id="typeOfRole">{roletype}</h3>
            <h3>{positionhierarchy}</h3>
        </div>}
      </div>
      </Button>
    )
}
export default Card;