import React from "react";
import {
    Avatar,
    Box,
    Button,
    Divider,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import lastImageBGLP from "../../Assets/lastImageBGLP.png";
import stratoswhite from "../../Assets/stratoswhite.svg";
import "./LastComponent.css"; // Import the CSS file
import Footer from "./Footer";

const LastComponent = ({ isMobile }) => {
    return !isMobile ? (
        <div className="container">
            <img src={lastImageBGLP} alt="Background Image" className="image" />
            <div className="content">
                <Typography
                    variant="h3"
                    sx={{
                        textAlign: "center",
                        letterSpacing: "-0.02rem",
                        color: "#FFF",
                        fontWeight: "700",
                        width: "80%",
                    }}
                >
                    Be the first to know what’s new within our Stratos
                    community!
                </Typography>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-start",
                        padding: 2,
                        width: "50%",
                        margin: 1,
                        marginBottom: 4,
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            width: "80%",
                        }}
                    >
                        <TextField
                            placeholder="Enter your email"
                            variant="outlined"
                            required
                            fullWidth
                            InputProps={{
                                style: {
                                    fontSize: "15px",
                                },
                                inputProps: {
                                    style: {
                                        color: "gray",
                                        fontSize: "15px",
                                    },
                                },
                            }}
                            sx={{
                                margin: 1,
                                background: "#FFF",
                                border: "1px solid #D0D5DD",
                                boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
                                borderRadius: "8px",
                                marginBottom: 0,
                            }}
                        />
                        <Typography
                            variant="caption"
                            sx={{ margin: 1, marginTop: 0.5 }}
                        >
                            We care about your data in our privacy policy
                        </Typography>
                    </Box>
                    <Button
                        sx={{
                            color: "#FFFFFF",
                            background:
                                "linear-gradient(96.34deg, #52B1BB, #008997 100%)",
                            borderRadius: "8px",
                            paddingY: 1.5,
                            paddingX: 3,
                            fontSize: "18px",
                            cursor: "pointer",
                            border: "none",
                            textTransform: "none",
                            marginX: 3,
                            marginTop: 1,
                        }}
                        variant="contained"
                    >
                        Subscribe
                    </Button>
                </Box>
                <Footer />
                <Divider
                    orientation="horizontal"
                    sx={{
                        backgroundColor: "white",
                    }}
                    flexItem
                    variant="fullWidth"
                />
                <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    sx={{ margin: 1, width: "100%" }}
                >
                    <Avatar
                        src={stratoswhite}
                        sx={{
                            display: "flex",
                            borderRadius: 0,
                            bgcolor: "transparent",
                        }}
                        imgProps={{
                            style: { objectFit: "contain" },
                        }}
                    />
                    <Typography variant="body1">
                        © 2023 Stratos. All rights reserved.
                    </Typography>
                </Stack>
            </div>
        </div>
    ) : (
        <div className="mobile-container">
            <img
                src={lastImageBGLP}
                alt="Background Image"
                className="mobile-image"
            />
            <div className="mobile-content">
                <Typography
                    variant="body1"
                    sx={{
                        textAlign: "center",
                        letterSpacing: "-0.02rem",
                        color: "#FFF",
                        fontWeight: "700",
                        width: "80%",
                    }}
                >
                    Be the first to know what’s new within our Stratos
                    community!
                </Typography>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-start",
                        padding: 2,
                        width: "100%",
                        margin: 1,
                        paddingBottom: 8,
                        marginBottom: 0,
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            width: "80%",
                        }}
                    >
                        <TextField
                            placeholder="Enter your email"
                            variant="outlined"
                            required
                            fullWidth
                            InputProps={{
                                style: {
                                    fontSize: "12px",
                                },
                                inputProps: {
                                    style: {
                                        color: "gray",
                                        fontSize: "12px",
                                    },
                                },
                            }}
                            sx={{
                                margin: 1,
                                background: "#FFF",
                                border: "1px solid #D0D5DD",
                                boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
                                borderRadius: "8px",
                                marginBottom: 0,
                            }}
                        />
                        <Typography
                            variant="caption"
                            sx={{ margin: 1, marginTop: 0.5 }}
                        >
                            We care about your data in our privacy policy
                        </Typography>
                    </Box>
                    <Button
                        sx={{
                            color: "#FFFFFF",
                            background:
                                "linear-gradient(96.34deg, #52B1BB, #008997 100%)",
                            borderRadius: "8px",
                            paddingY: 1.5,
                            paddingX: 3,
                            fontSize: "18px",
                            cursor: "pointer",
                            border: "none",
                            textTransform: "none",
                            marginX: 3,
                            marginTop: 1,
                        }}
                        variant="contained"
                    >
                        Subscribe
                    </Button>
                </Box>
                <Footer />
                <Divider
                    orientation="horizontal"
                    sx={{
                        backgroundColor: "white",
                    }}
                    flexItem
                    variant="fullWidth"
                />
                <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    alignItems={"flex-start"}
                    sx={{ margin: 1, width: "100%" }}
                >
                    <Avatar
                        src={stratoswhite}
                        sx={{
                            display: "flex",
                            borderRadius: 0,
                            bgcolor: "transparent",
                            marginBottom: 0,
                            paddingTop: 1.5,
                        }}
                        imgProps={{
                            style: { objectFit: "contain" },
                        }}
                    />
                    <Typography variant="body1">
                        © 2023 Stratos. All rights reserved.
                    </Typography>
                </Stack>
            </div>
        </div>
    );
};

export default LastComponent;
