import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { resetAll as resetMessages } from "../messages/messageSlice";
import { resetAll as resetNotifications } from "../notifications/notificationsSlice";
import { resetEverthing as resetPosts } from "../posts/postSlice";
import { resetAll as resetReports } from "../report/reportSlice";
import { resetAll as resetUserProfile } from "../userprofile/userProfileSlice";
import authService from "./authService";
//get user from local storage
const user = JSON.parse(localStorage.getItem("user"));
const token = JSON.parse(localStorage.getItem("token"));
console.log("user", user);
console.log("token", token);
// localStorage.removeItem("user");
// localStorage.removeItem("token");

const initialState = {
  user: user ? user : null,
  token: token ? token : null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
  refferedUsersCount: 0,
  editProfileImageLoading: false,
  users: [],
};

export const signInWithGoogle = createAsyncThunk(
  "auth/signInWithGoogle",
  async (data, thunkAPI) => {
    try {
      console.log("data.googleToken", data.googleToken);
      return await authService.signInWithGoogle(data.googleToken, data.refCode);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//register user
export const register = createAsyncThunk(
  "auth/register",
  async (data, thunkAPI) => {
    try {
      return await authService.register(data.user, data.refCode);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const backdoor = createAsyncThunk(
  "auth/backdoor",
  async (data, thunkAPI) => {
    try {
      return await authService.backdoor(data.user);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//update user

export const updateUserLayer = createAsyncThunk(
  "auth/updateUserLayer",
  async (userLayers, thunkAPI) => {
    // const usertoken = JSON.parse(localStorage.getItem("token"));

    try {
      const user_token = thunkAPI.getState().auth.token;
      const response = await authService.updateUserLayer(
        { userLayers },
        user_token
      );
      return response;
    } catch (error) {
      const message =
        error.response && error.response.data && error.response.data.error
          ? error.response.data.error
          : "";
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// update user interests

export const updateUserInterests = createAsyncThunk(
  "auth/updateUserInterests",
  async (userInterests, thunkAPI) => {
    // const usertoken = JSON.parse(localStorage.getItem("token"));

    try {
      const user_token = thunkAPI.getState().auth.token;
      const response = await authService.updateUserInterests(
        { userInterests },
        user_token
      );
      return response;
    } catch (error) {
      const message =
        error.response && error.response.data && error.response.data.error
          ? error.response.data.error
          : "";
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// update user ergs

export const updateUserErgs = createAsyncThunk(
  "auth/updateUserErgs",
  async (userErgs, thunkAPI) => {
    // const usertoken = JSON.parse(localStorage.getItem("token"));

    try {
      const user_token = thunkAPI.getState().auth.token;
      const response = await authService.updateUserErgs(
        { userErgs },
        user_token
      );
      return response;
    } catch (error) {
      const message =
        error.response && error.response.data && error.response.data.error
          ? error.response.data.error
          : "";
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//update account details

export const updateAccountDetails = createAsyncThunk(
  "auth/updateAccountDetails",
  async (userDetails, thunkAPI) => {
    try {
      //const user_token = thunkAPI.getState().auth.token;
      const response = await authService.updateAccountDetails({
        userDetails,
        token,
      });
      return response;
    } catch (error) {
      const message =
        error.response && error.response.data && error.response.data.error
          ? error.response.data.error
          : "";
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//update user

export const updateUserPersonal = createAsyncThunk(
  "auth/updateUserPersonal",
  async (userDetails, thunkAPI) => {
    try {
      const user_token = thunkAPI.getState().auth.token;
      const response = await authService.updateUserPersonal(
        userDetails,
        user_token
      );

      return response.userDetails;
    } catch (error) {
      const message =
        error.response && error.response.data && error.response.data.error
          ? error.response.data.error
          : "";
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// upload user profilePic
export const uploadImage = createAsyncThunk(
  "auth/uploadImage",
  async (formData, thunkAPI) => {
    try {
      const user_token = thunkAPI.getState().auth.token;
      console.log("user_token", user_token);
      const response = await authService.uploadImage(formData, user_token);
      return response;
    } catch (error) {
      const message =
        error.response && error.response.data && error.response.data.error
          ? error.response.data.error
          : "";
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// remove user profilepic
export const removeImage = createAsyncThunk(
  "auth/removeImage",
  async (_, thunkAPI) => {
    try {
      const user_token = thunkAPI.getState().auth.token;

      const response = await authService.removeImage({ user_token });
      return response;
    } catch (error) {
      const message =
        error.response && error.response.data && error.response.data.error
          ? error.response.data.error
          : "";
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//get user
export const getUser = createAsyncThunk("auth/getUser", async (_, thunkAPI) => {
  try {
    //const user_token = thunkAPI.getState().auth.token;
    const response = await authService.getUser({
      token,
    });
    return response;
  } catch (error) {
    const message =
      error.response && error.response.data && error.response.data.error
        ? error.response.data.error
        : "";
    return thunkAPI.rejectWithValue(message);
  }
});

//check user is valid
export const checkUser = createAsyncThunk(
  "auth/checkUser",
  async (email, thunkAPI) => {
    try {
      const response = await authService.checkUser(email);
      return response;
    } catch (error) {
      const message =
        error.response && error.response.data && error.response.data.error
          ? error.response.data.error
          : "";
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// send password reset email
export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async (email, thunkAPI) => {
    try {
      const response = await authService.resetPassword(email);
      return response;
    } catch (error) {
      const message =
        error.response && error.response.data && error.response.data.error
          ? error.response.data.error
          : "";
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const logout = createAsyncThunk("auth/logout", async (_, thunkAPI) => {
  await authService.logout();
  thunkAPI.dispatch(resetMessages());
  thunkAPI.dispatch(resetNotifications());
  thunkAPI.dispatch(resetPosts());
  thunkAPI.dispatch(resetReports());
  thunkAPI.dispatch(resetUserProfile());
});

//login user
export const login = createAsyncThunk("auth/login", async (user, thunkAPI) => {
  try {
    return await authService.login(user);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const getRefferedUsersCount = createAsyncThunk(
  "auth/getRefferedUsersCount",
  async (_, thunkAPI) => {
    try {
      console.log("token", token);
      return await authService.getRefferedUsersCount({ token });
    } catch (error) {
      const message =
        error.response && error.response.data && error.response.data.error
          ? error.response.data.error
          : "";
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getUsers = createAsyncThunk(
  "auth/getUsers",
  async (filters, thunkAPI) => {
    try {
      // const token = thunkAPI.getState().auth.token;
      const response = await authService.getUsers({
        filters,
        token,
      });

      return response;
    } catch (error) {
      const message =
        error.response && error.response.data && error.response.data.error
          ? error.response.data.error
          : "";
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getMatches = createAsyncThunk(
  "auth/getMatches",
  async (_, thunkAPI) => {
    const usertoken = JSON.parse(localStorage.getItem("token"));
    try {
      console.log("token!", token);
      return await authService.getMatches({ token });
    } catch (error) {
      const message =
        error.response && error.response.data && error.response.data.error
          ? error.response.data.error
          : "";
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const setIsNewUserFalse = createAsyncThunk(
  "auth/setIsNewUserFalse",
  async (_, thunkAPI) => {
    try {
      return await authService.setIsNewUserFalse({ token });
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isLoading = false;
      state.message = "";
    },
    resetUser: (state) => {
      state.user = null;
      state.token = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signInWithGoogle.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(signInWithGoogle.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.user = action.payload.user;
        state.token = action.payload.token;
      })
      .addCase(signInWithGoogle.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
        state.user = null;
      })
      .addCase(setIsNewUserFalse.fulfilled, (state, action) => {
        state.user.isNewUser = false;
      })
      .addCase(setIsNewUserFalse.rejected, (state, action) => {
        state.user.isNewUser = true;
      })
      .addCase(register.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(register.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.user = action.payload.user;
        state.token = action.payload.token;
      })
      .addCase(register.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.user = null;
      })
      .addCase(logout.fulfilled, (state) => {
        state.user = null;
        state.token = null;
        state.message = "";
      })
      .addCase(updateUserPersonal.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateUserPersonal.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        let tempUser = action.payload;
        const temp = { ...state.user, ...tempUser };
        state.user = temp;
        localStorage.setItem("user", JSON.stringify(temp));
      })
      .addCase(updateUserPersonal.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(uploadImage.pending, (state) => {})
      .addCase(uploadImage.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.user.profileImage.url = `${
          action.payload.profileImage.url
        }#timestamp=${Date.now()}`;
        state.user.profileImage.flag = action.payload.profileImage.flag;
      })
      .addCase(uploadImage.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(removeImage.pending, (state) => {
        state.editProfileImageLoading = true;
      })
      .addCase(removeImage.fulfilled, (state, action) => {
        state.editProfileImageLoading = false;
        state.isSuccess = true;
        state.user.profileImage.url = action.payload.profileImage.url;
        state.user.profileImage.flag = action.payload.profileImage.flag;
      })
      .addCase(removeImage.rejected, (state, action) => {
        state.editProfileImageLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateAccountDetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateAccountDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.user.email = action.payload.email;
      })
      .addCase(updateAccountDetails.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.user = action.payload.user;
      })
      .addCase(getUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(checkUser.pending, (state) => {
        // state.isLoading = true;
      })
      .addCase(checkUser.fulfilled, (state, action) => {
        // state.isLoading = false;
        // state.isSuccess = true;
        state.user = action.payload.user;
      })
      .addCase(checkUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(resetPassword.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(backdoor.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(backdoor.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.user = action.payload.user;
        state.token = action.payload.token;
      })
      .addCase(backdoor.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.user = null;
      })

      .addCase(login.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.user = action.payload.user;
        state.token = action.payload.token;
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.user = null;
      })
      .addCase(updateUserLayer.pending, (state) => {})
      .addCase(updateUserLayer.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.user.layers = action.payload.layers;
      })
      .addCase(updateUserLayer.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateUserInterests.pending, (state) => {})
      .addCase(updateUserInterests.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.user.interests = action.payload.interests;
      })
      .addCase(updateUserInterests.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateUserErgs.pending, (state) => {})
      .addCase(updateUserErgs.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.user.ergs = action.payload.ergs;
      })
      .addCase(updateUserErgs.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getRefferedUsersCount.pending, (state) => {})
      .addCase(getRefferedUsersCount.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.isLoading = false;
        state.user.refferedUsersCount = action.payload.refferedUsersCount;
      })
      .addCase(getRefferedUsersCount.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getUsers.pending, (state) => {})
      .addCase(getUsers.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.isLoading = false;
        state.users = action.payload.users;
      })
      .addCase(getUsers.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getMatches.pending, (state) => {})
      .addCase(getMatches.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.isLoading = false;
        state.matches = action.payload.matches;
      })
      .addCase(getMatches.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});
export const { reset, resetUser } = authSlice.actions;
export default authSlice.reducer;
