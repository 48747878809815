import {
  Avatar,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  alpha,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
// [STRAT-38] - add university logos?
import { degreeIllustration } from "../../../Assets/utilFunctions";

import { CloseOutlined } from "@mui/icons-material";
import dayjs from "dayjs";

const AddEducation = ({ sch, onSchoolFieldChange, index, removeEducation }) => {
  const [school, setSchoolName] = useState(sch?.school);
  const [fieldOfStudy, setFieldOfStudy] = useState(sch?.fieldOfStudy);
  const [degree, setDegree] = useState(sch?.degree);

  const [startDate, setStartDate] = useState(
    sch?.startDate === ""
      ? new Date()
      : new Date(`${sch?.startDate.month} 2, ${sch?.startDate.year}`) // hacky to get date to work in Safari
  );

  const [presentlyStudying, setPresentlyStudying] = useState(
    sch?.endDate === "present" ? true : false
  );

  const [endDate, setEndDate] = useState(
    sch?.endDate === "present" || sch.endDate === ""
      ? new Date()
      : new Date(`${sch?.endDate.month} 2, ${sch?.endDate.year}`)
  );

  const handlePresentChange = () => {
    setPresentlyStudying(!presentlyStudying);
  };

  // change the end date based on presently working
  useEffect(() => {
    if (presentlyStudying) {
      onSchoolFieldChange("endDate", "present");
    }
    if (!presentlyStudying) {
      const month = dayjs(endDate).format("MMMM");
      const year = dayjs(endDate).format("YYYY");
      onSchoolFieldChange("endDate", {
        month,
        year,
      });
    }
  }, [presentlyStudying]);

  // handles changes to each field
  const handleSchoolFieldChange = (fieldName) => (e) => {
    const newValue = e.target.value;
    onSchoolFieldChange(fieldName, newValue);
  };

  // layout for textfield
  const sxForTextField = {
    width: "100%",
    "& .MuiFilledInput-root": {
      overflow: "hidden",
      borderRadius: "7px",
      backgroundColor: "#F3F9FB",
      border: "1px solid",
      borderColor: "#D2E1E2",
      transition: "border-color background-color box-shadow",
      "&:hover": {
        backgroundColor: "#F3F9FB",
      },
      "&.Mui-focused": {
        backgroundColor: "#F3F9FB",
        boxShadow: `${alpha("#000", 0.25)} 0 0 0 2px`,
        borderColor: "#D2E1E2",
      },
    },
  };
  const inputPropsForTextField = {
    style: {
      fontSize: 16,
      fontWeight: 300,
      color: "#2A2E33",
      paddingLeft: 12,
      marginLeft: 0,
    },
  };
  const inputLabelPropsForTextfield = {
    style: {
      fontWeight: 400,
      fontSize: 12,
      color: "#16171B",
    },
  };

  return (
    <Stack
      direction={"row"}
      alignItems={"flex-start"}
      justifyContent={"center"}
      sx={{ m: 1 }}
    >
      <Avatar
        src={degreeIllustration(degree)} // [STRAT-38] - add university logos?
        sx={{
          width: "40px",
          height: "40px",
          margin: 1,
          borderRadius: 0,
        }}
      />
      <Stack
        direction={"column"}
        alignItems={"flex-start"}
        justifyContent={"center"}
        spacing={1}
      >
        <TextField
          name="school"
          onChange={(e) => {
            setSchoolName(e.target.value);
            onSchoolFieldChange("school", e.target.value);
          }}
          size="small"
          value={school}
          label="School"
          id="school"
          required
          error={school.trim() === ""}
          helperText={school.trim() === "" ? "school is required" : ""}
          variant="filled"
          InputLabelProps={inputLabelPropsForTextfield}
          InputProps={{
            disableUnderline: true,
            classes: {
              root: "MuiFilledInput-root",
              focused: "Mui-focused",
            },
          }}
          inputProps={inputPropsForTextField}
          sx={sxForTextField}
        />

        <TextField
          name="fieldOfStudy"
          onChange={(e) => {
            setFieldOfStudy(e.target.value);
            onSchoolFieldChange("fieldOfStudy", e.target.value);
          }}
          size="small"
          value={fieldOfStudy}
          label="Field Of Study"
          id="fieldOfStudy"
          required
          error={fieldOfStudy.trim() === ""}
          helperText={
            fieldOfStudy.trim() === "" ? "field of study is required" : ""
          }
          variant="filled"
          InputLabelProps={inputLabelPropsForTextfield}
          InputProps={{
            disableUnderline: true,
            classes: {
              root: "MuiFilledInput-root",
              focused: "Mui-focused",
            },
          }}
          inputProps={inputPropsForTextField}
          sx={{ ...sxForTextField, width: "100%" }}
        />

        <FormControl
          variant="filled"
          sx={{ ...sxForTextField, width: "100%" }}
          size="small"
          required
        >
          <InputLabel htmlFor="degree" {...inputLabelPropsForTextfield}>
            Degree
          </InputLabel>
          <Select
            value={degree}
            onChange={(e) => {
              setDegree(e.target.value);
              onSchoolFieldChange("degree", e.target.value);
            }}
            displayEmpty
            inputProps={{
              disableUnderline: true,
              classes: {
                root: "MuiFilledInput-root",
                focused: "Mui-focused",
              },
            }}
            {...inputPropsForTextField}
          >
            <MenuItem value="Associate's">Associate's</MenuItem>
            <MenuItem value="Bachelor's">Bachelor's</MenuItem>
            <MenuItem value="Doctorate's">Doctorate's</MenuItem>
            <MenuItem value="Graduate's">Graduate's</MenuItem>
          </Select>
        </FormControl>

        <FormControlLabel
          control={
            <Checkbox
              onChange={handlePresentChange}
              checked={presentlyStudying}
            />
          }
          label="I am currently studying here."
        />

        <Stack
          direction={"row"}
          alignItems={"flex-start"}
          justifyContent={"center"}
          spacing={1}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Start Date"
              value={startDate}
              views={["year", "month"]}
              onChange={(val) => {
                setStartDate(val);
                const month = dayjs(val).format("MMMM");
                const year = dayjs(val).format("YYYY");
                onSchoolFieldChange("startDate", {
                  month,
                  year,
                });
              }}
              renderInput={(props) => (
                <TextField
                  required
                  variant="filled"
                  InputLabelProps={inputLabelPropsForTextfield}
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      root: "MuiFilledInput-root",
                      focused: "Mui-focused",
                    },
                  }}
                  inputProps={inputPropsForTextField}
                  sx={sxForTextField}
                  {...props}
                />
              )}
            />

            <DatePicker
              label="End Date"
              value={endDate}
              onChange={(val) => {
                setEndDate(val);
                const month = dayjs(val).format("MMMM");
                const year = dayjs(val).format("YYYY");
                onSchoolFieldChange("endDate", {
                  month,
                  year,
                });
              }}
              minDate={startDate}
              views={["year", "month"]}
              disabled={presentlyStudying}
              renderInput={(props) => (
                <TextField
                  required
                  variant="filled"
                  InputLabelProps={inputLabelPropsForTextfield}
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      root: "MuiFilledInput-root",
                      focused: "Mui-focused",
                    },
                  }}
                  inputProps={inputPropsForTextField}
                  sx={sxForTextField}
                  {...props}
                />
              )}
            />
          </LocalizationProvider>
        </Stack>
      </Stack>
      <IconButton color="#16171B" onClick={() => removeEducation(index)}>
        <CloseOutlined />{" "}
      </IconButton>
    </Stack>
  );
};

export default AddEducation;
