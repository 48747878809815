import React from 'react'
import './MobileMessage.css'

import StratosLogo from '../Assets/logo.png'
import { Box } from '@mui/system'

const MobileMessage = () =>
{
   

  return (
    <div className='mobile-container'>
        <img src={StratosLogo} alt="Logo"  />
          <Box className='mobile-container_text'>
          <h1>We're in Beta and our platform is best used on desktop</h1>
          </Box>
    </div>
  )
}

export default MobileMessage