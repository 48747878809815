import { ArrowForward } from "@mui/icons-material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Grow,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import GridLayout from "react-grid-layout";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useNavigate } from "react-router-dom";
import AmazonLogo from "../../Assets/Partners/amazon.png";
import GoogleLogo from "../../Assets/Partners/google1.png";
import PatreonLogo from "../../Assets/Partners/patreon1.png";
import SageLogo from "../../Assets/Partners/sage1.png";
import XactlyLogo from "../../Assets/Partners/xactly1.png";
import Events from "../../Assets/event_manage.svg";
import Knowledge from "../../Assets/knowledge.svg";
import KnowledgeSharing from "../../Assets/knowledge_sharing_2.png";
import Members from "../../Assets/member_engagement.svg";
import Brain from "../../Assets/brain.svg";
import DoorClosed from "../../Assets/door-closed-blue.svg";
import DoorOpen from "../../Assets/door-open-blue.svg";
// import Resources from "../../Assets/resource_central.svg";
// Company Logos
import apple_logo from "../../Assets/apple_logo_grey.png";
import asana_logo from "../../Assets/asana_logo.png";
import salesforce_logo from "../../Assets/salesforce_logo.png";
import snap_logo from "../../Assets/snap_logo.svg";
import stealth_logo from "../../Assets/stealth-startup-logo.png";
import nike_logo from "../../Assets/nike.png";
import youtube_logo from "../../Assets/youtube.png";
import google_logo from "../../Assets/google.png";
import apple_square from "../../Assets/logos/apple_square.png";
import google_square from "../../Assets/logos/google_square.png";
import xactly_square from "../../Assets/logos/xactly_square.png";
import asana_square from "../../Assets/logos/asana_square.png";
import patreon_square from "../../Assets/logos/patreon_square.png";
import microsoft_square from "../../Assets/logos/microsoft_square.png";
import salesforce_square from "../../Assets/logos/salesforce_square.png";
import nike_square from "../../Assets/logos/nike_square.png";
import youtube_square from "../../Assets/logos/youtube_square.png";
import pinterest_square from "../../Assets/logos/pinterest_square.png";
import yext_square from "../../Assets/logos/yext_square.png";
import meta_square from "../../Assets/logos/meta_square.png";
import chewy_square from "../../Assets/logos/chewy_square.png";
import nvidia_square from "../../Assets/logos/nvidia_square.png";
import amazon_square from "../../Assets/logos/amazon_square.png";
import instagram_square from "../../Assets/logos/instagram_square.png";
import wayfair_square from "../../Assets/logos/wayfair_square.png";
import airbnb_square from "../../Assets/logos/airbnb_square.png";
import palantir_square from "../../Assets/logos/palantir_square.png";
import instacart_square from "../../Assets/logos/instacart_square.png";
import oracle_square from "../../Assets/logos/oracle_square.png";
import scale_square from "../../Assets/logos/scale_square.png";
import lyft_square from "../../Assets/logos/lyft_square.png";

// import SkyBackground from "../../Assets/SkyBackground3.png";

import "./Homepage2.css";

const Homepage = () => {
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const openPopup = Boolean(anchorEl);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 7,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 4,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 3,
    },
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          paddingX: { xs: 1, sm: 1, md: 3 },
          // background: "#FFFFFF", //"linear-gradient( #FFFFFF, #52B1BB)",
          // backgroundImage: url("../../Assets/SkyBackground3.png"),
          paddingBottom: 2,
          paddingTop: 10,
        }}
      >
        <Grow in {...{ timeout: 2000 }}>
          <Stack direction="row" spacing={2} justifyContent="flex-start">
            {/* Lefthand Side */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                paddingTop: 1,
                m: 1,
                // width: "100%",
              }}
            >
              <Typography
                sx={{
                  fontSize: "65px",
                  lineHeight: 1,
                  fontFamily: "Wix Madefor Text",
                  color: "#333333",
                  maxWidth: "900px",
                  p: 2,
                  fontWeight: "800",
                }}
              >
                The future of networking
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  fontFamily: "Wix Madefor Text",
                  fontWeight: "500",
                  padding: 2,
                  paddingLeft: 0,
                  paddingBottom: 4,
                  color: "#333333",
                }}
              >
                Elevating those already at the top;
                <br />
                We are the gateway to what's next.
              </Typography>

              <Button
                sx={{
                  color: "#FFFFFF",
                  background:
                    "linear-gradient(96.34deg, #52B1BB, #008997 100%)",
                  borderRadius: "32px",
                  fontSize: "15px",
                  height: "40px",
                  cursor: "pointer",
                  border: "none",
                  textTransform: "none",
                  marginBottom: 2,
                }}
                variant="contained"
                onClick={() => navigate("/SignUp")}
              >
                <span style={{ verticalAlign: "middle" }}>Sign Up</span>
                <ArrowForward
                  style={{
                    verticalAlign: "middle",
                    paddingLeft: 0.5,
                  }}
                />
              </Button>

              <Typography
                variant="h6"
                sx={{
                  fontFamily: "Wix Madefor Text",
                  fontWeight: "500",
                  fontSize: "18px",
                  padding: 2,
                  paddingLeft: 0,
                  paddingBottom: 4,
                  color: "#333333",
                }}
              >
                Matching coming April 2024.
              </Typography>

              {/* <div
                className="backdoor-block"
                onMouseOver={() => setOpen(true)}
                onMouseOut={() => setOpen(false)}
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/Request")}
              >
                <img src={open ? DoorOpen : DoorClosed} width={"50 px"} />
              </div> */}
            </Box>

            {/* Righthand Side */}
            {/* <Box
          sx={{
            display: { xs: "flex", sm: "flex", md: "none" },
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            padding: 1,
          }}
        ></Box> */}

            <Box
              sx={{
                display: { xs: "none", sm: "flex", md: "flex" },
                flexDirection: "column",
                width: "60%",
                // justifyContent: "center",
                // alignItems: "center",
                // textAlign: "center",
                // paddingTop: 1,
                m: 1,
                // width: "100%",
              }}
            >
              <Carousel
                draggable={false}
                arrows={false}
                className="gd-carousel"
                responsive={responsive}
                //   ssr={true} // means to render carousel on server-side.
                infinite={true}
                autoPlay={true}
                autoPlaySpeed={6000}
                containerClass="carousel-container"
                //   removeArrowOnDeviceType={["tablet", "mobile"]}
                itemClass="carousel-item-padding-40-px"
                buttonColor="#52B1BB"
                slidesToSlide={1}
                //   backgroundColor="#52B1BB"
              >
                <img
                  style={{
                    display: "block",
                    height: "100px",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                  src={apple_square}
                />
                <img
                  style={{
                    display: "block",
                    height: "100px",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                  src={snap_logo}
                />
                <img
                  style={{
                    display: "block",
                    height: "100px",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                  src={google_square}
                />
                <img
                  style={{
                    display: "block",
                    height: "100px",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                  src={nike_square}
                />
                <img
                  style={{
                    display: "block",
                    height: "100px",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                  src={asana_square}
                />
              </Carousel>

              <Carousel
                draggable={false}
                arrows={false}
                className="gd-carousel"
                responsive={responsive}
                //   ssr={true} // means to render carousel on server-side.
                infinite={true}
                autoPlay={true}
                autoPlaySpeed={3600}
                rtl={true}
                containerClass="carousel-container"
                //   removeArrowOnDeviceType={["tablet", "mobile"]}
                itemClass="carousel-item-padding-40-px"
                buttonColor="#52B1BB"
                //   backgroundColor="#52B1BB"
              >
                <img
                  style={{
                    display: "block",
                    height: "100px",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                  src={salesforce_square}
                />
                <img
                  style={{
                    display: "block",
                    height: "100px",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                  src={microsoft_square}
                />
                <img
                  style={{
                    display: "block",
                    height: "75px",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                  src={stealth_logo}
                />
                <img
                  style={{
                    display: "block",
                    height: "100px",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                  src={youtube_square}
                />
                <img
                  style={{
                    display: "block",
                    height: "100px",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                  src={xactly_square}
                />
              </Carousel>

              <Carousel
                draggable={false}
                arrows={false}
                className="gd-carousel"
                responsive={responsive}
                //   ssr={true} // means to render carousel on server-side.
                infinite={true}
                autoPlay={true}
                autoPlaySpeed={4500}
                containerClass="carousel-container"
                //   removeArrowOnDeviceType={["tablet", "mobile"]}
                itemClass="carousel-item-padding-40-px"
                buttonColor="#52B1BB"
                //   backgroundColor="#52B1BB"
              >
                <img
                  style={{
                    display: "block",
                    height: "100px",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                  src={pinterest_square}
                />
                <img
                  style={{
                    display: "block",
                    height: "100px",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                  src={nvidia_square}
                />
                <img
                  style={{
                    display: "block",
                    height: "100px",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                  src={meta_square}
                />
                <img
                  style={{
                    display: "block",
                    height: "100px",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                  src={yext_square}
                />
                <img
                  style={{
                    display: "block",
                    height: "100px",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                  src={amazon_square}
                />
              </Carousel>

              <Carousel
                draggable={false}
                arrows={false}
                className="gd-carousel"
                responsive={responsive}
                //   ssr={true} // means to render carousel on server-side.
                infinite={true}
                autoPlay={true}
                autoPlaySpeed={5050}
                containerClass="carousel-container"
                //   removeArrowOnDeviceType={["tablet", "mobile"]}
                itemClass="carousel-item-padding-40-px"
                buttonColor="#52B1BB"
                //   backgroundColor="#52B1BB"
              >
                <img
                  style={{
                    display: "block",
                    height: "100px",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                  src={chewy_square}
                />
                <img
                  style={{
                    display: "block",
                    height: "100px",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                  src={wayfair_square}
                />
                <img
                  style={{
                    display: "block",
                    height: "100px",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                  src={airbnb_square}
                />
                <img
                  style={{
                    display: "block",
                    height: "100px",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                  src={instagram_square}
                />
                <img
                  style={{
                    display: "block",
                    height: "100px",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                  src={patreon_square}
                />
              </Carousel>

              <Carousel
                draggable={false}
                arrows={false}
                className="gd-carousel"
                responsive={responsive}
                //   ssr={true} // means to render carousel on server-side.
                infinite={true}
                autoPlay={true}
                autoPlaySpeed={3250}
                containerClass="carousel-container"
                //   removeArrowOnDeviceType={["tablet", "mobile"]}
                itemClass="carousel-item-padding-40-px"
                buttonColor="#52B1BB"
                //   backgroundColor="#52B1BB"
                rtl={true}
              >
                <img
                  style={{
                    display: "block",
                    height: "100px",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                  src={palantir_square}
                />
                <img
                  style={{
                    display: "block",
                    height: "100px",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                  src={oracle_square}
                />
                <img
                  style={{
                    display: "block",
                    height: "100px",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                  src={lyft_square}
                />
                <img
                  style={{
                    display: "block",
                    height: "100px",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                  src={scale_square}
                />
                <img
                  style={{
                    display: "block",
                    height: "100px",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                  src={instacart_square}
                />
              </Carousel>
            </Box>
          </Stack>
        </Grow>

        <Box
          sx={{
            display: { xs: "none", sm: "none", md: "grid" },
            flexDirection: "column",
            alignItems: "center",
            padding: 2,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <Stack
            direction={"column"}
            display={"flex"}
            alignSelf={"center"}
            width={"100%"}
          >
            <Typography
              align="center"
              sx={{
                fontSize: "20px",
                fontFamily: "Wix Madefor Text",
                paddingBottom: 1.5,
                marginTop: "35px",
                color: "#333333",
                fontWeight: "500",
              }}
            >
              Professional networking reimagined.
            </Typography>
            <Typography
              align="center"
              sx={{
                fontSize: "16px",
                fontFamily: "Wix Madefor Text",

                color: "#333333",
                marginBottom: 3,
              }}
            >
              Get matched with top talent from Fortune 500 companies.
            </Typography>
          </Stack>
        </Box>
        <Divider sx={{ marginTop: 4 }} />

        <Typography
          align="center"
          variant="h4"
          sx={{
            fontWeight: "700",
            fontSize: "45px",
            // paddingY: 1,
            marginTop: "75px",
            fontFamily: "Wix Madefor Text",
            color: "#333333",
          }}
        >
          Why Stratos?
        </Typography>
        <Typography
          variant="h6"
          align="center"
          sx={{
            fontFamily: "Wix Madefor Text",
            fontWeight: "500",
            paddingY: 4,
            color: "#333333",
          }}
        >
          Here are some of our key features that will propel your career to the
          next level.
        </Typography>
        <Stack
          direction="row"
          spacing={3}
          justifyContent="flex-start"
          sx={{
            width: "100%",
            paddingY: 2,
            display: { xs: "none", sm: "none", md: "flex" },
          }}
        >
          <Stack
            direction="row"
            spacing={3}
            justifyContent="flex-start"
            sx={{
              width: "50%",
              background: "#FFF", // "linear-gradient(96.34deg, #52B1BB, #008997 100%)",
              borderRadius: "14px",
              borderColor: "#52B1BB",
              border: 1,
              paddingY: 4,
            }}
          >
            <Box
              sx={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                margin: 0,
              }}
            >
              <Avatar
                alt="Curated Networking Matches"
                src={Brain}
                sx={{
                  display: "flex",
                  borderRadius: 0,
                  bgcolor: "transparent",
                  width: "100px",
                  paddingLeft: 3,
                  color: "#333333",
                }}
                imgProps={{
                  style: { objectFit: "contain" },
                }}
              />
            </Box>
            <Box sx={{ paddingY: 3 }}>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "600",
                  fontFamily: "Wix Madefor Text",
                  color: "#333333",
                  paddingY: 1,
                  textAlign: "left",
                }}
              >
                AI Networking
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontWeight: "300",
                  fontFamily: "Wix Madefor Text",
                  color: "#333333",
                  textAlign: "left",
                  paddingY: 1,
                }}
              >
                We offer AI matches with top professionals, like dating but for
                networking
              </Typography>
            </Box>
          </Stack>

          <Stack
            direction="row"
            spacing={3}
            justifyContent="flex-start"
            sx={{
              width: "50%",
              background: "#FFF", // "linear-gradient(96.34deg, #52B1BB, #008997 100%)",
              borderRadius: "14px",
              borderColor: "#52B1BB",
              border: 1,
              paddingY: 4,
            }}
          >
            <Box
              sx={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                margin: 0,
              }}
            >
              <Avatar
                alt="What is stratos"
                src={KnowledgeSharing}
                sx={{
                  display: "flex",
                  borderRadius: 0,
                  bgcolor: "transparent",
                  width: "100px",
                  paddingLeft: 3,
                  color: "#333333",
                }}
                imgProps={{
                  style: { objectFit: "contain" },
                }}
              />
            </Box>
            <Box sx={{ paddingY: 3 }}>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "600",
                  fontFamily: "Wix Madefor Text",
                  color: "#333333",
                  padding: 1,
                  textAlign: "left",
                }}
              >
                Knowledge Sharing
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontWeight: "300",
                  fontFamily: "Wix Madefor Text",
                  color: "#333333",
                  textAlign: "left",
                  padding: 1,
                }}
              >
                Facilitate ongoing improvement by sharing knowledge from the
                best in industry
              </Typography>
            </Box>
          </Stack>
        </Stack>

        <Stack
          direction="row"
          spacing={3}
          justifyContent="flex-start"
          sx={{
            width: "100%",
            paddingY: 2,
            display: { xs: "none", sm: "none", md: "flex" },
          }}
        >
          <Stack
            direction="row"
            spacing={3}
            justifyContent="flex-start"
            sx={{
              width: "50%",
              background: "#FFF", // "linear-gradient(96.34deg, #52B1BB, #008997 100%)",
              borderRadius: "14px",
              borderColor: "#52B1BB",
              border: 1,
              paddingY: 4,
            }}
          >
            <Box
              sx={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                margin: 0,
              }}
            >
              <Avatar
                alt="resource cent"
                src={Knowledge}
                sx={{
                  display: "flex",
                  borderRadius: 0,
                  bgcolor: "transparent",
                  width: "100px",
                  paddingLeft: 3,
                  color: "#333333",
                }}
                imgProps={{
                  style: { objectFit: "contain" },
                }}
              />
            </Box>
            <Box sx={{ paddingY: 3 }}>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "600",
                  fontFamily: "Wix Madefor Text",
                  color: "#333333",
                  padding: 1,
                  textAlign: "left",
                }}
              >
                Idea Centralization
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontWeight: "300",
                  fontFamily: "Wix Madefor Text",
                  color: "#333333",
                  textAlign: "left",
                  padding: 1,
                }}
              >
                We improve productivity with a centralized thought-sharing hub,
                saving time and enhancing accessibility for resources and ideas
              </Typography>
            </Box>
          </Stack>

          <Stack
            direction="row"
            spacing={3}
            justifyContent="flex-start"
            sx={{
              width: "50%",
              background: "#FFF", // "linear-gradient(96.34deg, #52B1BB, #008997 100%)",
              borderRadius: "14px",
              borderColor: "#52B1BB",
              border: 1,
              paddingY: 4,
            }}
          >
            <Box
              sx={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                margin: 0,
              }}
            >
              <Avatar
                alt="User Engagement"
                src={Members}
                sx={{
                  display: "flex",
                  borderRadius: 0,
                  bgcolor: "transparent",
                  width: "100px",
                  paddingLeft: 3,
                  color: "#333333",
                }}
                imgProps={{
                  style: { objectFit: "contain" },
                }}
              />
            </Box>
            <Box sx={{ paddingY: 3 }}>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "600",
                  fontFamily: "Wix Madefor Text",
                  color: "#333333",
                  paddingY: 1,
                  textAlign: "left",
                }}
              >
                User Engagement
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontWeight: "300",
                  fontFamily: "Wix Madefor Text",
                  color: "#333333",
                  textAlign: "left",
                  paddingY: 1,
                }}
              >
                We boost user involvement with features encouraging active
                participation, fostering a connected and productive community
              </Typography>
            </Box>
          </Stack>
        </Stack>

        {/* Mobile Version */}

        <Box
          sx={{
            display: { xs: "flex", sm: "flex", md: "none" },
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            padding: 1,
          }}
        >
          <Stack
            direction="column"
            spacing={1}
            justifyContent="center"
            alignItems={"center"}
            sx={{
              width: "100%",
              // background: "linear-gradient(96.34deg, #52B1BB, #008997 100%)",
              // borderRadius: "10px",
              background: "#FFF", // "linear-gradient(96.34deg, #52B1BB, #008997 100%)",
              borderRadius: "14px",
              borderColor: "#52B1BB",
              border: 1,
              paddingX: 1,
              paddingY: 4,
              margin: 1,
            }}
          >
            <Avatar
              alt="Brain"
              src={Brain}
              sx={{
                display: "flex",
                borderRadius: 0,
                bgcolor: "transparent",
                width: "100px",
              }}
              imgProps={{
                style: {
                  objectFit: "contain",
                },
              }}
            />
            <Typography
              variant="h5"
              sx={{
                fontWeight: "600",
                color: "#16171B",
                padding: 1,
                textAlign: "center",
              }}
            >
              AI Networking
            </Typography>

            <Box sx={{ width: "100%" }}>
              <Typography
                variant="body1"
                sx={{
                  fontWeight: "300",
                  color: "#4B4E58",
                  textAlign: "left",
                  padding: 1,
                }}
              >
                We offer AI matches with top professionals, like dating but for
                networking
              </Typography>
            </Box>
          </Stack>
          <Stack
            direction="column"
            spacing={1}
            justifyContent="center"
            alignItems={"center"}
            sx={{
              width: "100%",
              // background: "linear-gradient(96.34deg, #52B1BB, #008997 100%)",
              // borderRadius: "10px",
              background: "#FFF", // "linear-gradient(96.34deg, #52B1BB, #008997 100%)",
              borderRadius: "14px",
              borderColor: "#52B1BB",
              border: 1,
              paddingX: 1,
              paddingY: 4,
              margin: 1,
            }}
          >
            <Avatar
              alt="KnowledgeSharing"
              src={KnowledgeSharing}
              sx={{
                display: "flex",
                borderRadius: 0,
                bgcolor: "transparent",
                width: "100px",
              }}
              imgProps={{
                style: {
                  objectFit: "contain",
                },
              }}
            />
            <Typography
              variant="h5"
              sx={{
                fontWeight: "600",
                color: "#16171B",
                padding: 1,
                textAlign: "center",
              }}
            >
              Knowledge Sharing
            </Typography>

            <Box sx={{ width: "100%" }}>
              <Typography
                variant="body1"
                sx={{
                  fontWeight: "300",
                  color: "#4B4E58",
                  textAlign: "left",
                  padding: 1,
                }}
              >
                Facilitate ongoing improvement by sharing knowledge from the
                best in industry
              </Typography>
            </Box>
          </Stack>

          <Stack
            direction="column"
            spacing={1}
            justifyContent="center"
            alignItems={"center"}
            sx={{
              width: "100%",
              // background: "linear-gradient(96.34deg, #52B1BB, #008997 100%)",
              // borderRadius: "10px",
              background: "#FFF", // "linear-gradient(96.34deg, #52B1BB, #008997 100%)",
              borderRadius: "14px",
              borderColor: "#52B1BB",
              border: 1,
              paddingX: 1,
              paddingY: 4,
              margin: 1,
            }}
          >
            <Avatar
              alt="resource cent"
              src={Knowledge}
              sx={{
                display: "flex",
                borderRadius: 0,
                bgcolor: "transparent",
                width: "100px",
              }}
              imgProps={{
                style: {
                  objectFit: "contain",
                },
              }}
            />
            <Typography
              variant="h5"
              sx={{
                fontWeight: "600",
                color: "#16171B",
                padding: 1,
                textAlign: "center",
              }}
            >
              Idea Centralization
            </Typography>

            <Box sx={{ width: "100%" }}>
              <Typography
                variant="body1"
                sx={{
                  fontWeight: "300",
                  color: "#4B4E58",
                  textAlign: "left",
                  padding: 1,
                }}
              >
                We improve productivity with a centralized thought-sharing hub,
                saving time and enhancing accessibility for resources and ideas
              </Typography>
            </Box>
          </Stack>

          <Stack
            direction="column"
            spacing={1}
            justifyContent="center"
            alignItems={"center"}
            sx={{
              width: "100%",
              // background: "linear-gradient(96.34deg, #52B1BB, #008997 100%)",
              // borderRadius: "10px",
              background: "#FFF", // "linear-gradient(96.34deg, #52B1BB, #008997 100%)",
              borderRadius: "14px",
              borderColor: "#52B1BB",
              border: 1,
              paddingX: 1,
              paddingY: 4,
              margin: 1,
            }}
          >
            <Avatar
              alt="User Engagement"
              src={Members}
              sx={{
                display: "flex",
                borderRadius: 0,
                bgcolor: "transparent",
                width: "100px",
              }}
              imgProps={{
                style: {
                  objectFit: "contain",
                },
              }}
            />
            <Typography
              variant="h5"
              sx={{
                fontWeight: "600",
                color: "#16171B",
                padding: 1,
                textAlign: "center",
              }}
            >
              User Engagement
            </Typography>

            <Box sx={{ width: "100%" }}>
              <Typography
                variant="body1"
                sx={{
                  fontWeight: "300",
                  color: "#4B4E58",
                  textAlign: "left",
                  padding: 1,
                }}
              >
                We boost user involvement with features encouraging active
                participation, fostering a connected and productive community
              </Typography>
            </Box>
          </Stack>
        </Box>
      </Box>

      <Divider sx={{ marginTop: 4 }} />
    </>
  );
};

export default Homepage;
