import React, { useEffect, useState } from "react";
import { Box, Button, Chip, MenuItem, Select } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";

import { updateUserLayer } from "../../../features/auth/authSlice";
import { layersToPrompts, cities, ergs } from "../../constants";
// import { useWhatChanged } from "@simbathesailor/use-what-changed";

const ChooseLayers = ({ handleAddLayerClose }) => {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [userLayers, setUserLayers] = useState(user.layers);

  let updatedLayersToPrompts = {};
  for (const layer in layersToPrompts) {
    if (layer !== "ERG" && layer !== "City") {
      updatedLayersToPrompts[layer] = layersToPrompts[layer];
    }
  }
  // compiling all erg-based layers
  ergs.forEach((erg) => {
    if (erg in layersToPrompts) {
      updatedLayersToPrompts[erg] = layersToPrompts["ERG"].concat(
        layersToPrompts[erg]
      );
    } else {
      updatedLayersToPrompts[erg] = layersToPrompts["ERG"];
    }
  });
  // compiling all city-based layers
  cities.forEach((city) => {
    if (city in layersToPrompts) {
      updatedLayersToPrompts[city] = layersToPrompts["City"].concat(
        layersToPrompts[city]
      );
    } else {
      updatedLayersToPrompts[city] = layersToPrompts["City"];
    }
  });
  const layers = Object.keys(updatedLayersToPrompts);

  const handleChangeSelect = (event) => {
    const {
      target: { value },
    } = event;
    console.log(value);
    setUserLayers(value);
  };

  const handleConfirm = () => {
    handleAddLayerClose();
    dispatch(updateUserLayer(userLayers));
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        padding: 1,
        background: "white",
        justifyContent: "flex-start",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          padding: 1,
          height: "auto",
          alignItems: "center",
          mb: 20,
        }}
      >
        <Select
          sx={{
            width: "80%",
            height: "auto",
            color: "#52B1BB",
            border: "1px solid #52B1BB",
          }}
          placeholder="Select Layers"
          multiple
          value={userLayers}
          onChange={handleChangeSelect}
          renderValue={(selected) => (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 0.5,
              }}
            >
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
        >
          {layers.map((layer, index) => (
            <MenuItem key={layer} value={layer}>
              {layer != "All" && layer}
            </MenuItem>
          ))}
        </Select>
      </Box>

      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          variant="outlined"
          sx={{
            width: "20%",
            m: 1,
            color: "#FFFFFF",
            background: "#52B1BB",
            display: "block",
            borderRadius: "32px",
            paddingY: 1,
            paddingX: 3,
            textTransform: "none",
          }}
          onClick={() => handleAddLayerClose()}
        >
          Cancel
        </Button>
        <Button
          variant="outlined"
          disabled={userLayers.length < 1}
          sx={{
            width: "20%",
            m: 1,
            color: "#52B1BB",
            background: "#FFFFFF",
            display: "block",
            borderRadius: "32px",
            paddingY: 1,
            paddingX: 3,
            textTransform: "none",
          }}
          onClick={handleConfirm}
        >
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default ChooseLayers;
