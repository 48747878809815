 import React from 'react';
 import "../Pages/DirectMessaging/Home.scss";
 import UserDMNavbar from "./UserDmNavbar";
 import Search from "./Search";
 import Chats from "./Chats";



const Sidebar = () => {
    return (
        <div className='sidebar'>
            <UserDMNavbar />
            <Search/>
            <Chats/>
        </div>
    )
}

export default Sidebar;