import {
  Avatar,
  Box,
  Popover,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { companyIllustration } from "../../Assets/utilFunctions";
import { companies } from "../constants";

const FAQ = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        paddingX: { xs: 3, sm: 3, md: 7 },
        background: "linear-gradient( #FFFFFF, #52B1BB)",
        paddingTop: 10,
        paddingBottom: 10,
      }}
    >
      <Typography
        variant="h4"
        sx={{
          fontWeight: "700",
          fontSize: "50px",
          margin: 3,
          textAlign: "center",
        }}
      >
        FAQ
      </Typography>

      <Typography
        variant="h3"
        sx={{
          fontWeight: "700",
          fontSize: "30px",
          marginY: 3,
        }}
      >
        How do I create an account?
      </Typography>
      <Typography
        variant="h6"
        sx={{
          fontWeight: "500",
          fontSize: "16px",
          color: "#16171B",
          paddingLeft: 0,
          marginBottom: 1,
          lineHeight: 2,
          letterSpacing: "-1.5%",
          maxWidth: "700px",
        }}
      >
        If work at one of our{" "}
        <span
          style={{
            fontWeight: "bolder",
            cursor: "pointer",
          }}
          aria-owns={open ? "mouse-over-popover" : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        >
          approved companies
        </span>
        , simply{" "}
        <Tooltip title="✍️" placement="top">
          <span
            style={{
              fontWeight: "bolder",
              cursor: "pointer",
            }}
            onClick={() => navigate("/Welcome")}
          >
            sign up
          </span>
        </Tooltip>{" "}
        with your corporate email. It's that easy. Join us seamlessly and start
        boosting your career and networking potential right away!
        {<br />}
        Eager to join us but not an employee at one of our auto-approved
        companies?{" "}
        <Tooltip title="🚪" placement="top">
          <span
            style={{
              fontWeight: "bolder",
              cursor: "pointer",
            }}
            onClick={() => navigate("/Request")}
          >
            Request approval
          </span>
        </Tooltip>{" "}
        for your new account. We're green-lighting a select number of accounts
        every week. Get in on the action!
      </Typography>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
        PaperProps={{ sx: { width: "375px", margin: "0 20px" } }} // Set a fixed width or use 'fit-content' to adjust the width based on the content
      >
        <Stack
          direction={"column"}
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fill, minmax(40px, 1fr))",
            gap: "4px 20px",
            justifyContent: "center",
          }}
        >
          {companies.map(
            (company) =>
              !["Non-Partner Company", "Stealth"].includes(company) && (
                <Avatar
                  key={company} // Add a unique key for each Avatar
                  src={companyIllustration(company)}
                  sx={{
                    width: "40px",
                    height: "40px",
                    margin: 0.25,
                    borderRadius: 0,
                  }}
                />
              )
          )}
        </Stack>
      </Popover>

      <Typography
        variant="h3"
        sx={{
          fontWeight: "700",
          fontSize: "30px",
          marginY: 3,
        }}
      >
        How can my organization get access to Stratos?
      </Typography>
      <Typography
        variant="h6"
        sx={{
          fontWeight: "500",
          fontSize: "16px",
          color: "#16171B",
          paddingLeft: 0,
          marginBottom: 1,
          lineHeight: 2,
          letterSpacing: "-1.5%",
          maxWidth: "700px",
        }}
      >
        A list of your group's emails is all we need to invite your group to
        Stratos and give them permissions to create an account if they desire!
        {<br />}
        Contact Jonathan at{" "}
        <a href="mailto:jonathan@trystratos.com">jonathan@trystratos.com</a>, to
        initiate the user invite process with us, and we will work with you to
        make it as seamless as possible.
      </Typography>

      <Typography
        variant="h3"
        sx={{
          fontWeight: "700",
          fontSize: "30px",
          marginY: 3,
        }}
      >
        Why can't anyone create an account?
      </Typography>
      <Typography
        variant="h6"
        sx={{
          fontWeight: "500",
          fontSize: "16px",
          color: "#16171B",
          paddingLeft: 0,
          marginBottom: 1,
          lineHeight: 2,
          letterSpacing: "-1.5%",
          maxWidth: "700px",
        }}
      >
        Our network is restricted to F500 companies, to top-tier talent. Only
        giving verified users access to our community also serves to prevent any
        potential bad actors from entering the Stratosphere.
      </Typography>

      <Typography
        variant="h3"
        sx={{
          fontWeight: "700",
          fontSize: "30px",
          marginY: 3,
        }}
      >
        What can I do in the Stratosphere?
      </Typography>
      <Typography
        variant="h6"
        sx={{
          fontWeight: "500",
          fontSize: "16px",
          color: "#16171B",
          paddingLeft: 0,
          marginBottom: 1,
          lineHeight: 2,
          letterSpacing: "-1.5%",
          maxWidth: "700px",
        }}
      >
        {/* <Tooltip title="✨" placement="top">
          <span
            style={{
              fontWeight: "bolder",
              cursor: "pointer",
            }}
            onClick={() => navigate("/Product")}
          >
            features
          </span>
        </Tooltip> */}
        Gain exclusive access to top-tier talent from Fortune 500 companies with
        AI-powered matchmaking. Collaborate and share ideas in a centralized
        hub. Enjoy VIP access to exclusive events and networking opportunities.
      </Typography>
    </Box>
  );
};

export default FAQ;
