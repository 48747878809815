import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import PlaceIcon from "@mui/icons-material/Place";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import LinesEllipsis from "react-lines-ellipsis";
import responsiveHOC from "react-lines-ellipsis/lib/responsiveHOC";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import {
  companyIllustration,
  degreeIllustration,
} from "../../../Assets/utilFunctions";
import { LogoutMixPanel } from "../../../MixPanel";
import { logout } from "../../../features/auth/authSlice";
import { createChat } from "../../../features/messages/messageSlice";
import { blockUser, reportUser } from "../../../features/report/reportSlice";
import { getUserProfile } from "../../../features/userprofile/userProfileSlice";

const UserProfile = () => {
  const { user, message } = useSelector((state) => state.userProfile);
  const { user: authUser } = useSelector((state) => state.auth);

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  // check if user is another profile or current profile
  useEffect(() => {
    const url = location.pathname;
    const parts = url.split("/");
    const userId = parts[parts.length - 1];

    if (userId === authUser.userId) {
      navigate(`/HomeScreen/Profile`);
    } else {
      dispatch(getUserProfile({ id: userId }));
    }
  }, []);

  // show error
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  // logout when error from BE
  useEffect(() => {
    if (message === "auth/id-token-expired") {
      LogoutMixPanel(user.email);
      dispatch(logout());
      setShowErrorAlert(true);
    }
  }, [message]);

  // for report and blocking
  const [open, setOpen] = useState(false);
  const [openReport, setOpenReport] = useState(false);
  const [reason, setReason] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenReport = () => {
    setOpenReport(true);
    setOpen(false);
  };

  const handldeCloseReport = () => {
    setOpenReport(false);
    setOpen(false);
  };

  const handleReportButton = () => {
    setOpenReport(false);
    setOpen(false);
    dispatch(
      reportUser({
        reportedUser: user.userId,
        reportingReason: reason,
        reportedEmail: user.email,
      })
    );
  };

  const handleBlockUserButton = () => {
    setOpen(false);
    dispatch(blockUser({ blockedUser: user.userId, blockedEmail: user.email }));
    navigate("/HomeScreen");
  };

  // to take user to messaging
  const handleMessageClick = () => {
    dispatch(createChat(user.userId));
    navigate("/HomeScreen/Messages", {
      state: { chatUser: user.userId },
    });
  };

  // get current job from experience
  const [currentJob, setCurrentJob] = useState(null);

  const ResponsiveLinesEllipsis = responsiveHOC()(LinesEllipsis);
  const [expanded, setExpanded] = useState(false);

  // [STRAT-38] - could change to currentCompany & jobTitle
  useEffect(() => {
    if (user?.experience && user?.experience.length > 0) {
      setCurrentJob(user?.experience[0]);
    }
  }, [user?.experience]);

  // extract birth date properly
  const [birthDate, setBirthDate] = useState(user?.birthDate);
  useEffect(() => {
    const isoString = user?.birthDate;
    const date = new Date(isoString);

    const options = { day: "numeric", month: "long", year: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);

    setBirthDate(formattedDate);
  }, []);

  const city = user?.city ? user?.city : "";
  const state = user?.state ? user?.state : "";
  const cityState = city && state ? city + ", " + state : city;

  return (
    user && (
      <Box
        x={{
          display: "flex",
          backgroundColor: "#E9E9E9",
          width: "90%",
          height: "90%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Helmet>
          <title>User Profile - Stratos</title>
        </Helmet>

        {showErrorAlert && (
          <Dialog
            open={showErrorAlert}
            onClose={() => {
              console.log("message", message);
              console.log("alert close");
              setShowErrorAlert(false);
              navigate("/Welcome");
            }}
          >
            <DialogTitle>{"Info"}</DialogTitle>
            <DialogContent>
              <DialogContentText>{"You have signed out! "}</DialogContentText>
            </DialogContent>
          </Dialog>
        )}

        <Dialog open={open} onClose={handleClose}>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Would you like to report or block ? {user.firstName || user.name}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleBlockUserButton}>Block</Button>
            <Button onClick={handleOpenReport} autoFocus>
              Report
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={openReport} onClose={handldeCloseReport}>
          <DialogTitle>Reporting Reason</DialogTitle>
          <DialogContent>
            <DialogContentText>
              To report this post you need to provide a valid reason. We will
              review the post and inform concerned user.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Reason"
              type="text"
              value={reason}
              onChange={(e) => setReason(e.target.value)}
              fullWidth
              variant="standard"
              multiline
              inputProps={{
                style: {
                  height: "20px",
                  fontSize: "14px",
                },
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleReportButton}>Report</Button>
            <Button onClick={handldeCloseReport}>Cancel</Button>
          </DialogActions>
        </Dialog>

        <Box
          sx={{
            display: "flex",
            margin: { xs: 2, sm: 2, md: 3 },
            backgroundColor: "white",
            width: "95%",
            height: "100%",
            borderRadius: "10px",
            flexDirection: "column",
            padding: 2,
          }}
        >
          <Stack
            direction={{
              xs: "column",
              sm: "row",
              md: "row",
            }}
            justifyContent="space-between"
            alignItems="center"
            width={"100%"}
          >
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              {user.profileImage.url && user.profileImage.flag ? (
                <Avatar
                  src={user.profileImage.url}
                  sx={{ width: "100px", height: "100px" }}
                />
              ) : (
                <Avatar
                  src={
                    "https://firebasestorage.googleapis.com/v0/b/stratosv1.appspot.com/o/default-user-profile.png?alt=media"
                  }
                  sx={{ width: "100px", height: "100px" }}
                />
              )}

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  margin: 1,
                  justifyContent: "center",
                }}
              >
                {user?.name !== null && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: {
                          xs: 20,
                          sm: 20,
                          md: 25,
                        },
                        fontWeight: {
                          xs: 600,
                          sm: 600,
                          md: 700,
                        },
                      }}
                    >
                      {user.firstName || user.name}
                    </Typography>
                    <Tooltip title="Block or Report User">
                      <IconButton
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        onClick={handleClickOpen}
                      >
                        <RemoveCircleOutlineOutlinedIcon
                          sx={{
                            height: 17,
                            width: 17,
                          }}
                          color="error"
                        />
                      </IconButton>
                    </Tooltip>
                  </Box>
                )}
                {cityState && (
                  <Typography
                    sx={{
                      fontSize: 18,
                      fontWeight: "500",
                      color: "#52B1BB",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <PlaceIcon color="#52B1BB" />
                    {cityState ? cityState : "location"}
                  </Typography>
                )}
                {user?.pronouns && user?.pronouns.length > 0 && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 1,
                    }}
                  >
                    {user?.pronouns &&
                      user?.pronouns
                        .slice(0, user?.pronouns.length - 1)
                        .map((p) => (
                          <Typography
                            key={p + "p"}
                            variant="body2"
                            color="#FFFFFF"
                            sx={{
                              color: "#52B1BB",
                              fontSize: 16,
                            }}
                          >
                            {p}/
                          </Typography>
                        ))}
                    <Typography
                      key={user?.pronouns[user?.pronouns.length - 1] + "p"}
                      variant="body2"
                      color="#FFFFFF"
                      sx={{
                        color: "#52B1BB",
                        fontSize: 16,
                      }}
                    >
                      {user?.pronouns[user?.pronouns.length - 1]}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>

            <Box
              sx={{
                display: { xs: "none", sm: "none", md: "flex" },
              }}
            >
              <Button
                sx={{
                  color: "white",
                  backgroundColor: "#52B1BB",
                  borderRadius: "4px",
                  textTransform: "none",
                  fontSize: "12",
                  ":hover": {
                    color: "#52B1BB",
                    backgroundColor: "#D2E1E2",
                  },
                }}
                onClick={() => handleMessageClick()}
              >
                Message
              </Button>
            </Box>
          </Stack>

          {currentJob && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Avatar
                src={companyIllustration(currentJob.company)}
                sx={{
                  width: "32px",
                  height: "32px",
                  margin: 1,
                  borderRadius: 0,
                }}
              />
              <Box
                sx={{
                  margin: 1,
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Typography
                  sx={{
                    color: "#16171B",
                    fontSize: "18",
                    fontWeight: 500,
                  }}
                >
                  {currentJob.position}
                </Typography>
                <Typography
                  sx={{
                    color: "#767676",
                    fontSize: "18",
                    fontWeight: 500,
                  }}
                >
                  &nbsp;at&nbsp;
                </Typography>
                <Typography
                  sx={{
                    color: "#16171B",
                    fontSize: "18",
                    fontWeight: 500,
                  }}
                >
                  {currentJob.company}
                </Typography>
              </Box>
            </Box>
          )}

          {/* {user?.linkedin && user?.linkedin !== "" && (
            <Box
              sx={{
                margin: 1,
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Typography
                sx={{
                  color: "#16171B",
                  fontSize: "18",
                  fontWeight: 500,
                }}
              >
                LinkedIn: &nbsp;
              </Typography>

              <Link to={user.linkedin} underline="none" color="inherit">
                <Typography
                  style={{
                    userSelect: "text",
                    overflowWrap: "anywhere",
                    color: "#767676",
                    fontSize: "18",
                    fontWeight: 500,
                  }}
                >
                  {user.linkedin}
                </Typography>
              </Link>
            </Box>
          )} */}

          {user?.bio && user?.bio.length > 0 && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                margin: 1,
                width: "60%",
              }}
            >
              <Typography
                sx={{
                  color: "#52B1BB",
                  fontSize: 25,
                  fontWeight: 700,
                }}
              >
                Bio
              </Typography>
              {!expanded ? (
                <>
                  <Typography
                    variant="body2"
                    color="#16171B"
                    sx={{
                      fontSize: "14px",
                      whiteSpace: "pre-wrap",
                      fontWeight: 400,
                    }}
                  >
                    <ResponsiveLinesEllipsis
                      text={user.bio}
                      maxLine="3"
                      ellipsis={
                        <Button
                          onClick={() => setExpanded(true)}
                          sx={{
                            textTransform: "none",
                            display: "flex",
                            justifyContent: "flex-start",
                            paddingLeft: 0,
                            color: "#878787",
                            width: "fit-content",
                          }}
                        >
                          ...Show More
                        </Button>
                      }
                      trimRight
                      basedOn="words"
                    />
                  </Typography>
                </>
              ) : (
                <>
                  <Typography
                    variant="body2"
                    color="#16171B"
                    sx={{
                      fontSize: "14px",
                      whiteSpace: "pre-wrap",
                      fontWeight: 400,
                    }}
                  >
                    {user?.bio}
                  </Typography>
                  <IconButton
                    onClick={() => setExpanded(false)}
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      color: "#878787",
                      width: "fit-content",
                    }}
                  >
                    <ExpandLessIcon />
                  </IconButton>
                </>
              )}
            </Box>
          )}

          {user?.ergs && user?.ergs.length > 0 && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                margin: 1,
                width: "60%",
              }}
            >
              <Typography
                sx={{
                  color: "#52B1BB",
                  fontSize: 25,
                  fontWeight: 700,
                }}
              >
                Groups
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  flexWrap: "wrap",
                  marginTop: 1,
                }}
              >
                {user?.ergs &&
                  user?.ergs.map((erg) => (
                    <Typography
                      key={erg + "erg"}
                      variant="body2"
                      color="#FFFFFF"
                      sx={{
                        fontSize: "15px",
                        whiteSpace: "nowrap",
                        fontWeight: 500,
                        marginRight: 3,
                        borderRadius: "27px",
                        paddingY: 0.5,
                        paddingX: 1.25,
                        marginBottom: 2,
                        backgroundColor: "#52B1BB",
                      }}
                    >
                      {erg}
                    </Typography>
                  ))}
              </Box>
            </Box>
          )}

          {user?.interests && user?.interests.length > 0 && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                margin: 1,
                width: "60%",
              }}
            >
              <Typography
                sx={{
                  color: "#52B1BB",
                  fontSize: 25,
                  fontWeight: 700,
                }}
              >
                Interests
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  flexWrap: "wrap",
                  marginTop: 1,
                }}
              >
                {user?.interests &&
                  user?.interests.map((interest) => (
                    <Typography
                      key={interest}
                      variant="body2"
                      color="#008997"
                      sx={{
                        fontSize: "14px",
                        whiteSpace: "nowrap",
                        fontWeight: 400,
                        marginRight: 3,
                        borderRadius: "4px",
                        padding: 0.5,
                        marginBottom: 2,
                        backgroundColor: "rgba(82, 177, 187, 0.2)",
                      }}
                    >
                      {interest}
                    </Typography>
                  ))}
              </Box>
            </Box>
          )}

          {user?.layers && user?.layers.length > 0 && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                margin: 1,
                width: "60%",
              }}
            >
              <Typography
                sx={{
                  color: "#52B1BB",
                  fontSize: 25,
                  fontWeight: 700,
                }}
              >
                My Layers
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  flexWrap: "wrap",
                  marginTop: 1,
                }}
              >
                {user?.layers.map((layer) => (
                  <Typography
                    key={layer}
                    variant="body2"
                    color="#16171B"
                    sx={{
                      fontSize: "15px",
                      whiteSpace: "nowrap",
                      fontWeight: 500,
                      marginRight: 3,
                      borderRadius: "27px",
                      paddingY: 0.5,
                      paddingX: 1.25,
                      marginBottom: 2,
                      backgroundColor: "#EBEBEB",
                    }}
                  >
                    {layer}
                  </Typography>
                ))}
              </Box>
            </Box>
          )}

          {user?.experience?.length > 0 && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                margin: 1,
                width: "60%",
              }}
            >
              <Typography
                sx={{
                  color: "#52B1BB",
                  fontSize: 25,
                  fontWeight: 700,
                }}
              >
                Most Recent Role
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  flexWrap: "wrap",
                  marginTop: 1,
                  alignItems: "flex-start",
                }}
              >
                {user?.experience.slice(0, 1).map(
                  (
                    exp // only shows most recent experience
                  ) => (
                    <Stack
                      key={exp}
                      direction={"row"}
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      <Avatar
                        src={companyIllustration(exp.company)}
                        sx={{
                          width: "40px",
                          height: "40px",
                          margin: 1,
                          borderRadius: 0,
                        }}
                      />
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                          }}
                        >
                          <Typography
                            variant="body2"
                            color="#16171B"
                            sx={{
                              fontSize: "15px",
                              whiteSpace: "nowrap",
                              fontWeight: 500,
                            }}
                          >
                            {exp.company}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="#878787"
                            sx={{
                              fontSize: "12px",
                              whiteSpace: "nowrap",
                              fontWeight: 700,
                              marginLeft: 0.5,
                            }}
                          >
                            {exp.endDate != "present"
                              ? `(${exp.startDate.month}/${exp.startDate.year} - ${exp.endDate.month}/${exp.endDate.year})`
                              : `(${exp.startDate.month}/${exp.startDate.year} - ${exp.endDate})`}
                          </Typography>
                        </Box>

                        <Typography
                          variant="body2"
                          color="#878787"
                          sx={{
                            fontSize: "12px",
                            whiteSpace: "nowrap",
                            fontWeight: 700,
                          }}
                        >
                          {exp.position}
                        </Typography>
                      </Box>
                    </Stack>
                  )
                )}
              </Box>
            </Box>
          )}

          {user?.education &&
            user?.education.length > 0 && ( // [STRAT-38] - add university logos?
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  margin: 1,
                  width: "60%",
                }}
              >
                <Typography
                  sx={{
                    color: "#52B1BB",
                    fontSize: 25,
                    fontWeight: 700,
                  }}
                >
                  School
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    flexWrap: "wrap",
                    marginTop: 1,
                    alignItems: "flex-start",
                  }}
                >
                  {user?.education.map((sch) => (
                    <Stack
                      key={
                        sch.school +
                        sch.degree +
                        sch.fieldOfStudy +
                        sch.startDate
                      }
                      direction={"row"}
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      <Avatar
                        src={degreeIllustration(sch.degree)}
                        sx={{
                          width: "40px",
                          height: "40px",
                          margin: 1,
                          borderRadius: 0,
                        }}
                      />
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                          }}
                        >
                          <Typography
                            variant="body2"
                            color="#16171B"
                            sx={{
                              fontSize: "15px",
                              whiteSpace: "nowrap",
                              fontWeight: 500,
                            }}
                          >
                            {sch.school}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="#878787"
                            sx={{
                              fontSize: "12px",
                              whiteSpace: "nowrap",
                              fontWeight: 700,
                              marginLeft: 0.5,
                            }}
                          >
                            {sch.endDate != "present"
                              ? `(${sch.startDate.month}/${sch.startDate.year} - ${sch.endDate.month}/${sch.endDate.year})`
                              : `(${sch.startDate.month}/${sch.startDate.year} - ${sch.endDate})`}
                          </Typography>
                        </Box>

                        <Typography
                          variant="body2"
                          color="#878787"
                          sx={{
                            fontSize: "12px",
                            whiteSpace: "nowrap",
                            fontWeight: 700,
                          }}
                        >
                          {sch.degree} degree, {sch.fieldOfStudy}
                        </Typography>
                      </Box>
                    </Stack>
                  ))}
                </Box>
              </Box>
            )}
        </Box>
      </Box>
    )
  );
};

export default UserProfile;
