import { MessageFilled as MessageIcon } from "@ant-design/icons";
import { AddCircleRounded, Share as ShareIcon } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Avatar,
  Badge,
  Box,
  Button,
  CssBaseline,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Drawer,
  IconButton,
  MobileStepper,
  Tab,
  Tabs,
  Toolbar,
  Typography,
  TextField,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Link,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { LogoutMixPanel } from "../../MixPanel";
import Home from "./Home/Home";
import Notifications from "./Notifications/Notifications";
import Profile from "./Profile/Profile";
import Settings from "./Settings/Settings";
import UserProfile from "./UserProfile/UserProfile";
// import EmailIcon from "@mui/icons-material/Email";
import FeedbackIcon from "@mui/icons-material/Feedback";
import GroupsIcon from "@mui/icons-material/Groups";
import HomeIcon from "@mui/icons-material/Home";
import LogoutIcon from "@mui/icons-material/Logout";
import NotificationsIcon from "@mui/icons-material/NotificationsActive";
import ProfileIcon from "@mui/icons-material/Person";
import SettingsIcon from "@mui/icons-material/Settings";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import EmailIcon from "@mui/icons-material/Email";
import TextsmsIcon from "@mui/icons-material/Textsms";
import { makeStyles } from "@mui/styles";
import NavHomeIcon from "../../Assets/homeIcon.svg";
import stratoswhite from "../../Assets/stratoswhite.svg";
import NetworkingIcon from "../../Assets/networking_icon.svg";
import AddBirthdate from "../../components/Onboarding/AddBirthdate";
import AddNewProfile from "../../components/Onboarding/AddNewProfile";
import AddYourExperience from "../../components/Onboarding/AddYourExperience";
import ChooseEthnicity from "../../components/Onboarding/ChooseEthnicity";
import ChooseUserType from "../../components/Onboarding/ChooseUserType";
import ChooseYourCity from "../../components/Onboarding/ChooseYourCity";
import ChooseYourErgs from "../../components/Onboarding/ChooseYourErgs";
import ChooseYourInterests from "../../components/Onboarding/ChooseYourInterests";
import ChooseYourLayers from "../../components/Onboarding/ChooseYourLayers";
import ChooseYourPronouns from "../../components/Onboarding/ChooseYourPronouns";
import WhatAreLayers from "../../components/Onboarding/WhatAreLayers";
import WhatArePrompts from "../../components/Onboarding/WhatArePrompts";
import Spinner from "../../components/Spinner";
import {
  logout,
  reset,
  updateUserErgs,
  updateUserInterests,
  updateUserLayer,
  updateUserPersonal,
} from "../../features/auth/authSlice";
import { resetAll } from "../../features/posts/postSlice";
import { getBlockedUsers } from "../../features/report/reportSlice";
import { sortExpriences } from "../utils";

import "@fontsource/montserrat/200.css";
import { getUnreadMessagesCount } from "../../features/messages/messageSlice";

import Network from "./Network/Network";
import Match from "./Network/Match";
import DirectMessaging from "./UserToUserMessaging/DirectMessaging";

// Custom SVG icon component
const NetworkingSvgIcon = (props) => (
  <Box
    component="img"
    src={NetworkingIcon}
    sx={{ width: 20, height: 20, marginRight: 1 }}
  />
);

const drawerWidth = 18;
const useStyles = makeStyles((theme) => ({
  drawer: {
    width: `${drawerWidth}%`,
    flexShrink: 0,
    borderRadius: "8px", // Adjust the value as needed
  },
  drawerPaper: {
    width: `${drawerWidth}%`,
    backgroundColor: "black",
    color: "white",
    borderRadius: "8px", // Adjust the value as needed
    minWidth: "200px",
  },
  toolbar: theme.mixins.toolbar,
}));

const HomeScreen = () => {
  // functional

  const { user, isLoading } = useSelector((state) => state.auth);
  const { posts } = useSelector((state) => state.posts);
  const { unreadMessagesCount } = useSelector((state) => state.messages);

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [inviteDialogOpen, setInviteDialogOpen] = useState(false);

  const openInviteDialog = () => setInviteDialogOpen(true);
  const closeInviteDialog = () => setInviteDialogOpen(false);

  const inviteLink = "https://stratos.community/SignUp?ref=referralapril24";
  const inviteMessage =
    "Hey, you should check out Stratos, the exclusive social platform for young professionals. Here is a sign up link to join the community: " +
    inviteLink;

  const copyInviteLink = () => {
    navigator.clipboard.writeText(inviteLink);
    alert("Link copied to clipboard!");
    closeInviteDialog();
  };

  const sendEmail = () => {
    const mailto = `mailto:?subject=Join me on Stratos&body=${encodeURIComponent(
      inviteMessage
    )}`;
    window.location.href = mailto;
    closeInviteDialog();
  };

  const sendSMS = () => {
    const smsLink = `sms:?&body=${encodeURIComponent(inviteMessage)}`;
    window.location.href = smsLink;
    closeInviteDialog();
  };

  const inviteOptions = (
    <Dialog open={inviteDialogOpen} onClose={closeInviteDialog}>
      <DialogTitle>Invite Friends to Stratos</DialogTitle>
      <DialogContent>
        <List>
          <ListItem button onClick={copyInviteLink}>
            <ListItemIcon>
              <ContentCopyIcon />
            </ListItemIcon>
            <ListItemText primary="Copy Invite Link" />
          </ListItem>
          <ListItem button onClick={sendEmail}>
            <ListItemIcon>
              <EmailIcon />
            </ListItemIcon>
            <ListItemText primary="Send via Email" />
          </ListItem>
          <ListItem button onClick={sendSMS}>
            <ListItemIcon>
              <TextsmsIcon />
            </ListItemIcon>
            <ListItemText primary="Send via SMS" />
          </ListItem>
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeInviteDialog} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );

  // if user data isnt stored in storage
  useEffect(() => {
    if (!user) {
      LogoutMixPanel(user.email);
      dispatch(logout());
      dispatch(resetAll());
      navigate("/Welcome");
    }
  }, [user]);

  // for google first time sign in
  useEffect(() => {
    if (user.isNewUser) {
      dispatch(reset());
      navigate("/loginauth");
    }
  }, []);

  // for blocked users
  useEffect(() => {
    dispatch(getBlockedUsers());
  }, []);

  // logout button function
  const handleLogout = () => {
    navigate("/Welcome");
    LogoutMixPanel(user.email);
    dispatch(logout());
    dispatch(reset());
    dispatch(resetAll());
  };

  // display new post or not toggle
  const [displayNewPost, setDisplayNewPost] = useState(false);

  // add new user experience during onboarding
  const [experience, setExperience] = useState(
    user?.experience ? user?.experience : []
  );

  // onboarding
  const [userType, setUserType] = useState("");
  const [ethnicity, setEthnicity] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [yourPronouns, setYourPronouns] = useState([]);
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [userLayers, setUserLayers] = useState([]);
  const [userInterests, setUserInterests] = useState([]);
  const [userErgs, setUserErgs] = useState([]);
  const [showOnboarding, setshowOnboarding] = useState(false);
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleClose = (event, reason) => {
    if (reason && reason == "backdropClick") return;
    setshowOnboarding(false);
  };

  const OnboardingSteps = [
    {
      label: "Add Your Birthdate",
      component: (
        <AddBirthdate birthDate={birthDate} setBirthDate={setBirthDate} />
      ),
    },
    {
      label: "Select Your Pronouns",
      component: (
        <ChooseYourPronouns
          yourPronouns={yourPronouns}
          setYourPronouns={setYourPronouns}
        />
      ),
    },
    {
      label: "Select Your Ethnicity",
      component: (
        <ChooseEthnicity ethnicity={ethnicity} setEthnicity={setEthnicity} />
      ),
    },
    {
      label: "What kind of user are you?",
      component: (
        <ChooseUserType userType={userType} setUserType={setUserType} />
      ),
    },
    {
      label: "What groups are you in?",
      component: (
        <ChooseYourErgs userErgs={userErgs} setUserErgs={setUserErgs} />
      ),
    },
    {
      label: "Where are you located?",
      component: (
        <ChooseYourCity
          country={country}
          setCountry={setCountry}
          state={state}
          setState={setState}
          city={city}
          setCity={setCity}
        />
      ),
    },
    { label: "What Are Layers?", component: <WhatAreLayers /> },
    {
      label: "Choose Your Layers",
      component: (
        <ChooseYourLayers
          userLayers={userLayers}
          setUserLayers={setUserLayers}
        />
      ),
    },
    {
      label: "What Are You Looking For",
      component: (
        <ChooseYourInterests
          userInterests={userInterests}
          setUserInterests={setUserInterests}
        />
      ),
    },
    { label: "What Are Prompts", component: <WhatArePrompts /> },
    {
      label: "Upload Your Profile Pic",
      component: <AddNewProfile handleNext={handleNext} />,
    },
    {
      label: "Add Your Experience",
      component: (
        <AddYourExperience
          experience={experience}
          setExperience={setExperience}
        />
      ),
    },
  ];

  const maxSteps = OnboardingSteps.length;

  useEffect(() => {
    if (location.state) {
      setshowOnboarding(location.state.showOnboarding);
    }
  }, []);

  // layout

  // tabs
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  // incase refresh, find selected tab
  const currentTab = () => {
    let path = location.pathname;
    if (path === "/HomeScreen/") return 0;
    else if (path === "/HomeScreen/Match") return 1;
    else if (path === "/HomeScreen/Network") return 2;
    else if (path === "/HomeScreen/Messages") return 3;
    else if (path === "/HomeScreen/Notifications") return 4;
    else if (path === "/HomeScreen/Profile") return 5;
    else if (path === "/HomeScreen/Settings") return 6;
    else return 0;
  };
  // check if needed
  if (selectedTab !== currentTab()) {
    setSelectedTab(currentTab());
  }
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const tabLayout = {
    color: "white",
    textTransform: "none",
    "& .MuiTab-root": {
      fontFamily: "Roboto",
    },
    "&.Mui-selected": {
      backgroundColor: "#52B1BB", // selected color
      color: "white",
      borderRadius: "8px",
    },
    display: "flex",
    justifyContent: "flex-start",
    paddingLeft: 5,
  };

  const classes = useStyles();

  const titleCase = (str) => {
    return str
      .toLowerCase()
      .split(" ")
      .map(function (word) {
        return word.replace(word[0], word[0].toUpperCase());
      })
      .join(" ");
  };

  useEffect(() => {
    dispatch(getUnreadMessagesCount());
  }, [unreadMessagesCount]);

  const drawer = (
    <Box
      sx={{
        backgroundColor: "black",
        borderRadius: "16px",
        height: "10%",
      }}
    >
      <Box
        sx={{
          height: "100%",
          display: { xs: "none", sm: "none", md: "flex" },
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Avatar
          src={stratoswhite}
          sx={{
            display: "flex",
            borderRadius: 0,
            bgcolor: "transparent",
          }}
          imgProps={{
            style: { objectFit: "contain" },
          }}
        />
        <Typography
          sx={{
            fontSize: "30px",
            fontWeight: "700",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            ml: 2,
          }}
        >
          Stratos
        </Typography>
      </Box>
      <Divider sx={{ height: { xs: "60px", sm: "60px", md: "60%" } }} />
      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        orientation="vertical"
        indicatorColor="transparent"
      >
        <Tab
          sx={tabLayout}
          label={"Home"}
          component={Link}
          to={"/HomeScreen/"}
          icon={<HomeIcon style={{ fontSize: "24px" }} />}
          iconPosition="start"
        />

        <Tab
          sx={tabLayout}
          label={"Your Matches"}
          component={Link}
          to={"/HomeScreen/Match"}
          icon={<NetworkingSvgIcon />}
          iconPosition="start"
        />

        <Tab
          sx={tabLayout}
          label={"Stratos Network"}
          component={Link}
          to={"/HomeScreen/Network"}
          icon={<GroupsIcon />}
          iconPosition="start"
        />

        <Tab
          sx={tabLayout}
          label={"Messages"}
          component={Link}
          to={"/HomeScreen/Messages"}
          icon={
            <Badge
              badgeContent={unreadMessagesCount}
              color="error"
              variant="dot"
            >
              <MessageIcon style={{ fontSize: "22px" }} />
            </Badge>
          }
          iconPosition="start"
        />
        <Tab
          sx={tabLayout}
          label={"Notifications"}
          component={Link}
          to={"/HomeScreen/Notifications"}
          icon={<NotificationsIcon />}
          iconPosition="start"
        />
        <Tab
          sx={tabLayout}
          label={"Profile"}
          component={Link}
          to={"/HomeScreen/Profile"}
          icon={<ProfileIcon />}
          iconPosition="start"
        />
        <Tab
          sx={tabLayout}
          label={"Settings"}
          component={Link}
          to={"/HomeScreen/Settings"}
          icon={<SettingsIcon />}
          iconPosition="start"
        />
        <Tab
          sx={tabLayout}
          label={"Logout"}
          icon={<LogoutIcon style={{ fontSize: "22px" }} />}
          iconPosition="start"
          onClick={handleLogout}
        />
        <Tab
          sx={tabLayout}
          label={"User Feedback"}
          icon={<FeedbackIcon style={{ fontSize: "22px" }} />}
          iconPosition="start"
          href="mailto:support@trystratos.com"
        />
      </Tabs>
    </Box>
  );

  const appbar = (
    <AppBar
      position="fixed"
      sx={{
        width: { sm: `calc(100% - ${drawerWidth}%)` },
        ml: { sm: `${drawerWidth}%` },
        backgroundColor: { xs: "#52B1BB", sm: "#52B1BB", md: "white" },
        boxShadow: "none",
        height: { xs: "60px", sm: "60px", md: "80px" },
        justifyContent: "center",
      }}
    >
      <Toolbar
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <IconButton
          edge="start"
          onClick={handleDrawerToggle}
          sx={{
            display: { sm: "flex", md: "none" },
            color: "white",
          }}
        >
          {!mobileOpen ? <MenuIcon fontSize="large" /> : <CloseIcon />}
        </IconButton>

        <Box
          component={"div"}
          sx={{
            display: { xs: "none", sm: "none", md: "flex" },
            flexDirection: "row",
            width: "70%",
          }}
        >
          <Avatar
            src={NavHomeIcon}
            sx={{
              display: "flex",
              borderRadius: 0,
              bgcolor: "transparent",
              width: "28px",
              height: "auto",
              mr: 2,
              cursor: "pointer",
              borderBottom: 0,
            }}
            imgProps={{
              style: { objectFit: "contain" },
            }}
            component={Link}
            to="/HomeScreen/"
            onClick={() => setSelectedTab(0)}
          />
          <Button
            variant="outlined"
            startIcon={
              <AddCircleRounded
                style={{
                  color: "#52B1BB",
                  fontSize: "32px",
                }}
              />
            }
            sx={{
              width: "50%",
              margin: "0px",
              padding: 1,
              backgroundColor: "#F8F8F8",
              "&:hover": {
                backgroundColor: "promptBox.light",
                opacity: [0.9, 0.8, 0.7],
              },
              borderRadius: "30px",
              display: "flex",
              justifyContent: "flex-start",
              borderColor: "#F8F8F8",
              textTransform: "none",
              color: "#9C9C9C",
            }}
            fullWidth
            align="right"
            onClick={() => setDisplayNewPost(!displayNewPost)}
          >
            Post in the Stratosphere 🚀
          </Button>
        </Box>

        <Box
          sx={{
            height: "100%",
            display: { xs: "flex", sm: "flex", md: "none" },
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Avatar
            src={stratoswhite}
            sx={{
              display: "flex",
              borderRadius: 0,
              bgcolor: "transparent",
            }}
            imgProps={{
              style: { objectFit: "contain" },
            }}
          />
          <Typography
            sx={{
              fontSize: "30px",
              fontWeight: "700",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              ml: 2,
            }}
          >
            Stratos
          </Typography>
        </Box>

        <IconButton
          onClick={openInviteDialog}
          sx={{
            display: { sm: "flex", md: "none" },
            color: "white",
            width: "32px",
            height: "32px",
          }}
        >
          <ShareIcon />
        </IconButton>

        <IconButton
          edge="start"
          component={Link}
          to="/HomeScreen/Messages"
          onClick={() => setSelectedTab(1)}
          sx={{
            display: { sm: "flex", md: "none" },
            color: "white",
            width: "32px",
            height: "32px",
          }}
        >
          <MessageIcon />
        </IconButton>

        <Box
          component={"div"}
          sx={{
            display: { xs: "none", sm: "none", md: "flex" },
            flexDirection: "row",
            width: "30%",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <IconButton
            // onClick={() =>
            //   navigator.clipboard.writeText(
            //     "https://stratos.community/SignUp?ref=referralapril24"
            //   )
            // }
            onClick={openInviteDialog}
            sx={{ marginRight: 2, color: "#8c8c8c" }}
          >
            <ShareIcon />
            <Typography variant="body2" sx={{ marginLeft: 1 }}>
              Invite Friends
            </Typography>
          </IconButton>

          <Avatar
            src={user?.profileImage?.url}
            alt="Profile Image"
            sx={{
              width: 32,
              height: 32,
              marginRight: 1,
              cursor: "pointer",
              borderBottom: 0,
            }}
            component={Link}
            to="/HomeScreen/Profile"
          />
          <Typography
            sx={{
              color: "#16171B",
              fontSize: "14px",
              fontWeight: "500",
            }}
          >
            Welcome, {user?.name}
          </Typography>
          <IconButton
            edge="start"
            sx={{
              ml: 1,
              color: "#8c8c8c",
            }}
            onClick={handleLogout}
          >
            <LogoutIcon fontSize={"28px"} />
          </IconButton>
        </Box>
      </Toolbar>
    </AppBar>
  );

  const main = (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        p: { xs: 1, sm: 1, md: 2 },
        width: { sm: `calc(100% - ${drawerWidth}%)` },
        backgroundColor: "#f8f4f4",
        height: "100%",
        flexDirection: "column",
        backgroundColor: "#E9E9E9",
      }}
    >
      <Toolbar />
      <Routes>
        <Route
          path="/"
          element={
            <Home
              displayNewPost={displayNewPost}
              setDisplayNewPost={setDisplayNewPost}
            />
          }
        />
        <Route path="/Profile" element={<Profile />} />
        <Route
          path="/Settings"
          element={<Settings drawerWidth={drawerWidth} />}
        />
        <Route path="/Notifications" element={<Notifications />} />
        <Route path="/Profile/:id" element={<UserProfile />} />
        <Route path="/Messages" element={<DirectMessaging />} />
        <Route path="/Network" element={<Network />} />
        <Route path="/Match" element={<Match />} />
      </Routes>
      <Dialog
        open={showOnboarding}
        onClose={handleClose}
        PaperProps={{
          style: {
            // backgroundColor: "#fff",
            height: "500px",
            width: "500px",
          },
        }}
      >
        <DialogTitle
          sx={{
            fontWeight: "bold",
            color: "#52B1BB",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {OnboardingSteps[activeStep].label}
        </DialogTitle>
        <DialogContent sx={{ textAlign: "center" }}>
          {OnboardingSteps[activeStep].component}
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center" }}>
          <MobileStepper
            variant="dots"
            steps={maxSteps}
            position="static"
            activeStep={activeStep}
            sx={{
              width: "100%",
            }}
            nextButton={
              <Button
                size="small"
                onClick={() => {
                  if (activeStep === 4) {
                    dispatch(updateUserErgs(userErgs));
                    handleNext();
                  } else if (activeStep === 7) {
                    dispatch(updateUserLayer(userLayers));
                    handleNext();
                  } else if (activeStep === 8) {
                    dispatch(updateUserInterests(userInterests));
                    handleNext();
                  } else if (activeStep === 11) {
                    const sortedExperience = sortExpriences(experience);
                    const jobTitle =
                      sortedExperience.length > 0
                        ? sortedExperience[0].position
                        : "";
                    const currentCompany =
                      sortedExperience.length > 0
                        ? sortedExperience[0].company
                        : "";
                    const newUserData = {
                      birthDate,
                      ethnicity,
                      userType,
                      country,
                      state,
                      city,
                      jobTitle,
                      currentCompany,
                      experience: sortedExperience,
                      pronouns: yourPronouns,
                    };
                    console.log("userData", newUserData);
                    dispatch(updateUserPersonal({ newUserData }));
                    console.log("close");
                    handleClose();
                  } else {
                    handleNext();
                  }
                }}
                disabled={
                  (activeStep === 0 && birthDate.trim() === "") || // requires birthdate
                  (activeStep === 2 && ethnicity.trim() === "") || // requires ethnicity
                  (activeStep === 3 && userType.trim() === "") || // requires user type
                  // (activeStep === 4 && userErgs.length === 0) || // requires ERG, not a req anymore
                  (activeStep === 5 && city.trim() === "") || // requires city
                  (activeStep === 7 && userLayers.length === 0) || // requires layers
                  (activeStep === 8 && userInterests.length === 0) || // requires interests
                  (activeStep === 11 && // requires experience
                    experience.some(
                      (exp) =>
                        exp.company.trim() === "" ||
                        exp.position.trim() === "" ||
                        exp.startDate === "" ||
                        exp.endDate === ""
                    ))
                }
              >
                {activeStep === maxSteps - 1 ? "Finish" : "Next"}
              </Button>
            }
            backButton={
              <Button
                size="small"
                onClick={handleBack}
                disabled={activeStep === 0}
              >
                Prev
              </Button>
            }
          />
        </DialogActions>
      </Dialog>
    </Box>
  );

  // loading
  if (isLoading) {
    return <Spinner />;
  }

  return (
    user && (
      <Box sx={{ display: "flex", height: "100%" }}>
        <CssBaseline />
        {appbar}
        {mobileOpen && (
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              flexShrink: 0,
              width: `${drawerWidth}%`,
              display: { xs: "flex", sm: "flex", md: "none" },
              boxSizing: "border-box",
              zIndex: (theme) => theme.zIndex.appBar - 1,
            }}
            PaperProps={{
              style: {
                width: `${drawerWidth}%`,
                backgroundColor: "black",
                color: "white",
                borderRadius: "8px",
                minWidth: "200px",
                boxSizing: "border-box",
                zIndex: (theme) => theme.zIndex.appBar - 1,
              },
            }}
          >
            {drawer}
          </Drawer>
        )}
        <Box
          component="nav"
          sx={{
            width: { sm: `${drawerWidth}%` },
            flexShrink: { sm: 0 },
          }}
        >
          <Drawer
            variant="permanent"
            sx={{
              flexShrink: 0,
              width: `${drawerWidth}%`,
              display: { xs: "none", sm: "none", md: "flex" },
            }}
            PaperProps={{
              style: {
                width: `${drawerWidth}%`,
                backgroundColor: "black",
                color: "white",
                borderRadius: "8px",
                minWidth: "200px",
              },
            }}
            open={!mobileOpen}
          >
            {drawer}
          </Drawer>
        </Box>

        {main}
        {inviteOptions}
      </Box>
    )
  );
};

export default HomeScreen;
