import {
  Avatar,
  Box,
  Chip,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { TrackUserProfileClick } from "../../../MixPanel";
import { setAllMessagesRead } from "../../../features/messages/messageSlice";

const ChatListCard = ({ chat, highlightChatCard }) => {
  const { user } = useSelector((state) => state.auth);
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [bgColor, setBgColor] = useState("#fff");
  const [messageAt, setMessageAt] = useState(chat.lastMessage.at);
  useEffect(() => {
    setBgColor(highlightChatCard ? "#e9f6f8" : "#fff");

    // set msgs to read
    if (highlightChatCard) {
      dispatch(setAllMessagesRead(chat.otherUser));
    }
  }, [highlightChatCard, chat]);

  useEffect(() => {
    if (chat.lastMessage.at !== "") {
      const messageDate = moment(chat.lastMessage.at);
      const yesterday = moment().subtract(1, "day");
      const currentDate = moment().startOf("day");

      if (messageDate.isSame(currentDate, "day")) {
        setMessageAt(messageDate.format("h:mm A"));
      } else if (messageDate.isSame(yesterday, "day")) {
        setMessageAt("Yesterday");
      } else {
        setMessageAt(messageDate.fromNow());
      }
    }
  }, [chat.lastMessage.at]);

  const handleIconClick = () => {
    TrackUserProfileClick(
      user.email,
      user.userId,
      chat.otherUserData.name,
      chat.otherUser,
      chat.otherUserData.jobTitle,
      chat.otherUserData.currentCompany
    );
    //dispatch(getUserProfile({ id: post.author.id }));
    navigate(
      `/HomeScreen/Profile/${chat.otherUser}`,
      { state: { userId: user.userId } },
      { preventScrollReset: true }
    );
  };

  const publicName = chat.otherUserData.firstName || chat.otherUserData.name;

  return (
    <Box
      sx={{
        height: "40%",
        width: "100%",
        border: 0,
        borderTop: "1px",
        borderStyle: "solid",
        borderRadius: 0,
        borderColor: "#CACACA",
        cursor: "pointer",
        "&:hover": {
          backgroundColor: "#F5F5F5",
        },
        backgroundColor: bgColor,
        padding: 0.5,
      }}
    >
      <Stack
        direction={"row"}
        justifyContent="space-between"
        alignItems="center"
        padding={0}
        spacing={0.5}
      >
        <Avatar
          onClick={handleIconClick}
          sx={{
            width: isSmallScreen ? "30px" : "40px",
            height: isSmallScreen ? "30px" : "40px",
          }}
          src={chat.otherUserData.profileImage.url}
        />
        <Box sx={{ flexGrow: 1, minWidth: 0 }}>
          <Stack sx={{ width: "100%" }} direction="row">
            <Stack
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={0.5}
              sx={{ width: "50%" }}
            >
              <Typography
                variant="body2"
                sx={{
                  fontWeight: "bold",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
                fontSize={14}
              >
                {publicName.length > 15
                  ? publicName.substring(0, 13) + "..."
                  : publicName}
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{
                  color: "#CACACA",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
                fontSize={12}
              >
                {chat.lastMessage.content.length > 20
                  ? chat.lastMessage.content.substring(0, 20) + "..."
                  : chat.lastMessage.content}
                {/* {chat.lastMessage.by_first_name !== "" &&
                  ((
                    chat.lastMessage.by_first_name +
                    ": " +
                    chat.lastMessage.content
                  ).length > 20
                    ? (
                        chat.lastMessage.by_first_name +
                        ": " +
                        chat.lastMessage.content
                      ).substring(0, 20) + "..."
                    : chat.lastMessage.by_first_name +
                      ": " +
                      chat.lastMessage.content)} */}
              </Typography>
            </Stack>
            <Stack
              direction="column"
              justifyContent="space-between"
              alignItems="flex-end"
              spacing={0.5}
              sx={{ width: "50%" }}
              paddingX={0.5}
            >
              <Typography
                variant="caption"
                sx={{
                  color: "#CACACA",
                  mr: 1,
                  overflow: "chip",
                  whiteSpace: "nowrap",
                }}
              >
                {chat.lastMessage.at != "" && messageAt}
              </Typography>
              {chat.unreadMessages > 0 && (
                <Chip
                  label={chat.unreadMessages}
                  color="primary"
                  size="small"
                  sx={{ fontSize: 10 }}
                />
              )}
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
};

export default ChatListCard;
