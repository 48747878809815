import React from "react";
import "./Blog.css";
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';




function Blog() {
    const navigate = useNavigate()
    return (
        <div>
            <div>

            
            <h1 className="stratosblog">
                Stratos Blog
            </h1>
            <p1 className="stratospara">
            Stratos blogs is a resource hub for Black & Latinx professionals and students. Our writers share informative and empowering content to educate and uplift our communities towards a more equitable future.
            </p1>

            
            <div className="indivPost" onClick={() => navigate("../BlogDetail")}>
                <div className="articleimg"/>
                <h1 className="articletag">Design</h1>
                <h2 className="title">UX review presentations</h2>
                <p1 className="articleblurb">How do you create compelling <br/> presentations that wow your colleagues <br/> and impress your managers?</p1>
            </div>
            <div className="indivPost">
                <div className="articleimg"/>
                <h1 className="articletag">Design</h1>
                <h2 className="title">UX review presentations</h2>
                <p1 className="articleblurb">How do you create compelling <br/> presentations that wow your colleagues <br/> and impress your managers?</p1>
            </div>
            <div className="indivPost">
                <div className="articleimg"/>
                <h1 className="articletag">Design</h1>
                <h2 className="title">UX review presentations</h2>
                <p1 className="articleblurb">How do you create compelling <br/> presentations that wow your colleagues <br/> and impress your managers?</p1>
            </div>
            

            </div>
            {/* <div className="stratosstroke"/> */}
        </div>
    );
}

export default Blog;