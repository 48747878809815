import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import PostSkeleton from "../../../components/PostSkeleton";
import DisplayPostCard from "./DisplayPostCard";

const TabPanel = ({
  currentLayerName,
  setCurrentLayerName,
  setCurrentLayer,
  layers,
}) => {
  const { posts } = useSelector((state) => state.posts);
  const { notifications } = useSelector((state) => state.notifications);
  const [layerPosts, setLayerPosts] = useState([]);

  const dispatch = useDispatch();
  const { postid } = useParams();
  useEffect(() => {
    if (posts) {
      if (currentLayerName) {
        if (currentLayerName.trim().toLowerCase() === "all") {
          let all = [];
          for (const key in posts) {
            all.push(...posts[key]);
          }
          all.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
          setLayerPosts(all);
        } else {
          setLayerPosts(posts[currentLayerName.toUpperCase()]);
          const index = layers.indexOf(currentLayerName.toUpperCase());
          setCurrentLayer(index);
        }
      }
    }
  }, [posts, currentLayerName]);

  return (
    <>
      {layerPosts || layerPosts?.length > 0 ? (
        layerPosts?.map((post) => (
          <Box
            key={post.id}
            sx={{
              marginLeft: "5px",
              marginRight: "5px",
              marginTop: "20px",
              marginBottom: "20px",
            }}
          >
            <DisplayPostCard
              post={post}
              setCurrentLayerName={setCurrentLayerName}
            />
          </Box>
        ))
      ) : (
        <>
          <PostSkeleton />
          <PostSkeleton />
          <PostSkeleton />
        </>
      )}
    </>
  );
};

export default TabPanel;
