import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { Button, Paper, Skeleton, Stack, Typography } from "@mui/material";
import React from "react";
import "./WhatArePrompts.css";

const WhatArePrompts = () => {
  return (
    <Stack
      direction="column"
      spacing={2}
      justifyContent="center"
      alignItems="center"
      padding={2}
    >
      <Typography sx={{ fontSize: 20 }}>
        Prompts spark vibrant conversations with other professionals on Stratos.{" "}
      </Typography>
      <Paper
        elevation={2}
        sx={{
          borderRadius: 6,
          width: "100%",
          flexDirection: "column",
          padding: 1,
        }}
      >
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: "18",
            background: "#ffffff",
            color: "#000000",
            border: "none",
          }}
        >
          My unpopular opinion is....
        </Typography>
        <Button
          startIcon={<ArrowRightIcon sx={{ color: "#52B1BB" }} />}
          style={{ textTransform: "none" }}
          sx={{ padding: 0 }}
        >
          <Typography
            sx={{
              fontSize: "14",
              background: "#ffffff",
              color: "#52B1BB",
              border: "none",
            }}
            variant="body1"
          >
            Entrepreneurship
          </Typography>
        </Button>
      </Paper>
      <Paper
        elevation={2}
        sx={{
          borderRadius: 6,
          width: "100%",
          display: "flex",
          flexDirection: "column",
          padding: 1,
        }}
      >
        <Typography
          component="button"
          sx={{
            fontWeight: "bold",
            fontSize: "18",
            background: "#ffffff",
            color: "#000000",
            border: "none",
          }}
        >
          "Let’s chat if…"
        </Typography>
        <Button
          startIcon={<ArrowRightIcon sx={{ color: "#52B1BB" }} />}
          style={{ textTransform: "none" }}
          sx={{ padding: 0 }}
        >
          <Typography
            sx={{
              fontSize: "14",
              background: "#ffffff",
              color: "#52B1BB",
              border: "none",
            }}
            variant="body1"
          >
            ERG Leads
          </Typography>
        </Button>
      </Paper>
      <Paper
        elevation={2}
        sx={{
          borderRadius: 6,
          width: "100%",
          display: "flex",
          flexDirection: "column",
          padding: 1,
        }}
      >
        <Skeleton height={18} width="60%" sx={{ marginLeft: 8 }} />
        <Button
          startIcon={<ArrowRightIcon sx={{ color: "#52B1BB" }} />}
          style={{ textTransform: "none" }}
          sx={{ padding: 0 }}
        >
          <Skeleton height={14} width="20%" sx={{ bgcolor: "#52B1BB" }} />
        </Button>
      </Paper>
    </Stack>
  );
};
export default WhatArePrompts;
