import {
    Box,
    Button,
    Stack,
    TextField,
    Typography,
    alpha,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateAccountDetails } from "../../../features/auth/authSlice";

const AccountSettings = () => {
    const dispatch = useDispatch();

    const { user } = useSelector((state) => state.auth);

    const [email, setEmail] = useState(user?.email);
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    // for layout of textfields
    const sxForTextField = {
        "& .MuiFilledInput-root": {
            overflow: "hidden",
            borderRadius: "7px",
            backgroundColor: "#F3F9FB",
            border: "1px solid",
            borderColor: "#D2E1E2",
            transition: "border-color background-color box-shadow",
            "&:hover": {
                backgroundColor: "#F3F9FB",
            },
            "&.Mui-focused": {
                backgroundColor: "#F3F9FB",
                boxShadow: `${alpha("#000", 0.25)} 0 0 0 2px`,
                borderColor: "#D2E1E2",
            },
        },
    };
    const inputPropsForTextField = {
        style: {
            fontSize: 16,
            fontWeight: 300,
            color: "#2A2E33",
        },
    };

    const inputLabelPropsForTextfield = {
        style: {
            fontWeight: 400,
            fontSize: 12,
            color: "#16171B",
        },
    };

    const disableSaveButton = () => {};

    const saveChanges = () => {
        dispatch(
            updateAccountDetails({
                email,
                oldPassword,
                newPassword,
                confirmPassword,
            })
        );
    };

    return (
        <Box>
            <Typography
                sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-start",
                    m: 1,
                    fontWeight: 700,
                    fontSize: "20px",
                }}
            >
                Account Settings
            </Typography>

            <TextField
                name="email"
                onChange={(e) => setEmail(e.target.value)}
                size="small"
                value={email}
                label="Email"
                id="email"
                variant="filled"
                InputLabelProps={inputLabelPropsForTextfield}
                InputProps={{
                    disableUnderline: true,
                    classes: {
                        root: "MuiFilledInput-root",
                        focused: "Mui-focused",
                    },
                }}
                inputProps={inputPropsForTextField}
                sx={{ ...sxForTextField, width: "100%" }}
            />
            <Stack
                direction={{ xs: "column", sm: "column", md: "row" }}
                justifyContent="space-between"
                alignItems="flex-start"
                spacing={2}
                marginY={2}
                sx={{ width: "100%" }}
            >
                <TextField
                    name="oldPassword"
                    onChange={(e) => setOldPassword(e.target.value)}
                    size="small"
                    fullWidth
                    value={oldPassword}
                    label="Current Password"
                    id="oldPassword"
                    variant="filled"
                    InputLabelProps={inputLabelPropsForTextfield}
                    InputProps={{
                        disableUnderline: true,
                        classes: {
                            root: "MuiFilledInput-root",
                            focused: "Mui-focused",
                        },
                    }}
                    inputProps={inputPropsForTextField}
                    sx={sxForTextField}
                    type="password"
                />

                <TextField
                    name="newPassword"
                    onChange={(e) => setNewPassword(e.target.value)}
                    size="small"
                    fullWidth
                    value={newPassword}
                    label="New Password"
                    id="newPassword"
                    variant="filled"
                    InputLabelProps={inputLabelPropsForTextfield}
                    InputProps={{
                        disableUnderline: true,
                        classes: {
                            root: "MuiFilledInput-root",
                            focused: "Mui-focused",
                        },
                    }}
                    inputProps={inputPropsForTextField}
                    sx={sxForTextField}
                    type="password"
                />

                <TextField
                    name="confirmPassword"
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    size="small"
                    fullWidth
                    value={confirmPassword}
                    label="Confirm New Password"
                    id="confirmPassword"
                    variant="filled"
                    InputLabelProps={inputLabelPropsForTextfield}
                    InputProps={{
                        disableUnderline: true,
                        classes: {
                            root: "MuiFilledInput-root",
                            focused: "Mui-focused",
                        },
                    }}
                    inputProps={inputPropsForTextField}
                    sx={sxForTextField}
                    type="password"
                />
            </Stack>
            <Box
                sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                }}
            >
                <Button
                    onClick={saveChanges}
                    disabled={disableSaveButton()}
                    variant="outline"
                    sx={{
                        color: "#fff",
                        backgroundColor: "#52B1BB",
                        textTransform: "none",
                        "&:hover": {
                            backgroundColor: "#52B1BB",
                        },
                        width: { xs: "100%", sm: "100%", md: "auto" },
                    }}
                >
                    Save
                </Button>
            </Box>
        </Box>
    );
};

export default AccountSettings;
