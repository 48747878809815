import { Box, Button, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Spinner from "../../../components/Spinner";
import {
  getAllUserChats,
  resetChatMessages,
  setTempChats,
  updateCurrentChatUser,
} from "../../../features/messages/messageSlice";
import ChatListCard from "./ChatListCard";

const ChatLists = ({
  //setCurrentChatUser,
  setCurrentChatUserIndex,
  currentChatUserIndex,
  chatUserLocationState,
  setChatUserLocationState,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { tempChats, chats, isSuccess, isLoading, currentChatUser } =
    useSelector((state) => state.messages);

  // get all user chats
  useEffect(() => {
    dispatch(getAllUserChats());
  }, []);

  // if chats exist, set the tempchats variable to chats from BE
  useEffect(() => {
    // console.log("isSuccess", isSuccess);
    if (isSuccess && chats.length > 0) {
      // this sets current user to the tempchats 0 index
      dispatch(setTempChats());
    }
  }, [chats]);

  // when user selects another chat and current chat user changes, update currentChatUserIndex
  useEffect(() => {
    if (tempChats.length > 0 && currentChatUser) {
      console.log("currentChatUser", currentChatUser);
      // find that chat user in currents chats lists
      const otherUserId = currentChatUser.otherUser;
      const index = tempChats.findIndex((obj) => obj.otherUser === otherUserId);
      if (index !== -1) {
        console.log("Object found at index:", index);
        // set that user as current chat user
        //setCurrentChatUser(tempChats[index]);
        setCurrentChatUserIndex(index);
      }
    }
  }, [currentChatUser]);

  // if chatUserLocationState exists, set current chat user as this
  // (it will always exist in tempchats because when msg btn is clicked,
  // it adds that chat variable to tempchats)
  useEffect(() => {
    if (chatUserLocationState) {
      console.log("chatUserLocationState", chatUserLocationState);
      // find that chat user in currents chats lists
      const otherUserId = chatUserLocationState;
      const index = tempChats.findIndex((obj) => obj.otherUser === otherUserId);
      if (index !== -1) {
        console.log("Object found at index:", index);
        // set that user as current chat user
        dispatch(updateCurrentChatUser(tempChats[index].id));
        //setCurrentChatUser(tempChats[index]);
        setCurrentChatUserIndex(index);
        setChatUserLocationState("");
      }
    }
  }, []);

  // const [searchQuery, setSearchQuery] = useState("");

  // const filteredChats = tempChats.filter((chat) =>
  //     chat.otherUserData.name
  //         .toLowerCase()
  //         .includes(searchQuery.toLowerCase())
  // );

  if (!isLoading && isSuccess && tempChats.length === 0) {
    return (
      <Box textAlign="center">
        <Typography
          variant="body2"
          color="#52B1BB"
          textAlign={"center"}
          sx={{
            fontSize: "17px",
            whiteSpace: "nowrap",
            fontWeight: 550,
            marginTop: 3,
            marginBottom: 1,
          }}
        >
          🙄 you don't have any chats yet
        </Typography>
        <Typography
          variant="body2"
          textAlign={"center"}
          sx={{
            fontSize: "14px",
            whiteSpace: "nowrap",
            fontWeight: 500,
            m: 1,
          }}
        >
          Go strike up some conversations!
        </Typography>
        <Button
          textAlign={"center"}
          alignSelf={"center"}
          sx={{
            fontWeight: 400,
            fontSize: "14px",
            borderRadius: "27px",
            color: "white",
            backgroundColor: "#52B1BB",
            textTransform: "none",
            m: 1,
          }}
          onClick={() => navigate("/HomeScreen/Network")}
        >
          Head over to the Stratos Network 🌟
        </Button>
      </Box>
    );
  }

  return tempChats.length > 0 ? (
    <Box sx={{ width: "100%", overflowY: "auto" }}>
      {tempChats.map((chat, index) => (
        <Box
          onClick={() => {
            dispatch(resetChatMessages());
            dispatch(updateCurrentChatUser(chat.id));
            //setCurrentChatUser(chat);
            setCurrentChatUserIndex(index);
          }}
          key={chat.id}
        >
          <ChatListCard
            chat={chat}
            highlightChatCard={currentChatUserIndex == index ? true : false}
          />
        </Box>
      ))}
    </Box>
  ) : (
    <Spinner />
  );
};

export default ChatLists;
