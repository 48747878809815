import { ArrowForward } from "@mui/icons-material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Grow,
  Popover,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useNavigate } from "react-router-dom";
import AmazonLogo from "../../Assets/Partners/amazon.png";
import GoogleLogo from "../../Assets/Partners/google1.png";
import PatreonLogo from "../../Assets/Partners/patreon1.png";
import SageLogo from "../../Assets/Partners/sage1.png";
import XactlyLogo from "../../Assets/Partners/xactly1.png";
import Events from "../../Assets/event_manage.svg";
import Knowledge from "../../Assets/knowledge.svg";
import Members from "../../Assets/member_engagement.svg";
// import Resources from "../../Assets/resource_central.svg";
import Brain from "../../Assets/brain.svg";
import DoorClosed from "../../Assets/door-closed-blue.svg";
import DoorOpen from "../../Assets/door-open-blue.svg";
// import SkyBackground from "../../Assets/SkyBackground3.png";
import { companyIllustration } from "../../Assets/utilFunctions";
import { companies } from "../constants";

import "./Homepage2.css";

const Homepage = () => {
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const openPopup = Boolean(anchorEl);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          paddingX: { xs: 3, sm: 3, md: 7 },
          // background: "#FFFFFF", //"linear-gradient( #FFFFFF, #52B1BB)",
          // backgroundImage: url("../../Assets/SkyBackground3.png"),
          paddingBottom: 2,
          paddingTop: 10,
        }}
      >
        <Grow in {...{ timeout: 2000 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              paddingTop: 1,
              m: 1,
            }}
          >
            <Typography
              sx={{
                fontSize: "65px",
                lineHeight: 1,
                fontFamily: "Wix Madefor Text",
                color: "#333333",
                maxWidth: "900px",
                p: 2,
                fontWeight: "800",
              }}
            >
              Streamline company communication, collaboration and hiring
            </Typography>
            <Typography
              variant="h6"
              sx={{
                fontFamily: "Wix Madefor Text",
                fontWeight: "500",
                padding: 2,
                paddingLeft: 0,
                paddingBottom: 4,
                color: "#333333",
              }}
            >
              Breaking internal walls, providing global access
            </Typography>

            <Stack
              direction="row"
              spacing={3}
              justifyContent="flex-start"
              sx={{
                width: "100%",
                paddingY: 2,
                display: { xs: "none", sm: "none", md: "flex" },
              }}
            >
              <Stack
                direction="row"
                spacing={3}
                justifyContent="flex-start"
                sx={{
                  width: "30%",
                  background: "#FFF", // "linear-gradient(96.34deg, #52B1BB, #008997 100%)",
                  borderRadius: "14px",
                  borderColor: "#52B1BB",
                  border: 1,
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      fontWeight: "600",
                      fontFamily: "Wix Madefor Text",
                      color: "#333333",
                      paddingY: 1,
                    }}
                  >
                    ERG Leads
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: "300",
                      fontFamily: "Wix Madefor Text",
                      color: "#333333",
                      paddingY: 1,
                    }}
                  >
                    Interested in onboarding your group? Let's chat.
                  </Typography>
                  <Button
                    sx={{
                      color: "#FFFFFF",
                      background:
                        "linear-gradient(96.34deg, #52B1BB, #008997 100%)",
                      borderRadius: "32px",
                      fontSize: "15px",
                      height: "40px",
                      cursor: "pointer",
                      border: "none",
                      textTransform: "none",
                      paddingY: 1,
                      margin: 1,
                    }}
                    variant="contained"
                    href="https://calendly.com/trystratos"
                  >
                    <span style={{ verticalAlign: "middle" }}>Book a call</span>
                    <ArrowForward
                      style={{
                        verticalAlign: "middle",
                        paddingLeft: 0.5,
                      }}
                    />
                  </Button>
                </Box>
              </Stack>

              <Stack
                direction="row"
                spacing={3}
                justifyContent="flex-start"
                sx={{
                  width: "30%",
                  background: "#FFF", // "linear-gradient(96.34deg, #52B1BB, #008997 100%)",
                  borderRadius: "14px",
                  borderColor: "#52B1BB",
                  border: 1,
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      fontWeight: "600",
                      fontFamily: "Wix Madefor Text",
                      color: "#333333",
                      padding: 1,
                    }}
                  >
                    Individual User
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: "300",
                      fontFamily: "Wix Madefor Text",
                      color: "#333333",
                      padding: 1,
                    }}
                  >
                    If you work at an{" "}
                    <span
                      style={{
                        fontWeight: "bolder",
                        cursor: "pointer",
                      }}
                      aria-owns={openPopup ? "mouse-over-popover" : undefined}
                      aria-haspopup="true"
                      onMouseEnter={handlePopoverOpen}
                      onMouseLeave={handlePopoverClose}
                    >
                      established partner company
                    </span>
                    , you can create an account
                  </Typography>
                  <Popover
                    id="mouse-over-popover"
                    sx={{
                      pointerEvents: "none",
                    }}
                    open={openPopup}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    onClose={handlePopoverClose}
                    disableRestoreFocus
                  >
                    <Stack direction={"row"}>
                      {companies.map(
                        (company) =>
                          !["Non-Partner Company", "Stealth"].includes(
                            company
                          ) && (
                            <Stack alignItems={"center"}>
                              <Avatar
                                src={companyIllustration(company)}
                                sx={{
                                  width: "40px",
                                  height: "40px",
                                  margin: 1,
                                  borderRadius: 0,
                                }}
                              />
                              <Typography sx={{ p: 1 }}>{company}</Typography>
                            </Stack>
                          )
                      )}
                    </Stack>
                  </Popover>
                  <Button
                    sx={{
                      color: "#FFFFFF",
                      background:
                        "linear-gradient(96.34deg, #52B1BB, #008997 100%)",
                      borderRadius: "32px",
                      fontSize: "15px",
                      height: "40px",
                      cursor: "pointer",
                      border: "none",
                      textTransform: "none",
                      marginBottom: 2,
                    }}
                    variant="contained"
                    onClick={() => navigate("/SignUp")}
                  >
                    <span style={{ verticalAlign: "middle" }}>Sign Up</span>
                    <ArrowForward
                      style={{
                        verticalAlign: "middle",
                        paddingLeft: 0.5,
                      }}
                    />
                  </Button>
                </Box>
              </Stack>

              <Stack
                direction="row"
                spacing={3}
                justifyContent="flex-start"
                sx={{
                  width: "30%",
                  background: "#FFF", // "linear-gradient(96.34deg, #52B1BB, #008997 100%)",
                  borderRadius: "14px",
                  borderColor: "#52B1BB",
                  border: 1,
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      fontWeight: "600",
                      fontFamily: "Wix Madefor Text",
                      color: "#333333",
                      paddingY: 1,
                    }}
                  >
                    Not at a Partner Company?
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: "300",
                      fontFamily: "Wix Madefor Text",
                      color: "#333333",
                      paddingY: 1,
                    }}
                  >
                    Get on our waitlist. We approve a select amount of
                    individual accounts every week.
                  </Typography>
                  <div
                    className="backdoor-block"
                    onMouseOver={() => setOpen(true)}
                    onMouseOut={() => setOpen(false)}
                    style={{ cursor: "pointer" }}
                    onClick={() => navigate("/Request")}
                  >
                    <img src={open ? DoorOpen : DoorClosed} width={"50 px"} />
                  </div>
                </Box>
              </Stack>
            </Stack>
          </Box>
        </Grow>

        <Divider sx={{ marginTop: "70px" }} />

        <Typography
          align="center"
          variant="h4"
          sx={{
            fontFamily: "Wix Madefor Text",
            fontWeight: "800",
            fontSize: "45px",
            p: 1,
            marginTop: "75px", //"75px",
            marginBottom: "25px",
            color: "#333333",
          }}
        >
          Our Partners
        </Typography>
        <Carousel
          // arrows={false}
          className="gd-carousel"
          responsive={responsive}
          ssr={true} // means to render carousel on server-side.
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={3000}
          containerClass="carousel-container"
          removeArrowOnDeviceType={["tablet", "mobile"]}
          itemClass="carousel-item-padding-40-px"
          buttonColor="#52B1BB"
          //   backgroundColor="#52B1BB"
        >
          <img
            style={{
              display: "block",
              height: "100px",
              marginLeft: "auto",
              marginRight: "auto",
            }}
            src={GoogleLogo}
          />
          <img
            style={{
              display: "block",
              height: "100px",
              marginLeft: "auto",
              marginRight: "auto",
            }}
            src={AmazonLogo}
          />
          <img
            style={{
              display: "block",
              height: "100px",
              marginLeft: "auto",
              marginRight: "auto",
            }}
            src={PatreonLogo}
          />
          <img
            style={{
              display: "block",
              height: "100px",
              marginLeft: "auto",
              marginRight: "auto",
            }}
            src={SageLogo}
          />
          <img
            style={{
              display: "block",
              height: "100px",
              marginLeft: "auto",
              marginRight: "auto",
            }}
            src={XactlyLogo}
          />
        </Carousel>

        <Box
          sx={{
            display: { xs: "none", sm: "none", md: "grid" },
            flexDirection: "column",
            alignItems: "center",
            padding: 2,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <Stack
            direction={"column"}
            display={"flex"}
            alignSelf={"center"}
            width={"100%"}
          >
            <Typography
              align="center"
              sx={{
                fontSize: "25px",
                fontFamily: "Wix Madefor Text",
                paddingBottom: 1.5,
                marginTop: "75px",
                color: "#333333",
              }}
            >
              Connect your employee resource group (ERG) members with
            </Typography>
            <Typography
              align="center"
              sx={{
                fontSize: "40px",
                fontFamily: "Wix Madefor Text",
                fontWeight: "800",
                color: "#333333",
                marginBottom: 3,
              }}
            >
              top professionals
            </Typography>
          </Stack>

          <Stack
            direction={"column"}
            display={"inline-block"}
            textAlign={"left"}
          >
            <Stack direction={"row"} alignItems={"center"}>
              <CheckCircleIcon />
              <Typography
                align="center"
                alignContent={"center"}
                alignItems={"center"}
                justifyContent={"center"}
                sx={{
                  fontSize: "20px",
                  fontFamily: "Wix Madefor Text",
                  color: "#333333",
                  p: 1,
                }}
              >
                Streamline ERG communication for efficient information exchange
              </Typography>
            </Stack>

            <Stack direction={"row"} alignItems={"center"}>
              <CheckCircleIcon />
              <Typography
                alignContent={"center"}
                alignItems={"center"}
                justifyContent={"center"}
                sx={{
                  fontSize: "20px",
                  fontFamily: "Wix Madefor Text",
                  color: "#333333",
                  p: 1,
                }}
              >
                Fuel ERG learning with shared insights and best practices
              </Typography>
            </Stack>
            <Stack direction={"row"} alignItems={"center"}>
              <CheckCircleIcon />
              <Typography
                align="center"
                alignContent={"center"}
                alignItems={"center"}
                justifyContent={"center"}
                sx={{
                  fontSize: "20px",
                  fontFamily: "Wix Madefor Text",
                  color: "#333333",
                  p: 1,
                }}
              >
                Boost ERG engagement with interactive features, fostering a
                vibrant community
              </Typography>
            </Stack>
          </Stack>
        </Box>
        <Divider sx={{ marginTop: 4 }} />

        <Typography
          align="center"
          variant="h4"
          sx={{
            fontWeight: "700",
            fontSize: "45px",
            // paddingY: 1,
            marginTop: "75px",
            fontFamily: "Wix Madefor Text",
            color: "#333333",
          }}
        >
          Features
        </Typography>
        <Typography
          variant="h6"
          align="center"
          sx={{
            fontFamily: "Wix Madefor Text",
            fontWeight: "500",
            paddingY: 4,
            color: "#333333",
          }}
        >
          Here are some key elements to propel your employee experience to the
          next level.
        </Typography>
        <Stack
          direction="row"
          spacing={3}
          justifyContent="flex-start"
          sx={{
            width: "100%",
            paddingY: 2,
            display: { xs: "none", sm: "none", md: "flex" },
          }}
        >
          <Stack
            direction="row"
            spacing={3}
            justifyContent="flex-start"
            sx={{
              width: "50%",
              background: "#FFF", // "linear-gradient(96.34deg, #52B1BB, #008997 100%)",
              borderRadius: "14px",
              borderColor: "#52B1BB",
              border: 1,
              paddingY: 4,
            }}
          >
            <Box
              sx={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                margin: 0,
              }}
            >
              <Avatar
                alt="What is stratos"
                src={Brain}
                sx={{
                  display: "flex",
                  borderRadius: 0,
                  bgcolor: "transparent",
                  width: "100px",
                  paddingLeft: 3,
                  color: "#333333",
                }}
                imgProps={{
                  style: { objectFit: "contain" },
                }}
              />
            </Box>
            <Box sx={{ paddingY: 3 }}>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "600",
                  fontFamily: "Wix Madefor Text",
                  color: "#333333",
                  padding: 1,
                  textAlign: "left",
                }}
              >
                Knowledge Sharing
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontWeight: "300",
                  fontFamily: "Wix Madefor Text",
                  color: "#333333",
                  textAlign: "left",
                  padding: 1,
                }}
              >
                Facilitate ongoing improvement by sharing knowledge within ERG
                for enhanced efficiency
              </Typography>
            </Box>
          </Stack>

          <Stack
            direction="row"
            spacing={3}
            justifyContent="flex-start"
            sx={{
              width: "50%",
              background: "#FFF", // "linear-gradient(96.34deg, #52B1BB, #008997 100%)",
              borderRadius: "14px",
              borderColor: "#52B1BB",
              border: 1,
              paddingY: 4,
            }}
          >
            <Box
              sx={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                margin: 0,
              }}
            >
              <Avatar
                alt="Members"
                src={Members}
                sx={{
                  display: "flex",
                  borderRadius: 0,
                  bgcolor: "transparent",
                  width: "100px",
                  paddingLeft: 3,
                  color: "#333333",
                }}
                imgProps={{
                  style: { objectFit: "contain" },
                }}
              />
            </Box>
            <Box sx={{ paddingY: 3 }}>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "600",
                  fontFamily: "Wix Madefor Text",
                  color: "#333333",
                  paddingY: 1,
                  textAlign: "left",
                }}
              >
                Member Engagement
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontWeight: "300",
                  fontFamily: "Wix Madefor Text",
                  color: "#333333",
                  textAlign: "left",
                  paddingY: 1,
                }}
              >
                We boost team involvement with features encouraging active
                participation, fostering a connected and productive ERG
                community
              </Typography>
            </Box>
          </Stack>
        </Stack>

        <Stack
          direction="row"
          spacing={3}
          justifyContent="flex-start"
          sx={{
            width: "100%",
            paddingY: 2,
            display: { xs: "none", sm: "none", md: "flex" },
          }}
        >
          <Stack
            direction="row"
            spacing={3}
            justifyContent="flex-start"
            sx={{
              width: "50%",
              background: "#FFF", // "linear-gradient(96.34deg, #52B1BB, #008997 100%)",
              borderRadius: "14px",
              borderColor: "#52B1BB",
              border: 1,
              paddingY: 4,
            }}
          >
            <Box
              sx={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                margin: 0,
              }}
            >
              <Avatar
                alt="resource cent"
                src={Knowledge}
                sx={{
                  display: "flex",
                  borderRadius: 0,
                  bgcolor: "transparent",
                  width: "100px",
                  paddingLeft: 3,
                  color: "#333333",
                }}
                imgProps={{
                  style: { objectFit: "contain" },
                }}
              />
            </Box>
            <Box sx={{ paddingY: 3 }}>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "600",
                  fontFamily: "Wix Madefor Text",
                  color: "#333333",
                  padding: 1,
                  textAlign: "left",
                }}
              >
                Resource Centralization
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontWeight: "300",
                  fontFamily: "Wix Madefor Text",
                  color: "#333333",
                  textAlign: "left",
                  padding: 1,
                }}
              >
                We improve productivity with a centralized ERG hub, saving time
                and enhancing accessibility for resources and documents
              </Typography>
            </Box>
          </Stack>

          <Stack
            direction="row"
            spacing={3}
            justifyContent="flex-start"
            sx={{
              width: "50%",
              background: "#FFF", // "linear-gradient(96.34deg, #52B1BB, #008997 100%)",
              borderRadius: "14px",
              borderColor: "#52B1BB",
              border: 1,
              paddingY: 4,
            }}
          >
            <Box
              sx={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                margin: 0,
              }}
            >
              <Avatar
                alt="Events"
                src={Events}
                sx={{
                  display: "flex",
                  borderRadius: 0,
                  bgcolor: "transparent",
                  width: "100px",
                  paddingLeft: 3,
                  color: "#333333",
                }}
                imgProps={{
                  style: { objectFit: "contain" },
                }}
              />
            </Box>
            <Box sx={{ paddingY: 3 }}>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "600",
                  fontFamily: "Wix Madefor Text",
                  color: "#333333",
                  paddingY: 1,
                  textAlign: "left",
                }}
              >
                Event Management
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontWeight: "300",
                  fontFamily: "Wix Madefor Text",
                  color: "#333333",
                  textAlign: "left",
                  paddingY: 1,
                }}
              >
                We enhance ERG event productivity with the Spotlight Series,
                streamlining planning for success
              </Typography>
            </Box>
          </Stack>
        </Stack>

        {/* Mobile Version */}

        <Box
          sx={{
            display: { xs: "flex", sm: "flex", md: "none" },
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            padding: 1,
          }}
        >
          <Stack
            direction="column"
            spacing={1}
            justifyContent="center"
            alignItems={"center"}
            sx={{
              width: "100%",
              // background: "linear-gradient(96.34deg, #52B1BB, #008997 100%)",
              // borderRadius: "10px",
              background: "#FFF", // "linear-gradient(96.34deg, #52B1BB, #008997 100%)",
              borderRadius: "14px",
              borderColor: "#52B1BB",
              border: 1,
              paddingX: 1,
              paddingY: 4,
              margin: 1,
            }}
          >
            <Avatar
              alt="knowledge sharing"
              src={Brain}
              sx={{
                display: "flex",
                borderRadius: 0,
                bgcolor: "transparent",
                width: "100px",
              }}
              imgProps={{
                style: {
                  objectFit: "contain",
                },
              }}
            />
            <Typography
              variant="h5"
              sx={{
                fontWeight: "600",
                color: "#16171B",
                padding: 1,
                textAlign: "center",
              }}
            >
              Knowledge Sharing
            </Typography>

            <Box sx={{ width: "100%" }}>
              <Typography
                variant="body1"
                sx={{
                  fontWeight: "300",
                  color: "#4B4E58",
                  textAlign: "left",
                  padding: 1,
                }}
              >
                Facilitate ongoing improvement by sharing knowledge within ERG
                for enhanced efficiency
              </Typography>
            </Box>
          </Stack>

          <Stack
            direction="column"
            spacing={1}
            justifyContent="center"
            alignItems={"center"}
            sx={{
              width: "100%",
              // background: "linear-gradient(96.34deg, #52B1BB, #008997 100%)",
              // borderRadius: "10px",
              background: "#FFF", // "linear-gradient(96.34deg, #52B1BB, #008997 100%)",
              borderRadius: "14px",
              borderColor: "#52B1BB",
              border: 1,
              paddingX: 1,
              paddingY: 4,
              margin: 1,
            }}
          >
            <Avatar
              alt="Member Engagement"
              src={Members}
              sx={{
                display: "flex",
                borderRadius: 0,
                bgcolor: "transparent",
                width: "100px",
              }}
              imgProps={{
                style: {
                  objectFit: "contain",
                },
              }}
            />
            <Typography
              variant="h5"
              sx={{
                fontWeight: "600",
                color: "#16171B",
                padding: 1,
                textAlign: "center",
              }}
            >
              Member Engagement
            </Typography>

            <Box sx={{ width: "100%" }}>
              <Typography
                variant="body1"
                sx={{
                  fontWeight: "300",
                  color: "#4B4E58",
                  textAlign: "left",
                  padding: 1,
                }}
              >
                We boost team involvement with features encouraging active
                participation, fostering a connected and productive ERG
                community
              </Typography>
            </Box>
          </Stack>

          <Stack
            direction="column"
            spacing={1}
            justifyContent="center"
            alignItems={"center"}
            sx={{
              width: "100%",
              // background: "linear-gradient(96.34deg, #52B1BB, #008997 100%)",
              // borderRadius: "10px",
              background: "#FFF", // "linear-gradient(96.34deg, #52B1BB, #008997 100%)",
              borderRadius: "14px",
              borderColor: "#52B1BB",
              border: 1,
              paddingX: 1,
              paddingY: 4,
              margin: 1,
            }}
          >
            <Avatar
              alt="resource cent"
              src={Knowledge}
              sx={{
                display: "flex",
                borderRadius: 0,
                bgcolor: "transparent",
                width: "100px",
              }}
              imgProps={{
                style: {
                  objectFit: "contain",
                },
              }}
            />
            <Typography
              variant="h5"
              sx={{
                fontWeight: "600",
                color: "#16171B",
                padding: 1,
                textAlign: "center",
              }}
            >
              Resource Centralization
            </Typography>

            <Box sx={{ width: "100%" }}>
              <Typography
                variant="body1"
                sx={{
                  fontWeight: "300",
                  color: "#4B4E58",
                  textAlign: "left",
                  padding: 1,
                }}
              >
                We improve productivity with a centralized ERG hub, saving time
                and enhancing accessibility for resources and documents
              </Typography>
            </Box>
          </Stack>

          <Stack
            direction="column"
            spacing={1}
            justifyContent="center"
            alignItems={"center"}
            sx={{
              width: "100%",
              // background: "linear-gradient(96.34deg, #52B1BB, #008997 100%)",
              // borderRadius: "10px",
              background: "#FFF", // "linear-gradient(96.34deg, #52B1BB, #008997 100%)",
              borderRadius: "14px",
              borderColor: "#52B1BB",
              border: 1,
              paddingX: 1,
              paddingY: 4,
              margin: 1,
            }}
          >
            <Avatar
              alt="Events"
              src={Events}
              sx={{
                display: "flex",
                borderRadius: 0,
                bgcolor: "transparent",
                width: "100px",
              }}
              imgProps={{
                style: {
                  objectFit: "contain",
                },
              }}
            />
            <Typography
              variant="h5"
              sx={{
                fontWeight: "600",
                color: "#16171B",
                padding: 1,
                textAlign: "center",
              }}
            >
              Event Management
            </Typography>

            <Box sx={{ width: "100%" }}>
              <Typography
                variant="body1"
                sx={{
                  fontWeight: "300",
                  color: "#4B4E58",
                  textAlign: "left",
                  padding: 1,
                }}
              >
                We enhance ERG event productivity with the Spotlight Series,
                streamlining planning for success
              </Typography>
            </Box>
          </Stack>
        </Box>
      </Box>

      <Divider sx={{ marginTop: 4 }} />
    </>
  );
};

export default Homepage;
