import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    Chip,
    MenuItem,
    Select,
    Stack,
    Typography,
} from "@mui/material";

import { GiHamburgerMenu } from "react-icons/gi";
import { RxCrossCircled } from "react-icons/rx";

import { useDispatch, useSelector } from "react-redux";
import { updateUserInterests } from "../../../features/auth/authSlice";

const AddInterests = ({ handleAddInterestsClose }) => {
    const { user } = useSelector((state) => state.auth);

    const [userInterests, setUserInterests] = useState(user.interests);

    const allInterests = [
        "👨🏽‍🏫 Mentor-mentee",
        "💼 Job referral",
        "🫱🏽‍🫲🏼 In-person networking events",
        "💡 Career help/promotion advice",
        "👩🏽‍💻 Online Workshop/spotlight series",
        "📰 stay up-to-date with industry trends(news)",
        "🍻 In-person hangouts",
        "📑 Educational resources",
        "💬 Group discussion: based on topics, identity, etc,",
        "🗂️ Looking for side-project partners",
        "🗓️ Exposure (especially for KOL, event organizers and influencers)",
    ];

    const dispatch = useDispatch();

    const handleChangeSelect = (event) => {
        const {
            target: { value },
        } = event;
        console.log(value);
        setUserInterests(value);
    };

    const handleConfirm = () => {
        handleAddInterestsClose();
        dispatch(updateUserInterests(userInterests));
    };

    return (
        <Box
            sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                padding: 1,
                background: "white",
                justifyContent: "flex-start",
                alignItems: "center",
            }}
        >
            <Box
                sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    padding: 1,
                    height: "auto",
                    alignItems: "center",
                    mb: 20,
                }}
            >
                <Select
                    sx={{
                        width: "80%",
                        height: "auto",
                        color: "#52B1BB",
                        border: "1px solid #52B1BB",
                    }}
                    placeholder="Select Interests"
                    multiple
                    value={userInterests ? userInterests : []}
                    onChange={handleChangeSelect}
                    renderValue={(selected) => (
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: 0.5,
                            }}
                        >
                            {selected.map((value) => (
                                <Chip key={value} label={value} />
                            ))}
                        </Box>
                    )}
                >
                    {allInterests.map((interest, index) => (
                        <MenuItem key={index} value={interest}>
                            {interest}
                        </MenuItem>
                    ))}
                </Select>
            </Box>

            <Box
                sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Button
                    variant="outlined"
                    sx={{
                        width: "20%",
                        m: 1,
                        color: "#FFFFFF",
                        background: "#52B1BB",
                        display: "block",
                        borderRadius: "32px",
                        paddingY: 1,
                        paddingX: 3,
                        textTransform: "none",
                    }}
                    onClick={() => handleAddInterestsClose()}
                >
                    Cancel
                </Button>
                <Button
                    variant="outlined"
                    disabled={userInterests?.length < 1}
                    sx={{
                        width: "20%",
                        m: 1,
                        color: "#52B1BB",
                        background: "#FFFFFF",
                        display: "block",
                        borderRadius: "32px",
                        paddingY: 1,
                        paddingX: 3,
                        textTransform: "none",
                    }}
                    onClick={handleConfirm}
                >
                    Save
                </Button>
            </Box>
        </Box>
    );
};

export default AddInterests;
