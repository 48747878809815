import React from "react";

import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

const TermsOfService = ({ handleClose, open }) => {
  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle
        style={{
          textAlign: "center",
        }}
      >
        Stratos Terms of Service
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          sx={{
            textAlign: "center",
            fontFamily: "serif",
            paddingBottom: 2,
          }}
        >
          Stratos Solutions Inc.
        </DialogContentText>
        <DialogContentText
          sx={{
            textAlign: "center",
            fontFamily: "serif",
          }}
        >
          Terms of Service Agreement
        </DialogContentText>
        <DialogContentText
          sx={{
            textAlign: "center",
            fontFamily: "serif",
          }}
        >
          Effective Date: April 6, 2023
        </DialogContentText>
        <DialogContentText
          sx={{
            textAlign: "center",
            fontFamily: "serif",
            paddingBottom: 1,
          }}
        >
          Last Updated: December 12, 2023
        </DialogContentText>
        <DialogContentText
          sx={{
            // textAlign: "center",
            fontFamily: "serif",
            paddingTop: 1,
            paddingBottom: 1,
          }}
        >
          PLEASE READ THIS TERMS OF SERVICE AGREEMENT (THE “
          <span
            style={{
              fontWeight: "bolder",
            }}
          >
            TERMS OF SERVICE
          </span>
          ”) CAREFULLY. THESE TERMS OF SERVICE ARE AN AGREEMENT BETWEEN YOU AND
          STRATOS SOLUTIONS INC., a DELAWARE CORPORATION (“
          <span
            style={{
              fontWeight: "bolder",
            }}
          >
            STRATOS
          </span>
          ,” “
          <span
            style={{
              fontWeight: "bolder",
            }}
          >
            WE
          </span>
          ,” OR “
          <span
            style={{
              fontWeight: "bolder",
            }}
          >
            US
          </span>
          ”), AND GOVERN YOUR ACCESS AND USE OF STRATOS’ WEBSITE(S),
          STRATOS-BRANDED APPS AND OTHER STRATOS-RELATED SITES, COMMUNICATIONS,
          APPS AND OTHER SERVICES TO WHICH THESE TERMS OF SERVICE ARE LINKED,
          INCLUDING THE SERVICES AND RESOURCES AVAILABLE ON OR ENABLED THEREON
          (COLLECTIVELY, THE “
          <span
            style={{
              fontWeight: "bolder",
            }}
          >
            SERVICES
          </span>
          ”). REGISTERED USERS OF OUR SERVICES ARE “
          <span
            style={{
              fontWeight: "bolder",
            }}
          >
            MEMBERS
          </span>
          ”AND UNREGISTERED USERS ARE “
          <span
            style={{
              fontWeight: "bolder",
            }}
          >
            VISITORS
          </span>
          ”. BY CLICKING “I ACCEPT” OR SIMILAR, REGISTERING FOR AN ACCOUNT (AS
          DEFINED BELOW), ACCESSING OUR SERVICES AND/OR USING OUR SERVICES YOU
          REPRESENT THAT (1) YOU HAVE READ, UNDERSTAND, AND AGREE TO BE BOUND BY
          THESE TERMS OF SERVICE, (2) YOU ARE OF LEGAL AGE TO FORM A BINDING
          CONTRACT WITH STRATOS WITHOUT PARENTAL CONSENT (INCLUDING USING OF
          YOUR PERSONAL DATA), AND (3) YOU HAVE THE RIGHT, AUTHORITY, AND
          CAPACITY TO ENTER INTO THE TERMS OF SERVICE (ON BEHALF OF YOURSELF
          AND, AS APPLICABLE, THE ENTITY THAT YOU REPRESENT). IF THE INDIVIDUAL
          ENTERING INTO THESE TERMS OR OTHERWISE ACCESSING OR USING THE SERVICES
          IS DOING SO ON BEHALF OF, OR WITHIN HIS, HER OR THEIR CAPACITY AS A
          REPRESENTATIVE, AGENT, OR EMPLOYEE OF AN ENTITY, SUCH INDIVIDUAL AND
          SUCH ENTITY AGREE THAT: (i) “
          <span
            style={{
              fontWeight: "bolder",
            }}
          >
            YOU
          </span>
          ” AND “
          <span
            style={{
              fontWeight: "bolder",
            }}
          >
            YOUR
          </span>
          ” AS USED HEREIN APPLY TO SUCH ENTITY AND, AS APPLICABLE, SUCH
          INDIVIDUAL; AND (ii) YOU REPRESENT AND WARRANT THAT THE INDIVIDUAL
          ENTERING INTO THESE TERMS HAS THE POWER, RIGHT, AUTHORITY, AND
          CAPACITY TO ENTER INTO THESE TERMS ON BEHALF OF SUCH ENTITY.{" "}
          <span
            style={{
              fontWeight: "bolder",
              marginBottom: 2,
            }}
          >
            IF YOU DO NOT AGREE TO BE BOUND BY THE TERMS OF SERVICE, YOU MAY NOT
            ACCESS OR USE THE SERVICES.
          </span>
        </DialogContentText>
        <DialogContentText
          sx={{
            fontFamily: "serif",
            fontWeight: "bolder",
            paddingTop: 1,
            paddingBottom: 1,
          }}
        >
          PLEASE BE AWARE THAT SECTION 10 (DISPUTE RESOLUTION) OF THIS
          AGREEMENT, BELOW, CONTAINS PROVISIONS GOVERNING HOW DISPUTES THAT YOU
          AND WE HAVE AGAINST EACH OTHER ARE RESOLVED, INCLUDING, WITHOUT
          LIMITATION, ANY DISPUTES THAT AROSE OR WERE ASSERTED PRIOR TO THE
          EFFECTIVE DATE OF THIS AGREEMENT. IN PARTICULAR, IT CONTAINS AN
          ARBITRATION AGREEMENT WHICH WILL, WITH LIMITED EXCEPTIONS, REQUIRE
          DISPUTES BETWEEN US TO BE SUBMITTED TO BINDING AND FINAL ARBITRATION.
          UNLESS YOU OPT OUT OF THE ARBITRATION AGREEMENT: (1) YOU WILL ONLY BE
          PERMITTED TO PURSUE DISPUTES OR CLAIMS AND SEEK RELIEF AGAINST US ON
          AN INDIVIDUAL BASIS, NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY CLASS
          OR REPRESENTATIVE ACTION OR PROCEEDING; AND (2) YOU ARE WAIVING YOUR
          RIGHT TO PURSUE DISPUTES OR CLAIMS AND SEEK RELIEF IN A COURT OF LAW
          AND TO HAVE A JURY TRIAL.
        </DialogContentText>
        <DialogContentText
          sx={{
            fontFamily: "serif",
            fontWeight: "bolder",
            paddingTop: 1,
            paddingBottom: 1,
          }}
        >
          ANY DISPUTE, CLAIM OR REQUEST FOR RELIEF RELATING IN ANY WAY TO YOUR
          USE OF THE SERVICES WILL BE GOVERNED AND INTERPRETED BY AND UNDER THE
          LAWS OF THE STATE OF DELAWARE, CONSISTENT WITH THE FEDERAL ARBITRATION
          ACT, WITHOUT GIVING EFFECT TO ANY PRINCIPLES THAT PROVIDE FOR THE
          APPLICATION OF THE LAW OF ANY OTHER JURISDICTION.
        </DialogContentText>
        <DialogContentText
          sx={{
            fontFamily: "serif",
            fontWeight: "bolder",
            paddingTop: 1,
            paddingBottom: 1,
          }}
        >
          PLEASE BE AWARE THAT SECTION 12.2 (STRATOS COMMUNICATIONS) OF THIS
          AGREEMENT, BELOW, CONTAINS YOUR OPT-IN CONSENT TO RECEIVE
          COMMUNICATIONS FROM US, INCLUDING VIA EMAIL, TEXT MESSAGE, CALLS AND
          PUSH NOTIFICATION.
        </DialogContentText>
        <DialogContentText
          sx={{
            fontFamily: "serif",
            paddingTop: 1,
            paddingBottom: 1,
          }}
        >
          Your use of, and participation in, certain portions of the Services
          may be subject to additional terms (“
          <span
            style={{
              fontWeight: "bolder",
            }}
          >
            Supplemental Terms
          </span>
          ”) and such Supplemental Terms will either be listed in the Terms of
          Service or will be presented to you for your acceptance when you sign
          up to use the supplemental portions of the Services. If the Terms of
          Service are inconsistent with the Supplemental Terms, the Supplemental
          Terms shall control with respect to such portions of the Services. The
          Terms of Service and any applicable Supplemental Terms are referred to
          herein as the “
          <span
            style={{
              fontWeight: "bolder",
            }}
          >
            Agreement
          </span>
          .” PLEASE NOTE THAT THE AGREEMENT IS SUBJECT TO CHANGE BY STRATOS IN
          ITS SOLE DISCRETION AT ANY TIME IN ACCORDANCE WITH SECTION 12.1.
          PLEASE REGULARLY CHECK THE TERMS OF SERVICE TO VIEW THE THEN-CURRENT
          TERMS.
        </DialogContentText>

        <DialogContentText
          sx={{
            fontFamily: "serif",
            fontWeight: "bolder",
            paddingTop: 1,
            textIndent: "5%",
          }}
        >
          1. STRATOS SERVICES.
        </DialogContentText>
        <DialogContentText
          sx={{
            fontFamily: "serif",
            textIndent: "15%",
          }}
        >
          <span
            style={{
              fontWeight: "bolder",
            }}
          >
            1.1 Overview.{" "}
          </span>
          Stratos is an Atlanta-based, TechStars-backed social network that
          connects ERGs across the country. We drive value by offering safe
          places to find community with other ERG group members.
        </DialogContentText>
        <DialogContentText
          sx={{
            fontFamily: "serif",
            textIndent: "15%",
          }}
        >
          <span
            style={{
              fontWeight: "bolder",
            }}
          >
            1.2 Registering Your Account.{" "}
          </span>
          To access and/or use certain features of the Services you may be
          required to register for an account on the Services (“
          <span
            style={{
              fontWeight: "bolder",
            }}
          >
            Account
          </span>
          ”). You represent and warrant that all information that you provide in
          registering an Account is, and at all times will be kept, true,
          accurate, and complete. You are responsible for all activities that
          occur under your Account unless you close it or report misuse. You
          agree (i) not to create an Account using a false identity or
          information, or on behalf of someone other than yourself, (ii) to use
          a strong password and keep it confidential, (iii) not transfer any
          part of your Account and (iv) follow the law and any Stratos rules and
          community policies. As between you and others, your Account belongs to
          you. However, if the Services were purchased by another party for you
          to use, the party paying for such Service has the right to control
          access to and get reports on your use of such paid Services; however,
          they do not have rights to your personal account.
        </DialogContentText>

        <DialogContentText
          sx={{
            fontFamily: "serif",
            fontWeight: "bolder",
            paddingTop: 1,
            textIndent: "5%",
          }}
        >
          2. LIMITS AND RULES OF BEHAVIOR.
        </DialogContentText>
        <DialogContentText
          sx={{
            fontFamily: "serif",
            textIndent: "15%",
          }}
        >
          <span
            style={{
              fontWeight: "bolder",
            }}
          >
            2.1 Limits.{" "}
          </span>
          Stratos reserves the right to limit your use of the Services,
          including the number of your connections and your ability to contact
          other Members. Stratos reserves the right to restrict, suspend, or
          terminate your account if you breach this Agreement or the law or are
          misusing, in the sole discretion of Stratos, the Services. We may
          change, suspend or discontinue any of our Services. We may also modify
          our prices effective prospectively upon reasonable notice to the
          extent allowed under the law.
        </DialogContentText>
        <DialogContentText
          sx={{
            fontFamily: "serif",
            textIndent: "15%",
          }}
        >
          <span
            style={{
              fontWeight: "bolder",
            }}
          >
            2.2 Rules of Behavior.{" "}
          </span>
          You agree that you will (i) comply with all applicable laws,
          including, without limitation, privacy laws, intellectual property
          laws, anti-spam laws, export control laws, tax laws, and regulatory
          requirements, (ii) provide accurate information to Stratos and keep
          such information updated, (iii) use your real name on your profile and
          (iv) use the Services in a professional and responsible manner. You
          agree that you will not (i) create a false identity, misrepresent your
          identity, create a Member profile for anyone other than yourself (a
          real person), or use or attempt to use another’s account, (ii)
          develop, support or use software, devices, scripts, robots or any
          other means or processes (including crawlers, browser plugins and
          add-ons or any other technology) to scrape the Services or otherwise
          copy profiles and other data from the Services, (iii) override any
          security feature or bypass or circumvent any access controls or use
          limits of the Service, (iv) copy, use, disclose or distribute any
          information obtained from the Services, whether directly or through
          third parties (such as search engines), without the consent of
          Stratos, (v) disclose information that you do not have the consent to
          disclose, (vi) violate the intellectual property rights of others,
          including copyrights, patents, trademarks, trade secrets or other
          proprietary rights, (vii) violate the intellectual property or other
          rights of Stratos, including, without limitation, (a) copying or
          distributing our learning videos or other materials or (b) copying or
          distributing our technology, unless it is released under open source
          licenses, (viii) post anything that contains software viruses, worms,
          or any other harmful code, (ix) reverse engineer, decompile,
          disassemble, decipher or otherwise attempt to derive the source code
          for the Services or any related technology that is not open source,
          (x) imply or state that you are affiliated with or endorsed by Stratos
          without our express consent, (xi) rent, lease, loan, trade,
          sell/re-sell or otherwise monetize the Services or related data or
          access to the same, without Stratos’ consent, (xii) deep-link to our
          Services for any purpose other than to promote your profile without
          Stratos’ consent, (xiii) use bots or other automated methods to access
          the Services, add or download contacts, send or redirect messages,
          (xiv) monitor the Services’ availability, performance or functionality
          for any competitive purpose, (xv) engage in “framing,” “mirroring,” or
          otherwise simulating the appearance or function of the Services, (xvi)
          overlay or otherwise modify the Services or their appearance (such as
          by inserting elements into the Services or removing, covering, or
          obscuring an advertisement included on the Services), (xvii) interfere
          with the operation of, or place an unreasonable load on, the Services
          (e.g., spam, denial of service attack, viruses, gaming algorithms),
          (xviii) use the Services to threaten, incite or promote violence,
          (xix) use the Services to share material depicting the exploitation of
          children, (xx) use the Services to promote, sell, or attempt to
          purchase illegal or dangerous goods or services, (xxi) share content
          promoting dangerous organizations or individuals, (xxii) engage in
          sexual innuendos or unwanted advances, (xxiii) share harmful or
          shocking material, including nudity and pornography, and/or (xxiv)
          violate any Stratos policies or any additional terms concerning a
          specific Service that are provided when you sign up for or start using
          such Service.
        </DialogContentText>
        <DialogContentText
          sx={{
            fontFamily: "serif",
            textIndent: "15%",
          }}
        >
          <span
            style={{
              fontWeight: "bolder",
            }}
          >
            2.3 Sharing.{" "}
          </span>
          The Services allow messaging and sharing of information in many ways.
          Information and content that you share or post may be seen by other
          Members, Visitors or others (including off of the Services). When have
          made settings available, we will honor the choices you make about who
          can see content or information. We are not obligated to publish any
          information or content on our Services and can remove it with or
          without notice. We don’t promise to store or keep showing any
          information and content that you’ve posted. Stratos is not a storage
          service. You agree that we have no obligation to store, maintain or
          provide you a copy of any content or information that you or others
          provide, except to the extent required by applicable law.
        </DialogContentText>

        <DialogContentText
          sx={{
            fontFamily: "serif",
            fontWeight: "bolder",
            paddingTop: 1,
            textIndent: "5%",
          }}
        >
          3. PRICING AND PAYMENT.
        </DialogContentText>
        <DialogContentText
          sx={{
            fontFamily: "serif",
            textIndent: "15%",
          }}
        >
          <span
            style={{
              fontWeight: "bolder",
            }}
          >
            3.1 Payment.{" "}
          </span>
          You agree to pay all fees or charges to your Account in accordance
          with the fees, charges and billing terms in effect at the time a fee
          or charge is due and payable. If requested, you must provide Stratos
          with one or more valid credit cards of a payment provider (each, a “
          <span
            style={{
              fontWeight: "bolder",
            }}
          >
            Payment Method
          </span>
          ”). Your agreement with the provider of the Payment Method governs
          your use of the designated Payment Method, and you must refer to that
          agreement, not this Agreement, to determine your rights and
          liabilities with respect to your Payment Method. By providing Stratos
          with your Payment Method and associated payment information, you agree
          that Stratos is authorized to immediately charge your Account for all
          fees and charges due and payable hereunder and that no additional
          notice or consent is required. You agree to immediately notify Stratos
          of any change in your billing address or the Payment Method used for
          payment hereunder. Stratos reserves the right at any time to change
          its prices and billing methods, either immediately upon posting on
          Services or by e-providing notice to you. Stratos may store and
          continue billing your payment method (e.g. credit card) even after it
          has expired, to avoid interruptions in your Services and to use to pay
          other Services you may buy.
        </DialogContentText>

        <DialogContentText
          sx={{
            fontFamily: "serif",
            fontWeight: "bolder",
            paddingTop: 1,
            textIndent: "5%",
          }}
        >
          4. OWNERSHIP.
        </DialogContentText>
        <DialogContentText
          sx={{
            fontFamily: "serif",
            textIndent: "15%",
          }}
        >
          <span
            style={{
              fontWeight: "bolder",
            }}
          >
            4.1 Services.{" "}
          </span>
          The Services, and the information and content available on the
          Services are protected by copyright laws throughout the world. Except
          with respect to Your Content (as defined below), you agree that
          Stratos and its licensors own all rights, title and interest in the
          Services (including but not limited to, any computer code, themes,
          objects, artwork, methods of operation, moral rights, documentation,
          and software). You will not remove, alter or obscure any copyright,
          trademark, service mark or other proprietary rights notices. Stratos
          and its licensors reserve all rights not granted in the Agreement.
        </DialogContentText>
        <DialogContentText
          sx={{
            fontFamily: "serif",
            textIndent: "15%",
          }}
        >
          <span
            style={{
              fontWeight: "bolder",
            }}
          >
            4.2 Access to Services.{" "}
          </span>
          Subject to your compliance with the Agreement, Stratos grants you a
          limited non-exclusive, non-transferable, non-sublicensable, revocable
          right to access and use the Services solely for your own personal or
          internal business purposes.
        </DialogContentText>
        <DialogContentText
          sx={{
            fontFamily: "serif",
            textIndent: "15%",
          }}
        >
          <span
            style={{
              fontWeight: "bolder",
            }}
          >
            4.3 Your Content.{" "}
          </span>
          You acknowledge that any information, data, text, software, messages,
          tags and/or other materials accessible through the Services (“
          <span
            style={{
              fontWeight: "bolder",
            }}
          >
            Content
          </span>
          ”) is the sole responsibility of the party from whom such Content
          originated. This means that you, and not Stratos, are entirely
          responsible for any Content that you upload, post, email, transmit or
          otherwise make available (“
          <span
            style={{
              fontWeight: "bolder",
            }}
          >
            Make Available
          </span>
          ”) through the Services (“
          <span
            style={{
              fontWeight: "bolder",
            }}
          >
            Your Content
          </span>
          ”). Unless expressly agreed to by Stratos in writing elsewhere,
          Stratos has no obligation to store any of Your Content that you Make
          Available on the Services. Stratos has no responsibility or liability
          to you for the deletion or accuracy of any Content, including Your
          Content; the failure to store, transmit or receive transmission of
          Content; or the security, privacy, storage, or transmission of other
          communications originating with or involving use of the Services.
          Stratos does not claim ownership of Your Content. However, when you
          Make Available any of Your Content on or through the Services, you
          represent that you own Your Content and/or have the right to grant us
          the license to Your Content set forth below.
        </DialogContentText>
        <DialogContentText
          sx={{
            fontFamily: "serif",
            textIndent: "15%",
          }}
        >
          <span
            style={{
              fontWeight: "bolder",
            }}
          >
            4.4 License to Your Content.{" "}
          </span>
          You grant Stratos a fully paid, royalty-free, perpetual, irrevocable,
          worldwide, royalty-free, non-exclusive and fully sublicensable right
          (including any moral rights) and license to use Your Content (in whole
          or in part) for the purposes of operating and providing Services to
          you. You agree that you, not Stratos, are responsible for all of Your
          Content that you Make Available on or in Services.
        </DialogContentText>
        <DialogContentText
          sx={{
            fontFamily: "serif",
            textIndent: "15%",
          }}
        >
          <span
            style={{
              fontWeight: "bolder",
            }}
          >
            4.5 Feedback.{" "}
          </span>
          If you provide Stratos with any ideas, suggestions, documents, and/or
          proposals related to Stratos or its business or products (“
          <span
            style={{
              fontWeight: "bolder",
            }}
          >
            Feedback
          </span>
          ”), you hereby grant to Stratos a fully paid, royalty-free, perpetual,
          irrevocable, worldwide, non-exclusive, and fully sublicensable right
          and license to use, reproduce, perform, display, distribute, adapt,
          modify, re-format, create derivative works of, and otherwise
          commercially or non-commercially exploit in any manner, any and all
          Feedback, and to sublicense the foregoing rights, in connection with
          the operation and maintenance of Services and/or Stratos’ business, or
          for any other purpose. You represent and warrant that you have all
          rights necessary to provide the Feedback.
        </DialogContentText>

        <DialogContentText
          sx={{
            fontFamily: "serif",
            textIndent: "5%",
          }}
        >
          <span
            style={{
              fontWeight: "bolder",
            }}
          >
            5. INDEMNIFICATION.{" "}
          </span>
          You agree to indemnify and hold Stratos, its parents, subsidiaries,
          affiliates, officers, employees, agents, partners, suppliers, and
          licensors (each, a “
          <span
            style={{
              fontWeight: "bolder",
            }}
          >
            Stratos Party
          </span>
          ” and collectively, the “
          <span
            style={{
              fontWeight: "bolder",
            }}
          >
            Stratos Parties
          </span>
          ”) harmless from any losses, costs, liabilities and expenses
          (including reasonable attorneys’ fees) relating to or arising out of
          any and all of the following: (i) your use of, or inability to use,
          any Service, (ii) your violation of the Agreement, (iii) your
          violation of any rights of another party, including any other user of
          the Services, (iv) your violation of any applicable laws, rules or
          regulations, (v) any amounts charged or chargeable to you but not
          received by the applicable Stratos Party and/or (vi) Your Content.
          Stratos reserves the right, at its own cost, to assume the exclusive
          defense and control of any matter otherwise subject to indemnification
          by you, in which event you will fully cooperate with Stratos in
          asserting any available defenses. This provision does not require you
          to indemnify any of the Stratos Parties for any unconscionable
          commercial practice by such party or for such party’s fraud,
          deception, false promise, misrepresentation or concealment, or
          suppression or omission of any material fact in connection with the
          Services or any Services provided hereunder. You agree that the
          provisions in this section will survive any termination of your
          Account, the Agreement and/or your access to Services.
        </DialogContentText>

        <DialogContentText
          sx={{
            fontFamily: "serif",
            fontWeight: "bolder",
            paddingTop: 1,
            textIndent: "5%",
          }}
        >
          6. DISCLAIMER OF WARRANTIES AND CONDITIONS.
        </DialogContentText>
        <DialogContentText
          sx={{
            fontFamily: "serif",
            textIndent: "15%",
          }}
        >
          <span
            style={{
              fontWeight: "bolder",
            }}
          >
            6.1 AS IS.{" "}
          </span>
          YOU EXPRESSLY UNDERSTAND AND AGREE THAT TO THE EXTENT PERMITTED BY
          APPLICABLE LAW, YOUR USE OF THE SERVICES IS AT YOUR SOLE RISK, AND THE
          SERVICES ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS, WITH ALL
          FAULTS. TO THE FULLEST EXTENT PERMITTED UNDER APPLICABLE LAW STRATOS
          PARTIES EXPRESSLY DISCLAIM ALL WARRANTIES, REPRESENTATIONS, AND
          CONDITIONS OF ANY KIND, WHETHER EXPRESS, STATUTORY OR IMPLIED,
          INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OR CONDITIONS OF
          MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT
          ARISING FROM USE OF THE SERVICES, WARRANTY OF TITLE, ACCURACY OF DATA,
          NON-INFRINGEMENT.
        </DialogContentText>
        <DialogContentText
          sx={{
            fontFamily: "serif",
            textIndent: "20%",
          }}
        >
          <span
            style={{
              fontWeight: "bolder",
            }}
          >
            (a).{" "}
          </span>
          NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED FROM
          STRATOS OR THROUGH THE SERVICES WILL CREATE ANY WARRANTY NOT EXPRESSLY
          MADE HEREIN AND STRATOS MAKES NO WARRANTY OR GUARANTEE THAT SUCH
          ADVICE OR INFORMATION IS ACCURATE OR COMPLETE.
        </DialogContentText>
        <DialogContentText
          sx={{
            fontFamily: "serif",
            textIndent: "20%",
          }}
        >
          <span
            style={{
              fontWeight: "bolder",
            }}
          >
            (b).{" "}
          </span>
          THE SERVICES MAY BE SUBJECT TO DELAYS, CANCELLATIONS AND OTHER
          DISRUPTIONS. STRATOS MAKES NO WARRANTY, REPRESENTATION OR CONDITION
          WITH RESPECT TO THE SERVICES, INCLUDING BUT NOT LIMITED TO, THE
          QUALITY, EFFECTIVENESS, COMPLETENESS, REPUTATION AND OTHER
          CHARACTERISTICS OF THE SERVICES.
        </DialogContentText>
        <DialogContentText
          sx={{
            fontFamily: "serif",
            textIndent: "20%",
          }}
        >
          <span
            style={{
              fontWeight: "bolder",
            }}
          >
            (c).{" "}
          </span>
          STRATOS PARTIES MAKE NO WARRANTY, REPRESENTATION OR CONDITION THAT:
          (1) THE SERVICES WILL MEET YOUR REQUIREMENTS; (2) YOUR USE OF THE
          SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE OR ERROR-FREE; (3)
          CHARGES MADE THROUGH THE SERVICES WILL BE TIMELY OR ACCURATE; (4) YOU
          WILL NOT BE ASSESSED ADDITIONAL FEES IN EXCESS ANY THIRD PARTY; OR (5)
          ANY RECORDS PROVIDED BY OR THROUGH THE SERVICES WILL BE ACCURATE OR
          RELIABLE.
        </DialogContentText>
        <DialogContentText
          sx={{
            fontFamily: "serif",
            textIndent: "20%",
          }}
        >
          <span
            style={{
              fontWeight: "bolder",
            }}
          >
            (d).{" "}
          </span>
          ANY CONTENT ACCESSED THROUGH THE SERVICES IS ACCESSED AT YOUR OWN
          RISK, AND YOU SHALL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR
          PROPERTY, INCLUDING, BUT NOT LIMITED TO, YOUR COMPUTER SYSTEM AND ANY
          DEVICE YOU USE TO ACCESS THE SERVICES, OR ANY OTHER LOSS THAT RESULTS
          FROM ACCESSING SUCH CONTENT.
        </DialogContentText>
        <DialogContentText
          sx={{
            fontFamily: "serif",
            textIndent: "20%",
          }}
        >
          <span
            style={{
              fontWeight: "bolder",
            }}
          >
            (e).{" "}
          </span>
          FROM TIME TO TIME, STRATOS MAY OFFER NEW “BETA” FEATURES OR TOOLS WITH
          WHICH ITS USERS MAY EXPERIMENT. SUCH FEATURES OR TOOLS ARE OFFERED
          SOLELY FOR EXPERIMENTAL PURPOSES AND WITHOUT ANY WARRANTY OF ANY KIND,
          AND MAY BE MODIFIED OR DISCONTINUED AT STRATO’S SOLE DISCRETION. THE
          PROVISIONS OF THIS SECTION APPLY WITH FULL FORCE TO SUCH FEATURES OR
          TOOLS.
        </DialogContentText>
        <DialogContentText
          sx={{
            fontFamily: "serif",
            textIndent: "15%",
          }}
        >
          <span
            style={{
              fontWeight: "bolder",
            }}
          >
            6.2 No Liability for Conduct of Third Parties.{" "}
          </span>
          YOU ACKNOWLEDGE AND AGREE THAT STRATOS PARTIES ARE NOT LIABLE, AND YOU
          AGREE NOT TO SEEK TO HOLD STRATOS PARTIES LIABLE, FOR THE CONDUCT OF
          OR ANY MATERIALS PROVIDED BY THIRD PARTIES, INCLUDING OPERATORS OF
          EXTERNAL SITES, APPLICABLE GOVERNMENTAL AUTHORITIES OR OTHER LAW
          ENFORCEMENT. STRATOS MAKES NO WARRANTY THAT ANY MATERIALS OR SERVICES
          PROVIDED BY THIRD PARTIES WILL MEET YOUR REQUIREMENTS OR BE AVAILABLE
          ON AN UNINTERRUPTED, SECURE, OR ERROR-FREE BASIS. STRATOS MAKES NO
          WARRANTY REGARDING THE QUALITY OF ANY SUCH SERVICES, OR THE ACCURACY,
          TIMELINESS, TRUTHFULNESS, COMPLETENESS OR RELIABILITY OF ANY
          THIRD-PARTY MATERIALS OBTAINED THROUGH OR IN CONNECTION WITH THE
          SERVICES.
        </DialogContentText>

        <DialogContentText
          sx={{
            fontFamily: "serif",
            fontWeight: "bolder",
            paddingTop: 1,
            textIndent: "5%",
          }}
        >
          7. LIMITATION OF LIABILITY.
        </DialogContentText>
        <DialogContentText
          sx={{
            fontFamily: "serif",
            textIndent: "15%",
          }}
        >
          <span
            style={{
              fontWeight: "bolder",
            }}
          >
            7.1 Disclaimer of Certain Damages.{" "}
          </span>
          YOU UNDERSTAND AND AGREE THAT, TO THE FULLEST EXTENT PROVIDED BY LAW,
          IN NO EVENT SHALL STRATOS PARTIES BE LIABLE FOR ANY LOSS OF PROFITS,
          BUSINESS OPPORTUNITIES, REPUTATION (E.G., OFFENSIVE OR DEFAMATORY
          STATEMENTS), REVENUE OR DATA, INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE
          OR CONSEQUENTIAL DAMAGES, OR DAMAGES OR COSTS DUE TO LOSS OF
          PRODUCTION OR USE, BUSINESS INTERRUPTION, OR PROCUREMENT OF SUBSTITUTE
          GOODS OR SERVICES, IN EACH CASE WHETHER OR NOT STRATOS HAS BEEN
          ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, ARISING OUT OF OR IN
          CONNECTION WITH THE AGREEMENT ON ANY THEORY OF LIABILITY, WHETHER
          BASED ON WARRANTY, COPYRIGHT, CONTRACT, TORT (INCLUDING NEGLIGENCE) OR
          ANY OTHER LEGAL THEORY. THE FOREGOING LIMITATION OF LIABILITY SHALL
          NOT APPLY TO LIABILITY OF A STRATOS PARTY FOR (i) DEATH OR PERSONAL
          INJURY CAUSED BY A STRATOS PARTY’S NEGLIGENCE; OR FOR (ii) ANY INJURY
          CAUSED BY A STRATOS PARTY’S FRAUD OR FRAUDULENT MISREPRESENTATION.
        </DialogContentText>
        <DialogContentText
          sx={{
            fontFamily: "serif",
            textIndent: "15%",
          }}
        >
          <span
            style={{
              fontWeight: "bolder",
            }}
          >
            7.2 Cap on Liability.{" "}
          </span>
          TO THE FULLEST EXTENT PROVIDED BY LAW, STRATOS PARTIES WILL NOT BE
          LIABLE TO YOU FOR MORE THAN THE GREATER OF (a) THE TOTAL AMOUNT PAID
          TO STRATOS BY YOU DURING THE ONE-MONTH PERIOD PRIOR TO THE ACT,
          OMISSION OR OCCURRENCE GIVING RISE TO SUCH LIABILITY OR (b) $1000. THE
          FOREGOING CAP ON LIABILITY SHALL NOT APPLY TO LIABILITY OF A STRATOS
          PARTY FOR (i) DEATH OR PERSONAL INJURY CAUSED BY A STRATOS PARTY’S
          NEGLIGENCE; OR FOR (ii) ANY INJURY CAUSED BY A STRATOS PARTY’S FRAUD,
          FRAUDULENT MISREPRESENTATION, GROSS NEGLIGENCE OR INTENTIONAL
          MISCONDUCT, OR IN CASES OF NEGLIGENCE IN WHICH A MATERIAL OBLIGATION
          HAS BEEN BREACHED, A MATERIAL OBLIGATION BEING SUCH WHICH FORMS A
          PREREQUISITE TO OUR DELIVERY OF SERVICES AND ON WHICH YOU MAY
          REASONABLY RELY, BUT ONLY TO THE EXTENT THAT THE DAMAGES WERE DIRECTLY
          CAUSED BY THE BREACH AND WERE FORESEEABLE UPON CONCLUSION OF THIS
          AGREEMENT AND TO THE EXTENT THAT THEY ARE TYPICAL IN THE CONTEXT OF
          THIS AGREEMENT.
        </DialogContentText>
        <DialogContentText
          sx={{
            fontFamily: "serif",
            textIndent: "15%",
          }}
        >
          <span
            style={{
              fontWeight: "bolder",
            }}
          >
            7.3 Exclusion of Damages.{" "}
          </span>
          CERTAIN JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF
          CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE
          EXCLUSIONS OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MIGHT HAVE
          ADDITIONAL RIGHTS.
        </DialogContentText>
        <DialogContentText
          sx={{
            fontFamily: "serif",
            textIndent: "15%",
          }}
        >
          <span
            style={{
              fontWeight: "bolder",
            }}
          >
            7.4 Basis of the Bargain.{" "}
          </span>
          THE LIMITATIONS OF LIABILITIES SET FORTH ABOVE ARE FUNDAMENTAL
          ELEMENTS OF THE BASIS OF THE BARGAIN BETWEEN STRATOS AND YOU AND SHALL
          APPLY TO ALL CLAIMS OF LIABILITY EVEN IF STRATOS PARTIES HAVE BEEN
          TOLD OF THE POSSIBILITY OF ANY SUCH DAMAGE AND EVEN IF THESE REMEDIES
          FAIL THEIR ESSENTIAL PURPOSE.
        </DialogContentText>

        <DialogContentText
          sx={{
            fontFamily: "serif",
            textIndent: "5%",
          }}
        >
          <span
            style={{
              fontWeight: "bolder",
            }}
          >
            8. MONITORING AND ENFORCEMENT.{" "}
          </span>
          If Stratos becomes aware of any possible violations by you of the
          Agreement, Stratos reserves the right to investigate such violations.
          If, as a result of the investigation, Stratos believes that criminal
          activity has occurred, Stratos reserves the right to refer the matter
          to, and to cooperate with, any and all applicable legal authorities.
          If Stratos reasonably believes that you may have violated this
          Agreement and/or applicable law, Stratos is entitled, except to the
          extent prohibited by applicable law, to take or refrain from taking
          any action, including without limitation to disclose any information
          or materials on or in Services, including Your Content, in Stratos’
          possession in connection with your use of Services, to (i) comply with
          applicable laws, legal process or governmental request; (ii) enforce
          the Agreement, (iii) respond to any claims that Your Content violates
          the rights of third parties, (iv) respond to your requests for
          customer service and/or (v) protect the rights, property or personal
          safety of Stratos, users of the Services or the public, and all
          enforcement or other government officials, as Stratos in its sole
          discretion believes to be necessary or appropriate.
        </DialogContentText>

        <DialogContentText
          sx={{
            fontFamily: "serif",
            fontWeight: "bolder",
            paddingTop: 1,
            textIndent: "5%",
          }}
        >
          9. TERM AND TERMINATION.
        </DialogContentText>
        <DialogContentText
          sx={{
            fontFamily: "serif",
            textIndent: "15%",
          }}
        >
          <span
            style={{
              fontWeight: "bolder",
            }}
          >
            9.1 Term.{" "}
          </span>
          The Agreement commences on the date when you accept the Terms of
          Service (as described in the preamble above) and remain in full force
          and effect while you use the Services, unless terminated earlier in
          accordance with the Agreement.
        </DialogContentText>
        <DialogContentText
          sx={{
            fontFamily: "serif",
            textIndent: "15%",
          }}
        >
          <span
            style={{
              fontWeight: "bolder",
            }}
          >
            9.2 Prior Use.{" "}
          </span>
          Notwithstanding the foregoing, you hereby acknowledge and agree that
          the Agreement commenced on the earlier to occur of (a) the date you
          first used the Services or (b) the date you accepted the Agreement,
          and it will remain in full force and effect while you use any
          Services, unless earlier terminated in accordance with the Agreement.
        </DialogContentText>
        <DialogContentText
          sx={{
            fontFamily: "serif",
            textIndent: "15%",
          }}
        >
          <span
            style={{
              fontWeight: "bolder",
            }}
          >
            9.3 Termination of Services by Stratos.{" "}
          </span>
          Stratos reserves the right to terminate your access to and/or use of
          the Services, including to delete your Account, for any reason in its
          sole discretion, and at any time.
        </DialogContentText>
        <DialogContentText
          sx={{
            fontFamily: "serif",
            textIndent: "15%",
          }}
        >
          <span
            style={{
              fontWeight: "bolder",
            }}
          >
            9.4 Termination of Services by You.{" "}
          </span>
          If you want to terminate this Agreement, you may do so by notifying
          Stratos through the Services and initiating an Account closure at any
          time.
        </DialogContentText>
        <DialogContentText
          sx={{
            fontFamily: "serif",
            textIndent: "15%",
          }}
        >
          <span
            style={{
              fontWeight: "bolder",
            }}
          >
            9.5 Effect of Termination.{" "}
          </span>
          Upon termination of this Agreement, your right to use such Service
          will automatically terminate immediately. You understand that any
          termination of the Agreement may involve deletion of Your Content from
          our live databases, except as may otherwise be required by applicable
          law. Stratos will not have any liability whatsoever to you for any
          suspension or termination, including for deletion of Your Content. All
          provisions of the Agreement which by their nature should survive,
          shall survive termination of this Agreement, including without
          limitation, Members and/or Visitors’ rights to further re-share
          content and information you shared through the Services, any amounts
          owed by either party prior to termination remain owed after
          termination, Section 2.2 (Rules of Behavior), Section 4 (Ownership),
          Section 6 (Disclaimer of Warranties and Conditions), Section 7
          (Limitation of Liability), Section 10 (Dispute Resolution) and Section
          12 (General Provisions).
        </DialogContentText>
        <DialogContentText
          sx={{
            fontFamily: "serif",
            textIndent: "15%",
          }}
        >
          <span
            style={{
              fontWeight: "bolder",
            }}
          >
            9.6 No Subsequent Registration.{" "}
          </span>
          If your registration(s) with, or ability to access, the Services, is
          discontinued by Stratos due to your violation of any portion of the
          Agreement or for conduct otherwise inappropriate for the community,
          then you agree that you shall not attempt to re-register with or
          access any Services through use of a different Account or otherwise.
          In the event that you violate the immediately preceding sentence,
          Stratos reserves the right, in its sole discretion, to immediately
          take any or all of the actions set forth herein without any notice or
          warning to you.
        </DialogContentText>

        <DialogContentText
          sx={{
            fontFamily: "serif",
            fontWeight: "bolder",
            paddingTop: 1,
            textIndent: "5%",
          }}
        >
          10. DISPUTE RESOLUTION. Please read the following arbitration
          agreement in this section (“Arbitration Agreement”) carefully. It
          requires you to arbitrate disputes with Stratos and limits the manner
          in which you can seek relief from us.
        </DialogContentText>
        <DialogContentText
          sx={{
            fontFamily: "serif",
            textIndent: "15%",
          }}
        >
          <span
            style={{
              fontWeight: "bolder",
            }}
          >
            10.1 Applicability of Arbitration Agreement.{" "}
          </span>
          You agree that any dispute, claim, or request for relief relating in
          any way to your access or use of the Services, to any products sold or
          distributed through the Services, or to any aspect of your
          relationship with Stratos, will be resolved by binding arbitration,
          rather than in court, except that (a) you may assert claims or seek
          relief in small claims court if your claims qualify; and (b) you or
          Stratos may seek equitable relief in court for infringement or other
          misuse of intellectual property rights (such as trademarks, trade
          dress, domain names, trade secrets, copyrights, and patents).{" "}
          <span
            style={{
              fontWeight: "bolder",
            }}
          >
            This Arbitration Agreement shall apply, without limitation, to all
            disputes or claims and requests for relief that arose or were
            asserted before the effective date of this Agreement or any prior
            version of this Agreement.
          </span>
        </DialogContentText>
        <DialogContentText
          sx={{
            fontFamily: "serif",
            textIndent: "15%",
          }}
        >
          <span
            style={{
              fontWeight: "bolder",
            }}
          >
            10.2 Arbitration Rules and Forum.{" "}
          </span>
          The Federal Arbitration Act governs the interpretation and enforcement
          of this Arbitration Agreement. To begin an arbitration proceeding, you
          must send a letter requesting arbitration and describing your dispute
          or claim or request for relief to the address set forth in Section
          12.9. The arbitration will be conducted by JAMS, an established
          alternative dispute resolution provider, at a JAMS office in Fulton
          County, Georgia. Disputes involving claims, counterclaims, or request
          for relief under $250,000, not inclusive of attorneys’ fees and
          interest, shall be subject to JAMS’s most current version of the
          Streamlined Arbitration Rules and procedures available at
          http://www.jamsadr.com/rules-streamlined-arbitration/; all other
          disputes shall be subject to JAMS’s most current version of the
          Comprehensive Arbitration Rules and Procedures, available at
          http://www.jamsadr.com/rules-comprehensive-arbitration/. JAMS’s rules
          are also available at www.jamsadr.com or by calling JAMS at
          800-352-5267. If JAMS is not available to arbitrate, the parties will
          select an alternative arbitral forum. If the arbitrator finds that you
          cannot afford to pay JAMS’s filing, administrative, hearing and/or
          other fees and cannot obtain a waiver from JAMS, Stratos will pay them
          for you. In addition, Stratos will reimburse all such JAMS’s filing,
          administrative, hearing and/or other fees for disputes, claims, or
          requests for relief totaling less than $10,000 unless the arbitrator
          determines the claims are frivolous. You may choose to have the
          arbitration conducted by telephone, based on written submissions, or
          in person in the country where you live or at another mutually agreed
          location. Any judgment on the award rendered by the arbitrator may be
          entered in any court of competent jurisdiction.
        </DialogContentText>
        <DialogContentText
          sx={{
            fontFamily: "serif",
            textIndent: "15%",
          }}
        >
          <span
            style={{
              fontWeight: "bolder",
            }}
          >
            10.3 Authority of Arbitrator.{" "}
          </span>
          The arbitrator shall have exclusive authority to (a) determine the
          scope and enforceability of this Arbitration Agreement and (b) resolve
          any dispute related to the interpretation, applicability,
          enforceability or formation of this Arbitration Agreement including,
          but not limited to, any assertion that all or any part of this
          Arbitration Agreement is void or voidable. The arbitration will decide
          the rights and liabilities, if any, of you and Stratos. The
          arbitration proceeding will not be consolidated with any other matters
          or joined with any other cases or parties. The arbitrator shall have
          the authority to grant motions dispositive of all or part of any
          claim. The arbitrator shall have the authority to award monetary
          damages and to grant any non-monetary remedy or relief available to an
          individual under applicable law, the arbitral forum’s rules, and the
          Agreement (including the Arbitration Agreement). The arbitrator shall
          issue a written award and statement of decision describing the
          essential findings and conclusions on which the award is based,
          including the calculation of any damages awarded. The arbitrator has
          the same authority to award relief on an individual basis that a judge
          in a court of law would have. The award of the arbitrator is final and
          binding upon you and us.
        </DialogContentText>
        <DialogContentText
          sx={{
            fontFamily: "serif",
            textIndent: "15%",
          }}
        >
          <span
            style={{
              fontWeight: "bolder",
            }}
          >
            10.4 Waiver of Jury Trial.{" "}
          </span>
          YOU AND STRATOS HEREBY WAIVE ANY CONSTITUTIONAL AND STATUTORY RIGHTS
          TO SUE IN COURT AND HAVE A TRIAL IN FRONT OF A JUDGE OR A JURY. You
          and Stratos are instead electing that all disputes, claims, or
          requests for relief shall be resolved by arbitration under this
          Arbitration Agreement. An arbitrator can award on an individual basis
          the same damages and relief as a court and must follow this Agreement
          as a court would. However, there is no judge or jury in arbitration,
          and court review of an arbitration award is subject to very limited
          review.
        </DialogContentText>
        <DialogContentText
          sx={{
            fontFamily: "serif",
            textIndent: "15%",
          }}
        >
          <span
            style={{
              fontWeight: "bolder",
            }}
          >
            10.5 Waiver of Class or Other Non-Individualized Relief.{" "}
          </span>
          ALL DISPUTES, CLAIMS, AND REQUESTS FOR RELIEF WITHIN THE SCOPE OF THIS
          ARBITRATION AGREEMENT MUST BE ARBITRATED ON AN INDIVIDUAL BASIS AND
          NOT ON A CLASS OR COLLECTIVE BASIS, ONLY INDIVIDUAL RELIEF IS
          AVAILABLE, AND CLAIMS OF MORE THAN ONE CUSTOMER OR USER CANNOT BE
          ARBITRATED OR CONSOLIDATED WITH THOSE OF ANY OTHER CUSTOMER OR USER.
          If a decision is issued stating that applicable law precludes
          enforcement of any of this section’s limitations as to a given
          dispute, claim, or request for relief, then such aspect must be
          severed from the arbitration and brought into the State or Federal
          Courts located in the State of Georgia. All other disputes, claims, or
          requests for relief shall be arbitrated.
        </DialogContentText>
        <DialogContentText
          sx={{
            fontFamily: "serif",
            textIndent: "15%",
          }}
        >
          <span
            style={{
              fontWeight: "bolder",
            }}
          >
            10.6 30-Day Right to Opt Out.{" "}
          </span>
          You have the right to opt out of the provisions of this Arbitration
          Agreement by sending written notice of your decision to opt out to the
          address set forth in Section 12.9, within thirty (30) days after first
          becoming subject to this Arbitration Agreement. Your notice must
          include your name and address, your Stratos username (if any), the
          email address you used to set up your Stratos account (if you have
          one), and an unequivocal statement that you want to opt out of this
          Arbitration Agreement. If you opt out of this Arbitration Agreement,
          all other parts of this Agreement will continue to apply to you.
          Opting out of this Arbitration Agreement has no effect on any other
          arbitration agreements that you may currently have, or may enter in
          the future, with us.
        </DialogContentText>
        <DialogContentText
          sx={{
            fontFamily: "serif",
            textIndent: "15%",
          }}
        >
          <span
            style={{
              fontWeight: "bolder",
            }}
          >
            10.7 Severability.{" "}
          </span>
          Except as provided in Section 10.5, if any part or parts of this
          Arbitration Agreement are found under the law to be invalid or
          unenforceable, then such specific part or parts shall be of no force
          and effect and shall be severed and the remainder of the Arbitration
          Agreement shall continue in full force and effect.
        </DialogContentText>
        <DialogContentText
          sx={{
            fontFamily: "serif",
            textIndent: "15%",
          }}
        >
          <span
            style={{
              fontWeight: "bolder",
            }}
          >
            10.8 Survival of Agreement.{" "}
          </span>
          This Arbitration Agreement will survive the termination of your
          relationship with Stratos.
        </DialogContentText>
        <DialogContentText
          sx={{
            fontFamily: "serif",
            textIndent: "15%",
          }}
        >
          <span
            style={{
              fontWeight: "bolder",
            }}
          >
            10.9 Modification.{" "}
          </span>
          Notwithstanding any provision in this Agreement to the contrary, we
          agree that if Stratos makes any future material change to this
          Arbitration Agreement, you may reject that change within thirty (30)
          days of such change becoming effective by writing Stratos at the
          address set forth in Section 12.9.
        </DialogContentText>

        <DialogContentText
          sx={{
            fontFamily: "serif",
            textIndent: "5%",
          }}
        >
          <span
            style={{
              fontWeight: "bolder",
            }}
          >
            11. THIRD-PARTY SERVICES.{" "}
          </span>
          The Services may contain links to third-party websites, applications,
          and advertisements for or from third parties (collectively,
          “Third-Party Services”). When you click on a link to a Third-Party
          Service, we will not warn you that you have left the Services and are
          subject to the terms and conditions (including privacy policies) of
          another website or destination. Such Third-Party Services are not
          under the control of Stratos. Stratos is not responsible for any
          Third-Party Service. Stratos provides these Third-Party Services only
          as a convenience and does not review, approve, monitor, endorse,
          warrant, or make any representations with respect to Third-Party
          Services, or any product or service provided in connection therewith.
          You use all links in Third-Party Services at your own risk. When you
          leave our Services, this Agreement and our policies no longer govern.
          You should review applicable terms and policies, including privacy and
          data gathering practices, of any Third-Party Service, and make
          whatever investigation you feel necessary or appropriate before
          proceeding with any transaction with any third party.
        </DialogContentText>

        <DialogContentText
          sx={{
            fontFamily: "serif",
            fontWeight: "bolder",
            paddingTop: 1,
            textIndent: "5%",
          }}
        >
          12. GENERAL PROVISIONS.
        </DialogContentText>
        <DialogContentText
          sx={{
            fontFamily: "serif",
            textIndent: "15%",
          }}
        >
          <span
            style={{
              fontWeight: "bolder",
            }}
          >
            12.1 Changes to the Terms.{" "}
          </span>
          Stratos may modify this Agreement at any time in its sole discretion.
          When changes are made, Stratos will make a new copy of the Terms of
          Service Agreement available at the Services and will update the “Last
          Updated” and “Effective Date” dates at the top of the Terms of Service
          Agreement. We may provide additional notice of any material changes,
          such as by sending an email to you at the last email address you
          provided to us pursuant to the Agreement. Stratos may require you to
          provide consent to the updated Agreement in a specified manner before
          further use of the Services is permitted. If you do not agree to any
          change(s) after receiving a notice of such change(s), you must stop
          using the Services and may close your account. Otherwise, your
          continued use of the Services after we publish or send a notice about
          our changes constitutes your acceptance of such change(s). We agree
          that changes cannot be retroactive.{" "}
        </DialogContentText>
        <DialogContentText
          sx={{
            fontFamily: "serif",
            textIndent: "15%",
          }}
        >
          <span
            style={{
              fontWeight: "bolder",
            }}
          >
            12.2 Stratos Communications.{" "}
          </span>
          By entering into this Agreement or using the Services, you agree to
          receive electronic communications from us, including via email. Such
          communications may include but are not limited to: operational
          communications from Stratos updates concerning the Services, and
          promotional communications. IF YOU WISH TO OPT OUT OF PROMOTIONAL
          EMAILS, YOU CAN UNSUBSCRIBE FROM OUR PROMOTIONAL EMAIL LIST BY
          FOLLOWING THE UNSUBSCRIBE OPTIONS IN THE PROMOTIONAL EMAIL ITSELF.
        </DialogContentText>
        <DialogContentText
          sx={{
            fontFamily: "serif",
            textIndent: "15%",
          }}
        >
          <span
            style={{
              fontWeight: "bolder",
            }}
          >
            12.3 Electronic Communications.{" "}
          </span>
          The communications between you and Stratos may take place via
          electronic means, whether you visit the Services or send Stratos
          emails, or whether Stratos posts notices on the Services or
          communicates with you via email. For contractual purposes, you (a)
          consent to receive communications from Stratos in an electronic form;
          and (b) agree that all terms and conditions, agreements, notices,
          disclosures, and other communications that Stratos provides to you
          electronically satisfy any legal requirement that such communications
          would satisfy if it were to be in writing. The foregoing does not
          affect your statutory rights, including but not limited to the
          Electronic Signatures in Global and National Commerce Act at 15 U.S.C.
          §7001 et seq.
        </DialogContentText>
        <DialogContentText
          sx={{
            fontFamily: "serif",
            textIndent: "15%",
          }}
        >
          <span
            style={{
              fontWeight: "bolder",
            }}
          >
            12.4 Assignment.{" "}
          </span>
          The Agreement, and your rights and obligations hereunder, may not be
          assigned, subcontracted, delegated or otherwise transferred by you
          without Stratos’ prior written consent, and any attempted assignment,
          subcontract, delegation, or transfer in violation of the foregoing
          will be null and void. However, you agree that Stratos may assign this
          Agreement to its affiliates or a party that buys it without your
          consent. There are no third-party beneficiaries to this Agreement.
        </DialogContentText>
        <DialogContentText
          sx={{
            fontFamily: "serif",
            textIndent: "15%",
          }}
        >
          <span
            style={{
              fontWeight: "bolder",
            }}
          >
            12.5 Force Majeure.{" "}
          </span>
          Stratos shall not be liable for any delay or failure to perform
          resulting from causes outside its reasonable control, including, but
          not limited to, acts of God, war, terrorism, riots, embargos,
          pandemic, epidemic, government action or inaction, acts of civil or
          military authorities, fire, floods, accidents, strikes or shortages of
          transportation facilities, fuel, energy, labor or materials.
        </DialogContentText>
        <DialogContentText
          sx={{
            fontFamily: "serif",
            textIndent: "15%",
          }}
        >
          <span
            style={{
              fontWeight: "bolder",
            }}
          >
            12.6 Questions, Complaints, Claims.{" "}
          </span>
          If you have any questions, complaints or claims with respect to
          Services, please contact us at the address set forth in Section 12.9.
          We will do our best to address your concerns. If you feel that your
          concerns have been addressed incompletely, we invite you to let us
          know for further investigation.
        </DialogContentText>
        <DialogContentText
          sx={{
            fontFamily: "serif",
            textIndent: "15%",
          }}
        >
          <span
            style={{
              fontWeight: "bolder",
            }}
          >
            12.7 Exclusive Venue.{" "}
          </span>
          To the extent the parties are permitted under this Agreement to
          initiate litigation in a court, both you and Stratos agree that all
          claims and disputes arising out of or relating to the Agreement will
          be litigated exclusively in the state or federal courts located in
          Fulton County, Georgia.
        </DialogContentText>
        <DialogContentText
          sx={{
            fontFamily: "serif",
            textIndent: "15%",
          }}
        >
          <span
            style={{
              fontWeight: "bolder",
            }}
          >
            12.8 Governing Law.{" "}
          </span>
          THE TERMS AND ANY ACTION RELATED THERETO WILL BE GOVERNED AND
          INTERPRETED BY AND UNDER THE LAWS OF THE STATE OF DELAWARE, CONSISTENT
          WITH THE FEDERAL ARBITRATION ACT, WITHOUT GIVING EFFECT TO ANY
          PRINCIPLES THAT PROVIDE FOR THE APPLICATION OF THE LAW OF ANOTHER
          JURISDICTION. THE UNITED NATIONS CONVENTION ON CONTRACTS FOR THE
          INTERNATIONAL SALE OF GOODS DOES NOT APPLY TO THE AGREEMENT.
        </DialogContentText>
        <DialogContentText
          sx={{
            fontFamily: "serif",
            textIndent: "15%",
          }}
        >
          <span
            style={{
              fontWeight: "bolder",
            }}
          >
            12.9 Notice.{" "}
          </span>
          Where Stratos requires that you provide an email address, you are
          responsible for providing Stratos with your most current email
          address. In the event that the last email address you provided to
          Stratos is not valid, or for any reason is not capable of delivering
          to you any notices required or permitted by the Agreement, Stratos’
          dispatch of the email containing such notice will nonetheless
          constitute effective notice. You may give notice to Stratos at the
          following address: Stratos Solutions Inc., 120 W Trinity Pl, Decatur,
          GA 30030. Such notice shall be deemed given when received by Stratos
          by letter delivered by nationally recognized overnight delivery
          service or first class postage prepaid mail at the above address. For
          questions, complaints, or support requests, please email Stratos at:
          info@trystratos.com
        </DialogContentText>
        <DialogContentText
          sx={{
            fontFamily: "serif",
            textIndent: "15%",
          }}
        >
          <span
            style={{
              fontWeight: "bolder",
            }}
          >
            12.10 Waiver.{" "}
          </span>
          Any waiver or failure to enforce any provision of the Agreement on one
          occasion will not be deemed a waiver of any other provision or of such
          provision on any other occasion.
        </DialogContentText>
        <DialogContentText
          sx={{
            fontFamily: "serif",
            textIndent: "15%",
          }}
        >
          <span
            style={{
              fontWeight: "bolder",
            }}
          >
            12.11 Severability.{" "}
          </span>
          If any portion of this Agreement is held invalid or unenforceable,
          that portion shall be construed in a manner to reflect, as nearly as
          possible, the original intention of the parties, and the remaining
          portions shall remain in full force and effect.
        </DialogContentText>
        <DialogContentText
          sx={{
            fontFamily: "serif",
            textIndent: "15%",
          }}
        >
          <span
            style={{
              fontWeight: "bolder",
            }}
          >
            12.12 Export Control.{" "}
          </span>
          You may not use, export, import, or transfer the Services except as
          authorized by U.S. law, the laws of the jurisdiction in which you
          obtained the Services, and any other applicable laws. In particular,
          but without limitation, the Services may not be exported or
          re-exported (a) into any United States embargoed countries, or (b) to
          anyone on the U.S. Treasury Department’s list of Specially Designated
          Nationals or the U.S. Department of Commerce’s Denied Person’s List or
          Entity List. By using Services, you represent and warrant that (i) you
          are not located in a country that is subject to a U.S. Government
          embargo, or that has been designated by the U.S. Government as a
          “terrorist supporting” country and (ii) you are not listed on any U.S.
          Government list of prohibited or restricted parties. You also will not
          use the Services for any purpose prohibited by U.S. law, including the
          development, design, manufacture or production of missiles, nuclear,
          chemical or biological weapons. You acknowledge and agree that
          products, services or technology provided by Stratos are subject to
          the export control laws and regulations of the United States. You
          shall comply with these laws and regulations and shall not, without
          prior U.S. government authorization, export, re-export, or transfer
          Stratos products, services or technology, either directly or
          indirectly, to any country in violation of such laws and regulations.
        </DialogContentText>
        <DialogContentText
          sx={{
            fontFamily: "serif",
            textIndent: "15%",
          }}
        >
          <span
            style={{
              fontWeight: "bolder",
            }}
          >
            12.13 Consumer Complaints.{" "}
          </span>
          In accordance with the laws and regulations of the state of Georgia,
          you may report complaints to the Georgia Department of Law's Consumer
          Protection Division by contacting them in writing at 2 Martin Luther
          King, Jr. Drive SE, Suite 356, Atlanta, Georgia 30334-9077, an online
          Consumer Complaint Form or by telephone at 404-651-8600 or
          1-800-869-1123 (toll-free in Georgia). For further information, please
          see How Do I File Complaint? on the Georgia Department of Law's
          Consumer Protection Division website (currently at
          https://consumer.georgia.gov/resolve-your-dispute/how-do-i-file-complaint).
        </DialogContentText>
        <DialogContentText
          sx={{
            fontFamily: "serif",
            textIndent: "15%",
          }}
        >
          <span
            style={{
              fontWeight: "bolder",
            }}
          >
            12.14 Entire Agreement.{" "}
          </span>
          The Agreement is the final, complete and exclusive agreement of the
          parties with respect to the subject matter hereof and supersedes and
          merges all prior discussions between the parties with respect to such
          subject matter.
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};
export default TermsOfService;
