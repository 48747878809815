import axios from "axios";

const API = axios.create({
  baseURL: "https://us-central1-stratosv1.cloudfunctions.net/api",
});

// api url: https://us-central1-stratosv1.cloudfunctions.net/api
// localhost serving: http://127.0.0.1:5001/stratosv1/us-central1/api
// Emulator UI: http://127.0.0.1:4000
// Web App: http://localhost:8080/

const getUserProfile = async ({ id }) => {
  console.log(id);
  try {
    const response = await API.get(`/users/user/${id}`);
    console.log("<--- USER PROFILE --->", response.data);
    return response.data;
  } catch (error) {
    return error;
  }
};

const userProfileService = {
  getUserProfile,
};

export default userProfileService;
