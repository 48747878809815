// File for all our preset values that get reused across our app

// interests to select from
export const allInterests = [
  "👨🏽‍🏫 Mentor-mentee",
  "💼 Job referral",
  "🫱🏽‍🫲🏼 In-person networking events",
  "💡 Career help/promotion advice",
  "👩🏽‍💻 Online Workshop/spotlight series",
  "📰 stay up-to-date with industry trends(news)",
  "🍻 In-person hangouts",
  "📑 Educational resources",
  "💬 Group discussion: based on topics, identity, etc,",
  "🗂️ Looking for side-project partners",
  "🗓️ Exposure (especially for KOL, event organizers and influencers)",
];

// layers to their prompts dict
export const layersToPrompts = {
  Trending: [
    "Embracing your journey towards better mental health: How do you recognize the strides you've made in your mental health?",
    "Embracing your journey towards better mental health: How do you prioritize mental well-being in a demanding workplace environment?",
    "Embracing your journey towards better mental health: What strategies do you use to maintain a healthy work-life balance while focusing on improving mental health?",
    "Embracing your journey towards better mental health: How do you set and maintain boundaries at work for mental well-being?",
    "Embracing your journey towards better mental health: How do you overcome setbacks in your mental health journey?",
    "Embracing your journey towards better mental health: How do you handle guilt or pressure about taking time off for mental health?",
  ],
  All: [
    "Share your story",
    "Q&A",
    "Rant",
    "Unpopular opinion",
    "A recent accomplishment",
  ],
  // default subset to all city layers
  City: [
    "Let’s meet up if...",
    "What are good spots to...",
    "I am looking for",
    "I am new to the city",
    "PSA",
    "Announcement to all local professionals",
    "Want to hear others thoughts on",
    "Let’s network",
    "There is an event at…",
    "My favorite spot is",
    "I am looking for professionals who…",
    "My unpopular opinion",
  ],
  Culture: [
    "Pop Culture",
    "Fashion Culture",
    "Black Culture",
    "Women Winning",
    "Film & Cinema",
    "Music & Art",
    "Hispanic & Latin Culture",
    "Nerd Culture",
  ],
  // default subset to all ERG layers
  ERG: [
    "Let’s network",
    "Stay motivated while pursuing a promotion…",
    "How do you balance career growth with personal goals and interests?",
    "Navigating challenges",
    "An overshare",
    "My unpopular opinion",
    "Mentorship Moments",
    "Tech Trends",
    "Dropping a music recommendation…",
    "Dropping a book recommendation…",
    "Dropping a podcast recommendation…",
    "Dropping an article recommendation…",
    "PSA",
  ],
  Women: [
    "Balancing Act",
    "Empowering Women in STEM",
    "Breaking Barriers",
    "Women Trailblazers in Tech",
  ],
  Latinx: ["Latinx Trailblazers in Tech"],
  Black: ["Black Trailblazers in Tech"],
  Newsstand: [
    "Latest Tech Marvels",
    "Emerging Technologies",
    "Innovation Across Industries",
    "Disruptive innovations",
    "Tech and social impact",
    "Here’s a cool article",
    "Let’s Talk Culture",
    "Let’s Talk Finance",
    "Let’s Talk AI",
    "Let’s Talk Ethical Tech",
    "Let’s Talk Education",
    "Let’s Talk Web3",
  ],
  "Real Talk": [
    "Unfiltered thoughts",
    "Venting session",
    "Honest reflections",
    "Mistakes and growth",
    "Self-reflection",
  ],
  Networking: [
    "Introduction round",
    "Seeking advice",
    "Project collaboration",
    "Professional development",
    "Book/article recommendations",
    "Local meetup interest",
    "Return to office work tips",
    "Let's hack together",
  ],
  "ERG Leads": [
    "What events have you done?",
    "What’s worked for me is…",
    "Let’s chat if…",
    "Let’s collaborate…",
    "Here’s an idea for an event…",
    "My advice on being a good ERG lead…",
    "Dropping a book recommendation…",
    "Dropping a podcast recommendation…",
    "Dropping an article recommendation…",
    "PSA",
  ],
};

// cities (try to keep in alphabetical order please)
// only cities with big enough user population make this list (used for layers & Network filter)
export const cities = [
  "Atlanta",
  //   "Austin",
  // "Boston",
  //   "Chicago",
  //   "Dallas",
  // "Denver",
  //   "Houston",
  // "Los Angeles",
  // "New York City",
  //   "Philadelphia",
  //   "Phoenix",
  //   "San Antonio",
  //   "San Diego",
  "San Francisco",
  //   "San Jose",
];

// Groups (try to keep in alphabetical order please)
export const ergs = [
  "Black",
  "Latinx",
  "Women",
  "LGBTQ+",
  "Asian",
  "Veteran",
  "Young Professionals",
  "Indigenous Americans",
  "Disabilities",
];

// companies on the platform (try to keep in alphabetical order please)
export const companies = [
  "Amazon",
  "Google",
  "Nike",
  "Microsoft",
  "Youtube",
  "Snap",
  "Salesforce",
  "Meta Platforms",
  "Oracle",
  "Uber",
  "Netflix",
  "NVIDIA",
  "Wayfair",
  "Chewy",
  "Airbnb",
  "Asana",
  "Apple",
  "Walmart",
  "Paypal",
  "Patreon",
  "Sage",
  "Xactly",
  "Stratos",
  "DigitalOcean",
  "Pinterest",
  "Crunchbase",
  "Yext",
  "Lyft",
  "Palantir",
  "Scale AI",
  "Instacart",
  "Zocdoc",
  "CaptivateIQ",
  "Samsara",
  "Conduent",
  "Verizon Communications",
  "Comcast",
  "AT&T",
  "Raytheon Technologies",
  "Stealth",
  "Non-Partner Company",
];

// pronouns choices - same as what instagram launched with for english speakers
export const pronouns = [
  "he",
  "him",
  "his",
  "she",
  "her",
  "hers",
  "they",
  "them",
  "theirs",
  "ze",
  "hir",
  "hirs",
  "zir",
  "zie",
  "thon",
  "thons",
  "ve",
  "ver",
  "vis",
  "vi",
  "vir",
  "xe",
  "xem",
  "xyr",
  "mer",
  "mers",
  "per",
  "pers",
  "ne",
  "nee",
  "nir",
  "nirs",
  "ner",
  "ners",
  "co",
  "cos",
  "e",
  "ey",
  "em",
  "eir",
  "fae",
  "faer",
];

// roles/positions on the platform
export const roles = [
  "Account Manager",
  "Backend Software Engineer",
  "Business Analyst",
  "Product Manager",
  "Program Manager",
  "Data Analyst",
  "Data Engineer",
  "Data Scientist",
  "DevOps Engineer",
  "Office Manager",
  "Operations Manager",
  "Support Analyst",
  "Software Developer",
  "Software Engineer",
  "Frontend Software Engineer",
  "Fullstack Software Engineer",
  "Senior Solutions Consultant",
  "Corporate Account Manager",
  "Corporate Recruiter",
  "Culture Belonging Inclusion & Diversity Leader",
  "Curriculum Developer",
  "Customer Account Manager",
  "Customer Sales Specialist",
  "Customer Services Analyst",
  "Customer Success Coach",
  "Customer Success Engineer",
  "Customer Support Analyst",
  "Director, Ecosystem Performance and Execution",
  "Expert Services Consultant",
  "Financial Accountant, Sr",
  "Growth Account Executive",
  "Head of Product",
  "IT Support Engineer",
  "Immigration Specialist",
  "Inside Sales, Accountants",
  "LS Partner Advisor",
  "Lead Corporate Recruiter",
  "Lead Implementation Consultant",
  "Manager, Customer Experience Projects",
  "Manager, Customer Services",
  "Manager, Product Support Education",
  "Marketing Associate",
  "Office Coordinator",
  "Operations Manager, Services",
  "Partner Account Manager",
  "People Lead",
  "People Services Specialist",
  "Recruiting Coordinator",
  "Risk Manager",
  "Sales Development Rep.",
  "Sales Enablement Program Manager",
  "Sales Engineer",
  "Sales Operations Analyst",
  "Senior Business Analyst",
  "Business Continuity and Third Party Risk Manager",
  "Senior Business Improvement Analyst, Sales Adminis",
  "Senior Customer Services Analyst",
  "Senior Online Community Manager",
  "Online Community Manager",
  "Senior Procurement Operations Manager",
  "Senior Support Analyst",
  "Specialist, Sales Administration & Operations",
  "Sr. Partner Engagement Manager",
  "Partner Engagement Manager",
  "Support Enablement Specialist",
  "Trainer",
  "Founder, Executive & Leadership Coach",
  "Security Professional",
  "Customer Engineer",
  "Sales",
  "Marketing Manager",
  "Engineer Manager",
  "Senior Security Professional",
  "Senior Account Manager",
  "Corporate PR Manager",
  "Voice of Customer Analyst",
  "WFM Intraday Analyst",
  "Transformation Senior Manager",
  "Global CX Analyst",
  "Sr. Solution Consultant",
  "UX/UI Designer",
  "Apprentice Diversity, Equity & Inclusion Communica",
  "Senior IT Director, Business Partner",
  "Senior Manager - Insight, Readiness, and Change",
  "Sales Development Representative",
  "Senior Corporate Affairs Business Partner",
  "Business Change Partner",
  "Employee Relations Consultant",
  "Communications Consultant",
  "Privacy Operations Manager",
  "Change Manager, M&A and Finance",
  "Associate Engineer",
  "Sales Operations Business Analyst",
  "Sales Development Representative",
  "Content Strategist",
  "BI Strategist and Architect",
  "Sr. Project Manager",
  "Manager, Partnership and Compliance",
  "Manager, Solution Consulting-General Business",
  "Technical Support Analyst",
  "Principal Content Designer",
  "Senior UX Researcher",
  "Product Marketing Manager",
  "Senior Business Intelligence Analyst",
  "L&D Business Partner",
  "Compensation Partner",
  "Principal UX Researcher",
  "Field Sales Engineer",
  "Associate Project Manager",
  "Country Community Lead",
  "Senior Solution Consultant",
  "Customer Service Analyst",
  "Operations Manager Services",
  "Customer Services Agent",
  "Learning Experience Specialist",
  "Senior Sales Enablement Program Manager",
  "Associate .Net Software Engineer",
  "Senior Customer Support Analyst",
  "Manager, Social Selling",
  "Senior Manager - Sage Belong (UK&I)",
  "Sales Admin Specialist",
  "Director, International Launch",
  "Senior Business/Data Analyst",
  "Performance and Insights Analyst",
  "Senior Project Manager",
  "Principal Expert Services Consultant",
  "Director, Customer Services",
  "Retentions Representative",
  "Incident Manager",
  "Sr. Public Relations Manager",
  "Event Lead",
  "Senior Wellbeing Manager",
  "People Services Representative",
  "Senior Program Manager",
  "Account Executive - SMB",
  "Talent Brand Apprentice",
  "CX Program Manager",
  "Director Process Optimization",
  "Program Manager, Learning Services",
  "Executive Assistant",
  "Talent Capability Lead",
  "Partnership & Compliance Manager",
  "Sr. Director of Technology Strategy",
  "Founder",
  "CEO",
  "CFO",
  "COO",
  "CTO",
  "CMO",
];
