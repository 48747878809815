import {
  Avatar,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  alpha,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { companyIllustration } from "../../Assets/utilFunctions";
import { companies, roles } from "../../Pages/constants";

import { CloseOutlined } from "@mui/icons-material";
import dayjs from "dayjs";

const AddExperience = ({ exp, onFieldChange, index, removeExperience }) => {
  const [company, setCompany] = useState(exp?.company);
  const [position, setPosition] = useState(exp?.position);

  const [startDate, setStartDate] = useState(
    exp?.startDate === ""
      ? new Date()
      : new Date(`${exp?.startDate.month} 2, ${exp?.startDate.year}`)
  );

  const [presentlyWorking, setPresentlyWorking] = useState(
    exp?.endDate === "present" ? true : false
  );

  const [endDate, setEndDate] = useState(
    exp?.endDate === "present" || exp.endDate === ""
      ? new Date()
      : new Date(`${exp?.endDate.month} 2, ${exp?.endDate.year}`)
  );

  // handles changes to each field
  const handleFieldChange = (fieldName) => (e) => {
    const newValue = e.target.value;
    onFieldChange(fieldName, newValue);
  };

  // layout for textfield
  const sxForTextField = {
    width: "100%",
    "& .MuiFilledInput-root": {
      overflow: "hidden",
      borderRadius: "7px",
      backgroundColor: "#F3F9FB",
      border: "1px solid",
      borderColor: "#D2E1E2",
      transition: "border-color background-color box-shadow",
      "&:hover": {
        backgroundColor: "#F3F9FB",
      },
      "&.Mui-focused": {
        backgroundColor: "#F3F9FB",
        boxShadow: `${alpha("#000", 0.25)} 0 0 0 2px`,
        borderColor: "#D2E1E2",
      },
    },
  };
  const inputPropsForTextField = {
    style: {
      fontSize: 16,
      fontWeight: 300,
      color: "#2A2E33",
      paddingLeft: 12,
      marginLeft: 0,
    },
  };
  const inputLabelPropsForTextfield = {
    style: {
      fontWeight: 400,
      fontSize: 12,
      color: "#16171B",
    },
  };

  // to toggle presentlyWorking check mark
  const handlePresentChange = () => {
    setPresentlyWorking(!presentlyWorking);
  };

  // change the end date based on presently working
  useEffect(() => {
    if (presentlyWorking) {
      onFieldChange("endDate", "present");
    }
    if (!presentlyWorking) {
      const month = dayjs(endDate).format("MMMM");
      const year = dayjs(endDate).format("YYYY");
      onFieldChange("endDate", {
        month,
        year,
      });
    }
  }, [presentlyWorking]);

  return (
    <Stack
      direction={"row"}
      alignItems={"flex-start"}
      justifyContent={"center"}
      sx={{ m: 1 }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Avatar
          src={companyIllustration(company)}
          sx={{
            width: "40px",
            height: "40px",
            margin: 1,
            borderRadius: 0,
          }}
        />
        <IconButton
          color="#16171B"
          onClick={() => removeExperience(index)}
          sx={{
            display: { xs: "flex", sm: "flex", md: "none" },
          }}
        >
          <CloseOutlined />
        </IconButton>
      </Box>
      <Stack
        direction={"column"}
        alignItems={"flex-start"}
        justifyContent={"center"}
        spacing={1}
      >
        <FormControl
          required
          error={company.trim() === ""}
          variant="filled"
          sx={{ ...sxForTextField, width: "100%" }}
          size="small"
        >
          <InputLabel htmlFor="company" {...inputLabelPropsForTextfield}>
            Company
          </InputLabel>
          <Select
            value={company}
            onChange={(e) => {
              setCompany(e.target.value);
              onFieldChange("company", e.target.value);
            }}
            displayEmpty
            inputProps={{
              disableUnderline: true,
              classes: {
                root: "MuiFilledInput-root",
                focused: "Mui-focused",
              },
            }}
            {...inputPropsForTextField}
          >
            {companies.map((company) => (
              <MenuItem key={company} value={company}>
                {company}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>
            {company.trim() === "" ? "company is required" : ""}
          </FormHelperText>
        </FormControl>

        <FormControl
          required
          error={position.trim() === ""}
          variant="filled"
          sx={{ ...sxForTextField, width: "100%" }}
          size="small"
        >
          <InputLabel htmlFor="position" {...inputLabelPropsForTextfield}>
            Position
          </InputLabel>
          <Select
            value={position}
            onChange={(e) => {
              setPosition(e.target.value);
              onFieldChange("position", e.target.value);
            }}
            displayEmpty
            inputProps={{
              disableUnderline: true,
              classes: {
                root: "MuiFilledInput-root",
                focused: "Mui-focused",
              },
            }}
            {...inputPropsForTextField}
          >
            {roles.map((role) => (
              <MenuItem value={role}>{role}</MenuItem>
            ))}
          </Select>
          <FormHelperText>
            {position.trim() === "" ? "role is required" : ""}
          </FormHelperText>
        </FormControl>

        <FormControlLabel
          control={
            <Checkbox
              onChange={handlePresentChange}
              checked={presentlyWorking}
            />
          }
          label=<Typography
            variant="body1"
            sx={{
              fontSize: {
                xs: "10px",
                sm: "12px",
                md: "14px",
              },
            }}
          >
            I am currently working here.
          </Typography>
        />

        <Stack
          direction={{ xs: "column", sm: "column", md: "row" }}
          alignItems={"flex-start"}
          justifyContent={"center"}
          spacing={1}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Start Date"
              value={startDate}
              views={["year", "month"]}
              onChange={(val) => {
                setStartDate(val);
                const month = dayjs(val).format("MMMM");
                const year = dayjs(val).format("YYYY");
                onFieldChange("startDate", {
                  month,
                  year,
                });
              }}
              renderInput={(props) => (
                <TextField
                  variant="filled"
                  InputLabelProps={inputLabelPropsForTextfield}
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      root: "MuiFilledInput-root",
                      focused: "Mui-focused",
                    },
                  }}
                  inputProps={inputPropsForTextField}
                  sx={sxForTextField}
                  {...props}
                />
              )}
            />

            <DatePicker
              label="End Date"
              value={endDate}
              onChange={(val) => {
                setEndDate(val);
                const month = dayjs(val).format("MMMM");
                const year = dayjs(val).format("YYYY");
                onFieldChange("endDate", {
                  month,
                  year,
                });
              }}
              minDate={startDate}
              views={["year", "month"]}
              disabled={presentlyWorking}
              renderInput={(props) => (
                <TextField
                  variant="filled"
                  InputLabelProps={inputLabelPropsForTextfield}
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      root: "MuiFilledInput-root",
                      focused: "Mui-focused",
                    },
                  }}
                  inputProps={inputPropsForTextField}
                  sx={sxForTextField}
                  {...props}
                />
              )}
            />
          </LocalizationProvider>
        </Stack>
      </Stack>
      <IconButton
        color="#16171B"
        onClick={() => removeExperience(index)}
        sx={{ display: { xs: "none", sm: "none", md: "flex" } }}
      >
        <CloseOutlined />
      </IconButton>
    </Stack>
  );
};

export default AddExperience;
