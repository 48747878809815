import axios from "axios";

const API = axios.create({
  baseURL: "https://us-central1-stratosv1.cloudfunctions.net/api",
});

// api url: https://us-central1-stratosv1.cloudfunctions.net/api
// localhost serving: http://127.0.0.1:5001/stratosv1/us-central1/api
// Emulator UI: http://127.0.0.1:4000
// Web App: http://localhost:8080/

const getRefferedUsersCount = async ({ token }) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  console.log("token", token);

  const response = await API.get("/users/getRefferedUsersCount", config, {
    headers: {
      Access: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });

  return response.data;
};

//register
const register = async (userData, refCode) => {
  let route = "/signup";

  if (refCode) {
    route = `/signup/${refCode}`;
  }

  const response = await API.post(route, userData, {
    headers: {
      Access: "application/json",
      "Content-Type": "application/json",
    },
  });

  if (response.data.userDetails) {
    localStorage.setItem("user", JSON.stringify(response.data.userDetails));
    localStorage.setItem("token", JSON.stringify(response.data.token));

    const result = {
      user: response.data.userDetails,
      token: response.data.token,
    };
    return result;
  } else {
    return response.data;
  }
};

const signInWithGoogle = async (googleToken, refCode) => {
  let route = "/signInWithGoogle";

  if (refCode) {
    route = `/signInWithGoogle/${refCode}`;
  }

  console.log("googleToken", googleToken);

  const response = await API.post(
    route,
    { googleToken },
    {
      headers: {
        Access: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    }
  );

  if (response.data.userDetails) {
    localStorage.setItem("user", JSON.stringify(response.data.userDetails));
    localStorage.setItem("token", JSON.stringify(response.data.token));

    const result = {
      user: response.data.userDetails,
      token: response.data.token,
    };
    return result;
  } else {
    return response.data;
  }
};

const setIsNewUserFalse = async ({ token }) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await API.get("/setIsNewUserFalse", config);
    return response.data;
  } catch (error) {
    return error;
  }
};

const updateUserLayer = async ({ userLayers }, token) => {
  let layerData = { layers: userLayers };
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await API.post("/users/updateLayer", layerData, config, {
    headers: {
      Access: "application/json",
      "Content-Type": "application/json",
    },
  });

  return response.data;
};

const updateUserInterests = async ({ userInterests }, token) => {
  let interestsData = { interests: userInterests };
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await API.post(
    "/users/updateInterests",
    interestsData,
    config,
    {
      headers: {
        Access: "application/json",
        "Content-Type": "application/json",
      },
    }
  );

  return response.data;
};

const updateUserErgs = async ({ userErgs }, token) => {
  let ergsData = { ergs: userErgs };
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await API.post("/users/updateErgs", ergsData, config, {
    headers: {
      Access: "application/json",
      "Content-Type": "application/json",
    },
  });

  return response.data;
};

//logout user
const logout = async () => {
  localStorage.removeItem("user");
  localStorage.removeItem("token");
};

const removeImage = async ({ user_token }) => {
  const token = user_token;
  console.log("token", token);
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await API.get("/users/removeImage", config);
  return response.data;
};

const uploadImage = async (formData, user_token) => {
  const token = user_token;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  };
  console.log("token", token);

  const response = await API.post("/users/uploadImage", formData, config);
  return response.data;
};

const updateUserPersonal = async (userDetails, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await API.put(
    "/users/updatePersonal",
    userDetails.newUserData,
    config,
    {
      headers: {
        Access: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
  return response.data;
};

const updateAccountDetails = async ({ userDetails, token }) => {
  const { userData } = userDetails;

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await API.put("/users/updateAccount", userData, config);
    return response.data;
  } catch (error) {
    return error;
  }
};

const getUser = async ({ token }) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await API.get("/users/me", config);
    return response.data;
  } catch (error) {
    return error;
  }
};

const checkUser = async ({ email }) => {
  try {
    const response = await API.get(`/validusers/${email}`);
    console.log("<--- VALID USER DATA --->", response.data);
    return response.data;
  } catch (error) {
    return error;
  }
};

const resetPassword = async ({ email }) => {
  try {
    const response = await API.post(
      "/users/resetPassword",
      { email },
      {
        headers: {
          Access: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

const login = async (user) => {
  const { email, password } = user;
  const response = await API.post(
    "/login",
    { email, password },
    { headers: { "Content-Type": "application/json" } }
  );

  localStorage.setItem("user", JSON.stringify(response.data.userDetails));
  localStorage.setItem("token", JSON.stringify(response.data.token));

  const result = {
    user: response.data.userDetails,
    token: response.data.token,
  };
  return result;
};

const getUsers = async ({ filters, token }) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await API.post(
    "/users/getUsers",
    filters.usersQuery,
    config,
    {
      headers: {
        Access: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    }
  );

  return response.data;
};

const getMatches = async ({ token }) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  console.log("token", token);

  const response = await API.get("/users/getMatches", config, {
    headers: {
      Access: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });

  return response.data;
};

// const getMatches = async ({ token }) => {
//   const config = {
//     headers: {
//       Authorization: `Bearer ${token}`,
//     },
//   };

//   const response = await API.post("/users/getMatches", config, {
//     headers: {
//       Access: "application/json",
//       "Content-Type": "application/json",
//       "Access-Control-Allow-Origin": "*",
//     },
//   });

//   return response.data;
// };

// backdoor
const backdoor = async (userData) => {
  let route = "/backdoor";
  const response = await API.post(route, userData, {
    headers: {
      Access: "application/json",
      "Content-Type": "application/json",
    },
  });
  return response.data;
};

const authService = {
  register,
  login,
  updateUserLayer,
  updateUserPersonal,
  getUser,
  updateAccountDetails,
  uploadImage,
  logout,
  getRefferedUsersCount,
  signInWithGoogle,
  setIsNewUserFalse,
  updateUserInterests,
  updateUserErgs,
  removeImage,
  getUsers,
  checkUser,
  backdoor,
  resetPassword,
  getMatches,
};

export default authService;
