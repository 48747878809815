import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { Box, Button, Typography } from "@mui/material";

const ChooseYourInterests = ({ userInterests, setUserInterests }) => {
  const location = useLocation();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, isSuccess, isError, isLoading, message } = useSelector(
    (state) => state.auth
  );
  const allInterests = [
    "👨🏽‍🏫 Mentor-mentee",
    "💼 Job referral",
    "🫱🏽‍🫲🏼 In-person networking events",
    "💡 Career help/promotion advice",
    "👩🏽‍💻 Online Workshop/spotlight series",
    "📰 stay up-to-date with industry trends(news)",
    "🍻 In-person hangouts",
    "📑 Educational resources",
    "💬 Group discussion: based on topics, identity, etc,",
    "🗂️ Looking for side-project partners",
    "🗓️ Exposure (especially for KOL, event organizers and influencers)",
  ];

  const handleInterestToggle = (interest) => {
    if (userInterests.includes(interest)) {
      setUserInterests(userInterests.filter((item) => item !== interest));
    } else {
      setUserInterests([...userInterests, interest]);
    }
  };

  const isSelected = (interest) => {
    return userInterests.includes(interest) ? "contained" : "outlined";
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Typography sx={{ fontSize: 20 }}>
        Tell us what you are interested in
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          width: "100%",
        }}
      >
        {allInterests.map((interest, index) => (
          <Button
            sx={{
              margin: 0.5,
              textTransform: "none",
              borderRadius: "12px",
              fontWeight: 400,
              fontSize: 15,
              paddingX: 2,
              paddingY: 0.5,
              "&:hover": {
                background: "#52B1BB",
                border: "2px solid #52B1BB",
                color: "#FFFFFF",
              },
            }}
            key={index}
            variant={isSelected(interest)}
            onClick={() => handleInterestToggle(interest)}
          >
            {interest}
          </Button>
        ))}
      </Box>

      {/* <Typography sx={{ color: "#C4C4C4" }}>
        select at least 1 to continue
      </Typography> */}
    </Box>
  );
};
export default ChooseYourInterests;
