export const sortExpriences = (experience) => {
  const sortedExperience = [...experience].sort((a, b) => {
    const yearA = a.startDate.year;
    const yearB = b.startDate.year;
    const monthA = a.startDate.month;
    const monthB = b.startDate.month;

    // Compare years in descending order
    if (yearA !== yearB) {
      return yearB - yearA;
    }

    // If years are the same, compare months in descending order
    const monthOrder = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return monthOrder.indexOf(monthB) - monthOrder.indexOf(monthA);
  });
  return sortedExperience;
};

export const sortUsers = (users) => {
  const sortedUser = [...users].sort((a, b) => {
    const profilePicA = a.profileImage?.flag;
    const profilePicB = b.profileImage?.flag;
    const jobTitleA = a.jobTitle;
    const jobTitleB = b.jobTitle;

    // Sort those with profile pics to the top
    if (profilePicA && !profilePicB) {
      return -1;
    } else if (!profilePicA && profilePicB) {
      return 1;
    }

    // If both have profile pics or both don't,
    // sort prioritize jobTitle
    if (jobTitleA && !jobTitleB) {
      return -1;
    } else if (!jobTitleA && jobTitleB) {
      return 1;
    } else {
      return 0;
    }
  });
  return sortedUser;
};
