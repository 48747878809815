import React from 'react'
import { Box,Paper, Button, Typography } from '@mui/material'



const PipelineCard = ({Profile}) => {
    return (
      <Paper className='mypipeline-container'>
    <Box className='mypipeline-userinfo'>
    <Box className='mypipeline-user-profile'>
        <img src={Profile} height={80} width={80} />
        <Typography variant='h2'>Olivia Rodriguez</Typography>
        <Typography variant='h6'>Software Engineer</Typography>
    </Box>

    <Box className='mypipeline-user-actions'>
        <Button className='message'>Message</Button>
        <Button className='reject'>Reject</Button>
        <Button className='archive'>Archive</Button>
    </Box>
</Box>

<Box className='mypipline-user-expierence'>
    {/* User Experience */}
    <Box className='user-experience'>
        <Box>
        <Typography variant='h1'>Experience</Typography>
        </Box>

        <Box className='company'>
            <Box>
        <Typography variant='h2'>Stratos</Typography>
                
            </Box>

            <Box>
        <Typography variant='h2'>2022(Present)</Typography>
                
            </Box>
        </Box>

        <Box className='company'>
        <Typography variant='h2'>Stratos</Typography>
        <Typography variant='h2'>2022(Present)</Typography>
        </Box>

        <Box className='company'>
        <Typography variant='h2'>Stratos</Typography>
        <Typography variant='h2'>2022(Present)</Typography>
        </Box>
        
      
    </Box>

    {/* Education */}

    <Box className='user-education'>
        <Box>
        <Typography variant='h1'>Education</Typography>
        </Box>

        <Box className='school'>
            <Box>
        <Typography variant='h2'>Stratos</Typography>
          <Typography variant='h6'>BS Economics and Finance</Typography>
            </Box>

            <Box>
        <Typography variant='h2'>2022(Present)</Typography>
                
            </Box>
        </Box>

      
    <Box className='user-layers'>
        <Box>
        <Typography variant='h1'>Layers</Typography>
        </Box>

        <Box className='layers'>
           
        <Typography variant='h2'>Technology</Typography>
        <Typography variant='h2'>Business</Typography>
            
        </Box>
        </Box>
        

    <Box className='user-workplace'>
        <Box>
        <Typography variant='h1'>Workplace</Typography>
        </Box>

        <Box className='workplace'>
           
        <Typography variant='h2'>Startup</Typography>
        <Typography variant='h2'>Mid Size</Typography>
        <Typography variant='h2'>Large Size</Typography>
            
        </Box>
    </Box>

</Box>
            </Box>
            </Paper>
  )
}

export default PipelineCard