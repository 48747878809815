import React from "react";

import { Box, Button, Typography } from "@mui/material";
import { ergs } from "../../Pages/constants";

const ChooseYourErgs = ({ userErgs, setUserErgs }) => {
  const handleErgToggle = (erg) => {
    if (userErgs.includes(erg)) {
      setUserErgs(userErgs.filter((item) => item !== erg));
    } else {
      setUserErgs([...userErgs, erg]);
    }
  };

  const isSelected = (erg) => {
    return userErgs.includes(erg) ? "contained" : "outlined";
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Typography sx={{ fontSize: 20, marginTop: "5px", marginBottom: "15px" }}>
        Select your employee resource groups
      </Typography>
      <Box
        sx={{
          display: "block",
          flexWrap: "wrap",
          width: "100%",
          alignItems: "center",
        }}
      >
        {ergs.map((erg) => (
          <Button
            sx={{
              margin: 0.5,
              textTransform: "none",
              borderRadius: "12px",
              fontWeight: 400,
              fontSize: 15,
              paddingX: 2,
              paddingY: 0.5,
              "&:hover": {
                background: "#52B1BB",
                border: "2px solid #52B1BB",
                color: "#FFFFFF",
              },
            }}
            key={erg}
            variant={isSelected(erg)}
            onClick={() => handleErgToggle(erg)}
          >
            {erg}
          </Button>
        ))}
      </Box>

      {/* <Typography sx={{ color: "#C4C4C4", marginTop: "10px" }}>
        select at least 1 to continue
      </Typography> */}
    </Box>
  );
};
export default ChooseYourErgs;
