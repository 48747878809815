import { Box, Button, Grow, TextField, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import Typewriter from "typewriter-effect";
import WebsiteNav from "../Website/Navbar/WebsiteNav";

import customers from "../../Assets/Imagesinfo-customers.png";
// /Users/christophergrey/Desktop/STRATOS-REPO/stratos/src/Assets/website-bg.png
import webBg from "../../Assets/website-bg1.png";
// import "./Website.css";
import "../Website/WebsiteHome/Website.css";
// /Users/christophergrey/Desktop/STRATOS-REPO/stratos/src/Pages/Website/WebsiteHome/Website.css
// import WhyStratos from "./WhyStratos/WhyStratos";
// import OurServices from "./OurServices/OurServices";
// import Story from "./Story/Story";
// import Features from "./Features/Features";
// import UserExpierence from "./UserExpierence/UserExpierence";
// import Contact from "./Contact/Contact";
// import Subscribe from "./Subscribe/Subscribe";

function TechStars() {
  const navigate = useNavigate();
  const handleClickStartChattingNow = () => {
    navigate("/Welcome");
  };

  return (
    <Box className="website-main-container">
      <WebsiteNav />
      <Grow in {...{ timeout: 2000 }}>
        <Box className="heading-container">
          <Box className="heading-info">
            <h3>Stratos is a community-based platform that serves</h3>
            <div>
              <Typewriter
                className="typewriter"
                options={{
                  strings: [
                    "Black & Latinx students, professionals and entrepreneurs",
                  ],
                  autoStart: true,
                  loop: true,
                }}
              />
            </div>
            {/* <div className="heading-info-btn-chat">
              <button
              onClick={handleClickStartChattingNow}
              >
                Start Chatting Now <ArrowRightAlt fontSize="10px" />
              </button>
            </div> */}

            <div className="heading-customers-info">
              <div className="heading-customer-images">
                <img src={customers} alt="customers info" />
              </div>
              {/* { <div className="heading-customers">
              <Typography variant="h1">2,291</Typography>
              <Typography variant="h2">Happy Customers</Typography>
            </div>} */}

              {/* { <div className="heading-customers-ratings">
              <Typography variant="h1">4.5/5</Typography>
              <img src={stars} alt="rating" />
            </div>} */}
            </div>
          </Box>
          <Box className="heading-image">
            <img src={webBg} alt="web background" />
          </Box>
        </Box>
      </Grow>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent={"center"}
        alignItems={"center"}
        width="70%"
        alignSelf={"center"}
        mt={10}
      >
        <Typography fontWeight={"600"} fontSize={"30px"} mb={8}>
          About Stratos
        </Typography>
        <Typography fontWeight={"400"} fontSize={"16px"}>
          Four years ago I went through a major life change. I went to a
          holistic doctor who checked my hormone and heavy metal levels after I
          was sick, had a failed pregnancy and had a consistent PH imbalance for
          a year that caused a litany of problems. I had to detox my body from
          heavy metals and get my hormones back in balance and I wanted to
          choose the most natural route to get there.
          <br />
          <br />
          <br />
          After consulting a holistic doctor and scouring the internet for
          better for you products , I decided to change my lifestyle; getting
          rif of most of my makeup and personal care and swapping for clean
          versions. Everything from makeup to period products had to be checked
          for toxic ingredients and be tossed if they were hormone disrupting
          (90% of them were awful!). I realized that there was not a single
          organic period care brand that I felt connected to or that felt
          comfortable and effective. I wanted to start my own brand, but I knew
          cotton was going through major issues. I had the idea to start a
          reusable pads company made with hemp, but I knew with reusables
          adoption was low. A few years later in 2020 I had the idea to make
          disposable menstrual pads with hemp fiber. I emailed 200 factories and
          got 200 N-Os! They said it was impossible and the processing
          capabilities didn't exist yet. Jump to August 2021 when we received
          our first angel investment and I bought on my best friend as
          co-founder Rebecca Caputo. Two months later we got our first prototype
          of the hemp fiber pad, and 8 months later in June of 2022 we launched
          Rif care.
          <br />
          <br />
          <br />
          Rif Care is a wellness company that makes period care out of hemp
          fiber. We focus on providing safe and non hormone disrupting cycle
          care solutions for women. We are a 100% BIPOC women owned small
          business. Every purchase of Rif helps our mission of lowering our
          carbon footprint, one period at a time. Thank you!
        </Typography>
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        justifyContent={"center"}
        alignItems={"center"}
        width="70%"
        alignSelf={"center"}
        mt={10}
      >
        <Typography fontWeight={"600"} fontSize={"30px"} mb={8}>
          Contact Us
        </Typography>
        <Box display={"flex"} flexDirection={"row"}>
          <Box mr={4}>
            <Typography fontWeight={"400"} fontSize={"16px"} mb={1}>
              Name
            </Typography>
            <TextField
              variant="outlined"
              inputProps={{
                style: {
                  height: 30,
                  fontSize: 20,
                  padding: 1,
                  paddingLeft: 2,
                  paddingRight: 2,
                },
              }}
            />
          </Box>
          <Box>
            <Typography fontWeight={"400"} fontSize={"16px"} mb={1}>
              Email
            </Typography>
            <TextField
              variant="outlined"
              inputProps={{
                style: {
                  height: 30,
                  fontSize: 20,
                  padding: 1,
                  paddingLeft: 2,
                  paddingRight: 2,
                },
              }}
            />
          </Box>
        </Box>

        <Box width={"56%"} mt={4}>
          <Typography fontWeight={"400"} fontSize={"16px"} mb={1}>
            Message
          </Typography>
          <TextField
            variant="outlined"
            multiline
            rows={5}
            inputProps={{
              style: {
                height: 30,
                fontSize: 20,
                padding: 1,
                paddingLeft: 2,
                paddingRight: 2,
              },
            }}
            fullWidth
          />
        </Box>
        <Button
          variant="contained"
          sx={{ marginTop: 4, bgcolor: "black", borderRadius: 10 }}
        >
          Send
        </Button>
      </Box>
    </Box>
  );
}

export default TechStars;
