import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import MenuIcon from "@mui/icons-material/Menu";
import { Box, IconButton } from "@mui/material";

import StratosLogo from "../../../Assets/stratos-logo.png";
import "./WebsiteNav.css";

// dead file i believe
function WebsiteNav() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const handleClickLogin = () => {
    navigate("/Welcome");
  };
  return (
    <Box className="navbar-container">
      <Box className="navbar-logo">
        <img alt="stratos logo" src={StratosLogo} />
      </Box>

      <Box className="nav-links">
        {/* <a href="#">Home</a> */}
        {/* <a href="#">About Stratos</a> */}
        {/* <a href="#">Team</a> */}
        {/* <a href="#">Blog</a> */}
        {/* <a href="#">Customers</a> */}
        {/* <a href="#">Home</a> */}
        <button onClick={handleClickLogin}>Login</button>
      </Box>

      <Box className="icon-button">
        <IconButton onClick={() => setOpen(!open)}>
          <MenuIcon sx={{ height: "30px", width: "30px" }} color="primary" />
        </IconButton>
      </Box>

      {open && (
        <Box className="app-nav">
          <a href="#">Home</a>
          <a href="#">About Stratos</a>
          <a href="#">Team</a>
          <a href="#">Blog</a>
          <a href="#">Customers</a>
          <button>Contact</button>
        </Box>
      )}
    </Box>
  );
}

export default WebsiteNav;
