import axios from "axios";

const API = axios.create({
  baseURL: "https://us-central1-stratosv1.cloudfunctions.net/api",
});
// api url: https://us-central1-stratosv1.cloudfunctions.net/api
// localhost serving: http://127.0.0.1:5001/stratosv1/us-central1/api
// Emulator UI: http://127.0.0.1:4000
// Web App: http://localhost:8080/

// create new post
const createPost = async (postData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  let layer = postData.layer;
  postData.layer = postData.layer.toUpperCase();
  console.log("new post data", postData);

  const response = await API.post("/posts/create", postData, config);

  let result = response.data;
  if (result.post) {
    result.post.layer = layer;
    console.log("{ layer, post: result } --> ", {
      layer,
      post: result.post,
    });
    return { layer: layer.toUpperCase(), post: result.post };
  }

  return result;
};

const likePost = async (likeData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await API.post("/posts/likePost", likeData, config, {
    headers: {
      Access: "application/json",
      "Content-Type": "application/json",
    },
  });

  let result = response.data;

  return result;
};

const unLikePost = async (unlikeData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await API.post("/posts/unLikePost", unlikeData, config, {
    headers: {
      Access: "application/json",
      "Content-Type": "application/json",
    },
  });

  let result = response.data;

  return result;
};

//get all posts
const getPosts = async ({ token }) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await API.get("/posts", config, {
    headers: {
      Access: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });

  return response.data;
};

//const getPostsByLayer = async (layer, token) => {
//    const config = {
//        headers: {
//            Authorization: `Bearer ${token}`,
//        },
//    };
//    console.log("was here", token, layer);
//    const response = await API.post(`/posts/postByLayer`, { layer }, config);
//
//    console.log(response);
//
//    return response.data;
//};

const getPostComments = async (commentData, token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await API.post("/posts/comments", commentData, config, {
      headers: {
        Access: "application/json",
        "Content-Type": "application/json",
      },
    });

    let result = response.data;

    return result;
  } catch (error) {
    console.log("error", error);
  }
};

const createPostComment = async (commentData, token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await API.post(
      "/posts/createComment",
      commentData,
      config,
      {
        headers: {
          Access: "application/json",
          "Content-Type": "application/json",
        },
      }
    );

    let result = response.data;

    return result;
  } catch (error) {
    console.log("error", error);
  }
};

const deletePostComment = async (postId, commentId, layer, token) => {
  try {
    const commentData = {
      layer: layer,
      postId: postId,
      commentId: commentId,
    };

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await API.post(
      "/posts/deleteComment",
      commentData,
      config,
      {
        headers: {
          Access: "application/json",
          "Content-Type": "application/json",
        },
      }
    );

    let result = response.data;

    return result;
  } catch (error) {
    console.log("error", error);
  }
};

const deletePost = async (postId, layer, token) => {
  try {
    const postData = {
      layer: layer,
      postId: postId,
    };

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await API.post("/posts/deletePost", postData, config, {
      headers: {
        Access: "application/json",
        "Content-Type": "application/json",
      },
    });

    let result = response.data;

    return result;
  } catch (error) {
    console.log("error", error);
  }
};

const postService = {
  createPost,
  getPosts,
  likePost,
  unLikePost,
  getPostComments,
  createPostComment,
  deletePostComment,
  deletePost,
};

export default postService;
