import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SendIcon from "@mui/icons-material/Send";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import {
  Avatar,
  Box,
  Button,
  CardHeader,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { RiShareForwardLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { convertDate } from "../../../Assets/utilFunctions";
import {
  createPostComment,
  likePost,
  unLikePost,
} from "../../../features/posts/postSlice";
import CommentsCard from "./CommentsCard";

import { TrackComment, TrackLike, TrackUnLike } from "../../../MixPanel";
import Spinner from "../../../components/Spinner";
import {
  blockUser,
  reportPost,
  reportPostBlockUser,
} from "../../../features/report/reportSlice";

import BlockIcon from "@mui/icons-material/Block";
import NoAccountsIcon from "@mui/icons-material/NoAccounts";
import PersonOffIcon from "@mui/icons-material/PersonOff";

const CommentsDialogBox = ({
  post,
  openCommentsDialog,
  handleCommentDialogClose,
}) => {
  const { comments, isCommentLoading } = useSelector((state) => state.posts);
  const { user } = useSelector((state) => state.auth);

  const [commentContent, setCommentContent] = React.useState("");

  const [like, setLike] = React.useState(false);
  const [likeCount, setLikeCount] = React.useState(0);

  const [tempComments, setTempComments] = React.useState([]);
  const [commentCount, setCommentCount] = React.useState(0);

  React.useEffect(() => {
    setLike(post.likedByCurrentUser ? post.likedByCurrentUser : false);
    setLikeCount(post.likesCount);
    setTempComments(comments);
    setCommentCount(post.commentsCount);
  }, []);

  React.useEffect(() => {
    setTempComments(comments);
    setCommentCount(post.commentsCount);
  }, [comments]);

  const dispatch = useDispatch();

  const handleWriteComment = () => {
    const commentData = {
      layer: post.layer,
      postId: post.id,
      commentContent: commentContent,
    };
    const tempCommentData = {
      layer: post.layer,
      postId: post.id,
      commentContent: commentContent,
      author: {
        name: user.name,
        profileImage: user.profileImage,
      },
    };
    TrackComment(
      user.email,
      post.id,
      post.author.name,
      post.author.id,
      post.author.jobTitle,
      post.author.currentCompany,
      user.jobTitle,
      user.currentCompany
    );
    setTempComments([...tempComments, tempCommentData]);
    const notificationData = {
      actionType: "Comment",
      content: post.content,
      postId: post.id,
      layer: post.layer,
      userToNotify: post.author.id,
    };
    dispatch(createPostComment({ commentData, notificationData }));

    setCommentContent("");
  };
  console.log("tempComments", tempComments);

  const handleLikeButton = (event) => {
    const likeData = { postId: post.id, layer: post.layer };

    if (post.likedByCurrentUser) {
      // unlinke the post
      setLike(false);
      setLikeCount(likeCount - 1);
      dispatch(unLikePost(likeData));
      TrackUnLike(
        user.email,
        post.id,
        post.author.name,
        post.author.id,
        user.name,
        user.userId,
        post.layer,
        post.title,
        post.content
      );
    } else {
      // like the post
      setLike(true);
      setLikeCount(likeCount + 1);
      dispatch(likePost(likeData));
      TrackLike(
        user.email,
        post.id,
        post.author.name,
        post.author.id,
        user.name,
        user.userId,
        post.layer,
        post.title,
        post.content
      );
    }
  };

  const [openReportMenu, setOpenReportMenu] = useState(null);
  const open = Boolean(openReportMenu);
  const handleReportMenuClick = (event) => {
    setOpenReportMenu(event.currentTarget);
  };
  const handleReportMenuClose = () => {
    setOpenReportMenu(null);
  };

  const [rprtMenu, setRprtMenu] = useState(false);
  const [reason, setReason] = useState("");

  const reportMenu = () => {
    setRprtMenu(true);
  };
  const closeRprtMenu = () => {
    setRprtMenu(false);
  };

  const [onlyReport, setOnlyReport] = useState(true);

  const handleReportButton = () => {
    setOnlyReport(true);
    dispatch(
      reportPost({
        reportedPost: post.id,
        reportingReason: reason,
        reportedPostLayer: post.layer,
        reportedPostAuthorId: post.author.id,
        reportedPostAuthorName: post.author.name,
      })
    );
  };

  const handleBlockUserButton = () => {
    dispatch(
      blockUser({
        blockedUser: post.author.id,
        blockedEmail: post.author.name,
      })
    );
  };

  const handleBlockReportButton = () => {
    setOnlyReport(false);
    reportMenu();
  };

  const handleReportAndBlock = () => {
    console.log("report and block");
    dispatch(
      reportPostBlockUser({
        reportedPost: post.id,
        reportingReason: reason,
        reportedPostLayer: post.layer,
        reportedPostAuthorId: post.author.id,
        reportedPostAuthorName: post.author.name,
        blockedUser: post.author.id,
        blockedEmail: post.author.name,
      })
    );
  };

  return (
    <Dialog
      open={openCommentsDialog}
      fullWidth
      onClose={handleCommentDialogClose}
    >
      <DialogTitle>
        <CardHeader
          avatar={
            <Avatar
              alt={post.author.name}
              src={
                post.author.profileImage
                  ? post.author.profileImage
                  : "https://firebasestorage.googleapis.com/v0/b/stratosv1.appspot.com/o/default-user-profile.png?alt=media"
              }
              sx={{ width: 50, height: 50 }}
            />
          }
          action={
            post.author.id !== user.userId && (
              <>
                <IconButton
                  aria-label="settings"
                  onClick={handleReportMenuClick}
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  anchorEl={openReportMenu}
                  open={open}
                  onClose={handleReportMenuClose}
                >
                  <MenuItem
                    onClick={reportMenu}
                    style={{
                      color: "#005257",
                      fontSize: "14px",
                    }}
                  >
                    <BlockIcon
                      style={{
                        transform: "rotateY(180deg)",
                        marginRight: "4px",
                        fontSize: "16px",
                      }}
                    />
                    Report Post
                  </MenuItem>
                  <MenuItem
                    onClick={handleBlockUserButton}
                    style={{
                      color: "#005257",
                      fontSize: "14px",
                    }}
                  >
                    <PersonOffIcon
                      style={{
                        marginRight: "4px",
                        fontSize: "16px",
                      }}
                    />
                    Block User
                  </MenuItem>
                  <Tooltip title="Report Post and Block User">
                    <MenuItem
                      onClick={handleReportAndBlock}
                      style={{
                        color: "#005257",
                        fontSize: "14px",
                      }}
                    >
                      <NoAccountsIcon
                        style={{
                          marginRight: "4px",
                          fontSize: "16px",
                        }}
                      />
                      Report & Block
                    </MenuItem>
                  </Tooltip>
                </Menu>
              </>
            )
          }
          title={post.author.name}
          subheader={convertDate(post.createdAt)}
        />
        <Typography
          gutterBottom
          variant="h5"
          component="div"
          sx={{ fontWeight: 700 }}
          color="primary"
        >
          {post.title}
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ fontSize: 14 }}
        >
          {post.content}
        </Typography>
        <Box>
          <Button
            startIcon={like ? <ThumbUpIcon /> : <ThumbUpOutlinedIcon />}
            onClick={handleLikeButton}
          >
            {likeCount}
          </Button>
          <Button startIcon={<ChatOutlinedIcon />}>{commentCount}</Button>
          <Button startIcon={<RiShareForwardLine />}>{post.sharesCount}</Button>
        </Box>
        <DialogContent
          dividers
          sx={{ paddingLeft: "0px", paddingRight: "0px" }}
        >
          <Typography
            variant="subtitle2"
            color="text.secondary"
            sx={{ fontSize: 14 }}
          >
            Responses ({commentCount})
          </Typography>

          {!isCommentLoading ? (
            tempComments.map((comment, index) => (
              <CommentsCard comment={comment} key={index} post={post} />
            ))
          ) : (
            <Spinner />
          )}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <TextField
              variant="outlined"
              multiline
              fullWidth
              minRows={1}
              maxRows={4}
              value={commentContent}
              sx={{ borderRadius: "30px" }}
              placeholder="Add to comment..."
              onChange={(e) => {
                e.preventDefault();
                setCommentContent(e.target.value);
              }}
            />
            <IconButton
              size="large"
              color="primary"
              onClick={handleWriteComment}
              disabled={
                !commentContent || commentContent.trim().length === 0
                  ? true
                  : false
              }
            >
              <SendIcon fontSize="30px" />
            </IconButton>
          </Box>
        </DialogContent>
      </DialogTitle>
    </Dialog>
  );
};

export default CommentsDialogBox;
