// grab the Mixpanel factory
import mixpanel from "mixpanel-browser";

mixpanel.init("9e83b0fe573e126531fd29b5a8fbd88d");

export const TrackRegister = (name, userEmail, jobTitle, currentCompany) => {
  mixpanel.people.set(userEmail, {
    name: name,
    screated: new Date().toISOString(),
    jobTitle: jobTitle,
    currentCompany: currentCompany,
  });

  mixpanel.track("register", {
    distinct_id: userEmail,
    name: name,
    jobTitle: jobTitle,
    currentCompany: currentCompany,
  });
};

export const LoginMixPanel = (userEmail) => {
  mixpanel.people.set(userEmail, {
    userEmail: userEmail,
    screated: new Date().toISOString(),
  });

  mixpanel.track("login", {
    distinct_id: userEmail,
    userEmail: userEmail,
  });
};

export const LogoutMixPanel = (userEmail) => {
  mixpanel.people.set(userEmail, {
    userEmail: userEmail,
    screated: new Date().toISOString(),
  });

  mixpanel.track("logout", {
    distinct_id: userEmail,
    userEmail: userEmail,
  });
};

export const UserCreatedPost = (
  userEmail,
  name,
  userId,
  layer,
  prompt,
  content
) => {
  console.log(
    "UserCreatedPost --> ",
    userEmail,
    name,
    userId,
    layer,
    prompt,
    content
  );
  mixpanel.track("User_Created_Post", {
    user_id: userId,
    userEmail: userEmail,
    name: name,
    layer: layer,
    prompt: prompt,
    content: content,
  });
};

export const TrackLike = (
  userEmail,
  postId,
  authorName,
  authorId,
  likerName,
  likerId,
  layer,
  prompt,
  content
) => {
  mixpanel.track("user_tracking_like", {
    distinct_id: userEmail,
    postId: postId,
    authorName: authorName,
    authorId: authorId,
    likerName: likerName,
    likerId: likerId,
    layer: layer,
    prompt: prompt,
    content: content,
  });
};

export const TrackUnLike = (
  userEmail,
  postId,
  authorName,
  authorId,
  likerName,
  likerId,
  layer,
  prompt,
  content
) => {
  mixpanel.track("user_tracking_unlike", {
    distinct_id: userEmail,
    postId: postId,
    authorName: authorName,
    authorId: authorId,
    likerName: likerName,
    likerId: likerId,
    layer: layer,
    prompt: prompt,
    content: content,
  });
};

export const TrackComment = (
  commenterEmail,
  postId,
  authorName,
  authorId,
  authorJobTitle,
  authorCurrentCompany,
  commenterJobTitle,
  commenterCurrentCompany
) => {
  mixpanel.track("user_tracking_comment", {
    commenterEmail: commenterEmail,
    postId: postId,
    authorName: authorName,
    authorId: authorId,
    authorJobTitle: authorJobTitle,
    authorCurrentCompany: authorCurrentCompany,
    commenterJobTitle: commenterJobTitle,
    commenterCurrentCompany: commenterCurrentCompany,
  });
};

export const TrackUserProfileClick = (
  userEmail,
  userId,
  profileName,
  profileId,
  profileJobTitle,
  profileCurrentCompany
) => {
  console.log(
    "Profile-->",
    userEmail,
    userId,
    profileName,
    profileId,
    profileJobTitle,
    profileCurrentCompany
  );
  mixpanel.track("user_tracking_userProfileClick", {
    distinct_id: userId,
    userEmail: userEmail,
    profileName: profileName,
    profileId: profileId,
    profileJobTitle: profileJobTitle,
    profileCurrentCompany: profileCurrentCompany,
  });
};

export const TrackDirectMessage = (
  senderName,
  senderId,
  senderJobTitle,
  senderCurrentCompany,
  receiverName,
  receiverId,
  receiverJobTitle,
  receiverCurrentCompany
) => {
  mixpanel.track("user_tracking_direct_message", {
    senderName: senderName,
    senderId: senderId,
    senderJobTitle: senderJobTitle,
    senderCurrentCompany: senderCurrentCompany,
    receiverName: receiverName,
    receiverId: receiverId,
    receiverJobTitle: receiverJobTitle,
    receiverCurrentCompany: receiverCurrentCompany,
  });
};
