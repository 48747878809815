import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ChooseLayers from "../Home/ChooseLayers";
import AddErgs from "./AddErgs";
import AddInterests from "./AddInterests";

const LayersInterests = () => {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);

  // add layers dialog box code
  const [openAddLayers, setOpenAddLayers] = useState(false);
  const handleAddLayerClose = (event, reason) => {
    if (reason && reason == "backdropClick") return;
    setOpenAddLayers(false);
  };

  // add interests dialog box code
  const [openAddInterests, setOpenAddInterests] = useState(false);
  const handleAddInterestsClose = (event, reason) => {
    if (reason && reason == "backdropClick") return;
    setOpenAddInterests(false);
  };

  // add Groups dialog box code
  const [openAddErgs, setOpenAddErgs] = useState(false);
  const handleAddErgsClose = (event, reason) => {
    if (reason && reason == "backdropClick") return;
    setOpenAddErgs(false);
  };

  return (
    <Box>
      <Box sx={{ paddingX: 1.5 }}>
        <Typography
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            m: 1,
            fontWeight: 700,
            fontSize: "20px",
          }}
        >
          My Layers
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              sm: "column",
              md: "row",
            },
            width: "auto",
            flexWrap: "wrap",
            marginTop: 1,
          }}
        >
          {user?.layers.map((layer) => (
            <Typography
              variant="body2"
              color="white"
              sx={{
                fontSize: "15px",
                whiteSpace: "nowrap",
                fontWeight: 500,
                marginRight: 3,
                borderRadius: "27px",
                paddingY: 0.25,
                paddingX: 1.25,
                marginBottom: 2,
                backgroundColor: "#52B1BB",
                width: "auto",
              }}
            >
              {layer}
            </Typography>
          ))}
          <Typography
            variant="body2"
            color="#52B1BB"
            sx={{
              fontSize: "15px",
              whiteSpace: "nowrap",
              fontWeight: 500,
              marginRight: 3,
              borderRadius: "27px",
              border: "1px solid #52B1BB",
              paddingY: 0.25,
              paddingX: 1.25,
              marginBottom: 2,
              backgroundColor: "#FFFFFF",
              cursor: "pointer",
            }}
            onClick={() => {
              setOpenAddLayers(true);
            }}
          >
            + Add
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          paddingX: 1.5,
          display: "flex",
          flexDirection: { xs: "column", sm: "column", md: "row" },
          width: "100%",
          flexWrap: "wrap",
        }}
      >
        <Typography
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            m: 1,
            fontWeight: 700,
            fontSize: "20px",
          }}
        >
          Interests
        </Typography>
        {user?.interests &&
          user?.interests.map((interest) => (
            <Typography
              variant="body2"
              color="#008997"
              sx={{
                fontSize: "14px",
                whiteSpace: {
                  xs: "normal",
                  sm: "normal",
                  md: "nowrap",
                },
                fontWeight: 400,
                marginRight: 3,
                borderRadius: "4px",
                padding: 0.5,
                marginBottom: 2,
                backgroundColor: "rgba(82, 177, 187, 0.2)",
              }}
            >
              {interest}
            </Typography>
          ))}
        <Typography
          variant="body2"
          color="#52B1BB"
          sx={{
            fontSize: "14px",
            whiteSpace: "nowrap",
            fontWeight: 400,
            marginRight: 3,
            borderRadius: "4px",
            padding: 0.5,
            marginBottom: 2,
            backgroundColor: "white",
            border: "1px solid #52B1BB",
            cursor: "pointer",
          }}
          onClick={() => setOpenAddInterests(true)}
        >
          ➕ Add More
        </Typography>
      </Box>

      <Box
        sx={{
          paddingX: 1.5,
          display: "flex",
          flexDirection: { xs: "column", sm: "column", md: "row" },
          width: "100%",
          flexWrap: "wrap",
        }}
      >
        <Typography
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            m: 1,
            fontWeight: 700,
            fontSize: "20px",
          }}
        >
          Groups
        </Typography>
        {user?.ergs &&
          user?.ergs.map((erg) => (
            <Typography
              variant="body2"
              color="#008997"
              sx={{
                fontSize: "14px",
                whiteSpace: {
                  xs: "normal",
                  sm: "normal",
                  md: "nowrap",
                },
                fontWeight: 400,
                marginRight: 3,
                borderRadius: "4px",
                padding: 0.5,
                marginBottom: 2,
                backgroundColor: "rgba(82, 177, 187, 0.2)",
              }}
            >
              {erg}
            </Typography>
          ))}
        <Typography
          variant="body2"
          color="#52B1BB"
          sx={{
            fontSize: "14px",
            whiteSpace: "nowrap",
            fontWeight: 400,
            marginRight: 3,
            borderRadius: "4px",
            padding: 0.5,
            marginBottom: 2,
            backgroundColor: "white",
            border: "1px solid #52B1BB",
            cursor: "pointer",
          }}
          onClick={() => setOpenAddErgs(true)}
        >
          ➕ Edit
        </Typography>
      </Box>

      <Dialog onClose={handleAddLayerClose} open={openAddLayers} fullWidth>
        <DialogTitle
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            fontWeight: "bold",
            color: "#52B1BB",
            justifyContent: "center",
            pb: "0",
          }}
        >
          Add More Layers
        </DialogTitle>
        <DialogContent>
          <ChooseLayers handleAddLayerClose={handleAddLayerClose} />
        </DialogContent>
      </Dialog>

      <Dialog
        onClose={handleAddInterestsClose}
        open={openAddInterests}
        fullWidth
      >
        <DialogTitle
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            fontWeight: "bold",
            color: "#52B1BB",
            justifyContent: "center",
            pb: "0",
          }}
        >
          Add More Interests
        </DialogTitle>
        <DialogContent>
          <AddInterests handleAddInterestsClose={handleAddInterestsClose} />
        </DialogContent>
      </Dialog>

      <Dialog onClose={handleAddErgsClose} open={openAddErgs} fullWidth>
        <DialogTitle
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            fontWeight: "bold",
            color: "#52B1BB",
            justifyContent: "center",
            pb: "0",
          }}
        >
          Edit Groups
        </DialogTitle>
        <DialogContent>
          <AddErgs handleAddErgsClose={handleAddErgsClose} />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default LayersInterests;
