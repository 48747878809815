import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { createNotification } from "../notifications/notificationsSlice";
import postService from "./postService";

const initialState = {
  posts: {},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
  comments: [],
  isCommentError: false,
  isCommentSuccess: false,
  isCommentLoading: false,
  isLikeLoading: false,
};

export const postSlice = createSlice({
  name: "post",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isLoading = false;
      state.message = "";
    },
    resetComments: (state) => {
      state.comments = [];
    },
    resetAll: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isLoading = false;
      state.message = "";
      state.posts = {};
    },
    resetEverthing: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isLoading = false;
      state.message = "";
      state.posts = {};
      state.comments = [];
      state.isCommentError = false;
      state.isCommentSuccess = false;
      state.isLikeLoading = false;
      state.isCommentLoading = false;
    },
    editPosts: (state, action) => {
      state.posts[action.payload.layerName.toUpperCase()] = state.posts[
        action.payload.layerName.toUpperCase()
      ].filter((p) => p.id !== action.payload.postId);
    },
    editComments: (state, action) => {
      state.comments = state.comments.filter((item) => {
        return item.id !== action.payload.commentId;
      });
    },
    hideUserPosts: (state, action) => {
      for (const layer in state.posts) {
        state.posts[layer] = state.posts[layer].filter(
          (p) => p.author.id !== action.payload.author
        );
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createPost.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createPost.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        if (!state.posts.hasOwnProperty(action.payload.layer)) {
          state.posts[action.payload.layer] = [];
        }
        state.posts[action.payload.layer].push(action.payload.post);
      })
      .addCase(createPost.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getPosts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPosts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.posts = action.payload.posts;
      })
      .addCase(getPosts.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(likePost.pending, (state) => {
        state.isLikeLoading = true;
      })
      .addCase(likePost.fulfilled, (state, action) => {
        state.isLikeLoading = false;

        state.posts[action.payload.layer][
          action.payload.index
        ].likedByCurrentUser = true;

        state.posts[action.payload.layer][action.payload.index].likesCount =
          state.posts[action.payload.layer][action.payload.index].likesCount +
          1;
      })
      .addCase(likePost.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.isLikeLoading = false;

        state.message = action.payload;
      })
      .addCase(unLikePost.pending, (state) => {
        state.isLikeLoading = true;
      })
      .addCase(unLikePost.fulfilled, (state, action) => {
        state.isLikeLoading = false;

        state.posts[action.payload.layer][
          action.payload.index
        ].likedByCurrentUser = false;
      })
      .addCase(unLikePost.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.isLikeLoading = false;
        state.message = action.payload;
      })
      .addCase(getPostComments.pending, (state) => {
        state.isCommentLoading = true;
        state.isCommentError = false;
        state.isCommentSuccess = false;
      })
      .addCase(getPostComments.fulfilled, (state, action) => {
        state.comments = action.payload.response.comments.filter(
          (c) => c.id !== action.payload.blockedUsers.map((u) => u)
        );
        state.comments = action.payload.response.comments;
        state.isCommentLoading = false;
        state.isCommentError = false;
        state.isCommentSuccess = true;
        state.message = action.payload.response;
      })
      .addCase(getPostComments.rejected, (state, action) => {
        state.isCommentLoading = false;
        state.isCommentError = true;
        state.isCommentSuccess = false;
        state.message = action.payload;
      })
      .addCase(createPostComment.pending, (state) => {
        //state.isCommentLoading = true;
        //state.isCommentError = false;
        //state.isCommentSuccess = false;
      })
      .addCase(createPostComment.rejected, (state, action) => {
        //state.isCommentLoading = false;
        //state.isCommentError = true;
        //state.isCommentSuccess = false;
        //state.message = action.payload;
      })
      .addCase(createPostComment.fulfilled, (state, action) => {
        state.comments.push(action.payload.commentData);
        state.posts[action.payload.layer][action.payload.index].commentsCount =
          state.posts[action.payload.layer][action.payload.index]
            .commentsCount + 1;
        //state.isCommentLoading = false;
        //state.isCommentError = false;
        //state.isCommentSuccess = true;
      })
      .addCase(deletePostComment.pending, (state) => {
        //state.isCommentLoading = true;
        //state.isCommentError = false;
        //state.isCommentSuccess = false;
      })
      .addCase(deletePostComment.rejected, (state, action) => {
        //state.isCommentLoading = false;
        //state.isCommentError = true;
        //state.isCommentSuccess = false;
        //state.message = action.payload;
      })
      .addCase(deletePostComment.fulfilled, (state, action) => {
        // remove just deleted comment from comments array
        state.comments = state.comments.filter((item) => {
          return item.id !== action.payload.commentId;
        });
        state.posts[action.payload.layer][action.payload.index].commentsCount =
          state.posts[action.payload.layer][action.payload.index]
            .commentsCount - 1;
        //state.isCommentLoading = false;
        //state.isCommentError = false;
        //state.isCommentSuccess = true;
      })
      .addCase(deletePost.pending, (state) => {
        //state.isCommentLoading = true;
        //state.isCommentError = false;
        //state.isCommentSuccess = false;
      })
      .addCase(deletePost.rejected, (state, action) => {
        //state.isCommentLoading = false;
        //state.isCommentError = true;
        //state.isCommentSuccess = false;
        //state.message = action.payload;
      })
      .addCase(deletePost.fulfilled, (state, action) => {
        // remove just deleted post from posts array
        // state.posts = state.posts.filter((item) => {
        //   return item.id !== action.payload.postId;
        // });
        state.posts[action.payload.layer] = state.posts[
          action.payload.layer
        ].filter((p) => p.id !== action.payload.postId);
        //state.isCommentLoading = false;
        //state.isCommentError = false;
        //state.isCommentSuccess = true;
      });
  },
});

//export const getPostsByLayer = createAsyncThunk(
//    "posts/getPostsByLayer",
//    async (layer, thunkAPI) => {
//        try {
//            const token = thunkAPI.getState().auth.token;
//            const response = await postService.getPostsByLayer(layer, token);
//            return response;
//        } catch (error) {
//            const message =
//                error.response &&
//                error.response.data &&
//                error.response.data.error
//                    ? error.response.data.error
//                    : "";
//            return thunkAPI.rejectWithValue(message);
//        }
//    }
//);

export const getPostComments = createAsyncThunk(
  "posts/getComments",
  async (commentData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token;
      const response = await postService.getPostComments(commentData, token);
      const blockedUsers = thunkAPI.getState().report.blockedUsers;
      return { response, blockedUsers };
    } catch (error) {
      const message =
        error.response && error.response.data && error.response.data.error
          ? error.response.data.error
          : "";
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const createPostComment = createAsyncThunk(
  "posts/createComment",
  async ({ commentData, notificationData }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token;
      const result = await postService.createPostComment(commentData, token);
      notificationData.commentId = result.commentData.id;
      console.log("notificationData", notificationData);
      thunkAPI.dispatch(createNotification(notificationData));

      const posts = thunkAPI.getState().posts.posts;

      let postArr = posts[result.layer];

      const index = postArr.findIndex((obj) => {
        console.log("obj.id:", obj.id);
        console.log("result.postId:", result.postId);
        return String(obj.id) === String(result.postId);
      });

      console.log("index", index);

      return {
        index,
        layer: result.layer,
        commentData: result.commentData,
      };
    } catch (error) {
      const message =
        error.response && error.response.data && error.response.data.error
          ? error.response.data.error
          : "";
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deletePostComment = createAsyncThunk(
  "posts/deleteComment",
  async ({ postId, commentId, layer }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token;
      const result = await postService.deletePostComment(
        postId,
        commentId,
        layer,
        token
      );

      const posts = thunkAPI.getState().posts.posts;

      let postArr = posts[result.layer];

      const index = postArr.findIndex((obj) => {
        console.log("obj.id:", obj.id);
        console.log("result.postId:", result.postId);
        return String(obj.id) === String(result.postId);
      });

      console.log("index", index);

      return {
        index,
        layer: result.layer,
        commentId: result.commentId,
      };
    } catch (error) {
      const message =
        error.response && error.response.data && error.response.data.error
          ? error.response.data.error
          : "";
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Create new post
export const createPost = createAsyncThunk(
  "posts/create",
  async (postData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token;
      const result = await postService.createPost(postData, token);
      console.log("result", result);
      return result;
    } catch (error) {
      const message =
        error.response && error.response.data && error.response.data.error
          ? error.response.data.error
          : "";
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deletePost = createAsyncThunk(
  "posts/deletePost",
  async ({ postId, layer }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token;
      const result = await postService.deletePost(postId, layer, token);

      return {
        // index,
        layer: result.layer,
        postId: result.postId,
      };
    } catch (error) {
      const message =
        error.response && error.response.data && error.response.data.error
          ? error.response.data.error
          : "";
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// like post
export const likePost = createAsyncThunk(
  "posts/likePost",
  async ({ likeData, notificationData }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token;
      let result = await postService.likePost(likeData, token);

      console.log("notificationData", notificationData);
      thunkAPI.dispatch(createNotification(notificationData)); // 500 error but works

      const posts = thunkAPI.getState().posts.posts;

      let postArr = posts[result.layer];

      const index = postArr.findIndex((obj) => {
        console.log("obj.id:", obj.id);
        console.log("result.postId:", result.postId);
        return String(obj.id) === String(result.postId);
      });

      return { index, layer: result.layer };
    } catch (error) {
      const message =
        error.response && error.response.data && error.response.data.error
          ? error.response.data.error
          : "";
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// unlike post
export const unLikePost = createAsyncThunk(
  "posts/unlikePost",
  async (unlikeData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token;
      let result = await postService.unLikePost(unlikeData, token);

      const posts = thunkAPI.getState().posts.posts;

      let postArr = posts[result.layer];

      const index = postArr.findIndex((obj) => {
        console.log("obj.id:", obj.id);
        console.log("result.postId:", result.postId);
        return String(obj.id) === String(result.postId);
      });

      return { index, layer: result.layer };
    } catch (error) {
      const message =
        error.response && error.response.data && error.response.data.error
          ? error.response.data.error
          : "";
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get user posts
export const getPosts = createAsyncThunk(
  "posts/getAll",
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token;
      return await postService.getPosts({ token });
    } catch (error) {
      const message =
        error.response && error.response.data && error.response.data.error
          ? error.response.data.error
          : "";
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const {
  reset,
  resetComments,
  resetAll,
  editPosts,
  editComments,
  hideUserPosts,
  resetEverthing,
} = postSlice.actions;
export default postSlice.reducer;
