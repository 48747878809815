import React from 'react'
import { GiPencil } from 'react-icons/gi'

import { Button,Box, Paper, Switch, Typography } from '@mui/material'


import memberImage from "./member.jpg"

import "./JobSettings.css"


const JobSettings = () => {
  return (
    <div className='job-settings-container'>
      
      <div className='job-details-heading'>
      <h4>Job Detials</h4>
      </div>

      <div className='job-settings-container_nav'>
          <a href="#jobdetails">Job Details</a>
          <a href="#jobtitle">Job Title</a>
          <a href="#rejectionsettings">Rejection Settings</a>
          <a href="#jobmembers">Job Memebers</a>
          <a href="#managepost">Manage Job Post</a>

    </div>

     
      <Paper elevation={0} id="jobdetails" className='job-settings_job-details'>
      
<div className='job-settings_job-details_info'>
  <div className='job-settings_job-details_info-title'>
    <p>Job TItle</p>
    <div className='job-settings-input'>
        <input placeholder='job title' />
        <GiPencil style={{marginRight:"5px"}} size={12} />
      </div>
  </div>
  <div className='job-settings_job-details_info-location'>
      <div className='job-settings_job-details_info-loc'>
      <p>Location</p>
      <div className='job-settings-input'>
        <input placeholder='location' />
        <GiPencil style={{marginRight:"5px"}} size={12} />
      </div>
      </div>
    <div className='job-settings_job-details_info-emp'>
        <p>Employment Type</p>
      <div className='job-settings-input'>
        <input placeholder='Employment Type' />
        <GiPencil style={{marginRight:"5px"}} size={12} />
        
      </div>
      </div>
  </div>
</div>
</Paper>


<Paper elevation={0} id="rejectionsettings" className='job-settings_rejection'>

<div className='job-settings_rejection-heading'>
  <div className='rejection-settings-heading'>
    <h4>Rejection Settings</h4>
  </div>
  <div className='rejection-settings-toggle'>
    <div>
    <p>You can turn on auto rejection settings</p>
    </div>
    <div>
    <Switch  />
    </div>
  </div>
</div>

<Box className='job-settings_rejection-message'>
  <div className='job-settings_rejection-message-input'>
    <Typography variant='p'>Auto Rejection Message</Typography>
    <textarea placeholder='auto rejection message' />
  </div>
  <div>
    <Button><GiPencil style={{marginRight:"10px"}} size={12} /> Edit</Button>
  </div>
  </Box>


</Paper>


<Paper elevation={0} id="jobmembers" className='job-settings_members'>
  <Box className="job-settings_members-heading"><Typography variant='h4'>Job Members</Typography></Box>
<Box className="job-settings_members-cards">
  
  <Box className="members">
    <Box>
    <img src={memberImage} alt="member" width={70} height={70} />
    </Box>
    <Box>

    <Typography className='member-name' variant='h5'>Olivia Rodriguez</Typography>
    <Typography className='member-positon'variant='p' >Software Engineer</Typography>
    </Box>
  </Box>

  <Box className="members">
    <Box>
    <img src={memberImage} alt="member" width={70} height={70} />
    </Box>
    <Box>

    <Typography className='member-name' variant='h5'>Olivia Rodriguez</Typography>
    <Typography className='member-positon' variant='p' >Software Engineer</Typography>
    </Box>
  </Box>

  </Box>
</Paper>


<Paper elevation={0} id="managepost" className='job-settings_manage-job-post'>

<Box className="job-settings_manage-job-post_heading"><Typography variant='h5'>Manage Job Post</Typography></Box>
<Box className="job-settings_manage-job-post_input">
  <Box>
  <Typography variant='p'>Manage Job Post</Typography>
  </Box>
  <Switch  />
  
</Box>
</Paper>


<Box className="job-settings_actions">
<button className='action-edit'> <GiPencil size={16} style={{marginRight:"10px"}}  /> Edit Job</button>
<div className='action-change'>
<button>Change</button>
</div>
  

      </Box>
      </div>

  )
}

export default JobSettings