import { Avatar, Box, Stack, Typography } from "@mui/material";
import moment from "moment";
import React from "react";
import "./MessageListCard.css";
const MessageListCard = ({ message, avatar, sender }) => {
  const isSenderMe = sender === "me";

  const messageDate = moment(message.createdAt);
  const yesterday = moment().subtract(1, "day");
  const currentDate = moment().startOf("day");
  let messageAt;
  if (messageDate.isSame(currentDate, "day")) {
    messageAt = messageDate.format("h:mm A");
  } else if (messageDate.isSame(yesterday, "day")) {
    messageAt = "Yesterday";
  } else {
    messageAt = messageDate.fromNow();
  }

  return (
    <Stack
      direction={"row"}
      spacing={0.5}
      padding={1}
      justifyContent={isSenderMe ? "flex-end" : "flex-start"}
      alignItems="center"
      sx={{
        width: "100%",
        borderRadius: isSenderMe ? "10px 10px 0 10px" : "10px 10px 10px 0",
        position: "relative",
      }}
    >
      {!isSenderMe && (
        <Avatar
          sx={{
            width: "30px",
            height: "30px",
          }}
          src={avatar}
        />
      )}
      <Stack
        direction={"column"}
        alignItems={isSenderMe ? "flex-end" : "flex-start"}
      >
        <Stack direction={"row"} spacing={0.5}>
          {isSenderMe && (
            <Typography
              variant="caption"
              sx={{
                color: "#CACACA",
                overflow: "chip",
                whiteSpace: "nowrap",
                p: 0.35,
              }}
              fontSize={10}
            >
              {messageAt}
            </Typography>
          )}
          <Typography
            variant="caption"
            sx={{
              color: "#000",
              overflow: "chip",
              whiteSpace: "nowrap",
              fontWeight: "bold",
            }}
            fontSize={12}
          >
            {message.author.firstName || message.author.name}
          </Typography>
          {!isSenderMe && (
            <Typography
              variant="caption"
              sx={{
                color: "#CACACA",
                overflow: "chip",
                whiteSpace: "nowrap",
                p: 0.25,
              }}
              fontSize={10}
            >
              {messageAt}
            </Typography>
          )}
        </Stack>
        <Box
          sx={{
            backgroundColor: isSenderMe ? "#399FAA" : "#F3F4F9",
            maxWidth: "90%",
          }}
          className={isSenderMe ? "message_before" : "message_after"}
        >
          <Typography variant="body1" fontSize={12}>
            {message.content}
          </Typography>
        </Box>
      </Stack>

      {isSenderMe && (
        <Avatar
          sx={{
            width: "30px",
            height: "30px",
          }}
          src={avatar}
        />
      )}
    </Stack>
  );
};

export default MessageListCard;
