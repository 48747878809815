import AddIcon from "@mui/icons-material/Add";
import { Box, Button, Stack, Typography } from "@mui/material";
import React from "react";
import AddExperience from "./AddExperience";

const AddYourExperience = ({ experience, setExperience }) => {
  // for updating experience when any field in experience changes
  const handleFieldChange = (index, fieldName, newValue) => {
    setExperience((prevExperience) => {
      const updatedExperience = [...prevExperience];
      updatedExperience[index] = {
        ...updatedExperience[index],
        [fieldName]: newValue,
      };
      return updatedExperience;
    });
  };

  const handleAddNewExperience = () => {
    let newExp = {
      company: "",
      position: "",
      startDate: "",
      endDate: "",
    };

    setExperience([...experience, newExp]);
  };

  const removeExperience = (index) => {
    setExperience((prevExperience) => {
      const newExperience = [...prevExperience];
      newExperience.splice(index, 1);
      return newExperience;
    });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        margin: { xs: 0, sm: 0, md: 1 },
        paddingX: { xs: 0.5, sm: 0.5, md: 1.5 },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          flexWrap: "wrap",
          marginTop: 1,
          alignItems: "flex-start",
        }}
      >
        <Button
          variant="text"
          startIcon={<AddIcon />}
          onClick={handleAddNewExperience}
          sx={{
            fontSize: { xs: "12px", sm: "14px", md: "16px" },
            textTransform: "none",
          }}
        >
          Add Your Experience
        </Button>
        <Typography
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            marginLeft: 1,
            fontSize: "11px",
          }}
        >
          * only your most recent experience will be shown to others
        </Typography>

        {experience.map((exp, index) => (
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <AddExperience
              exp={exp}
              onFieldChange={(fieldName, newValue) =>
                handleFieldChange(index, fieldName, newValue)
              }
              index={index}
              removeExperience={removeExperience}
            />
          </Stack>
        ))}
      </Box>
    </Box>
  );
};

export default AddYourExperience;
