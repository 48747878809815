import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Spinner from "../../components/Spinner";
import { backdoor, reset } from "../../features/auth/authSlice";
import "../SignUp/Signup.css";

const Backdoor = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isLoading, isSuccess, isError, message, user } = useSelector(
    (state) => state.auth
  );

  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  // const [anchorEl, setAnchorEl] = useState(null);
  // const handlePopoverOpen = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };
  // const handlePopoverClose = () => {
  //   setAnchorEl(null);
  // };
  // const open = Boolean(anchorEl);

  const [userData, setUserData] = useState({
    firstName: user?.firstName ? user.firstName : "",
    lastName: user?.lastName ? user.lastName : "",
    jobTitle: user?.jobTitle ? user.jobTitle : "",
    currentCompany: user?.currentCompany ? user.currentCompany : "",
    email: user?.email ? user.email : "",
    linkedin: "",
  });

  const { firstName, lastName, jobTitle, currentCompany, email, linkedin } =
    userData;

  const onChangeInput = (e) => {
    setUserData((inputTexts) => ({
      ...inputTexts,
      [e.target.name]: e.target.value,
    }));
  };

  const isDisabled = () => {
    if (
      firstName != "" &&
      lastName != "" &&
      email != "" &&
      currentCompany != "" &&
      jobTitle != "" &&
      linkedin != ""
    ) {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    // sign up is false, dispatch login
    if (isError) {
      setShowErrorAlert(true);
    }
    if (isSuccess) {
      setShowSuccess(true);
    }
  }, [isSuccess, isError]);

  if (isLoading) {
    return <Spinner />;
  }

  // call the action here..
  const sendBackdoorRequest = (e) => {
    e.preventDefault();

    const userData = {
      name: firstName + " " + lastName,
      firstName,
      lastName,
      email,
      jobTitle,
      currentCompany,
      linkedin,
    };
    console.log("backdoor dispatch", userData);

    dispatch(backdoor({ user: userData }));
  };

  return (
    <>
      {/* Error Dialog Box */}
      {showErrorAlert && (
        <Dialog
          open={showErrorAlert}
          onClose={() => {
            console.log("alert close");
            dispatch(reset());
            setShowErrorAlert(false);
            navigate("/");
          }}
        >
          <DialogTitle textAlign={"center"}>{"Error"}</DialogTitle>
          <DialogContent>
            <DialogContentText textAlign={"center"}>
              {"Error Details: " + message}
            </DialogContentText>{" "}
          </DialogContent>
        </Dialog>
      )}

      {/* Success Dialog Box */}
      {showSuccess && (
        <Dialog
          open={showSuccess}
          onClose={() => {
            console.log("alert close");
            dispatch(reset());
            setShowSuccess(false);
            navigate("/");
          }}
        >
          <DialogTitle textAlign={"center"}>{"Success ✨"}</DialogTitle>
          <DialogContent>
            <DialogContentText textAlign={"center"}>
              {"Your request has been submitted for review!"}
            </DialogContentText>{" "}
          </DialogContent>
        </Dialog>
      )}

      {/* Top of Page */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          paddingX: { xs: 3, sm: 3, md: 7 },
          background: "linear-gradient( #FFFFFF, #52B1BB)",
          paddingTop: 10,
          paddingBottom: 10,
          alignItems: "center",
        }}
      >
        <Typography
          variant="h4"
          sx={{
            fontWeight: "700",
            fontSize: "50px",
            margin: 3,
            textAlign: "center",
          }}
        >
          Request Access to Stratos 🪐
        </Typography>

        <Typography
          variant="h4"
          sx={{
            fontWeight: "700",
            fontSize: "30px",
            marginY: 3,
          }}
        >
          Let's get your account approved.
        </Typography>
        <Typography
          variant="h6"
          textAlign={"center"}
          sx={{
            fontWeight: "500",
            fontSize: "16px",
            color: "#16171B",
            paddingLeft: 0,
            marginBottom: 1,
            lineHeight: 2,
            letterSpacing: "-1.5%",
            maxWidth: "700px",
          }}
        >
          If you think you can make the cut for entering the Stratos community,
          submit your info here.{<br />}We review a select amount of account
          requests a week for approval.
        </Typography>

        <Paper
          className="signup2-form-container"
          sx={{
            alignItems: "center",
            justifyContent: "center",
            margin: 3,
          }}
        >
          <Typography variant="h6" className="form-heading-text">
            Enter your details
          </Typography>
          <Box
            className="form-heading"
            sx={{
              width: "80%",
              "@media(max-width: 780px)": {
                width: "74%",
              },
              "@media(max-width: 450px)": { width: "84%" },
              "@media(max-width: 375px)": { width: "86%" },
            }}
          >
            <IconButton size={"small"} onClick={() => navigate("/#")}>
              <ArrowBackIcon className="arrow-icon" />
            </IconButton>
          </Box>
          <Box className="signup-form" style={{ marginTop: "10px" }}>
            <Stack direction="column" spacing={2}>
              <TextField
                type={"text"}
                label="First Name"
                name="firstName"
                value={firstName}
                onChange={onChangeInput}
                required
                size="small"
                variant="outlined"
                fullWidth
                inputProps={{
                  style: { fontSize: 10 },
                }} // font size of input text
                InputLabelProps={{
                  shrink: true,
                  style: { fontSize: 10 },
                }}
              />

              <TextField
                type="text"
                label="Last Name"
                name="lastName"
                value={lastName}
                onChange={onChangeInput}
                required
                size="small"
                variant="outlined"
                fullWidth
                inputProps={{
                  style: { fontSize: 10 },
                }} // font size of input text
                InputLabelProps={{
                  shrink: true,
                  style: { fontSize: 10 },
                }}
              />

              <TextField
                type="email"
                label="Email"
                name="email"
                value={email}
                onChange={onChangeInput}
                required
                size="small"
                variant="outlined"
                fullWidth
                style={{ width: "100%" }}
                inputProps={{
                  style: { fontSize: 10 },
                }} // font size of input text
                InputLabelProps={{
                  shrink: true,
                  style: { fontSize: 10 },
                }}
              />

              <TextField
                type="currentCompany"
                label="Company you work at / most recently worked at"
                name="currentCompany"
                value={currentCompany}
                onChange={onChangeInput}
                required
                size="small"
                variant="outlined"
                fullWidth
                style={{ width: "100%" }}
                inputProps={{
                  style: { fontSize: 10 },
                }} // font size of input text
                InputLabelProps={{
                  shrink: true,
                  style: { fontSize: 10 },
                }}
              />

              <TextField
                type="jobTitle"
                label="your Job Title"
                name="jobTitle"
                value={jobTitle}
                onChange={onChangeInput}
                required
                size="small"
                variant="outlined"
                fullWidth
                style={{ width: "100%" }}
                inputProps={{
                  style: { fontSize: 10 },
                }} // font size of input text
                InputLabelProps={{
                  shrink: true,
                  style: { fontSize: 10 },
                }}
              />

              <TextField
                type="linkedin"
                label="LinkedIn URL"
                name="linkedin"
                value={linkedin}
                onChange={onChangeInput}
                required
                size="small"
                variant="outlined"
                fullWidth
                style={{ width: "100%" }}
                inputProps={{
                  style: { fontSize: 10 },
                }} // font size of input text
                InputLabelProps={{
                  shrink: true,
                  style: { fontSize: 10 },
                }}
              />

              <Button
                variant="contained"
                sx={{
                  color: "#fff",
                  backgroundColor: "#52B1BB",
                }}
                onClick={sendBackdoorRequest}
                disabled={isDisabled()}
              >
                Submit
              </Button>
            </Stack>
          </Box>
        </Paper>
      </Box>
    </>
  );
};

export default Backdoor;
