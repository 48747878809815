import {
  KeyboardArrowRight,
  Person,
  Settings as SettingsIcon,
  Window,
} from "@mui/icons-material";
import {
  Avatar,
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Paper,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { LogoutMixPanel } from "../../../MixPanel";
import { logout } from "../../../features/auth/authSlice";
import AccountSettings from "./AccountSettings";
import LayersInterests from "./LayersInterests";
import PersonalDetails from "./PersonalDetails";

const Settings = () => {
  const { user, message } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  // settings tabs
  const [settingsTab, setSettingsTab] = useState(0);
  const handleSettingsChange = (event, newValue) => {
    setSettingsTab(newValue);
  };

  // show error
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const navigate = useNavigate();
  // logout when error from BE
  useEffect(() => {
    if (message === "auth/id-token-expired") {
      LogoutMixPanel(user.email);
      dispatch(logout());
      setShowErrorAlert(true);
    }
  }, [message]);

  const sxForSettingsTab = {
    color: "#000000",
    fontWeight: 400,
    fontSize: "14px",
    textTransform: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    "& .MuiTab-root": {
      fontFamily: "Roboto",
    },
    "&.Mui-selected": {
      color: "black",
      borderLeft: "3px solid #52B1BB",
      borderBottom: "1px solid #DCEBF0",
    },
    paddingY: 0,
    paddingX: 1,
  };

  const componentArray = [
    <PersonalDetails />,
    <AccountSettings />,
    <LayersInterests />,
  ];

  const [value, setValue] = React.useState(0);
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  // make the height of tabs same
  const [tabsHeight, setTabsHeight] = useState(0);
  const tabsRef = useRef(null);

  useEffect(() => {
    const tabsElement = tabsRef.current;
    if (tabsElement) {
      const tabItems = tabsElement.querySelectorAll(".MuiTab-root");
      let maxTabHeight = 0;
      tabItems.forEach((tabItem) => {
        const tabHeight = tabItem.clientHeight;
        if (tabHeight > maxTabHeight) {
          maxTabHeight = tabHeight;
        }
      });
      setTabsHeight(maxTabHeight * 3); // Multiply by 3 to account for three tabs
    }
  }, []);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          backgroundColor: "#E9E9E9",
          width: "100%",
          height: "100%",
          paddingX: { xs: 2, sm: 2, md: 5 },
        }}
      >
        <Helmet>
          <title>Settings - Stratos</title>
        </Helmet>

        {showErrorAlert && (
          <Dialog
            open={showErrorAlert}
            onClose={() => {
              console.log("message", message);
              console.log("alert close");
              setShowErrorAlert(false);
              navigate("/Welcome");
            }}
          >
            <DialogTitle>{"Info"}</DialogTitle>
            <DialogContent>
              <DialogContentText>{"You have signed out! "}</DialogContentText>
            </DialogContent>
          </Dialog>
        )}

        <Box
          sx={{
            backgroundColor: "#FFFFFF",
            borderRadius: "5px",
            display: { xs: "none", sm: "none", md: "flex" },
            height: tabsHeight,
          }}
        >
          <Tabs
            ref={tabsRef}
            value={settingsTab}
            onChange={handleSettingsChange}
            orientation="vertical"
            indicatorColor="transparent"
            sx={{
              display: "flex",
              alignItems: "flex-start",
              height: "100%",
            }}
          >
            <Tab
              icon={<Person />}
              iconPosition="start"
              label={
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={3}
                  sx={{ width: "100%" }}
                >
                  <Typography variant="body1" noWrap>
                    Personal Details
                  </Typography>
                  <KeyboardArrowRight sx={{ color: "#BDBDBD" }} />
                </Stack>
              }
              sx={sxForSettingsTab}
            />
            <Tab
              icon={<SettingsIcon />}
              iconPosition="start"
              label={
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={3}
                  sx={{ width: "100%" }}
                >
                  <Typography variant="body1" noWrap>
                    Account Settings
                  </Typography>
                  <KeyboardArrowRight sx={{ color: "#BDBDBD" }} />
                </Stack>
              }
              sx={sxForSettingsTab}
            />
            <Tab
              icon={<Window />}
              iconPosition="start"
              label={
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={3}
                  sx={{ width: "100%" }}
                >
                  <Typography variant="body1" noWrap>
                    Layers & Interests
                  </Typography>
                  <KeyboardArrowRight sx={{ color: "#BDBDBD" }} />
                </Stack>
              }
              sx={sxForSettingsTab}
            />
          </Tabs>
        </Box>

        <Box
          sx={{
            display: "flex",
            marginX: 2,
            backgroundColor: "white",
            width: "100%",
            height: "100%",
            borderRadius: "10px",
            flexDirection: "column",
            padding: 2,
          }}
        >
          {componentArray[settingsTab]}
        </Box>
      </Box>
      <Box sx={{ display: { xs: "flex", sm: "flex", md: "none" } }}>
        <Paper
          sx={{
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: "drawer",
          }}
        >
          <BottomNavigation
            value={settingsTab}
            onChange={(event, newValue) => {
              console.log(settingsTab);
              setSettingsTab(newValue);
            }}
            sx={{ bgcolor: "#16171B", height: "auto" }}
          >
            <BottomNavigationAction
              value={0}
              icon={<Person fontSize="large" />}
              sx={{
                color: "white",
              }}
            />
            <BottomNavigationAction
              value={1}
              icon={<SettingsIcon fontSize="large" />}
              sx={{
                color: "white",
              }}
            />
            <BottomNavigationAction
              value={2}
              icon={<Window fontSize="large" />}
              sx={{
                color: "white",
              }}
            />
            <IconButton sx={{ color: "white" }}>
              <Avatar
                src={user.profileImage.url}
                alt="Profile Image"
                sx={{
                  cursor: "pointer",
                }}
                component={Link}
                to="/HomeScreen/Profile"
              />
            </IconButton>
          </BottomNavigation>
        </Paper>
      </Box>
    </>
  );
};

export default Settings;
