import { Box } from "@mui/material";
import React from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";

import { pronouns } from "../../Pages/constants";

const ChooseYourPronouns = ({ yourPronouns, setYourPronouns }) => {
  const options = pronouns.map((p) => ({
    label: p,
    value: p,
  }));

  const defaultOptions = yourPronouns.map((p) => ({
    label: p,
    value: p,
  }));

  const animatedComponents = makeAnimated();

  return (
    <Box
      sx={{
        background: "white",
        width: "auto",
        display: "flex",
        flexDirection: "column",
        height: "auto",
        alignItems: "center",
        borderRadius: "27px",
        justifySelf: "center",
        m: 1,
      }}
    >
      <Select
        options={options}
        onChange={(selected) => {
          let selectedPronouns = [];
          selected.forEach((p) => {
            selectedPronouns.push(p.value);
          });
          setYourPronouns(selectedPronouns);
        }}
        isMulti
        closeMenuOnSelect={false}
        components={animatedComponents}
        defaultValue={defaultOptions}
        placeholder={
          yourPronouns.length > 0 ? yourPronouns : "select your pronouns"
        }
        styles={{
          borderRadius: "27px",
        }}
      />
    </Box>
  );
};

export default ChooseYourPronouns;
