import { ArrowForward, Loop } from "@mui/icons-material";
import { Avatar, Box, Button, Grow, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useNavigate } from "react-router-dom";
import CareerLP from "../../Assets/CareerLP.svg";
import DM1 from "../../Assets/DM1.svg";
import DM2 from "../../Assets/DM2.svg";
import NetworkGrowth from "../../Assets/NetworkGrowth.svg";
import GoogleLogo from "../../Assets/Partners/google1.png";
import PatreonLogo from "../../Assets/Partners/patreon1.png";
import SageLogo from "../../Assets/Partners/sage1.png";
import XactlyLogo from "../../Assets/Partners/xactly1.png";
import WhatIsStratos from "../../Assets/WhatIsStratosLP.png";
import WhyStratos from "../../Assets/WhyStratosLP-cropped.svg";
import BuildingConfidence from "../../Assets/buildingConfidence.svg";
import StratosSpotlight from "../../Assets/stratosSpotlight.svg";
import WorkplaceJustice from "../../Assets/workplaceJustice.svg";
import ContactUs from "./ContactUs";
import "./Homepage.css";
import LastComponent from "./LastComponent";

const Homepage = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const handleClickLogin = () => {
    navigate("/Welcome");
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          paddingX: { xs: 3, sm: 3, md: 7 },
          background: "linear-gradient( #FFFFFF, #52B1BB)",
          paddingBottom: 10,
        }}
      >
        <Grow in {...{ timeout: 2000 }}>
          <Box
            sx={{
              display: { xs: "none", sm: "none", md: "flex" },
              flexDirection: "row",
              justifyContent: "space-between",
              paddingX: "10%",
              paddingY: 5,
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
                width: "30%",
                m: 1,
              }}
            >
              <Typography
                variant="h4"
                sx={{ fontWeight: "700", fontSize: "50px" }}
              >
                Stratos is the Place Where ERGs Find Community
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "500",
                  color: "grey",
                  padding: 2,
                  paddingLeft: 0,
                }}
              >
                The Exclusive Social Platform Connecting ERG Group Members from
                Top Technology Companies Across the Country.
              </Typography>
              <Button
                sx={{
                  color: "#FFFFFF",
                  display: "block",
                  background:
                    "linear-gradient(96.34deg, #52B1BB, #008997 100%)",
                  borderRadius: "14px",
                  fontSize: "15px",
                  height: "40px",
                  cursor: "pointer",
                  border: "none",
                  textTransform: "none",
                }}
                variant="contained"
                onClick={handleClickLogin}
              >
                <span style={{ verticalAlign: "middle" }}>Get Started Now</span>
                <ArrowForward
                  style={{
                    verticalAlign: "middle",
                    paddingLeft: 0.5,
                  }}
                />
              </Button>
            </Box>
            <Box sx={{ width: "70%" }}>
              <Avatar
                alt="What is stratos"
                src={WhatIsStratos}
                sx={{
                  display: "flex",
                  borderRadius: 0,
                  bgcolor: "transparent",
                  width: "100%",
                  height: "auto",
                }}
                imgProps={{
                  style: { objectFit: "contain" },
                }}
              />
            </Box>
          </Box>
        </Grow>

        <Box
          sx={{
            display: { xs: "none", sm: "none", md: "flex" },
            flexDirection: "row",
            justifyContent: "space-between",
            padding: 2,
            paddingTop: 3,
            width: "100%",
          }}
        >
          <Box sx={{ width: "48%" }}>
            <Avatar
              alt="What is stratos"
              src={WhyStratos}
              sx={{
                display: "flex",
                borderRadius: 0,
                bgcolor: "transparent",
                width: "100%",
                height: "auto",
              }}
              imgProps={{
                style: { objectFit: "contain" },
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
              width: "48%",
            }}
          >
            <Typography
              variant="h4"
              sx={{ fontWeight: "700", fontSize: "50px" }}
            >
              What Makes Stratos Different?
            </Typography>
            <Typography
              variant="h6"
              sx={{
                fontWeight: "500",
                color: "grey",
                padding: 2,
                paddingLeft: 0,
              }}
            >
              Stratos is bringing ERGs across different companies together.
              Creating a unified community. Stratos is a collaborative community
              that provides resources and connections to help professionals
              succeed. Our members are committed to working together to achieve
              their goals, and we offer a variety of resources to support their
              success.
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            display: { xs: "none", sm: "none", md: "grid" },
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            padding: 2,
            width: "100%",
          }}
        >
          <Typography
            align="center"
            variant="h4"
            sx={{
              fontWeight: "700",
              fontSize: "45px",
              padding: 1,
              marginTop: "75px",
              marginBottom: "25px",
            }}
          >
            Our Partners
          </Typography>
          <Carousel
            // arrows={false}
            className="gd-carousel"
            responsive={responsive}
            ssr={true} // means to render carousel on server-side.
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={3000}
            containerClass="carousel-container"
            removeArrowOnDeviceType={["tablet", "mobile"]}
            itemClass="carousel-item-padding-40-px"
            backgroundColor="#FFFFFF"
          >
            <img
              style={{
                display: "block",
                height: "100px",
                marginLeft: "auto",
                marginRight: "auto",
              }}
              src={GoogleLogo}
            />
            <img
              style={{
                display: "block",
                height: "100px",
                marginLeft: "auto",
                marginRight: "auto",
              }}
              src={PatreonLogo}
            />
            <img
              style={{
                display: "block",
                height: "100px",
                marginLeft: "auto",
                marginRight: "auto",
              }}
              src={SageLogo}
            />
            <img
              style={{
                display: "block",
                height: "100px",
                marginLeft: "auto",
                marginRight: "auto",
              }}
              src={XactlyLogo}
            />
          </Carousel>
          <Typography
            align="center"
            variant="h4"
            sx={{
              fontWeight: "700",
              fontSize: "45px",
              padding: 1,
              marginTop: "75px",
            }}
          >
            Our Platform is Built With You in Mind
          </Typography>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="space-between"
            sx={{
              width: "100%",
              padding: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "center",
                padding: 3,
                backgroundColor: "#FFF",
                color: "#4B4E58",
                width: "33%",
                borderRadius: "10px",
              }}
            >
              <Avatar
                alt="What is stratos"
                src={NetworkGrowth}
                sx={{
                  display: "flex",
                  borderRadius: 0,
                  bgcolor: "transparent",
                  width: "180px",
                  height: "45px",
                  padding: 2,
                }}
                imgProps={{
                  style: { objectFit: "contain" },
                }}
              />
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "600",
                  color: "#16171B",
                  padding: 2,
                }}
              >
                Network Growth
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "300",
                  color: "#4B4E58",
                  textAlign: "center",
                  padding: 2,
                }}
              >
                We connect you to ERG members from other top technology
                companies.
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "center",
                padding: 3,
                backgroundColor: "#FFF",
                color: "#4B4E58",
                width: "33%",
                borderRadius: "10px",
              }}
            >
              <Avatar
                alt="What is stratos"
                src={WorkplaceJustice}
                sx={{
                  display: "flex",
                  borderRadius: 0,
                  bgcolor: "transparent",
                  width: "180px",
                  height: "45px",
                  padding: 2,
                }}
                imgProps={{
                  style: { objectFit: "contain" },
                }}
              />
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "600",
                  color: "#16171B",
                  padding: 2,
                }}
              >
                Fostering Community
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "300",
                  color: "#4B4E58",
                  textAlign: "center",
                  padding: 2,
                }}
              >
                We empower our communities to achieve equitable and just career
                growth by identifying and navigating workplace challenges.
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "center",
                padding: 3,
                backgroundColor: "#FFF",
                color: "#4B4E58",
                width: "33%",
                borderRadius: "10px",
              }}
            >
              <Avatar
                alt="What is stratos"
                src={BuildingConfidence}
                sx={{
                  display: "flex",
                  borderRadius: 0,
                  bgcolor: "transparent",
                  width: "180px",
                  height: "45px",
                  padding: 2,
                }}
                imgProps={{
                  style: { objectFit: "contain" },
                }}
              />
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "600",
                  color: "#16171B",
                  padding: 2,
                }}
              >
                Building Confidence
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "300",
                  color: "#4B4E58",
                  textAlign: "center",
                  padding: 2,
                }}
              >
                We help you develop professional communication and networking
                skills as you use our platform.
              </Typography>
            </Box>
          </Stack>
        </Box>

        <Box
          sx={{
            display: { xs: "none", sm: "none", md: "flex" },
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            padding: 2,
          }}
        >
          <Typography
            variant="h4"
            sx={{ fontWeight: "700", fontSize: "45px", padding: 1 }}
          >
            Check Out our Features
          </Typography>
          <Stack
            direction="row"
            spacing={3}
            justifyContent="flex-start"
            sx={{
              width: "100%",
              padding: 2,
            }}
          >
            <Stack
              direction="row"
              spacing={3}
              justifyContent="center"
              sx={{
                width: "50%",
                background: "linear-gradient(96.34deg, #52B1BB, #008997 100%)",
                borderRadius: "10px",
                padding: 1,
                paddingY: 4,
              }}
            >
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={0}
              >
                <Box
                  sx={{
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                    margin: 0,
                  }}
                >
                  <Button
                    sx={{
                      color: "#FFF",
                      background: "rgba(255, 255, 255, 0.2)",
                      borderRadius: "27px",
                      fontSize: "15px",
                      border: "none",
                      textTransform: "none",
                      fontWeight: "bold",
                      margin: 1,
                    }}
                    variant="contained"
                  >
                    All
                  </Button>
                  <Button
                    sx={{
                      color: "#16171B",
                      background: "#FFF",
                      borderRadius: "27px",
                      fontSize: "15px",
                      border: "none",
                      textTransform: "none",
                      fontWeight: "bold",
                      margin: 1,
                    }}
                    variant="contained"
                  >
                    Technology
                  </Button>
                </Box>
                <Box
                  sx={{
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                    margin: 0,
                  }}
                >
                  <Button
                    sx={{
                      color: "#16171B",
                      background: "#FFF",
                      borderRadius: "27px",
                      fontSize: "15px",
                      border: "none",
                      textTransform: "none",
                      fontWeight: "bold",
                      margin: 1,
                    }}
                    variant="contained"
                  >
                    Business
                  </Button>
                  <Button
                    sx={{
                      color: "#16171B",
                      background: "#FFF",
                      borderRadius: "27px",
                      fontSize: "15px",
                      border: "none",
                      textTransform: "none",
                      fontWeight: "bold",
                      margin: 1,
                    }}
                    variant="contained"
                  >
                    Events
                  </Button>
                </Box>
              </Stack>
              <Box sx={{ width: "50%" }}>
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: "600",
                    color: "#FFF",
                    padding: 1,
                    textAlign: "center",
                  }}
                >
                  Layers
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: "300",
                    color: "#FFF",
                    textAlign: "left",
                    padding: 1,
                  }}
                >
                  We offer career opportunities and safe spaces for
                  professionals to connect and build community. These spaces are
                  called Layers, and they are organized by industry or interest.
                </Typography>
              </Box>
            </Stack>
            <Stack
              direction="row"
              spacing={3}
              justifyContent="center"
              sx={{
                width: "50%",
                background: "linear-gradient(96.34deg, #52B1BB, #008997 100%)",
                borderRadius: "10px",
                padding: 1,
                paddingY: 4,
              }}
            >
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={0}
                sx={{ width: "50%" }}
              >
                <Button
                  sx={{
                    color: "#FFF",
                    background: "rgba(255, 255, 255, 0.2)",
                    fontSize: "15px",
                    border: "none",
                    textTransform: "none",
                    fontWeight: "bold",
                    margin: 1,
                    whiteSpace: "nowrap",
                    paddingY: 0,
                  }}
                  variant="contained"
                >
                  🎓 Looking for a mentor
                </Button>
                <Button
                  sx={{
                    color: "#FFF",
                    background: "rgba(255, 255, 255, 0.2)",
                    fontSize: "15px",
                    border: "none",
                    textTransform: "none",
                    fontWeight: "bold",
                    margin: 1,
                    whiteSpace: "nowrap",
                    paddingY: 0,
                  }}
                  variant="contained"
                >
                  💼 career help
                </Button>
                <Button
                  sx={{
                    color: "#FFF",
                    background: "rgba(255, 255, 255, 0.2)",
                    fontSize: "15px",
                    border: "none",
                    textTransform: "none",
                    fontWeight: "bold",
                    margin: 1,
                    whiteSpace: "nowrap",
                    paddingY: 0,
                  }}
                  variant="contained"
                >
                  👩‍🏫 Welcome to be my mentee
                </Button>
              </Stack>
              <Box sx={{ width: "50%" }}>
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: "600",
                    color: "#FFF",
                    padding: 1,
                    textAlign: "center",
                  }}
                >
                  Tags
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: "300",
                    color: "#FFF",
                    textAlign: "left",
                    padding: 1,
                  }}
                >
                  We use current event-based trending words to encourage users
                  to share their quick reactions, insightful thoughts, or funny
                  curiosities in a Twitter-style format.
                </Typography>
              </Box>
            </Stack>
          </Stack>
          <Stack
            direction="row"
            spacing={3}
            justifyContent="center"
            sx={{
              width: "100%",
              background: "linear-gradient(96.34deg, #52B1BB, #008997 100%)",
              borderRadius: "10px",
              padding: 1,
              paddingY: 4,
            }}
          >
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={1}
            >
              <Avatar
                sx={{
                  background: "#FFF",
                  color: "#000",
                }}
              >
                <Loop />
              </Avatar>
              <Box
                sx={{
                  background: "#FFF",
                  borderRadius: "11px",
                  padding: 2,
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: "600",
                    color: "#black",
                    padding: 1,
                    textAlign: "left",
                    fontSize: "10px",
                    whiteSpace: "nowrap",
                  }}
                >
                  choose a prompt
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    color: "#818181",
                    padding: 1,
                    fontWeight: 400,
                    textAlign: "left",
                    borderBottom: "1px solid #E8E8E8",
                    fontSize: "10px",
                    whiteSpace: "nowrap",
                  }}
                >
                  A win in my career
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    color: "#818181",
                    padding: 1,
                    fontWeight: 400,
                    textAlign: "left",
                    borderBottom: "1px solid #E8E8E8",
                    fontSize: "10px",
                    whiteSpace: "nowrap",
                  }}
                >
                  What is your life goal?
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    color: "#818181",
                    padding: 1,
                    fontWeight: 400,
                    textAlign: "left",
                    borderBottom: "1px solid #E8E8E8",
                    fontSize: "10px",
                    whiteSpace: "nowrap",
                  }}
                >
                  How to balance rigorous workload and social life?
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    color: "#000",
                    padding: 1,
                    fontWeight: 400,
                    background: "#DBF1F2",
                    textAlign: "left",
                    fontSize: "10px",
                    whiteSpace: "nowrap",
                  }}
                >
                  How I avoid spreading myself thin….
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: "600",
                    color: "#FFF",
                    padding: 1,
                    textAlign: "center",
                  }}
                >
                  Prompts + Responses
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: "300",
                    color: "#FFF",
                    textAlign: "left",
                    padding: 1,
                  }}
                >
                  Allows users to start conversations with other professionals
                  who have similar interests. Posts are organized by layer, so
                  users can easily find conversations that are relevant to them.
                </Typography>
              </Box>
            </Stack>
          </Stack>

          <Stack
            direction="row"
            spacing={3}
            justifyContent="flex-start"
            sx={{
              width: "100%",
              padding: 3,
            }}
          >
            <Stack
              direction="row"
              spacing={0}
              justifyContent="center"
              alignItems={"center"}
              sx={{
                width: "50%",
                background: "linear-gradient(96.34deg, #52B1BB, #008997 100%)",
                borderRadius: "10px",
                padding: 1,
              }}
            >
              <Box sx={{ width: "50%" }}>
                <Avatar
                  alt="Stratos Spotlight"
                  src={StratosSpotlight}
                  sx={{
                    display: "flex",
                    borderRadius: 0,
                    bgcolor: "transparent",
                    width: "100%",
                    height: "auto",
                  }}
                  imgProps={{
                    style: { objectFit: "contain" },
                  }}
                />
              </Box>
              <Box sx={{ width: "50%" }}>
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: "600",
                    color: "#FFF",
                    padding: 1,
                    textAlign: "center",
                  }}
                >
                  Stratos Spotlight
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: "300",
                    color: "#FFF",
                    textAlign: "left",
                    padding: 1,
                  }}
                >
                  Learn from and connect with industry experts in 1 hour
                  sessions promoted through our platform.
                </Typography>
              </Box>
            </Stack>
            <Stack
              direction="row"
              spacing={3}
              justifyContent="center"
              sx={{
                width: "50%",
                background: "linear-gradient(96.34deg, #52B1BB, #008997 100%)",
                borderRadius: "10px",
                padding: 1,
                paddingY: 4,
              }}
            >
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={1}
                sx={{ width: "50%" }}
              >
                <Avatar
                  alt="Stratos Spotlight"
                  src={DM1}
                  sx={{
                    display: "flex",
                    borderRadius: 0,
                    bgcolor: "transparent",
                    width: "100%",
                    height: "auto",
                  }}
                  imgProps={{
                    style: { objectFit: "contain" },
                  }}
                />
                <Avatar
                  alt="Stratos Spotlight"
                  src={DM2}
                  sx={{
                    display: "flex",
                    borderRadius: 0,
                    bgcolor: "transparent",
                    width: "100%",
                    height: "auto",
                  }}
                  imgProps={{
                    style: { objectFit: "contain" },
                  }}
                />
              </Stack>
              <Box sx={{ width: "50%" }}>
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: "600",
                    color: "#FFF",
                    padding: 1,
                    textAlign: "center",
                  }}
                >
                  Direct Messaging
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: "300",
                    color: "#FFF",
                    textAlign: "left",
                    padding: 1,
                  }}
                >
                  Connect directly with other employees, professionals, and
                  mentors in our community.
                </Typography>
              </Box>
            </Stack>
          </Stack>
        </Box>

        <Box
          sx={{
            background: "#16171B",
            marginX: -1,
            width: "100%",
            display: { xs: "none", sm: "none", md: "flex" },
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
            padding: 2,
            borderRadius: "10px",
            marginY: 1,
          }}
        >
          <Box sx={{ width: "50%" }}>
            <Avatar
              alt="Stratos Spotlight"
              src={CareerLP}
              sx={{
                display: "flex",
                borderRadius: 0,
                bgcolor: "transparent",
                width: "100%",
                height: "auto",
              }}
              imgProps={{
                style: { objectFit: "contain" },
              }}
            />
          </Box>
          <Box sx={{ width: "50%" }}>
            <Typography
              variant="h2"
              sx={{
                fontWeight: "600",
                color: "#FFF",
                padding: 1,
                textAlign: "left",
              }}
            >
              Take Your Career to the Next Level
            </Typography>
            <Typography
              variant="h5"
              sx={{
                fontWeight: "400",
                color: "#FFF",
                textAlign: "left",
                padding: 1,
              }}
            >
              Our platform offers you the chance to explore new opportunities
              and connect with like-minded professionals. Unlock your career
              potential today! A safe space to engage with others that are on
              the same journey as you.
            </Typography>

            <Button
              sx={{
                color: "#FFFFFF",
                display: "block",
                background: "linear-gradient(96.34deg, #52B1BB, #008997 100%)",
                borderRadius: "14px",
                fontSize: "15px",
                height: "40px",
                cursor: "pointer",
                border: "none",
                textTransform: "none",
                filter: "drop-shadow(0px 4px 20px #0E909D)",
                margin: 1,
              }}
              variant="contained"
              onClick={handleClickLogin}
            >
              <span style={{ verticalAlign: "middle" }}>Get Started Now</span>
              <ArrowForward
                style={{
                  verticalAlign: "middle",
                  paddingLeft: 0.5,
                }}
              />
            </Button>
          </Box>
        </Box>

        <Box
          sx={{
            background: "#16171B",
            marginX: -1,
            width: "100%",
            display: { xs: "none", sm: "none", md: "flex" },
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "flex-start",
            paddingX: { xs: -3, sm: -3, md: -7 },
            borderRadius: "10px",
            marginY: 1,
            padding: 2,
          }}
        >
          <ContactUs
            iconColor={"#52B1BB"}
            buttonColor={"linear-gradient(96.34deg, #52B1BB, #008997 100%)"}
          />
        </Box>

        <Box
          sx={{
            marginX: { xs: -3, sm: -3, md: -7 },
            marginTop: 10,
            display: { xs: "none", sm: "none", md: "flex" },
          }}
        >
          <LastComponent isMobile={false} />
        </Box>

        {/*Mobile version */}

        <Grow in {...{ timeout: 2000 }}>
          <Box
            sx={{
              display: { xs: "flex", sm: "flex", md: "none" },
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
              padding: 1,
            }}
          >
            <Typography
              variant="h3"
              sx={{
                fontWeight: "700",
              }}
            >
              Stratos is the Place Where ERGs Find Community
            </Typography>
            <Box
              sx={{
                overflow: "hidden",
                paddingLeft: -2,
                marginLeft: -2,
              }}
            >
              <Avatar
                alt="What is stratos"
                src={WhatIsStratos}
                sx={{
                  display: "flex",
                  borderRadius: 0,
                  bgcolor: "transparent",
                  width: "130%",
                  height: "auto",
                }}
                imgProps={{
                  style: {
                    objectFit: "contain",
                  },
                }}
              />
            </Box>
            <Typography
              variant="h6"
              sx={{
                fontWeight: "500",
                color: "grey",
                padding: 1,
                paddingLeft: 0,
              }}
            >
              The Exclusive Social Platform Connecting ERG Group Members from
              Top Technology Companies Across the Country.
            </Typography>
            <Button
              sx={{
                color: "#FFFFFF",
                display: "block",
                background: "linear-gradient(96.34deg, #52B1BB, #008997 100%)",
                borderRadius: "14px",
                fontSize: "15px",
                height: "40px",
                cursor: "pointer",
                border: "none",
                textTransform: "none",
              }}
              variant="contained"
              onClick={handleClickLogin}
            >
              <span style={{ verticalAlign: "middle" }}>Get Started Now</span>
              <ArrowForward
                style={{
                  verticalAlign: "middle",
                  paddingLeft: 0.5,
                }}
              />
            </Button>
          </Box>
        </Grow>

        <Box
          sx={{
            display: { xs: "flex", sm: "flex", md: "none" },
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            padding: 1,
            paddingTop: 2,
          }}
        >
          <Typography
            variant="h3"
            sx={{
              fontWeight: "700",
            }}
          >
            What Makes Stratos Different?
          </Typography>
          <Typography
            variant="h6"
            sx={{
              fontWeight: "500",
              color: "grey",
              padding: 1,
              paddingLeft: 0,
            }}
          >
            Stratos is bringing ERGs across different companies together.
            Creating a unified community. Stratos is a collaborative community
            that provides resources and connections to help professionals
            succeed. Our members are committed to working together to achieve
            their goals, and we offer a variety of resources to support their
            success.
          </Typography>

          <Box sx={{ overflow: "hidden" }}>
            <Avatar
              alt="What is stratos"
              src={WhyStratos}
              sx={{
                display: "flex",
                borderRadius: 0,
                bgcolor: "transparent",
                width: "100%",
                height: "auto",
                justifyContent: "flex-start",
              }}
              imgProps={{
                style: {
                  objectFit: "contain",
                },
              }}
            />
          </Box>
        </Box>

        <Box
          sx={{
            display: { xs: "flex", sm: "flex", md: "none" },
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            padding: 2,
          }}
        >
          <Typography
            variant="h4"
            sx={{ fontWeight: "700", fontSize: "35px", padding: 1 }}
          >
            Our Platform is Built With You in Mind
          </Typography>
          <Stack
            direction="column"
            spacing={2}
            justifyContent="space-between"
            sx={{
              width: "100%",
              padding: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "center",
                padding: 1,
                backgroundColor: "#FFF",
                color: "#4B4E58",
                borderRadius: "10px",
              }}
            >
              <Avatar
                alt="What is stratos"
                src={NetworkGrowth}
                sx={{
                  display: "flex",
                  borderRadius: 0,
                  bgcolor: "transparent",
                  width: "180px",
                  height: "45px",
                  padding: 1,
                }}
                imgProps={{
                  style: { objectFit: "contain" },
                }}
              />
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "600",
                  color: "#16171B",
                  padding: 1,
                  textAlign: "center",
                }}
              >
                Network Growth
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontWeight: "300",
                  color: "#4B4E58",
                  textAlign: "center",
                  padding: 1,
                }}
              >
                We connect you to ERG members from other top technology
                companies.
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "center",
                padding: 2,
                backgroundColor: "#FFF",
                color: "#4B4E58",
                borderRadius: "10px",
              }}
            >
              <Avatar
                alt="What is stratos"
                src={WorkplaceJustice}
                sx={{
                  display: "flex",
                  borderRadius: 0,
                  bgcolor: "transparent",
                  width: "180px",
                  height: "45px",
                  padding: 1,
                }}
                imgProps={{
                  style: { objectFit: "contain" },
                }}
              />
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "600",
                  color: "#16171B",
                  padding: 1,
                  textAlign: "center",
                }}
              >
                Fostering Community
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontWeight: "300",
                  color: "#4B4E58",
                  textAlign: "center",
                  padding: 1,
                }}
              >
                We empower our communities to achieve equitable and just career
                growth by identifying and navigating workplace challenges.
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "center",
                padding: 2,
                backgroundColor: "#FFF",
                color: "#4B4E58",
                borderRadius: "10px",
              }}
            >
              <Avatar
                alt="What is stratos"
                src={BuildingConfidence}
                sx={{
                  display: "flex",
                  borderRadius: 0,
                  bgcolor: "transparent",
                  width: "180px",
                  height: "45px",
                  padding: 1,
                }}
                imgProps={{
                  style: { objectFit: "contain" },
                }}
              />
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "600",
                  color: "#16171B",
                  padding: 1,
                  textAlign: "center",
                }}
              >
                Building Confidence
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontWeight: "300",
                  color: "#4B4E58",
                  textAlign: "center",
                  padding: 1,
                }}
              >
                We help you develop professional communication and networking
                skills as you use our platform.
              </Typography>
            </Box>
          </Stack>
        </Box>

        <Box
          sx={{
            display: { xs: "flex", sm: "flex", md: "none" },
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            padding: 1,
          }}
        >
          <Typography
            variant="h4"
            sx={{
              fontWeight: "700",
              fontSize: "45px",
              padding: 1,
              textAlign: "center",
            }}
          >
            Check Out our Features
          </Typography>
          <Stack
            direction="column"
            spacing={3}
            justifyContent="center"
            alignItems={"center"}
            sx={{
              width: "100%",
              background: "linear-gradient(96.34deg, #52B1BB, #008997 100%)",
              borderRadius: "10px",
              paddingX: 1,
              paddingY: 4,
              margin: 1,
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontWeight: "600",
                color: "#FFF",
                padding: 1,
                textAlign: "center",
              }}
            >
              Layers
            </Typography>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={0}
            >
              <Box
                sx={{
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  margin: 0,
                }}
              >
                <Button
                  sx={{
                    color: "#FFF",
                    background: "rgba(255, 255, 255, 0.2)",
                    borderRadius: "27px",
                    fontSize: "15px",
                    border: "none",
                    textTransform: "none",
                    fontWeight: "bold",
                    margin: 1,
                  }}
                  variant="contained"
                >
                  All
                </Button>
                <Button
                  sx={{
                    color: "#16171B",
                    background: "#FFF",
                    borderRadius: "27px",
                    fontSize: "15px",
                    border: "none",
                    textTransform: "none",
                    fontWeight: "bold",
                    margin: 1,
                  }}
                  variant="contained"
                >
                  Technology
                </Button>
              </Box>
              <Box
                sx={{
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  margin: 0,
                }}
              >
                <Button
                  sx={{
                    color: "#16171B",
                    background: "#FFF",
                    borderRadius: "27px",
                    fontSize: "15px",
                    border: "none",
                    textTransform: "none",
                    fontWeight: "bold",
                    margin: 1,
                  }}
                  variant="contained"
                >
                  Business
                </Button>
                <Button
                  sx={{
                    color: "#16171B",
                    background: "#FFF",
                    borderRadius: "27px",
                    fontSize: "15px",
                    border: "none",
                    textTransform: "none",
                    fontWeight: "bold",
                    margin: 1,
                  }}
                  variant="contained"
                >
                  Events
                </Button>
              </Box>
            </Stack>
            <Box sx={{ width: "100%", margin: 1 }}>
              <Typography
                variant="body1"
                sx={{
                  fontWeight: "300",
                  color: "#FFF",
                  textAlign: "left",
                  paddingX: 1,
                  paddingY: 2,
                }}
              >
                We offer career opportunities and safe spaces for professionals
                to connect and build community. These spaces are called Layers,
                and they are organized by industry or interest.
              </Typography>
            </Box>
          </Stack>

          <Stack
            direction="column"
            spacing={3}
            justifyContent="center"
            alignItems={"center"}
            sx={{
              width: "100%",
              background: "linear-gradient(96.34deg, #52B1BB, #008997 100%)",
              borderRadius: "10px",
              paddingX: 1,
              paddingY: 4,
              margin: 1,
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontWeight: "600",
                color: "#FFF",
                padding: 1,
                textAlign: "center",
              }}
            >
              Tags
            </Typography>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={0}
              sx={{ width: "100%" }}
            >
              <Button
                sx={{
                  color: "#FFF",
                  background: "rgba(255, 255, 255, 0.2)",
                  fontSize: "15px",
                  border: "none",
                  textTransform: "none",
                  fontWeight: "bold",
                  margin: 1,
                  whiteSpace: "nowrap",
                  paddingY: 0,
                }}
                variant="contained"
              >
                🎓 Looking for a mentor
              </Button>
              <Button
                sx={{
                  color: "#FFF",
                  background: "rgba(255, 255, 255, 0.2)",
                  fontSize: "15px",
                  border: "none",
                  textTransform: "none",
                  fontWeight: "bold",
                  margin: 1,
                  whiteSpace: "nowrap",
                  paddingY: 0,
                }}
                variant="contained"
              >
                💼 career help
              </Button>
              <Button
                sx={{
                  color: "#FFF",
                  background: "rgba(255, 255, 255, 0.2)",
                  fontSize: "15px",
                  border: "none",
                  textTransform: "none",
                  fontWeight: "bold",
                  margin: 1,
                  whiteSpace: "nowrap",
                  paddingY: 0,
                }}
                variant="contained"
              >
                👩‍🏫 Welcome to be my mentee
              </Button>
            </Stack>
            <Box sx={{ width: "100%" }}>
              <Typography
                variant="body1"
                sx={{
                  fontWeight: "300",
                  color: "#FFF",
                  textAlign: "left",
                  padding: 1,
                }}
              >
                We use current event-based trending words to encourage users to
                share their quick reactions, insightful thoughts, or funny
                curiosities in a Twitter-style format.
              </Typography>
            </Box>
          </Stack>

          <Stack
            direction="column"
            spacing={3}
            justifyContent="center"
            alignItems={"center"}
            sx={{
              width: "100%",
              background: "linear-gradient(96.34deg, #52B1BB, #008997 100%)",
              borderRadius: "10px",
              paddingX: 1,
              paddingY: 4,
              margin: 1,
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontWeight: "600",
                color: "#FFF",
                padding: 1,
                textAlign: "center",
              }}
            >
              Prompts + Responses
            </Typography>
            <Box
              sx={{
                background: "#FFF",
                borderRadius: "11px",
                padding: 2,
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  fontWeight: "600",
                  color: "#black",
                  padding: 1,
                  textAlign: "left",
                  fontSize: "10px",
                  whiteSpace: "nowrap",
                }}
              >
                choose a prompt
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: "#818181",
                  padding: 1,
                  fontWeight: 400,
                  textAlign: "left",
                  borderBottom: "1px solid #E8E8E8",
                  fontSize: "10px",
                  whiteSpace: "nowrap",
                }}
              >
                A win in my career
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: "#818181",
                  padding: 1,
                  fontWeight: 400,
                  textAlign: "left",
                  borderBottom: "1px solid #E8E8E8",
                  fontSize: "10px",
                  whiteSpace: "nowrap",
                }}
              >
                What is your life goal?
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: "#818181",
                  padding: 1,
                  fontWeight: 400,
                  textAlign: "left",
                  borderBottom: "1px solid #E8E8E8",
                  fontSize: "10px",
                  whiteSpace: "nowrap",
                }}
              >
                How to balance rigorous workload and social life?
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: "#000",
                  padding: 1,
                  fontWeight: 400,
                  background: "#DBF1F2",
                  textAlign: "left",
                  fontSize: "10px",
                  whiteSpace: "nowrap",
                }}
              >
                How I avoid spreading myself thin….
              </Typography>
            </Box>
            <Box>
              <Typography
                variant="body1"
                sx={{
                  fontWeight: "300",
                  color: "#FFF",
                  textAlign: "left",
                  padding: 1,
                }}
              >
                Allows users to start conversations with other professionals who
                have similar interests. Posts are organized by layer, so users
                can easily find conversations that are relevant to them.
              </Typography>
            </Box>
          </Stack>

          <Stack
            direction="column"
            spacing={3}
            justifyContent="center"
            alignItems={"center"}
            sx={{
              width: "100%",
              background: "linear-gradient(96.34deg, #52B1BB, #008997 100%)",
              borderRadius: "10px",
              paddingX: 1,
              paddingY: 4,
              margin: 1,
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontWeight: "600",
                color: "#FFF",
                padding: 1,
                textAlign: "center",
              }}
            >
              Stratos Spotlight
            </Typography>

            <Box sx={{ width: "100%" }}>
              <Avatar
                alt="Stratos Spotlight"
                src={StratosSpotlight}
                sx={{
                  display: "flex",
                  borderRadius: 0,
                  bgcolor: "transparent",
                  width: "100%",
                  height: "auto",
                }}
                imgProps={{
                  style: { objectFit: "contain" },
                }}
              />
            </Box>
            <Typography
              variant="body1"
              sx={{
                fontWeight: "300",
                color: "#FFF",
                textAlign: "left",
                padding: 1,
              }}
            >
              Learn from and connect with industry experts in 1 hour sessions
              promoted through our platform.
            </Typography>
          </Stack>

          <Stack
            direction="column"
            spacing={3}
            justifyContent="center"
            alignItems={"center"}
            sx={{
              width: "100%",
              background: "linear-gradient(96.34deg, #52B1BB, #008997 100%)",
              borderRadius: "10px",
              paddingX: 1,
              paddingY: 4,
              margin: 1,
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontWeight: "600",
                color: "#FFF",
                padding: 1,
                textAlign: "center",
              }}
            >
              Direct Messaging
            </Typography>

            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={1}
              sx={{ width: "100%" }}
            >
              <Avatar
                alt="Stratos Spotlight"
                src={DM1}
                sx={{
                  display: "flex",
                  borderRadius: 0,
                  bgcolor: "transparent",
                  width: "100%",
                  height: "auto",
                }}
                imgProps={{
                  style: { objectFit: "contain" },
                }}
              />
              <Avatar
                alt="Stratos Spotlight"
                src={DM2}
                sx={{
                  display: "flex",
                  borderRadius: 0,
                  bgcolor: "transparent",
                  width: "100%",
                  height: "auto",
                }}
                imgProps={{
                  style: { objectFit: "contain" },
                }}
              />
            </Stack>
            <Typography
              variant="body1"
              sx={{
                fontWeight: "300",
                color: "#FFF",
                textAlign: "left",
                padding: 1,
              }}
            >
              Connect directly with other employees, professionals, and mentors
              in our community.
            </Typography>
          </Stack>
        </Box>

        <Box
          sx={{
            background: "#16171B",
            display: { xs: "flex", sm: "flex", md: "none" },
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            paddingX: 1,
            paddingY: 4, // Adjust the vertical padding
            borderRadius: "10px",
          }}
        >
          <Typography
            variant="h2"
            sx={{
              fontWeight: "600",
              color: "#FFF",
              padding: 1,
              textAlign: "left",
            }}
          >
            Take Your Career to the Next Level
          </Typography>
          <Box>
            <Avatar
              alt="career"
              src={CareerLP}
              sx={{
                display: "flex",
                borderRadius: 0,
                bgcolor: "transparent",
                width: "100%",
                height: "auto",
              }}
              imgProps={{
                style: { objectFit: "contain" },
              }}
            />
          </Box>

          <Box>
            <Typography
              variant="h5"
              sx={{
                fontWeight: "400",
                color: "#FFF",
                textAlign: "left",
                padding: 1,
              }}
            >
              Our platform offers you the chance to explore new opportunities
              and connect with like-minded professionals. Unlock your career
              potential today!
            </Typography>

            <Button
              sx={{
                color: "#FFFFFF",
                display: "block",
                background: "linear-gradient(96.34deg, #52B1BB, #008997 100%)",
                borderRadius: "14px",
                fontSize: "15px",
                height: "40px",
                cursor: "pointer",
                border: "none",
                textTransform: "none",
                filter: "drop-shadow(0px 4px 20px #0E909D)",
                margin: 1,
              }}
              variant="contained"
              onClick={handleClickLogin}
            >
              <span style={{ verticalAlign: "middle" }}>Get Started Now</span>
              <ArrowForward
                style={{
                  verticalAlign: "middle",
                  paddingLeft: 0.5,
                }}
              />
            </Button>
          </Box>
        </Box>

        <Box
          sx={{
            background: "#16171B",
            display: { xs: "flex", sm: "flex", md: "none" },
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "flex-start",
            paddingX: { xs: -3, sm: -3, md: -7 },
            borderRadius: "10px",
            marginY: 1,
            padding: 2,
          }}
        >
          <ContactUs
            iconColor={"#52B1BB"}
            buttonColor={"linear-gradient(96.34deg, #52B1BB, #008997 100%)"}
          />
        </Box>

        <Box
          sx={{
            marginX: { xs: -3, sm: -3, md: -7 },
            marginTop: 10,
            display: { xs: "flex", sm: "flex", md: "none" },
          }}
        >
          <LastComponent isMobile={true} />
        </Box>
      </Box>
    </>
  );
};

export default Homepage;
