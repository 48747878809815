import { ArrowForward, Loop } from "@mui/icons-material";
import { Avatar, Box, Button, Grow, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import "react-multi-carousel/lib/styles.css";
import { useNavigate } from "react-router-dom";
import CareerLP from "../../Assets/CareerLP.svg";
import DM1 from "../../Assets/DM1.svg";
import DM2 from "../../Assets/DM2.svg";
import WhatIsStratos from "../../Assets/WhatIsStratosLP.png";
import StratosSpotlight from "../../Assets/stratosSpotlight.svg";
import "./Homepage.css";

const Product = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const handleClickLogin = () => {
    navigate("/Welcome");
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          paddingX: { xs: 3, sm: 3, md: 7 },
          background: "#FFFFFF",
          paddingBottom: 10,
        }}
      >
        <Grow in {...{ timeout: 2000 }}>
          <Box
            sx={{
              display: { xs: "none", sm: "none", md: "flex" },
              flexDirection: "row",
              justifyContent: "space-between",
              paddingX: "10%",
              paddingY: 5,
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
                width: "30%",
                m: 1,
              }}
            >
              <Typography
                variant="h4"
                sx={{ fontWeight: "700", fontSize: "50px" }}
              >
                A Look at Stratos
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "500",
                  color: "grey",
                  padding: 2,
                  paddingLeft: 0,
                }}
              >
                A peak into the exclusive platform connecting professionals from
                top companies across the country.
              </Typography>
            </Box>
            <Box sx={{ width: "70%" }}>
              <Avatar
                alt="What is stratos"
                src={WhatIsStratos}
                sx={{
                  display: "flex",
                  borderRadius: 0,
                  bgcolor: "transparent",
                  width: "100%",
                  height: "auto",
                }}
                imgProps={{
                  style: { objectFit: "contain" },
                }}
              />
            </Box>
          </Box>
        </Grow>

        <Box
          sx={{
            display: { xs: "none", sm: "none", md: "flex" },
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            padding: 2,
          }}
        >
          <Typography
            variant="h4"
            sx={{ fontWeight: "700", fontSize: "45px", padding: 1 }}
          >
            Our Features
          </Typography>
          <Stack
            direction="row"
            spacing={3}
            justifyContent="flex-start"
            sx={{
              width: "100%",
              padding: 2,
            }}
          >
            <Stack
              direction="row"
              spacing={3}
              justifyContent="center"
              sx={{
                width: "50%",
                background: "linear-gradient(96.34deg, #52B1BB, #008997 100%)",
                borderRadius: "10px",
                padding: 1,
                paddingY: 4,
              }}
            >
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={0}
              >
                <Box
                  sx={{
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                    margin: 0,
                  }}
                >
                  <Button
                    sx={{
                      color: "#FFF",
                      background: "rgba(255, 255, 255, 0.2)",
                      borderRadius: "27px",
                      fontSize: "15px",
                      border: "none",
                      textTransform: "none",
                      fontWeight: "bold",
                      margin: 1,
                    }}
                    variant="contained"
                  >
                    All
                  </Button>
                  <Button
                    sx={{
                      color: "#16171B",
                      background: "#FFF",
                      borderRadius: "27px",
                      fontSize: "15px",
                      border: "none",
                      textTransform: "none",
                      fontWeight: "bold",
                      margin: 1,
                    }}
                    variant="contained"
                  >
                    San Francisco
                  </Button>
                </Box>
                <Box
                  sx={{
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                    margin: 0,
                  }}
                >
                  <Button
                    sx={{
                      color: "#16171B",
                      background: "#FFF",
                      borderRadius: "27px",
                      fontSize: "15px",
                      border: "none",
                      textTransform: "none",
                      fontWeight: "bold",
                      margin: 1,
                    }}
                    variant="contained"
                  >
                    Real Talk
                  </Button>
                  <Button
                    sx={{
                      color: "#16171B",
                      background: "#FFF",
                      borderRadius: "27px",
                      fontSize: "15px",
                      border: "none",
                      textTransform: "none",
                      fontWeight: "bold",
                      margin: 1,
                    }}
                    variant="contained"
                  >
                    ERG Leads
                  </Button>
                </Box>
              </Stack>
              <Box sx={{ width: "50%" }}>
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: "600",
                    color: "#FFF",
                    padding: 1,
                    textAlign: "center",
                  }}
                >
                  Layers
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: "300",
                    color: "#FFF",
                    textAlign: "left",
                    padding: 1,
                  }}
                >
                  We offer feed-style discussion spaces for professionals to
                  knowledge share, connect, and build community. These spaces
                  are called Layers, and they are organized by different topic
                  focuses.
                </Typography>
              </Box>
            </Stack>
            <Stack
              direction="row"
              spacing={3}
              justifyContent="center"
              sx={{
                width: "50%",
                background: "linear-gradient(96.34deg, #52B1BB, #008997 100%)",
                borderRadius: "10px",
                padding: 1,
                paddingY: 4,
              }}
            >
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={0}
                sx={{ width: "50%" }}
              >
                <Button
                  sx={{
                    color: "#FFF",
                    background: "rgba(255, 255, 255, 0.2)",
                    fontSize: "15px",
                    border: "none",
                    textTransform: "none",
                    fontWeight: "bold",
                    margin: 1,
                    whiteSpace: "nowrap",
                    paddingY: 0,
                  }}
                  variant="contained"
                >
                  🎓 Looking for a mentor
                </Button>
                <Button
                  sx={{
                    color: "#FFF",
                    background: "rgba(255, 255, 255, 0.2)",
                    fontSize: "15px",
                    border: "none",
                    textTransform: "none",
                    fontWeight: "bold",
                    margin: 1,
                    whiteSpace: "nowrap",
                    paddingY: 0,
                  }}
                  variant="contained"
                >
                  🍻 In-person hangouts
                </Button>
                <Button
                  sx={{
                    color: "#FFF",
                    background: "rgba(255, 255, 255, 0.2)",
                    fontSize: "15px",
                    border: "none",
                    textTransform: "none",
                    fontWeight: "bold",
                    margin: 1,
                    whiteSpace: "nowrap",
                    paddingY: 0,
                  }}
                  variant="contained"
                >
                  🗂️ Looking for side-project partners
                </Button>
                <Button
                  sx={{
                    color: "#FFF",
                    background: "rgba(255, 255, 255, 0.2)",
                    fontSize: "15px",
                    border: "none",
                    textTransform: "none",
                    fontWeight: "bold",
                    margin: 1,
                    whiteSpace: "nowrap",
                    paddingY: 0,
                  }}
                  variant="contained"
                >
                  💡 Career help/promotion advice
                </Button>
              </Stack>
              <Box sx={{ width: "50%" }}>
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: "600",
                    color: "#FFF",
                    padding: 1,
                    textAlign: "center",
                  }}
                >
                  Interests
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: "300",
                    color: "#FFF",
                    textAlign: "left",
                    padding: 1,
                  }}
                >
                  Personalize your profile to display to others exactly what you
                  are interested in using Stratos for. Let what you are looking
                  for come to you!
                </Typography>
              </Box>
            </Stack>
          </Stack>
          <Stack
            direction="row"
            spacing={3}
            justifyContent="center"
            sx={{
              width: "100%",
              background: "linear-gradient(96.34deg, #52B1BB, #008997 100%)",
              borderRadius: "10px",
              padding: 1,
              paddingY: 4,
            }}
          >
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={1}
            >
              <Avatar
                sx={{
                  background: "#FFF",
                  color: "#000",
                }}
              >
                <Loop />
              </Avatar>
              <Box
                sx={{
                  background: "#FFF",
                  borderRadius: "11px",
                  padding: 2,
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: "600",
                    color: "#black",
                    padding: 1,
                    textAlign: "left",
                    fontSize: "10px",
                    whiteSpace: "nowrap",
                  }}
                >
                  choose a prompt
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    color: "#818181",
                    padding: 1,
                    fontWeight: 400,
                    textAlign: "left",
                    borderBottom: "1px solid #E8E8E8",
                    fontSize: "10px",
                    whiteSpace: "nowrap",
                  }}
                >
                  A win in my career
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    color: "#818181",
                    padding: 1,
                    fontWeight: 400,
                    textAlign: "left",
                    borderBottom: "1px solid #E8E8E8",
                    fontSize: "10px",
                    whiteSpace: "nowrap",
                  }}
                >
                  What is your life goal?
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    color: "#818181",
                    padding: 1,
                    fontWeight: 400,
                    textAlign: "left",
                    borderBottom: "1px solid #E8E8E8",
                    fontSize: "10px",
                    whiteSpace: "nowrap",
                  }}
                >
                  How to balance rigorous workload and social life?
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    color: "#000",
                    padding: 1,
                    fontWeight: 400,
                    background: "#DBF1F2",
                    textAlign: "left",
                    fontSize: "10px",
                    whiteSpace: "nowrap",
                  }}
                >
                  How I avoid spreading myself thin….
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: "600",
                    color: "#FFF",
                    padding: 1,
                    textAlign: "center",
                  }}
                >
                  Prompts + Responses
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: "300",
                    color: "#FFF",
                    textAlign: "left",
                    padding: 1,
                  }}
                >
                  We utilize prompts to facilitate frequent conversation and
                  knowledge sharing in our community. Posts are organized by
                  layer, so users can easily find conversations that are
                  relevant to them.
                </Typography>
              </Box>
            </Stack>
          </Stack>

          <Stack
            direction="row"
            spacing={3}
            justifyContent="flex-start"
            sx={{
              width: "100%",
              padding: 3,
            }}
          >
            <Stack
              direction="row"
              spacing={0}
              justifyContent="center"
              alignItems={"center"}
              sx={{
                width: "50%",
                background: "linear-gradient(96.34deg, #52B1BB, #008997 100%)",
                borderRadius: "10px",
                padding: 1,
              }}
            >
              <Box sx={{ width: "50%" }}>
                <Avatar
                  alt="Stratos Spotlight"
                  src={StratosSpotlight}
                  sx={{
                    display: "flex",
                    borderRadius: 0,
                    bgcolor: "transparent",
                    width: "100%",
                    height: "auto",
                  }}
                  imgProps={{
                    style: { objectFit: "contain" },
                  }}
                />
              </Box>
              <Box sx={{ width: "50%" }}>
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: "600",
                    color: "#FFF",
                    padding: 1,
                    textAlign: "center",
                  }}
                >
                  Stratos Spotlight
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: "300",
                    color: "#FFF",
                    textAlign: "left",
                    padding: 1,
                  }}
                >
                  Learn from and connect with industry experts in our exclusive
                  sessions offered by Stratos.
                </Typography>
              </Box>
            </Stack>
            <Stack
              direction="row"
              spacing={3}
              justifyContent="center"
              sx={{
                width: "50%",
                background: "linear-gradient(96.34deg, #52B1BB, #008997 100%)",
                borderRadius: "10px",
                padding: 1,
                paddingY: 4,
              }}
            >
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={1}
                sx={{ width: "50%" }}
              >
                <Avatar
                  alt="Stratos Spotlight"
                  src={DM1}
                  sx={{
                    display: "flex",
                    borderRadius: 0,
                    bgcolor: "transparent",
                    width: "100%",
                    height: "auto",
                  }}
                  imgProps={{
                    style: { objectFit: "contain" },
                  }}
                />
                <Avatar
                  alt="Stratos Spotlight"
                  src={DM2}
                  sx={{
                    display: "flex",
                    borderRadius: 0,
                    bgcolor: "transparent",
                    width: "100%",
                    height: "auto",
                  }}
                  imgProps={{
                    style: { objectFit: "contain" },
                  }}
                />
              </Stack>
              <Box sx={{ width: "50%" }}>
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: "600",
                    color: "#FFF",
                    padding: 1,
                    textAlign: "center",
                  }}
                >
                  Direct Messaging
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: "300",
                    color: "#FFF",
                    textAlign: "left",
                    padding: 1,
                  }}
                >
                  Connect directly with other employees, professionals, and
                  mentors in our community.
                </Typography>
              </Box>
            </Stack>
          </Stack>
        </Box>

        <Box
          sx={{
            background: "linear-gradient(96.34deg, #52B1BB, #008997 100%)",
            marginX: -1,
            width: "100%",
            display: { xs: "none", sm: "none", md: "flex" },
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
            padding: 2,
            borderRadius: "10px",
            marginY: 1,
          }}
        >
          <Box sx={{ width: "50%" }}>
            <Avatar
              alt="career"
              src={CareerLP}
              sx={{
                display: "flex",
                borderRadius: 0,
                bgcolor: "transparent",
                width: "80%",
                height: "auto",
                padding: 2,
              }}
              imgProps={{
                style: { objectFit: "contain" },
              }}
            />
          </Box>
          <Box sx={{ width: "50%" }}>
            <Typography
              variant="h2"
              sx={{
                fontWeight: "600",
                color: "#FFF",
                padding: 1,
                textAlign: "left",
              }}
            >
              join the community today
            </Typography>
            <Typography
              variant="h5"
              sx={{
                fontWeight: "400",
                color: "#FFF",
                textAlign: "left",
                padding: 1,
              }}
            >
              Stratos offers you the chance to explore new opportunities and
              connect with like-minded professionals. Unlock your career
              potential today! A safe space to engage with others that are on
              the same journey as you.
            </Typography>

            <Button
              sx={{
                color: "#FFFFFF",
                display: "block",
                background: "#008997",
                borderRadius: "14px",
                fontSize: "15px",
                height: "40px",
                cursor: "pointer",
                border: "none",
                textTransform: "none",
                margin: 1,
              }}
              variant="contained"
              onClick={handleClickLogin}
            >
              <span style={{ verticalAlign: "middle" }}>Get Started Now</span>
              <ArrowForward
                style={{
                  verticalAlign: "middle",
                  paddingLeft: 0.5,
                }}
              />
            </Button>
          </Box>
        </Box>

        {/*Mobile version */}

        <Grow in {...{ timeout: 2000 }}>
          <Box
            sx={{
              display: { xs: "flex", sm: "flex", md: "none" },
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
              padding: 1,
            }}
          >
            <Typography
              variant="h3"
              sx={{
                fontWeight: "700",
                padding: 3,
              }}
            >
              A Look at Stratos
            </Typography>
            <Box
              sx={{
                overflow: "hidden",
              }}
            >
              <Avatar
                alt="What is stratos"
                src={WhatIsStratos}
                sx={{
                  display: "flex",
                  borderRadius: 0,
                  bgcolor: "transparent",
                  width: "100%",
                  height: "auto",
                }}
                imgProps={{
                  style: {
                    objectFit: "contain",
                  },
                }}
              />
            </Box>
            <Typography
              variant="h6"
              sx={{
                fontWeight: "500",
                color: "grey",
                padding: 1,
                paddingLeft: 0,
              }}
            >
              A peak into the exclusive platform connecting professionals from
              top companies across the country.
            </Typography>
          </Box>
        </Grow>

        <Box
          sx={{
            display: { xs: "flex", sm: "flex", md: "none" },
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            padding: 1,
          }}
        >
          <Typography
            variant="h4"
            sx={{
              fontWeight: "700",
              fontSize: "45px",
              padding: 1,
              textAlign: "center",
            }}
          >
            Our Features
          </Typography>
          <Stack
            direction="column"
            spacing={3}
            justifyContent="center"
            alignItems={"center"}
            sx={{
              width: "100%",
              background: "linear-gradient(96.34deg, #52B1BB, #008997 100%)",
              borderRadius: "10px",
              paddingX: 1,
              paddingY: 4,
              margin: 1,
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontWeight: "600",
                color: "#FFF",
                padding: 1,
                textAlign: "center",
              }}
            >
              Layers
            </Typography>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={0}
            >
              <Box
                sx={{
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  margin: 0,
                }}
              >
                <Button
                  sx={{
                    color: "#FFF",
                    background: "rgba(255, 255, 255, 0.2)",
                    borderRadius: "27px",
                    fontSize: "15px",
                    border: "none",
                    textTransform: "none",
                    fontWeight: "bold",
                    margin: 1,
                  }}
                  variant="contained"
                >
                  All
                </Button>
                <Button
                  sx={{
                    color: "#16171B",
                    background: "#FFF",
                    borderRadius: "27px",
                    fontSize: "15px",
                    border: "none",
                    textTransform: "none",
                    fontWeight: "bold",
                    margin: 1,
                  }}
                  variant="contained"
                >
                  San Francisco
                </Button>
              </Box>
              <Box
                sx={{
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  margin: 0,
                }}
              >
                <Button
                  sx={{
                    color: "#16171B",
                    background: "#FFF",
                    borderRadius: "27px",
                    fontSize: "15px",
                    border: "none",
                    textTransform: "none",
                    fontWeight: "bold",
                    margin: 1,
                  }}
                  variant="contained"
                >
                  Real Talk
                </Button>
                <Button
                  sx={{
                    color: "#16171B",
                    background: "#FFF",
                    borderRadius: "27px",
                    fontSize: "15px",
                    border: "none",
                    textTransform: "none",
                    fontWeight: "bold",
                    margin: 1,
                  }}
                  variant="contained"
                >
                  ERG Leads
                </Button>
              </Box>
            </Stack>
            <Box sx={{ width: "100%", margin: 1 }}>
              <Typography
                variant="body1"
                sx={{
                  fontWeight: "300",
                  color: "#FFF",
                  textAlign: "left",
                  paddingX: 1,
                  paddingY: 2,
                }}
              >
                We offer feed-style discussion spaces for professionals to
                knowledge share, connect, and build community. These spaces are
                called Layers, and they are organized by different topic
                focuses.
              </Typography>
            </Box>
          </Stack>

          <Stack
            direction="column"
            spacing={3}
            justifyContent="center"
            alignItems={"center"}
            sx={{
              width: "100%",
              background: "linear-gradient(96.34deg, #52B1BB, #008997 100%)",
              borderRadius: "10px",
              paddingX: 1,
              paddingY: 4,
              margin: 1,
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontWeight: "600",
                color: "#FFF",
                padding: 1,
                textAlign: "center",
              }}
            >
              Interests
            </Typography>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={0}
              sx={{ width: "100%" }}
            >
              <Button
                sx={{
                  color: "#FFF",
                  background: "rgba(255, 255, 255, 0.2)",
                  fontSize: "15px",
                  border: "none",
                  textTransform: "none",
                  fontWeight: "bold",
                  margin: 1,
                  whiteSpace: "nowrap",
                  paddingY: 0,
                }}
                variant="contained"
              >
                🎓 Looking for a mentor
              </Button>
              <Button
                sx={{
                  color: "#FFF",
                  background: "rgba(255, 255, 255, 0.2)",
                  fontSize: "15px",
                  border: "none",
                  textTransform: "none",
                  fontWeight: "bold",
                  margin: 1,
                  whiteSpace: "nowrap",
                  paddingY: 0,
                }}
                variant="contained"
              >
                🍻 In-person hangouts
              </Button>
              <Button
                sx={{
                  color: "#FFF",
                  background: "rgba(255, 255, 255, 0.2)",
                  fontSize: "15px",
                  border: "none",
                  textTransform: "none",
                  fontWeight: "bold",
                  margin: 1,
                  whiteSpace: "nowrap",
                  paddingY: 0,
                }}
                variant="contained"
              >
                🗂️ Looking for side-project partners
              </Button>
              <Button
                sx={{
                  color: "#FFF",
                  background: "rgba(255, 255, 255, 0.2)",
                  fontSize: "15px",
                  border: "none",
                  textTransform: "none",
                  fontWeight: "bold",
                  margin: 1,
                  whiteSpace: "nowrap",
                  paddingY: 0,
                }}
                variant="contained"
              >
                💡 Career help/promotion advice
              </Button>
            </Stack>
            <Box sx={{ width: "100%" }}>
              <Typography
                variant="body1"
                sx={{
                  fontWeight: "300",
                  color: "#FFF",
                  textAlign: "left",
                  padding: 1,
                }}
              >
                Personalize your profile to display to others exactly what you
                are interested in using Stratos for. Let what you are looking
                for come to you!
              </Typography>
            </Box>
          </Stack>

          <Stack
            direction="column"
            spacing={3}
            justifyContent="center"
            alignItems={"center"}
            sx={{
              width: "100%",
              background: "linear-gradient(96.34deg, #52B1BB, #008997 100%)",
              borderRadius: "10px",
              paddingX: 1,
              paddingY: 4,
              margin: 1,
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontWeight: "600",
                color: "#FFF",
                padding: 1,
                textAlign: "center",
              }}
            >
              Prompts + Responses
            </Typography>
            <Box
              sx={{
                background: "#FFF",
                borderRadius: "11px",
                padding: 2,
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  fontWeight: "600",
                  color: "#black",
                  padding: 1,
                  textAlign: "left",
                  fontSize: "10px",
                  whiteSpace: "nowrap",
                }}
              >
                choose a prompt
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: "#818181",
                  padding: 1,
                  fontWeight: 400,
                  textAlign: "left",
                  borderBottom: "1px solid #E8E8E8",
                  fontSize: "10px",
                  whiteSpace: "nowrap",
                }}
              >
                A win in my career
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: "#818181",
                  padding: 1,
                  fontWeight: 400,
                  textAlign: "left",
                  borderBottom: "1px solid #E8E8E8",
                  fontSize: "10px",
                  whiteSpace: "nowrap",
                }}
              >
                What is your life goal?
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: "#818181",
                  padding: 1,
                  fontWeight: 400,
                  textAlign: "left",
                  borderBottom: "1px solid #E8E8E8",
                  fontSize: "10px",
                  whiteSpace: "nowrap",
                }}
              >
                How to balance rigorous workload and social life?
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: "#000",
                  padding: 1,
                  fontWeight: 400,
                  background: "#DBF1F2",
                  textAlign: "left",
                  fontSize: "10px",
                  whiteSpace: "nowrap",
                }}
              >
                How I avoid spreading myself thin….
              </Typography>
            </Box>
            <Box>
              <Typography
                variant="body1"
                sx={{
                  fontWeight: "300",
                  color: "#FFF",
                  textAlign: "left",
                  padding: 1,
                }}
              >
                We utilize prompts to facilitate frequent conversation and
                knowledge sharing in our community. Posts are organized by
                layer, so users can easily find conversations that are relevant
                to them.
              </Typography>
            </Box>
          </Stack>

          <Stack
            direction="column"
            spacing={3}
            justifyContent="center"
            alignItems={"center"}
            sx={{
              width: "100%",
              background: "linear-gradient(96.34deg, #52B1BB, #008997 100%)",
              borderRadius: "10px",
              paddingX: 1,
              paddingY: 4,
              margin: 1,
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontWeight: "600",
                color: "#FFF",
                padding: 1,
                textAlign: "center",
              }}
            >
              Stratos Spotlight
            </Typography>

            <Box sx={{ width: "100%" }}>
              <Avatar
                alt="Stratos Spotlight"
                src={StratosSpotlight}
                sx={{
                  display: "flex",
                  borderRadius: 0,
                  bgcolor: "transparent",
                  width: "100%",
                  height: "auto",
                }}
                imgProps={{
                  style: { objectFit: "contain" },
                }}
              />
            </Box>
            <Typography
              variant="body1"
              sx={{
                fontWeight: "300",
                color: "#FFF",
                textAlign: "left",
                padding: 1,
              }}
            >
              Learn from and connect with industry experts in our exclusive
              sessions offered by Stratos.
            </Typography>
          </Stack>

          <Stack
            direction="column"
            spacing={3}
            justifyContent="center"
            alignItems={"center"}
            sx={{
              width: "100%",
              background: "linear-gradient(96.34deg, #52B1BB, #008997 100%)",
              borderRadius: "10px",
              paddingX: 1,
              paddingY: 4,
              margin: 1,
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontWeight: "600",
                color: "#FFF",
                padding: 1,
                textAlign: "center",
              }}
            >
              Direct Messaging
            </Typography>

            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={1}
              sx={{ width: "100%" }}
            >
              <Avatar
                alt="Stratos Spotlight"
                src={DM1}
                sx={{
                  display: "flex",
                  borderRadius: 0,
                  bgcolor: "transparent",
                  width: "100%",
                  height: "auto",
                }}
                imgProps={{
                  style: { objectFit: "contain" },
                }}
              />
              <Avatar
                alt="Stratos Spotlight"
                src={DM2}
                sx={{
                  display: "flex",
                  borderRadius: 0,
                  bgcolor: "transparent",
                  width: "100%",
                  height: "auto",
                }}
                imgProps={{
                  style: { objectFit: "contain" },
                }}
              />
            </Stack>
            <Typography
              variant="body1"
              sx={{
                fontWeight: "300",
                color: "#FFF",
                textAlign: "left",
                padding: 1,
              }}
            >
              Connect directly with other employees, professionals, and mentors
              in our community.
            </Typography>
          </Stack>
        </Box>

        <Box
          sx={{
            background: "linear-gradient(96.34deg, #52B1BB, #008997 100%)",
            display: { xs: "flex", sm: "flex", md: "none" },
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            paddingX: 1,
            paddingY: 4, // Adjust the vertical padding
            borderRadius: "10px",
          }}
        >
          <Typography
            variant="h3"
            sx={{
              fontWeight: "600",

              color: "#FFF",
              padding: 1,
              textAlign: "left",
            }}
          >
            join the community today
          </Typography>
          <Box>
            <Avatar
              alt="career"
              src={CareerLP}
              sx={{
                display: "flex",
                borderRadius: 0,
                bgcolor: "transparent",
                width: "80%",
                height: "auto",
                padding: 2,
              }}
              imgProps={{
                style: { objectFit: "contain" },
              }}
            />
          </Box>

          <Box>
            <Typography
              variant="h5"
              sx={{
                fontWeight: "400",
                color: "#FFF",
                textAlign: "left",
                padding: 1,
              }}
            >
              Stratos offers you the chance to explore new opportunities and
              connect with like-minded professionals. Unlock your career
              potential today!
            </Typography>

            <Button
              sx={{
                color: "#FFFFFF",
                display: "block",
                background: "#008997",
                borderRadius: "14px",
                fontSize: "15px",
                height: "40px",
                cursor: "pointer",
                border: "none",
                textTransform: "none",
                margin: 1,
              }}
              variant="contained"
              onClick={handleClickLogin}
            >
              <span style={{ verticalAlign: "middle" }}>Get Started Now</span>
              <ArrowForward
                style={{
                  verticalAlign: "middle",
                  paddingLeft: 0.5,
                }}
              />
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Product;
