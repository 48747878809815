import {
    Avatar,
    CardHeader,
    IconButton,
    Paper,
    Typography,
    Button,
    CardContent,
    CardActions,
    Card,
    Divider,
    Skeleton,
} from "@mui/material";
import React from "react";

const PostSkeleton = () => {
    return (
        <Paper elevation={0} sx={{ borderRadius: "15px", margin: "20px" }}>
            <Card sx={{ width: "100%", borderRadius: "15px" }}>
                <CardHeader
                    sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        padding: "0px",
                        paddingLeft: "16px",
                        paddingRight: "16px",
                        paddingTop: "16px",
                    }}
                    avatar={
                        <Skeleton
                            animation="wave"
                            variant="circular"
                            width={50}
                            height={50}
                            sx={{
                                width: "50px",
                                height: "50px",
                                borderRadius: "100%",
                                margin: "0px",
                                cursor: "pointer",
                            }}
                        />
                    }
                    title={
                        <Skeleton
                            animation="wave"
                            height={10}
                            width="20%"
                            style={{ marginBottom: 6 }}
                        />
                    }
                    subheader={
                        <Skeleton animation="wave" height={10} width="20%" />
                    }
                />
                <CardContent
                    sx={{
                        paddingLeft: "16px",
                        paddingRight: "16px",
                        paddingTop: "0px",
                        paddingBottom: "0px",
                    }}
                >
                    <React.Fragment>
                        <Skeleton animation="wave" height={18} width="40%" />
                        <Skeleton animation="wave" height={16} width="100%" />
                        <Skeleton animation="wave" height={16} width="100%" />
                        <Skeleton animation="wave" height={16} width="100%" />
                    </React.Fragment>
                </CardContent>
            </Card>
        </Paper>
    );
};

export default PostSkeleton;
