import React from 'react'
import { MdTimelapse } from "react-icons/md"
import {GoLocation} from 'react-icons/go'
import { RxCross2 } from "react-icons/rx"
import { GiPencil } from "react-icons/gi"
import {BiBuildings} from "react-icons/bi"

import companylogo from "./company-logo.jpg"

import "./JobPage.css"
import JobsInfo from './JobsInfo'
import JobSettings from './JobSettings/JobSettings'
import { Tab, Tabs, Box } from '@mui/material'
import YourPosts from './JobPost/YourPosts'
import Pipeline from './Pipeline/Pipeline'


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      style={{backgroundColor:'#F0F3F5', border: 'none', width: '100%'}}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box  style={{border: 'none', width: '100%'}}>
        {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


const JobPage = () =>
{

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className='container'>
      <div className='job-container'>

      <div className='job-details'>
        <div className='job-details_position'>
          <img className='company-image' src={companylogo} alt='company logo' />
          <div className='job-details_position-info'>
          <h4>Head of Product Design</h4>
            <div className='job-details_position-info-time'>
              <p style={{color: "rgb(147, 147, 147)"}}>December 18, 2022</p>
             
              <p> <span> <MdTimelapse size={15} className='icon' color="#52B1BB" /></span></p>
              <p style={{color:'#52B1BB', marginLeft:'3px'}}>Ends in 15 days</p>
            </div>
            
            </div>
          </div>

          <JobsInfo title="Location" subtitle="Atlanta, Georgia, United States" Icon = {GoLocation} />
          <JobsInfo title="Work Place Environment" subtitle="On-Site" Icon={BiBuildings} />
          
          <div className='job-edit'>
            <div className='job-edit_cross'>
              <button>
              <RxCross2 className='icon' />
              </button>
            </div>
            <div className='job-edit_pen'>
              <button>
                
              <GiPencil className='icon' />
             </button>
              
            </div>

          </div>

        </div>

        <Box className='jobpage-tab'>
      <Box classes='tabs'>
        <Tabs  value={value} onChange={handleChange} aria-label="basic tabs example" className='job-nav'>
          <Tab className='tab' label="Pipeline" {...a11yProps(0)} />
          <Tab className='tab' label="Your Posts" {...a11yProps(1)} />
          <Tab className='tab' label="Job Settings" {...a11yProps(2)} />
        </Tabs>
          </Box>
          
      <TabPanel className="tab-pannel" value={value} index={0}>
        <Pipeline />
      </TabPanel>
      <TabPanel className="tab-pannel" value={value} index={1}>
        <YourPosts />
      </TabPanel>
      <TabPanel className="tab-pannel" value={value} index={2}> 
       <JobSettings />
      </TabPanel>
    </Box>
        
        {/*<div className='job-nav'>
          
        <button>Pipeline</button>
        <button>Your Post</button>
        <button>Job Settings</button>

    </div> */}
      </div>
    

     
      </div>
      
  )
}

export default JobPage