import MenuIcon from "@mui/icons-material/Menu";
import { Tab, Tabs } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import stratosLong from "../../Assets/stratos_long_3.png";

const pages = ["Home", "About", "Investors", "FAQ"];

const Navbar = ({ tabValue, setTabValue }) => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const [selectedMenu, setSelectedMenu] = React.useState(0);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleTabChange = (event, newValue) => {
    console.log("new tab", newValue);
    setTabValue(newValue);
  };

  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const handleClickLogin = () => {
    navigate("/Welcome");
  };

  return (
    <AppBar
      position="sticky"
      sx={{
        backgroundColor: "rgba(255, 255, 255, 0.9)",
        color: "#6FBDC9",
        paddingX: { xs: 3, sm: 3, md: 7 },
        paddingY: 1,
        boxShadow: 0,
        backdropFilter: "blur(5px)",
        WebkitBackdropFilter: "blur(5px)",
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Avatar
            alt="stratos logo"
            src={stratosLong}
            sx={{
              display: { xs: "none", md: "flex" },
              borderRadius: 0,
              bgcolor: "transparent",
              width: "300px",
              height: "60px",
            }}
            imgProps={{
              style: { objectFit: "contain" },
            }}
          />

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "flex", md: "none" },
              justifyContent: "flex-start",
            }}
          >
            <IconButton onClick={handleOpenNavMenu} color="inherit">
              <MenuIcon sx={{ fontSize: "40px" }} />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page, index) => (
                <MenuItem
                  key={page}
                  onClick={() => {
                    setTabValue(index);
                    handleCloseNavMenu();
                  }}
                  sx={{ fontFamily: "Roboto" }}
                >
                  <Typography
                    textAlign="center"
                    sx={{
                      color: tabValue == index ? "#52B1BB" : "#16171B",
                      fontWeight: 500,
                      letterSpacing: "-0.03em",
                      fontSize: "15px",
                    }}
                  >
                    {page}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexGrow: 1,
              justifyContent: "flex-start",
            }}
          >
            <Avatar
              alt="stratos logo"
              src={stratosLong}
              sx={{
                display: { xs: "flex", md: "none" },
                borderRadius: 0,
                bgcolor: "transparent",
                width: "300px",
                height: "60px",
              }}
              imgProps={{
                style: { objectFit: "contain" },
              }}
            />
          </Box>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              justifyContent: "flex-end",
            }}
          >
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              indicatorColor="transparent"
            >
              {pages.map((page) => (
                <Tab
                  key={page}
                  sx={{
                    my: 2,
                    display: "block",
                    color: "#16171B",
                    textTransform: "none",
                    fontWeight: 500,
                    lineHeight: "20px",
                    letterSpacing: "-0.03em",
                    fontSize: "15px",
                    "& .MuiTab-root": {
                      fontFamily: "Roboto",
                    },
                  }}
                  label={page}
                  component="a"
                  href={`/${page}`}
                  onClick={(event) => {
                    event.preventDefault();
                  }}
                />
              ))}
            </Tabs>
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Button
              sx={{
                my: 2,
                ml: 1,
                color: "#FFFFFF",
                display: "block",
                background: "linear-gradient(96.34deg, #52B1BB, #008997 100%)",
                borderRadius: "32px",
                paddingY: 1,
                paddingX: 3,
                fontSize: "15px",
                gap: "8px",
                cursor: "pointer",
                width: "120px",
                height: "40px",
                border: "none",
                textTransform: "none",
              }}
              variant="contained"
              onClick={handleClickLogin}
            >
              Login
            </Button>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default Navbar;
