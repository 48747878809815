import React from "react";

import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

const CommunityGuidelines = ({ handleClose, open }) => {
  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle
        style={{
          textAlign: "center",
        }}
      >
        Stratos Community Guidelines
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          style={{
            textAlign: "center",
          }}
        >
          Stratos is a community that welcomes all voices, but that does not
          mean users can say whatever they want. In order for us to ensure that
          everyone has a safe and enjoyable experience, we are not allowing the
          following: hate content, inciting or glorifying violence, promoting
          self-harm, harassing others, posting sexually explicit content, or
          violating Stratos Terms of Services. If any users violate these rules
          result in your content being deleted. If a user repeatedly violates
          these rules will result in account suspension or termination.
        </DialogContentText>
        <DialogContentText
          sx={{
            color: "#52B1BB",
            fontWeight: "bolder",
            textAlign: "center",
            paddingTop: 2,
            paddingBottom: 1,
          }}
        >
          We do not allow:
        </DialogContentText>
        <DialogContentText
          style={{
            textAlign: "center",
          }}
        >
          - Racist comments
        </DialogContentText>
        <DialogContentText
          style={{
            textAlign: "center",
          }}
        >
          - Violence
        </DialogContentText>
        <DialogContentText
          style={{
            textAlign: "center",
          }}
        >
          - Classism
        </DialogContentText>
        <DialogContentText
          style={{
            textAlign: "center",
          }}
        >
          - Hate speech
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};
export default CommunityGuidelines;
