import axios from "axios";

const API = axios.create({
  baseURL: "https://us-central1-stratosv1.cloudfunctions.net/api",
});

// api url: https://us-central1-stratosv1.cloudfunctions.net/api
// localhost serving: http://127.0.0.1:5001/stratosv1/us-central1/api
// Emulator UI: http://127.0.0.1:4000
// Web App: http://localhost:8080/

// get surveys db
const getSurvey = async (userId, token) => {
  console.log("userId in service", userId);
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await API.post("/surveys", userId, config);
  let result = response.data;
  return result;
};

// respond to survey
const surveyResponse = async (surveyData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await API.post("/surveyResponses", surveyData, config);
  let result = response.data;
  return result;
};

const surveysService = { getSurvey, surveyResponse };

export default surveysService;
