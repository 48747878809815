import SendIcon from "@mui/icons-material/Send";
import SentimentSatisfiedAltOutlinedIcon from "@mui/icons-material/SentimentSatisfiedAltOutlined";
import {
  Box,
  Button,
  IconButton,
  Popover,
  Stack,
  TextField,
} from "@mui/material";
import EmojiPicker from "emoji-picker-react";
import React, { memo, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TrackDirectMessage } from "../../../MixPanel";
import {
  createMessage,
  getAllMessages,
  resetChatMessages,
  updateLastMessage,
} from "../../../features/messages/messageSlice";
import MessageListCard from "./MessageListCard";

const MessagesComponent = ({ currentChatUser }) => {
  //const { currentChatUser } = useSelector((state) => state.messages);
  const otherUserAvatar = currentChatUser.otherUserData.profileImage.url;
  const otherUserData = currentChatUser.otherUserData;
  const otherUser = currentChatUser.otherUser;
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { chatMessages } = useSelector((state) => state.messages);

  console.log("chatMessages", chatMessages);

  useEffect(() => {
    dispatch(resetChatMessages());
    dispatch(getAllMessages(otherUser));
  }, []);

  const [tempChatMessages, setTempChatMessages] = useState([]);
  useEffect(() => {
    setTempChatMessages(chatMessages);
  }, [chatMessages]);

  //useEffect(() => {
  //    if (currentChatUser && tempChatMessages.length > 0) {
  //        const updatedChatUser = { ...currentChatUser };
  //        updatedChatUser.lastMessage = {
  //            at: tempChatMessages[tempChatMessages.length - 1].createdAt,
  //            by: tempChatMessages[tempChatMessages.length - 1].author.name,
  //            by_ID: tempChatMessages[tempChatMessages.length - 1].author.id,
  //            content: tempChatMessages[tempChatMessages.length - 1].content,
  //        };
  //        setCurrentChatUser(updatedChatUser);
  //    }
  //}, [currentChatUser]);

  const [newMessage, setNewMessage] = useState("");

  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  const handleNewMessage = () => {
    setTempChatMessages([
      ...tempChatMessages,
      {
        createdAt: new Date().toISOString(),
        content: newMessage,
        chatID: currentChatUser.id,
        author: { id: user.userId, name: user.name, firstName: user.firstName },
      },
    ]);
    TrackDirectMessage(
      user.name,
      user.userId,
      user.jobTitle,
      user.currentCompany,
      otherUserData.name,
      otherUser,
      otherUserData.jobTitle,
      otherUserData.currentCompany
    );
    dispatch(createMessage({ otherUser, content: newMessage }))
      .then(() => {
        dispatch(updateLastMessage({ currentChatId: currentChatUser.id }));
      })
      .catch((error) => {
        console.log(error);
      });
    setNewMessage("");
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleNewMessage();
    }
  };

  const messagesContainerRef = useRef(null);

  useEffect(() => {
    scrollToBottom();
  }, [tempChatMessages]);

  const scrollToBottom = () => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop =
        messagesContainerRef.current.scrollHeight;
    }
  };

  // to toggle emoji popover
  const [anchorEl, setAnchorEl] = useState(null);

  const handleEmojiClick = (emojiObject, event) => {
    const emoji = emojiObject.emoji;
    setNewMessage((prevMessage) => prevMessage + emoji);
    handlePopoverClose();
  };
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "90%" }}>
      <Box sx={{ flex: 1, overflow: "auto" }} ref={messagesContainerRef}>
        <Stack direction={"column"} sx={{ width: "100%" }}>
          {tempChatMessages &&
            tempChatMessages.map((msg, index) => (
              <MessageListCard
                key={msg.id ? msg.id : index}
                message={msg}
                avatar={
                  msg.author.id == user.userId
                    ? user.profileImage.url
                    : otherUserAvatar
                }
                sender={msg.author.id == user.userId ? "me" : "other"}
              />
            ))}
        </Stack>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          border: 0,
          borderTop: "1px",
          borderStyle: "solid",
          borderRadius: 0,
          borderColor: "#CACACA",
          paddingX: 1,
          paddingY: 1,
        }}
      >
        <TextField
          variant="outlined"
          multiline
          fullWidth
          minRows={1}
          maxRows={4}
          value={newMessage}
          onKeyPress={handleKeyPress}
          placeholder="Type to add your comment"
          onChange={(e) => {
            e.preventDefault();
            setNewMessage(e.target.value);
          }}
          size="small"
          sx={{
            background: "#F3F5FC",
            borderRadius: "50px",
            marginRight: 1,
            paddingY: 0.25,
            paddingX: 1,
            "& .MuiOutlinedInput-root": {
              border: "none",
              paddingY: 0.25,
              paddingX: 1,
            },
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            "&:hover .MuiOutlinedInput-root": {
              border: "none",
            },
          }}
          InputProps={{
            endAdornment: (
              <IconButton
                onClick={(event) => {
                  event.preventDefault();
                  handlePopoverOpen(event);
                }}
                edge="end"
              >
                <SentimentSatisfiedAltOutlinedIcon />
              </IconButton>
            ),
            style: {
              fontSize: 12,
              fontWeight: 500,
              color: "#818181",
            },
          }}
        />
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <EmojiPicker
            onEmojiClick={handleEmojiClick}
            previewConfig={{ showPreview: false }}
          />
        </Popover>

        <Button
          variant="contained"
          color="primary"
          onClick={handleNewMessage}
          disabled={
            !newMessage || newMessage.trim().length === 0 ? true : false
          }
          sx={{
            padding: "13px",
            minWidth: 44,
            minHeight: 4,
            borderRadius: "15px",
          }}
        >
          <SendIcon fontSize="60px" />
        </Button>
      </Box>
    </Box>
  );
};

export default memo(MessagesComponent);

//{moment(new Date(chat.lastMessage.at) ).fromNow()}
