import { Avatar, CardHeader, Typography } from "@mui/material";
import React from "react";

const UserDataCard = ({ chat }) => {
  const publicName = chat.otherUserData.firstName || chat.otherUserData.name;

  return (
    <CardHeader
      sx={{
        "& .MuiCardHeader-avatar": {
          marginRight: "4px",
          marginTop: "4px",
        },
        padding: 1,
      }}
      avatar={
        <Avatar
          sx={{
            width: "30px",
            height: "30px",
          }}
          src={chat.otherUserData.profileImage.url}
        />
      }
      title={
        <Typography
          variant={"body2"}
          fontSize={13}
          sx={{
            marginTop: "4px",
          }}
        >
          {publicName}
        </Typography>
      }
      subheader={
        <Typography
          variant="subtitle1"
          sx={{
            color: "#CACACA",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
          fontSize={11}
        >
          {chat.otherUserData.jobtitle != "" && chat.otherUserData.jobtitle}
          {chat.otherUserData.currentCompany != "" &&
            ` @ ${chat.otherUserData.currentCompany}`}
        </Typography>
      }
    />
  );
};

export default UserDataCard;
