import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Box, Button, Slider, Stack } from "@mui/material";
import React, { useRef, useState } from "react";
import AvatarEditor from "react-avatar-editor";
import { useDispatch } from "react-redux";
import { uploadImage } from "../../features/auth/authSlice";

const AddNewProfile = ({ handleNext }) => {
  const [profilePic, setProfilePic] = useState("");
  const [saveProfilePic, setSaveProfilePic] = useState("");
  const dispatch = useDispatch();

  const handleUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = (event) => {
      setProfilePic(event.target.result);
    };
    setSaveProfilePic(file);
  };

  const onClickSave = () => {
    const image = editor.current.getImageScaledToCanvas().toDataURL();
  };

  const DataURIToBlob = (dataURI) => {
    const splitDataURI = dataURI.split(",");
    const byteString =
      splitDataURI[0].indexOf("base64") >= 0
        ? atob(splitDataURI[1])
        : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i);

    return new Blob([ia], { type: mimeString });
  };

  const handleSave = () => {
    // Get the cropped image data
    const image = editor.current.getImageScaledToCanvas().toDataURL();
    const file = DataURIToBlob(image);
    // Save the cropped image data to the state variable
    setProfilePic(file);
    let formData = new FormData();
    formData.append("file", file, "image.png");
    for (let [name, value] of formData) {
    }
    dispatch(uploadImage(formData));
  };

  // for avatar editor
  const editor = useRef(null);
  const [zoom, setZoom] = useState(1);
  const handleZoomChange = (event, newValue) => {
    setZoom(newValue);
  };

  return (
    <Stack
      direction={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      spacing={2}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <input type="file" onChange={handleUpload} />
      </Box>
      <AvatarEditor
        ref={editor}
        image={profilePic}
        width={150}
        height={150}
        borderRadius={100}
        scale={zoom}
      />
      <Box sx={{ width: "60%" }}>
        <Stack spacing={2} direction="row">
          <RemoveIcon />
          <Slider
            min={1}
            max={3}
            step={0.1}
            value={zoom}
            onChange={handleZoomChange}
          />
          <AddIcon />
        </Stack>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Button sx={{ textTransform: "none" }} onClick={handleNext}>
            Skip
          </Button>
          <Button sx={{ textTransform: "none" }} onClick={handleSave}>
            Save
          </Button>
        </Box>
      </Box>
    </Stack>
  );
};

export default AddNewProfile;
