import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React from "react";

const Filter = ({
  allPossibleOptions,
  filterSelections,
  setFilterSelections,
  filterPlaceholder,
}) => {
  const handleChangeSelect = (event) => {
    const {
      target: { value },
    } = event;
    console.log(value);
    setFilterSelections(value);
  };

  return (
    <Box
      sx={{
        width: "120px",
        display: "flex",
        flexDirection: "column",
        m: 1,
        justifyContent: "flex-start",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          background: "white",
          width: "120px",
          display: "flex",
          flexDirection: "column",
          height: "auto",
          alignItems: "center",
          borderRadius: "27px",
        }}
      >
        <FormControl fullWidth>
          <InputLabel
            id="demo-simple-select-label"
            shrink={false}
            sx={{
              fontSize: "13px",
            }}
          >
            {filterSelections.length === 0 && filterPlaceholder}
          </InputLabel>
          <Select
            sx={{
              color: "#52B1BB",
              borderRadius: "27px",
            }}
            multiple
            value={filterSelections ? filterSelections : []}
            onChange={handleChangeSelect}
            displayEmpty
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            renderValue={(selected) => (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 0.5,
                }}
              >
                {selected.map((value) => (
                  <Chip key={value} label={value} />
                ))}
              </Box>
            )}
          >
            {allPossibleOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
};

export default Filter;
