import { Avatar, Button, CardMedia, TextField, Typography } from '@mui/material'
import { Box, Stack } from '@mui/system'
import React from 'react'
import { Link } from 'react-router-dom';

import LoginPagePic from "../../../../Assets/LoginPageWeb.png";
import StratosLogo from "../../../../Assets/stratos-logo.png";

const LoginPage = () =>
{
    
  return (
      <Stack direction={"row"} justifyContent="space-between" bgcolor={"#ECF4F6"} height="100vh">
          <Box display="flex" flexDirection="column" flexGrow={1}>
              <Box display="flex" flexDirection="row" padding={5} >
                  <Avatar variant='square' src={StratosLogo} sx={{marginRight:"10px", height:27.33, width:53.33}} alt="logo stratos" />
                  <Typography fontWeight="500" fontSize={20}>STRATOS</Typography>
        </Box>
        
        <Box padding={5} display="flex" flexDirection="column">
          <Typography fontSize="32px" fontWeight="400" color="#88B3BA">Welcome to Stratos 👋🏻,</Typography>
          <Typography fontSize="36px" fontWeight="600">Please Login</Typography>
        </Box>

        <Box padding={5} display="flex" flexDirection="column" alignItems="flex-start" justifyContent="flex-start">
          <TextField variant='outlined' label="Email" type="email" fullWidth
          sx={{ marginBottom:3, width:"80%"}}
            inputProps={{
              style:{ height:4}
            }}
          />

          <TextField variant='outlined' label="Password" type="password" fullWidth
          sx={{width:"80%"}}
          inputProps={{
              style:{ height:4}
            }}
            
          />
            <Typography mt={1} color="#6FBDC9">Forget Password?</Typography>
          <Button variant="contained" sx={{ width: "80%", marginTop: 3 }} >Login</Button>
          <Typography mt={3} color="#949AA7">Don't have an account? <a href='#' style={{color:"black", marginLeft:"5px", textDecoration:"none", border:"none", fontWeight:'600'}}>Sign Up </a></Typography>
          </Box>
          </Box>
      <Box height="100vh" sx={{"@media (max-width:500px)":{
        display:'none'
      }}}>
          <CardMedia component="img" image={LoginPagePic} height="100%" />
    </Box>
    </Stack>
  )
}

export default LoginPage