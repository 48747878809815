import React from 'react'

// import {AuthContext} from "../"

const UserDMNavbar = () => {
    // const {currentUser} = useContext(AuthContext)
    return (
        <div className='navbardm'>
            <span className='logo'>Stratos Chat</span>
            <div className='user'>
                <img src="https://images.pexels.com/photos/15127334/pexels-photo-15127334.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" />
                <span>David</span>
                <button>logout</button>
            </div>
             
        </div>
    )
}

export default UserDMNavbar;