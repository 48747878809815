import React from "react";

import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";

const ChooseUserType = ({ userType, setUserType }) => {
  // to handle type select change
  const handleTypeChange = (event) => {
    setUserType(event.target.value);
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Typography
        sx={{ fontSize: 15, marginTop: "20px", marginBottom: "25px" }}
      >
        Are you an ERG Lead or Member?
      </Typography>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">select a type</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label="select a type"
          value={userType}
          onChange={handleTypeChange}
        >
          <MenuItem value={"ERG Member"}>ERG Member</MenuItem>
          <MenuItem value={"ERG Lead"}>ERG Lead</MenuItem>
          <MenuItem value={"Other"}>Other</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};
export default ChooseUserType;
