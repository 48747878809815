import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Input,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../../Assets/logo.png";
import { LoginMixPanel, TrackRegister } from "../../MixPanel";
import Spinner from "../../components/Spinner";
import {
  login,
  reset,
  signInWithGoogle,
  updateUserPersonal,
} from "../../features/auth/authSlice";
import ForgotPassword from "./ForgotPassword";

const Login = () => {
  const { isLoading, isSuccess, isError, message, user } = useSelector(
    (state) => state.auth
  );
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const [showErrorAlert, setShowErrorAlert] = useState(false);
  // forgot passwords things
  const [showSuccess, setShowSuccess] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [forgotPassEmail, setForgotPassEmail] = useState("");

  const [userData, setUserData] = useState({
    email: "",
    password: "",
  });

  const { email, password } = userData;

  // get ref link from url if it exists
  const params = new URLSearchParams(location.search);

  const handleCredentialResponse = (res) => {
    const refCode = params.get("ref");
    dispatch(signInWithGoogle({ googleToken: res.credential, refCode }));
  };

  useEffect(() => {
    /*global google */
    google.accounts.id.initialize({
      client_id:
        "261842456718-oon2niaduaiumkfq6g0srtea82umgg3v.apps.googleusercontent.com",
      callback: handleCredentialResponse,
    });

    const containerWidth =
      document.getElementById("GoogleSignInButton").parentElement.clientWidth;
    google.accounts.id.renderButton(
      document.getElementById("GoogleSignInButton"),
      {
        theme: "outline",
        size: "large",
        shape: "rectangular",
        width: containerWidth.toString(),
      }
    );
  }, []);

  const signInUser = (event) => {
    event.preventDefault();
    const userData = { email, password };
    LoginMixPanel(email);
    //dispatch signIn
    dispatch(login(userData));
  };

  const onChangeInput = (e) => {
    setUserData((inputTexts) => ({
      ...inputTexts,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(() => {
    // sign up is false, dispatch login
    if (isError) {
      setShowErrorAlert(true);
    }
    if (isSuccess) {
      if (!user) {
        setShowForgotPassword(false);
        setShowSuccess(true);
      } else if (!user.isNewUser) {
        LoginMixPanel(user.email);
        const lastActive = new Date().toISOString();
        const newUserData = {
          lastActive,
        };

        console.log("update activity", newUserData);
        dispatch(updateUserPersonal({ newUserData }));
        if (user.birthDate === "" || user.city === "") {
          dispatch(reset());
          navigate("/HomeScreen", {
            state: { showOnboarding: true },
          });
        } else {
          dispatch(reset());
          navigate("/HomeScreen", {
            state: { showOnboarding: false },
          });
        }
      } else {
        if (user.isNewUser) {
          TrackRegister(
            user.name,
            user.email,
            user.jobTitle,
            user.currentCompany
          );
          dispatch(reset());
          navigate("/loginauth");
        } else {
          LoginMixPanel(email);
          const lastActive = new Date().toISOString();
          const newUserData = {
            lastActive,
          };
          console.log("update activity", newUserData);
          dispatch(updateUserPersonal({ newUserData }));
          dispatch(reset());
          navigate("/HomeScreen");
        }
      }
      if (isError) {
        setShowErrorAlert(true);
      }
    }
  }, [isSuccess, isError]);

  if (isLoading) {
    return <Spinner />;
  }

  const ErrorAlert = () => {
    return (
      <Dialog
        open={showErrorAlert}
        onClose={() => {
          console.log("alert close");
          dispatch(reset());
          setShowErrorAlert(false);
          // navigate("/");
        }}
      >
        <DialogTitle>{"Error"}</DialogTitle>
        <DialogContent>
          <DialogContentText>{"Error Details: " + message}</DialogContentText>
        </DialogContent>
      </Dialog>
    );
  };

  const handleClickSignUp = () => {
    navigate("/SignUp");
  };

  const handleForgotPassword = () => {
    setForgotPassEmail(email);
    setShowForgotPassword(true);
  };

  return (
    <Box className="signup2-container">
      {/* Success Dialog Box */}
      {showSuccess && (
        <Dialog
          open={showSuccess}
          onClose={() => {
            console.log("alert close");
            dispatch(reset());
            setShowSuccess(false);
          }}
        >
          <DialogTitle textAlign={"center"}>{"Success ✨"}</DialogTitle>
          <DialogContent>
            <DialogContentText textAlign={"center"}>
              {"An email for resetting your password has been sent to " + email}
            </DialogContentText>{" "}
          </DialogContent>
        </Dialog>
      )}
      {isError && <ErrorAlert />}
      <ForgotPassword
        forgotPasswordEmail={forgotPassEmail}
        setForgotPasswordEmail={setForgotPassEmail}
        showForgotPassword={showForgotPassword}
        setShowForgotPassword={setShowForgotPassword}
      />
      <img src={logo} alt="logo" />
      <Paper className="signup2-form-container">
        <Stack
          direction={"column"}
          width={"100%"}
          alignItems={"center"}
          spacing={2}
        >
          <Typography color={"#52B1BB"} fontSize="18px" fontWeight={"800px"}>
            Login
          </Typography>
          <Input
            type="email"
            fullWidth
            placeholder="Email"
            inputProps={{
              style: {
                fontSize: "17px",
              },
            }}
            name="email"
            value={email}
            onChange={onChangeInput}
          />
          <Input
            type="password"
            fullWidth
            placeholder="Password"
            inputProps={{
              style: {
                fontSize: "17px",
              },
            }}
            name="password"
            value={password}
            onChange={onChangeInput}
          />

          <div id="GoogleSignInButton"></div>

          <Button
            onClick={signInUser}
            variant="contained"
            fullWidth
            sx={{
              marginTop: 2,
              padding: "8px 50px",
              width: "100%",
            }}
          >
            Login
          </Button>

          <Typography mt={2} mb={2} fontWeight="500" fontSize={16}>
            New to Stratos?{" "}
            <span
              style={{
                textDecoration: "none",
                border: "none",
                color: "#52B1BB",
                fontSize: "14px",
                cursor: "pointer",
              }}
              onClick={handleClickSignUp} // -> this allows sign up [STRAT-22]
              // onClick={() => navigate("/loginauth")} // -> this takes you to blank page if domain not added to oauth [STRAT-23]
            >
              Sign Up
            </span>
          </Typography>
          <Typography
            mt={2}
            mb={2}
            fontWeight="500"
            fontSize={12}
            color="#52B1BB"
            style={{
              cursor: "pointer",
            }}
            onClick={handleForgotPassword}
          >
            Forgot Password
          </Typography>
        </Stack>
      </Paper>
    </Box>
  );
};

export default Login;
