import { Box, Paper, Typography } from '@mui/material'
import React from 'react'
import './YourPosts.css'

const areaOfFocus = ["Design", "Social", "Technology"]

const YourPosts = () => {
  return (
      <Box className='job-post_container'>
          <Paper elevation={0} className='job-post_description'>
              <Box className='job-post_description-desc'>
                  <Typography variant='h6' className='description-heading'>Description:</Typography>
                  <Typography variant='p' className='description-subtitle'>
                  Stratos is hiring a Head of Product to join our design team and help us grow our reach and presence. We are looking Over the last five years, companies prioritizing diversity, equity, and inclusion have erupted. Many of these organizations focus on talent sourcing and creating job boards for minority professionals. Stratos sees that more change needs to be done in this space despite significant progress. Hence, we are making a new way for Black & Latinx professionals to find and connect with each other.Our goal is to revolutionize professional networking by creating a place where Black & Latinx professionals can access career opportunities, mentors, and community networking opportunities.
                  </Typography>
              </Box>

              <Box className='job-post_description-values'>
                  <Typography variant='h6' className='values-heading'>Our Values:</Typography>
                    <br />
                  <Typography variant='p' className='heading'>Deep Listening:</Typography>
                  <Typography variant='p' className='subtitle'>When you speak, we listen. We are willing to receive new information whether pleasant, unpleasant, or neutral.</Typography>
                  <br />
                  <Typography variant='p' className='heading'>Compassionate speech:</Typography>
                  <Typography variant='p' className='subtitle'>Our goal is to uplift with our words.</Typography>
                  <br />
                  <Typography variant='p' className='heading'>Result-oriented:</Typography>
                  <Typography variant='p' className='subtitle'>We believe in taking extreme ownership and prioritizing results.</Typography>
                  <br />
                  <Typography variant='p' className='heading'>Always giving:</Typography>
                  <Typography variant='p' className='subtitle'>We are not takers; we are here to give. The market needs us.</Typography>
                  <br />
                  <Typography variant='p' className='heading'>Customer-focused:</Typography>
                  <Typography variant='p' className='subtitle'>We are customer-obsessed. We exist to innovate on behalf of our customers constantly.</Typography>
                  <br />
                
                  <Box className='company-jd'>
                      
                  <Typography variant='p' className='subtitle'>Stratos is hiring a Head of Product to join our design team and help us grow our reach and presence. We are looking for someone passionate about the intersection between technology and diversity, equity, and inclusion. Over the last five years, companies prioritizing diversity, equity, and inclusion have erupted. Many of these organizations focus on talent sourcing and creating job boards for minority professionals. Stratos sees that more change needs to be done in this space despite significant progress. Hence, we are making a new way for Black & Latinx professionals to find and connect with each other.
                      </Typography>
                  </Box>
                      

              </Box>

              <Box className='job-info'>
                  <Box className='area-of-focus'>
                      <Typography variant='h7' className='job-info-heading'>Area of Focus</Typography>
                      <Box className='tech-container'>
                      {areaOfFocus.map(tech => <Box className='tech'><Typography variant='p' className='tech-subtitle subtitle'>{tech}</Typography></Box>)}
                      </Box>
                  </Box>
                  <Box className='industry'>
                      <Typography variant='h7' className='job-info-heading'>Industry</Typography>
                      <Box className='tech'><Typography variant='p' className='tech-subtitle subtitle'>Social Networking</Typography></Box>
                  </Box>
                  <Box className='workenvironment'>
                  <Typography variant='h7' className='job-info-heading'>Work Environment</Typography>
                      
                      <Box className='tech'><Typography variant='p' className='tech-subtitle subtitle'>Internship </Typography></Box>
                  </Box>
              </Box>
          </Paper>

          <Paper elevation={0} className='job-post_analytics'>
              <Box className='analytics-heading-box'>
              <Typography className='analytics-heading'>Job Analytics</Typography>
              </Box>
              <Box className='analytics'>
                  <Typography className='title'>Views</Typography>
                  <Typography className='number'>500</Typography>
              </Box>

              <Box className='analytics'>
              <Typography className='title'>Applicants</Typography>
                  <Typography className='number'>300</Typography>
              </Box>

              <Box className='analytics'>
              <Typography className='title'>Pipeline</Typography>
                  <Typography className='number'>100</Typography>
              </Box>
           
          </Paper>

    </Box>
  )
}

export default YourPosts