import React from 'react'

import "./JobPage.css"


const JobsInfo = ({title, subtitle, Icon}) => {
  return (
    
    <div className='job-details_location'>
    <div className='job-details_location-icon'>
    <Icon className="icon" size={10} />
    </div>
      <div className='job-details_location-info'>
              <p style={{ color: "rgb(147, 147, 147)" }}>{title}</p>
              <p>{subtitle }</p>
      </div>
  </div>
  )
}

export default JobsInfo