import React from "react";




function AboutStratos() {
    return (
        <div>

            <h1>
                Our Story
            </h1>
            <p1>

            </p1>

            <h1>
                Who We Are
            </h1>
            <p1>

            </p1>

            <h1>
                Our Mission
            </h1>

        </div>
    )
}
export default AboutStratos;