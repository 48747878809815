import {
  Avatar,
  Box,
  Button,
  Paper,
  Stack,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { TrackUserProfileClick } from "../../../MixPanel";
import Spinner from "../../../components/Spinner";
import { getUsers } from "../../../features/auth/authSlice";
import { createChat } from "../../../features/messages/messageSlice";
import { cities, companies, ergs, roles } from "../../constants";
import { sortUsers } from "../../utils";
import Filter from "./Filter";

const Network = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, users, isSuccess, isLoading } = useSelector(
    (state) => state.auth
  );

  // to take user to messaging
  const handleMessageClick = (userId) => {
    dispatch(createChat(userId));
    navigate("/HomeScreen/Messages", {
      state: { chatUser: userId },
    });
  };

  // filter handling
  const [cityFilterSelections, setCityFilterSelections] = useState([]);
  const [companyFilterSelections, setCompanyFilterSelections] = useState([]);
  const [ergFilterSelections, setErgFilterSelections] = useState([]);
  const [roleFilterSelections, setRoleFilterSelections] = useState([]);

  useEffect(() => {
    const usersQuery = {
      cities: cityFilterSelections,
      companies: companyFilterSelections,
      ergs: ergFilterSelections,
      roles: roleFilterSelections,
    };
    dispatch(getUsers({ usersQuery }));
  }, [
    cityFilterSelections,
    companyFilterSelections,
    ergFilterSelections,
    roleFilterSelections,
  ]);

  const sortedUser = sortUsers(users);

  return (
    <Box>
      <Toolbar />
      <Typography
        align="center"
        alignSelf="center"
        variant="h5"
        sx={{
          paddingLeft: 1,
          paddingBottom: 3,
          color: "#52B1BB",
          fontWeight: "700",
          fontSize: 20,
        }}
      >
        Connect in the Stratosphere
      </Typography>
      <Stack
        direction="row"
        justifyContent="center"
        sx={{
          paddingBottom: 3,
        }}
      >
        <Filter
          allPossibleOptions={cities}
          filterSelections={cityFilterSelections}
          setFilterSelections={setCityFilterSelections}
          filterPlaceholder={"Cities"}
        />
        <Filter
          allPossibleOptions={companies}
          filterSelections={companyFilterSelections}
          setFilterSelections={setCompanyFilterSelections}
          filterPlaceholder={"Companies"}
        />
        <Filter
          allPossibleOptions={ergs}
          filterSelections={ergFilterSelections}
          setFilterSelections={setErgFilterSelections}
          filterPlaceholder={"Groups"}
        />
        <Filter
          allPossibleOptions={roles}
          filterSelections={roleFilterSelections}
          setFilterSelections={setRoleFilterSelections}
          filterPlaceholder={"Roles"}
        />
      </Stack>

      <Stack direction="column" justifyContent="center" alignItems="center">
        {sortedUser &&
          sortedUser.map(
            (u) =>
              u.userId !== user.userId && (
                <Paper
                  key={u.userId}
                  sx={{
                    display: "block",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "25px",
                    marginBottom: "15px",
                    width: "90%",
                  }}
                >
                  <Stack direction={{ sm: "column", md: "row" }} spacing={1}>
                    <Tooltip title={u.firstName || u.name}>
                      <Avatar
                        alt={u.firstName || u.name}
                        src={u.profileImage.url}
                        onClick={() => {
                          navigate(
                            `/HomeScreen/Profile/${u.userId}`,
                            { state: { userId: u.userId } },
                            { preventScrollReset: true }
                          );
                          TrackUserProfileClick(
                            user.email,
                            user.userId,
                            u.name,
                            u.userId,
                            u.jobTitle,
                            u.currentCompany
                          );
                        }}
                        sx={{
                          cursor: "pointer",
                          alignItems: "center",
                          justifyContent: "center",
                          verticalAlign: "center",
                        }}
                      />
                    </Tooltip>
                    <Stack width={"20%"} minWidth={"125px"} paddingLeft={0.5}>
                      <Typography
                        variant="h5"
                        sx={{
                          fontWeight: 700,
                          fontSize: "15px",
                          overflow: "hidden",
                        }}
                        color="#1A1C1D"
                      >
                        {u.firstName || u.name}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="#8083A3"
                        sx={{
                          fontSize: "14px",
                          whiteSpace: "pre-wrap",
                          fontWeight: 400,
                          overflow: "hidden",
                        }}
                      >
                        {u.jobTitle && u.currentCompany
                          ? u.jobTitle + " @" + u.currentCompany
                          : ""}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="#74757a" //"#8083A3" more purple, what design used but a little weird irl
                        sx={{
                          fontSize: "14px",
                          whiteSpace: "pre-wrap",
                          fontWeight: 400,
                          overflow: "hidden",
                        }}
                      >
                        {u.state ? u.city + ", " + u.state : u.city}
                      </Typography>
                    </Stack>
                    <Box
                      width={"50%"}
                      minWidth={"250px"}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        marginTop: 3,
                        borderRadius: "4px",
                        padding: 0.5,
                        marginBottom: 3,
                      }}
                    >
                      {u?.interests &&
                        u?.interests.slice(0, 5).map((interest) => (
                          <Typography
                            variant="body2"
                            color="#16171B"
                            sx={{
                              fontSize: "13px",
                              fontWeight: 400,
                              padding: 0.25,
                              paddingRight: 1,
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {interest}
                          </Typography>
                        ))}
                    </Box>
                    <Box width={"10%"}>
                      <Button
                        variant="body2"
                        sx={{
                          height: "32px",
                          width: "130px",
                          fontSize: "14px",
                          lineHeight: "1.5",
                          whiteSpace: "nowrap",
                          fontWeight: 500,
                          marginRight: 3,
                          borderRadius: "27px",
                          padding: 0.5,
                          marginBottom: 2,
                          color: "white",
                          backgroundColor: "#52B1BB",
                          textTransform: "none",
                          ":hover": {
                            color: "#52B1BB",
                            backgroundColor: "#D2E1E2",
                          },
                        }}
                        onClick={() => handleMessageClick(u.userId)}
                      >
                        Send Message
                      </Button>
                    </Box>
                  </Stack>
                </Paper>
              )
          )}
        {users && users.length < 1 && <Spinner />}
      </Stack>
    </Box>
  );
};

export default Network;
