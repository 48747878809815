import React from 'react'

const Message = () => {
    return (
        <div className='messagedm owner'> 
            <div classsName="messageInfo">
            {/* <img src="https://images.pexels.com/photos/15127334/pexels-photo-15127334.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt=""/> */}
                <span>just now</span>
            </div>
            <div className='messageContent'>
                <p>hello</p>
                <img src="https://images.pexels.com/photos/15127334/pexels-photo-15127334.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" />
            </div>
        </div>
    );
};

export default Message;