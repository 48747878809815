import React, { useState } from "react";
import "react-multi-carousel/lib/styles.css";
import About from "./About";
import FAQ from "./FAQ";
import Footer2 from "./Footer2";
import ForInvestors from "./ForInvestors";
import Homepage3 from "./Homepage3";
import Navbar from "./Navbar";

const LandingPage = () => {
  const [tabValue, setTabValue] = useState(0);
  const [main, setMain] = useState("Home");

  const setPages = () => {
    switch (tabValue) {
      case 0:
        return <Homepage3 />;
        break;
      case 1:
        return <About />;
        break;
      case 2:
        return <ForInvestors />;
        break;
      case 3:
        return <FAQ />;
        break;
      default:
        return <Homepage3 />;
        break;
    }
  };

  return (
    <>
      <Navbar tabValue={tabValue} setTabValue={setTabValue} />
      {setPages()}
      <Footer2 />
    </>
  );
};

export default LandingPage;
