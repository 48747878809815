import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  IconButton,
  Menu,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { Loop } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { UserCreatedPost } from "../../../MixPanel";
import { createLayer, getLayers } from "../../../features/layers/layersSlice";
import { createPost } from "../../../features/posts/postSlice";
import { cities, ergs, layersToPrompts } from "../../constants";

const NewPost = () => {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { layers } = useSelector((state) => state.layers);

  useEffect(() => {
    const dispatchLayer = () => {
      dispatch(getLayers());
    };

    dispatchLayer();
  }, []);

  let updatedLayersToPrompts = {};
  for (const layer in layersToPrompts) {
    if (layer !== "ERG" && layer !== "City") {
      updatedLayersToPrompts[layer] = layersToPrompts[layer];
    }
  }
  // compiling all erg-based layers
  ergs.forEach((erg) => {
    if (erg in layersToPrompts) {
      updatedLayersToPrompts[erg] = layersToPrompts["ERG"].concat(
        layersToPrompts[erg]
      );
    } else {
      updatedLayersToPrompts[erg] = layersToPrompts["ERG"];
    }
  });
  // compiling all city-based layers
  cities.forEach((city) => {
    if (city in layersToPrompts) {
      updatedLayersToPrompts[city] = layersToPrompts["City"].concat(
        layersToPrompts[city]
      );
    } else {
      updatedLayersToPrompts[city] = layersToPrompts["City"];
    }
  });
  const updatedLayers = Object.keys(updatedLayersToPrompts);

  // generate random prompt index
  const generateRandomPrompt = (l) => {
    const index = generateRandomNumber(0, updatedLayersToPrompts[l].length);
    return index;
  };
  // generate random number
  const generateRandomNumber = (min, max) => {
    const randomDecimal = Math.random();
    const randomNumber = randomDecimal * (max - min) + min;
    const roundedNumber = Math.floor(randomNumber);
    return roundedNumber;
  };

  const [layer, setLayer] = useState("Trending");
  const [prompt, setPrompt] = useState(
    updatedLayersToPrompts[layer][generateRandomPrompt(layer)]
  );
  const [content, setContent] = useState("");
  const [charLimit, setCharLimit] = useState("1600");

  // to handle layer select change
  const handleLayerChange = (event) => {
    setLayer(event.target.value);
    setPrompt(
      updatedLayersToPrompts[event.target.value][
        generateRandomPrompt(event.target.value)
      ]
    );
    if (
      event.target.value === "Newsstand" ||
      event.target.value === "Trending"
    ) {
      setCharLimit("1600");
    } else {
      {
        setCharLimit("600");
      }
    }
  };

  // for prompts more memu
  const [anchorEl, setAnchorEl] = React.useState(null);
  const promptsMenuOpen = Boolean(anchorEl);
  const handleOpenPromptsMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePromptsMenuClose = () => {
    setAnchorEl(null);
  };

  // content textfield layout
  const sxForTextField = {
    width: "100%",
    "& .MuiFilledInput-root": {
      overflow: "hidden",
      backgroundColor: "transparent",
      transition: "border-color background-color box-shadow",
      "&:hover": {
        backgroundColor: "transparent",
      },
      "&.Mui-focused": {
        backgroundColor: "transparent",
      },
      padding: 0,
      paddingTop: 1,
    },
  };
  const inputPropsForTextField = {
    style: {
      fontSize: 15,
      fontWeight: 500,
      color: "#16171B",
      padding: 0,
      margin: 0,
    },
  };
  // create new layer when needed
  const handleCreateLayer = (layerName) => {
    const citytype = cities.includes(layerName) ? "city" : "";
    const ergtype = ergs.includes(layerName) ? "erg" : "";
    const type = citytype || ergtype ? citytype + ergtype : "";
    const layerData = {
      name: layerName,
      subtype: type,
    };
    //dispatch newLayer
    dispatch(createLayer(layerData));
  };

  // create new post when post button clicked
  const handleCreatePost = () => {
    console.log("layers", layers);
    const postData = {
      content,
      layer,
      title: prompt,
    };

    if (!layers.includes(layer)) {
      console.log("creating new layer:", layer);
      handleCreateLayer(layer);
    }

    //dispatch newPost
    UserCreatedPost(user.email, user.name, user.userId, layer, prompt, content);
    dispatch(createPost(postData));
  };

  return (
    <Card
      sx={{
        mt: -1,
        borderRadius: "0px 0px 20px 20px",
      }}
    >
      <CardHeader
        avatar={
          <Avatar
            src={user.profileImage.url}
            alt="Profile Image"
            sx={{
              width: 32,
              height: 32,
              cursor: "pointer",
              borderBottom: 0,
              marginRight: 0,
            }}
            component={Link}
            to="/HomeScreen/Profile"
          />
        }
        title={
          <FormControl sx={{ m: 1 }}>
            <Select
              sx={{
                borderRadius: "50px",
                fontSize: "10px",
                padding: "0",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
                "& .MuiSelect-select": {
                  paddingTop: 0.5,
                  paddingBottom: 0.25,
                  paddingX: 1,
                  color: "#38929B",
                },
                "& .MuiSelect-icon": {
                  color: "#38929B",
                },
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    bgcolor: "#FFFEFE",
                    border: "1px solid #ECEBEB",
                    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
                    borderRadius: "11px",
                    "& .MuiMenuItem-root": {
                      padding: 1,
                      fontWeight: 400,
                      fontSize: "12px",
                      borderBottom: "1px solid #E8E8E8",
                    },
                    color: "#39929B",
                    paddingX: 1,
                    maxHeight: 48 * 3.5,
                    "&::-webkit-scrollbar": {
                      width: "6px",
                    },
                    "&::-webkit-scrollbar-track": {
                      background: "#ECEBEB",
                      borderRadius: "3px",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      background: "#C4C4C4",
                      borderRadius: "3px",
                    },
                  },
                },
              }}
              displayEmpty
              value={layer}
              onChange={handleLayerChange}
              IconComponent={ExpandMoreIcon}
            >
              <MenuItem
                divider
                sx={{
                  color: "black",
                }}
              >
                choose a layer
              </MenuItem>
              {updatedLayers.map((layer) => (
                <MenuItem value={layer}>{layer}</MenuItem>
              ))}
            </Select>
          </FormControl>
        }
      />
      <CardContent
        sx={{
          paddingLeft: "64px",
          paddingRight: "20px",
          paddingTop: "10px",
          paddingBottom: "0px",
        }}
      >
        <Box display="flex" alignItems="flex-start">
          <Typography
            variant="h5"
            sx={{ fontWeight: 700, fontSize: "15px" }}
            color="black"
          >
            {prompt}
          </Typography>
          <Tooltip title="Randomize Prompt" placement="top">
            <IconButton
              sx={{
                background: "#FFFEFE",
                color: "black",
                border: "1px solid #EFEFEF",
                boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.2)",
                marginLeft: "5px",
                padding: 0.25,
              }}
              onClick={() =>
                setPrompt(
                  updatedLayersToPrompts[layer][generateRandomPrompt(layer)]
                )
              }
            >
              <Loop
                sx={{
                  fontSize: "12px",
                  "& path": { strokeWidth: "1px" },
                }}
              />
            </IconButton>
          </Tooltip>
          <Typography
            variant="body1"
            component="span"
            onClick={handleOpenPromptsMenu}
            sx={{
              color: "#B8B8B8",
              fontSize: "12px",
              fontWeight: "300",
              textTransform: "none",
              paddingTop: "0",
              paddingLeft: "0",
              textDecoration: "underline",
              cursor: "pointer",
              ml: 1,
            }}
          >
            more
          </Typography>
          <Menu
            anchorEl={anchorEl}
            open={promptsMenuOpen}
            onClose={handlePromptsMenuClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            PaperProps={{
              sx: {
                maxHeight: 48 * 4.5,
                paddingX: 1,
                bgcolor: "#FFFEFE",
                border: "1px solid #ECEBEB",
                boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
                borderRadius: "11px",
                overflowY: "auto", // Enable scroll for excess content
                "&::-webkit-scrollbar": {
                  width: "6px",
                },
                "&::-webkit-scrollbar-track": {
                  background: "#ECEBEB",
                  borderRadius: "3px",
                },
                "&::-webkit-scrollbar-thumb": {
                  background: "#C4C4C4",
                  borderRadius: "3px",
                },
              },
            }}
          >
            <MenuItem
              divider
              sx={{
                color: "black",
                fontSize: "12px",
                padding: 1,
                fontWeight: 400,
                borderBottom: "1px solid #E8E8E8",
              }}
            >
              choose a prompt
            </MenuItem>
            {updatedLayersToPrompts[layer].map((p) => (
              <MenuItem
                key={p}
                value={p}
                onClick={() => {
                  setPrompt(p);
                  handlePromptsMenuClose();
                }}
                sx={{
                  fontSize: "12px",
                  padding: 1,
                  fontWeight: 400,
                  borderBottom: "1px solid #E8E8E8",
                  color: "#38929B",
                }}
              >
                {p}
              </MenuItem>
            ))}
          </Menu>
        </Box>
        <Box>
          <TextField
            name="content"
            onChange={(e) => {
              setContent(e.target.value);
            }}
            size="small"
            value={content}
            id="content"
            variant="filled"
            multiline
            rows={3}
            InputProps={{
              disableUnderline: true,
              classes: {
                root: "MuiFilledInput-root",
                focused: "Mui-focused",
              },
            }}
            inputProps={{
              ...inputPropsForTextField,
              maxLength:
                layer === "Newsstand" || layer === "Trending" ? 1600 : 600,
            }}
            sx={sxForTextField}
          />
        </Box>
        {/*<Box>
                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                        {tags.map((tag) => (
                            <Typography
                                sx={{
                                    color: "#52B1BB",
                                    fontSize: "12px",
                                    fontWeight: "700",
                                }}
                            >
                                #{tag}&nbsp;
                            </Typography>
                        ))}
                    </Box>
                    <Typography
                        variant="body1"
                        component="span"
                        onClick={handleTagsMenuOpen}
                        sx={{
                            color: "#B8B8B8",
                            fontSize: "12px",
                            fontWeight: "300",
                            textTransform: "none",
                            paddingTop: "0",
                            paddingLeft: "0",
                            textDecoration: "none",
                            cursor: "pointer",
                            ml: 1,
                        }}
                    >
                        select tags
                    </Typography>
                    <Menu
                        anchorEl={anchorElTags}
                        open={Boolean(anchorElTags)}
                        onClose={handleMenuClose}
                        PaperProps={{
                            sx: {
                                maxHeight: 48 * 2.5,
                                paddingX: 1,
                                bgcolor: "#FFFEFE",
                                border: "1px solid #ECEBEB",
                                boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
                                borderRadius: "11px",
                                overflowY: "auto", // Enable scroll for excess content
                                "&::-webkit-scrollbar": {
                                    width: "6px",
                                },
                                "&::-webkit-scrollbar-track": {
                                    background: "#ECEBEB",
                                    borderRadius: "3px",
                                },
                                "&::-webkit-scrollbar-thumb": {
                                    background: "#C4C4C4",
                                    borderRadius: "3px",
                                },
                            },
                        }}
                    >
                        <MenuItem
                            divider
                            sx={{
                                color: "black",
                                fontSize: "12px",
                                padding: 1,
                                fontWeight: 400,
                                borderBottom: "1px solid #E8E8E8",
                            }}
                        >
                            choose tags
                        </MenuItem>
                        {allTags.map((option) => (
                            <MenuItem
                                key={option}
                                onClick={handleOptionSelect(option)}
                                selected={tags.includes(option)}
                                sx={{
                                    fontSize: "12px",
                                    padding: 1,
                                    fontWeight: 400,
                                    borderBottom: "1px solid #E8E8E8",
                                    color: "#38929B",
                                }}
                            >
                                {option}
                            </MenuItem>
                        ))}
                    </Menu>
                </Box>*/}
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            padding: 1,
          }}
        >
          <Typography sx={{ fontSize: "12px", fontWeight: "300" }}>
            ({content.length}/{charLimit} characters)
          </Typography>
          <Button
            sx={{
              backgroundColor: "#16171B",
              color: "white",
              textTransform: "none",
              borderRadius: "18px",
              ml: 1,
              fontSize: "12px",
              fontWeight: "700",
              paddingY: 0.25,
              paddingX: 1.5,
              ":disabled": {
                color: "gray",
              },
              ":hover": {
                color: "gray",
                backgroundColor: "#16171B",
              },
            }}
            onClick={handleCreatePost}
            disabled={content.length < 1}
          >
            Post
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default NewPost;
