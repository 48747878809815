import { Avatar, Box, Button, Typography } from "@mui/material";
import React from "react";
import TechstarsLogo from "../../Assets/TechstarsLogo.png";

const ForInvestors = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        paddingX: { xs: 3, sm: 3, md: 7 },
        background: "linear-gradient( #FFFFFF, #52B1BB)",
        paddingTop: 10,
        paddingBottom: 10,
        alignItems: "center",
      }}
    >
      <Typography
        variant="h4"
        sx={{
          fontWeight: "700",
          fontSize: "50px",
          margin: 3,
          textAlign: "center",
        }}
      >
        Funding round for our V1 launch!
      </Typography>

      <Typography
        variant="h5"
        // style="text-decoration:none"  throws error
        sx={{
          fontWeight: "500",
          color: "grey",
          padding: 2,
          paddingLeft: 0,
          width: { xs: "90%", sm: "90%", md: "60%" },
          margin: 3,
          textAlign: "center",
        }}
      >
        We are open to inbound interest and partnerships.{<br />}
        Prospective investors can chat with CEO Jonathan, or request materials
        from{" "}
        <a href="mailto:jonathan@trystratos.com">jonathan@trystratos.com</a>.
      </Typography>
      <Button
        sx={{
          color: "#FFFFFF",
          display: "block",
          background: "linear-gradient(96.34deg, #52B1BB, #008997 100%)",
          borderRadius: "32px",
          fontSize: "15px",
          height: "40px",
          cursor: "pointer",
          border: "none",
          textTransform: "none",
        }}
        variant="contained"
        href="https://calendly.com/trystratos"
      >
        <span style={{ verticalAlign: "middle" }}>Chat with CEO</span>
      </Button>

      <Typography
        variant="h4"
        sx={{
          fontWeight: "700",
          fontSize: "30px",
          paddingTop: 8,
          margin: 3,
          textAlign: "center",
        }}
      >
        Proudly backed by
      </Typography>
      <Avatar
        alt="Techstars Logo"
        src={TechstarsLogo}
        sx={{
          display: "flex",
          borderRadius: 0,
          bgcolor: "transparent",
          width: "300px",
          height: "60px",
        }}
        imgProps={{
          style: { objectFit: "contain" },
        }}
      />
    </Box>
  );
};

export default ForInvestors;
