import React from "react";

import {
    Box,
    TextField,
    Typography,
    alpha,
} from "@mui/material";

const AddBirthdate = ({ birthDate, setBirthDate }) => {

    const sxForTextField = {
        "& .MuiFilledInput-root": {
            overflow: "hidden",
            borderRadius: "7px",
            backgroundColor: "#F3F9FB",
            border: "1px solid",
            borderColor: "#D2E1E2",
            transition: "border-color background-color box-shadow",
            "&:hover": {
                backgroundColor: "#F3F9FB",
            },
            "&.Mui-focused": {
                backgroundColor: "#F3F9FB",
                boxShadow: `${alpha("#000", 0.25)} 0 0 0 2px`,
                borderColor: "#D2E1E2",
            },
        },
    };

    const inputPropsForTextField = {
        style: {
            fontSize: 16,
            fontWeight: 300,
            color: "#2A2E33",
        },
    };

    const inputLabelPropsForTextfield = {
        style: {
            fontWeight: 400,
            fontSize: 12,
            color: "#16171B",
        },
    };


    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
            }}
        >
            <Typography sx={{ fontSize: 15, marginTop:"20px", marginBottom:"10px" }}>
                Enter your birthdate
            </Typography>
            <Box
                sx={{
                    display: "block",
                    flexWrap: "wrap",
                    width: "100%",
                    alignItems: "center",
                }}
            >
                <TextField
                                name="birthDate"
                                onChange={(e) => setBirthDate(e.target.value)}
                                size="small"
                                value={birthDate}
                                label="birthdate"
                                id="birthDate"
                                variant="filled"
                                required
                                error={(birthDate.trim() === "")}
                                align="center"
                                helperText={(birthDate.trim() === "") ? "Birthdate is required" : ""}
                                InputLabelProps={inputLabelPropsForTextfield}
                                InputProps={{
                                    disableUnderline: true,
                                    classes: {
                                        root: "MuiFilledInput-root",
                                        focused: "Mui-focused",
                                    },
                                }}
                                inputProps={inputPropsForTextField}
                                sx={sxForTextField}
                                type="date"
                            />
            </Box>
        </Box>
    );
};
export default AddBirthdate;
