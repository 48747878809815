import "./App.css";
// import Button from 'react-bootstrap/Button';
// import { faFacebookF } from '@fortawesome/free-brands-svg-icons'
import { ThemeProvider } from "@mui/material";
import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import AccountSettings from "./Pages/AccountSettings";
import BrowsePartners from "./Pages/BrowsePartners";
import Home from "./Pages/DirectMessaging/Home";
import HomeScreen from "./Pages/HomeScreen/HomeScreen";
import UserProfile from "./Pages/HomeScreen/UserProfile/UserProfile";
import HomeScreen2 from "./Pages/HomeScreen2";
import JobDetails from "./Pages/JobDetails";
import Jobs from "./Pages/Jobs";
import JobPage from "./Pages/Jobs/JobPage";
import About from "./Pages/LandingPage/About";
import Backdoor from "./Pages/LandingPage/Backdoor.jsx";
import Contact from "./Pages/LandingPage/Contact";
import FAQ from "./Pages/LandingPage/FAQ";
import ForInvestors from "./Pages/LandingPage/ForInvestors";
import Homepage from "./Pages/LandingPage/Homepage";
import Homepage2 from "./Pages/LandingPage/Homepage2";
import Homepage3 from "./Pages/LandingPage/Homepage3";
import LandingPage from "./Pages/LandingPage/LandingPage";
import Product from "./Pages/LandingPage/Product";
import MeetTheFounders from "./Pages/MeetTheFounders";
import MyLayers from "./Pages/MyLayers";
import Login from "./Pages/SignUp/Login";
import OAuthLogin from "./Pages/SignUp/OAuthLogin/OAuthLogin";
import SignUp from "./Pages/SignUp/SignUp";
import TechStars from "./Pages/TechStars/TechStars";
import TermsOfService from "./Pages/TermsOfService";
import AboutStratos from "./Pages/Website/AboutStratos";
import Blog from "./Pages/Website/Blog";
import BlogDetail from "./Pages/Website/BlogDetail";
import Team from "./Pages/Website/Team";
import LoginPage from "./Pages/Website/WebsiteHome/SignUpPage/LoginPage";
import Welcome from "./Pages/Welcome/Welcome";
import WhatIsStratos from "./Pages/WhatIsStratos";
import { theme } from "./theme.js";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route path="/Welcome" element={<Welcome />} />
          <Route path="/SignUp" element={<SignUp />} />
          <Route path="/login" element={<Login />} />
          <Route path="/loginauth" element={<OAuthLogin />} />
          {/*<Route
						path="/EmailVerification"
						element={<EmailVerification />}
					/>
					<Route
						path="/LayersPreference"
						element={<LayersPreference />}
					/>
					<Route
						path="/InformationVerification"
						element={<InformationVerification />}
					/>
					<Route
						path="/CommunityGuidelines"
						element={<CommunityGuidelines />}
					/>*/}
          <Route path="/HomeScreen/*" element={<HomeScreen />} />
          <Route path="/HomeScreen/post/:postid" element={<HomeScreen />} />
          <Route path="/HomeScreen/user/:id" element={<UserProfile />} />
          <Route path="/HomeScreen2" element={<HomeScreen2 />} />
          <Route path="/Homepage2" element={<Homepage2 />} />
          <Route path="/Homepage" element={<Homepage />} />
          <Route path="/Homepage3" element={<Homepage3 />} />
          {/*<Route path="/Profile" element={<Profile />} />
                    <Route path="/Prompts" element={<Prompts />} />
                    <Route path="/Settings" element={<Settings />} />*/}

          <Route path="/AccountSettings" element={<AccountSettings />} />
          <Route path="/MyLayers" element={<MyLayers />} />
          <Route path="/" element={<LandingPage />} />
          <Route path="/Website/auth/login" element={<LoginPage />} />
          <Route path="/Blog" element={<Blog />} />
          <Route path="/BlogDetail" element={<BlogDetail />} />
          <Route path="/AboutStratos" element={<AboutStratos />} />
          <Route path="/Team" element={<Team />} />
          <Route path="/WhatisStratos" element={<WhatIsStratos />} />
          <Route path="/MeetTheFounders" element={<MeetTheFounders />} />
          <Route path="/BrowsePartners" element={<BrowsePartners />} />
          <Route path="/Jobs" element={<Jobs />} />
          <Route path="/JobDetails" element={<JobDetails />} />
          <Route path="/JobPage" element={<JobPage />} />
          <Route path="/Home" element={<Home />} />
          <Route path="/TermsOfService" element={<TermsOfService />} />
          <Route path="/TechStars" element={<TechStars />} />
          {
            // temp route for landing page
          }
          {/*<Route path="/LandingPage" element={<LandingPage />} />*/}
          <Route path="/Product" element={<Product />} />
          <Route path="/FAQ" element={<FAQ />} />
          <Route path="/Request" element={<Backdoor />} />
          <Route path="/about" element={<About />} />
          <Route path="/investors" element={<ForInvestors />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
