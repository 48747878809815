import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getSurvey,
  surveyResponse,
} from "../../../features/surveys/surveysSlice";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
  DialogActions,
  Typography,
  TextField, // Import TextField
} from "@mui/material";

const SurveyDialog = ({ open, onClose, userId }) => {
  const dispatch = useDispatch();
  const { survey } = useSelector((state) => state.surveys);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [otherOption, setOtherOption] = useState(""); // State to handle the custom option

  useEffect(() => {
    if (open) {
      dispatch(getSurvey({ userId }));
    }
  }, [dispatch, open, userId]);

  const handleOptionChange = (option) => {
    setSelectedOptions((prev) => ({
      ...prev,
      [option]: !prev[option],
    }));
  };

  const handleOtherOptionChange = (event) => {
    setOtherOption(event.target.value);
  };

  const handleSubmitSurvey = () => {
    // Include the otherOption in selectedOptions if it's not empty
    const finalSelectedOptions = { ...selectedOptions };
    if (otherOption.trim()) {
      finalSelectedOptions["Other"] = otherOption.trim();
    }

    const surveyResponseData = {
      userId,
      answer: finalSelectedOptions,
      surveyId: survey?.id,
    };

    dispatch(surveyResponse(surveyResponseData));
    console.log("Survey submitted with options: ", finalSelectedOptions);
    onClose(); // Close the dialog
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      {survey ? (
        <>
          <DialogTitle sx={{ textAlign: "center" }}>
            {survey.question}
          </DialogTitle>
          <DialogContent>
            <Typography variant="body1" gutterBottom align="center">
              {survey.context}
            </Typography>
            <FormControl component="fieldset" variant="standard">
              <FormGroup>
                {survey.options.map((option, index) => (
                  <FormControlLabel
                    key={index}
                    control={
                      <Checkbox
                        checked={!!selectedOptions[option]}
                        onChange={() => handleOptionChange(option)}
                      />
                    }
                    label={option}
                  />
                ))}
                <TextField
                  label="Other (please specify)"
                  variant="outlined"
                  value={otherOption}
                  onChange={handleOtherOptionChange}
                  fullWidth
                  margin="normal"
                />
              </FormGroup>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>Cancel</Button>
            <Button onClick={handleSubmitSurvey}>Submit</Button>
          </DialogActions>
        </>
      ) : (
        <>
          <DialogTitle sx={{ textAlign: "center" }}>No New Surveys</DialogTitle>
          <DialogContent>
            <Typography variant="body1" gutterBottom align="center">
              No new surveys for you at this time. Check back later!
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>Close</Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default SurveyDialog;
