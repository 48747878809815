import { createTheme } from "@mui/material/styles";
import { red } from "@mui/material/colors";

export const theme = createTheme({
    palette: {
        primary: {
            main: "#399FAA",
        },
        secondary: {
            main: "#FFFFFF",
        },
        drawer: {
            main: "#52B1BB",
        },
        background: {
            main: "#E5E5E5",
        },
        promptBox: {
            main: "#818181",
            light: "#FFFFFF",
            dark: "#F3F3F3",
        },
    },
});
