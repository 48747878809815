import BlockIcon from "@mui/icons-material/Block";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import NoAccountsIcon from "@mui/icons-material/NoAccounts";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import {
  Avatar,
  Button,
  Card,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { TrackUserProfileClick } from "../../../MixPanel";
import { deletePostComment } from "../../../features/posts/postSlice";
import {
  blockUser,
  reportComment,
  reportCommentBlockUser,
} from "../../../features/report/reportSlice";

const CommentsCard = ({ comment, post }) => {
  const { commentContent, author } = comment;
  const { user } = useSelector((state) => state.auth);

  const [openReportMenu, setOpenReportMenu] = useState(null);
  const open = Boolean(openReportMenu);
  const handleReportMenuClick = (event) => {
    setOpenReportMenu(event.currentTarget);
  };
  const handleReportMenuClose = () => {
    setOpenReportMenu(null);
  };

  const [rprtMenu, setRprtMenu] = useState(false);
  const [reason, setReason] = useState("");

  const [onlyReport, setOnlyReport] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleIconClick = () => {
    TrackUserProfileClick(
      user.email,
      user.userId,
      comment.author.name,
      comment.author.id,
      comment.author.jobTitle,
      comment.author.currentCompany
    );
    //dispatch(getUserProfile({ id: post.author.id }));
    navigate(
      `/HomeScreen/Profile/${comment.author.id}`,
      { state: { userId: user.userId } },
      { preventScrollReset: true }
    );
  };

  const reportMenu = () => {
    setRprtMenu(true);
  };
  const closeRprtMenu = () => {
    setRprtMenu(false);
  };

  const handleReportButton = () => {
    setOnlyReport(true);
    // report comment
    dispatch(
      reportComment({
        reportedPost: post.id,
        reportedComment: comment.id,
        reportingReason: reason,
        reportedPostLayer: post.layer,
        reportedCommentAuthorId: comment.author.id,
        reportedCommentAuthorName: comment.author.name,
      })
    );
  };

  const handleBlockUserButton = () => {
    dispatch(
      blockUser({
        blockedUser: post.author.id,
        blockedEmail: post.author.name,
      })
    );
  };

  const handleBlockReportButton = () => {
    setOnlyReport(false);
    reportMenu();
  };

  const handleReportAndBlock = () => {
    console.log("report and block");
    dispatch(
      reportCommentBlockUser({
        reportedPost: post.id,
        reportedComment: comment.id,
        reportingReason: reason,
        reportedPostLayer: post.layer,
        reportedCommentAuthorId: comment.author.id,
        reportedCommentAuthorName: comment.author.name,
        blockedUser: post.author.id,
        blockedEmail: post.author.name,
      })
    );
  };

  const handleDeleteCommentButton = () => {
    // delete comment
    dispatch(
      deletePostComment({
        postId: post.id,
        commentId: comment.id,
        layer: post.layer,
      })
    );
  };

  return (
    <Paper
      elevation={3}
      sx={{
        borderRadius: "15px",
        marginBottom: "10px",
        marginTop: "10px",
      }}
    >
      <Dialog open={rprtMenu} onClose={closeRprtMenu}>
        <DialogTitle>Reporting Reason</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To report this comment you need to provide a valid reason. We will
            review the comment and inform concerned user.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Reason"
            type="text"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            fullWidth
            variant="standard"
            multiline
            inputProps={{
              style: {
                height: "20px",
                fontSize: "14px",
              },
            }}
          />
        </DialogContent>
        <DialogActions>
          {!onlyReport ? (
            <Tooltip title={"Report Comment and Block this User"}>
              <Button onClick={handleReportAndBlock}>Report and Block</Button>
            </Tooltip>
          ) : (
            <Button onClick={handleReportButton}>Report</Button>
          )}
          <Button onClick={closeRprtMenu}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <Card
        sx={{
          width: "100%",
          borderRadius: "15px",
          margin: "0",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <CardHeader
          avatar={
            <Avatar
              alt={author.name}
              src={author.profileImage.url}
              sx={{ width: 40, height: 40, cursor: "pointer" }}
              onClick={handleIconClick}
            />
          }
          action={
            <>
              <IconButton aria-label="settings" onClick={handleReportMenuClick}>
                <MoreVertIcon />
              </IconButton>
              <Menu
                anchorEl={openReportMenu}
                open={open}
                onClose={handleReportMenuClose}
              >
                {author.id === user.userId && (
                  <MenuItem
                    onClick={handleDeleteCommentButton}
                    style={{
                      color: "#005257",
                      fontSize: "14px",
                    }}
                  >
                    <DeleteIcon
                      style={{
                        transform: "rotateY(180deg)",
                        marginRight: "4px",
                        fontSize: "16px",
                      }}
                    />
                    Delete Comment
                  </MenuItem>
                )}
                {author.id !== user.userId && (
                  <MenuItem
                    onClick={() => {
                      setOnlyReport(true);
                      reportMenu();
                    }}
                    style={{
                      color: "#005257",
                      fontSize: "14px",
                    }}
                  >
                    <BlockIcon
                      style={{
                        transform: "rotateY(180deg)",
                        marginRight: "4px",
                        fontSize: "16px",
                      }}
                    />
                    Report Comment
                  </MenuItem>
                )}
                {author.id !== user.userId && (
                  <MenuItem
                    onClick={handleBlockUserButton}
                    style={{
                      color: "#005257",
                      fontSize: "14px",
                    }}
                  >
                    <PersonOffIcon
                      style={{
                        marginRight: "4px",
                        fontSize: "16px",
                      }}
                    />
                    Block User
                  </MenuItem>
                )}
                {author.id !== user.userId && (
                  <MenuItem
                    onClick={handleBlockReportButton}
                    style={{
                      color: "#005257",
                      fontSize: "14px",
                    }}
                  >
                    <NoAccountsIcon
                      style={{
                        marginRight: "4px",
                        fontSize: "16px",
                      }}
                    />
                    Report & Block
                  </MenuItem>
                )}
              </Menu>
            </>
          }
          title={author.name}
          subheader={commentContent}
          sx={{
            margin: "0",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        />
      </Card>
    </Paper>
  );
};

export default CommentsCard;
