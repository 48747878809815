import React from "react";
import "./Team.css";
import LinkedInIcon from '@mui/icons-material/LinkedIn';


function Team() {
    return (
        <div>
            <div className="teamDiv">
            <h1 className="theTeam">The Team</h1>
            <h2 className="meet">Meet the Team Behind Stratos</h2>
            <p1 className="smallTeam">We’re a small team that loves to create great experiences and make meaningful <br/> connections between builders and customers. Join our remote ream!</p1>






            {/* <div className="openings">
            <h1>Open positions</h1>
            <h2>We’re looking for talented people</h2>
            <p1>Stratos is growing fast, and we are always looking for passionate, dynamic, and <br/> talented individuals to join our distributed team all around the world.</p1>
            </div> */}
            </div>
            <div className="indivBio">
                <div className="image"/>
                <h1 className="name">
                Jonathan Cornelius
                </h1>
                <h1 className="title">
                Founder & CEO
                </h1> 
                <h1 className="past">
                Former BDS @ CORL Technologies.    
                </h1>
                <LinkedInIcon className="socialicon"/>

            </div>
            <div className="indivBio">
                <div className="image"/>
                <h1 className="name">
                Christopher Grey
                </h1>
                <h1 className="title">
                Founder & CTO
                </h1> 
                <h1 className="past">
                Former Research Intern @ MSR    
                </h1>
                <LinkedInIcon className="socialicon"/>

            </div>
            


        </div>
    );
}

export default Team;