import { Avatar, Box, Stack, Typography } from "@mui/material";
import React from "react";
import jonathanheadshot from "../../Assets/jonathan.jpg";
import skylarheadshot from "../../Assets/skyheadshot.jpg";
const About = () => {
  return (
    <>
      <Box
        sx={{
          display: { xs: "none", sm: "none", md: "flex" },
          flexDirection: "column",
          paddingLeft: { xs: 3, sm: 3, md: 7 },
          paddingBottom: 10,
          alignItems: "center",
          justifyContent: "space-between",
          background: "linear-gradient(to right, #FFFFFF, #52B1BB)",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <Typography
          variant="h3"
          sx={{
            fontWeight: "700",
            fontSize: "50px",
            marginY: 3,
            paddingTop: 5,
          }}
        >
          Meet the Team
        </Typography>
        <Box
          sx={{
            display: { xs: "none", sm: "none", md: "flex" },
            flexDirection: "row",
            alignItems: "flex-start",
            justifyContent: "space-between",
          }}
        >
          <Stack
            direction="column"
            justifyContent="space-evenly"
            alignItems="center"
            sx={{
              width: "50%",
              margin: 3,
            }}
          >
            <Avatar
              src={jonathanheadshot}
              alt="jc"
              sx={{
                width: 300,
                height: 300,
                boxShadow: 1,
                // cursor: "pointer",
                "&:hover": {
                  boxShadow: 3,
                },
              }}
            />
            <Typography
              variant="h3"
              sx={{
                fontWeight: "700",
                fontSize: "30px",
                marginY: 3,
              }}
            >
              Jonathan Cornelius
            </Typography>
            <Typography
              variant="h6"
              sx={{
                fontWeight: "500",
                color: "#16171B",
                paddingLeft: 0,
                marginBottom: 1,
                lineHeight: 2,
                letterSpacing: "-1.5%",
                fontSize: "20px",
              }}
            >
              Jonathan is a seasoned recruiter with a remarkable track record,
              having successfully placed over 30 individuals in various roles
              while building impactful partnerships with leadership at 15+
              prominent tech companies. His journey began with entrepreneurship
              during his senior year of college when he bootstrapped a
              recruiting firm, laying the foundation for what would later become
              Stratos.{<br />}
              {<br />}Drawing from his experiences and forging connections with
              HR leaders, Jonathan has cultivated valuable insights into the
              ever-evolving landscape of hiring needs. At Stratos, he channels
              this expertise into providing tailored recruitment solutions for
              both job seekers and employers.{<br />}
              {<br />}
              Jonathan is not just passionate about filling roles; he's driven
              by the art of connecting talent with opportunities that align with
              their aspirations and the strategic goals of the companies he
              partners with. His commitment to fostering meaningful professional
              connections sets him apart in the dynamic world of recruitment.
            </Typography>
          </Stack>

          <Stack
            direction="column"
            justifyContent="space-evenly"
            alignItems="center"
            sx={{
              width: "50%",
              margin: 3,
            }}
          >
            <Avatar
              src={skylarheadshot}
              alt="skylar"
              sx={{
                width: 300,
                height: 300,
                boxShadow: 1,
                // cursor: "pointer",
                "&:hover": {
                  boxShadow: 3,
                },
              }}
            />
            <Typography
              variant="h3"
              sx={{
                fontWeight: "700",
                fontSize: "30px",
                marginY: 3,
              }}
            >
              Skylar Gordon
            </Typography>
            <Typography
              variant="h6"
              sx={{
                fontWeight: "500",
                color: "#16171B",
                paddingLeft: 0,
                marginBottom: 1,
                lineHeight: 2,
                letterSpacing: "-1.5%",
                fontSize: "20px",
              }}
            >
              Skylar Gordon emerges as a dynamic force in the tech landscape,
              armed with a Bachelors in Computation and Cognition from MIT and
              over four years of hands-on experience in AI and software
              development within startup realms. Since her youth, Skylar's
              fascination with technology and innovation has been a driving
              force, propelling her to not only embrace the future with
              enthusiasm but to actively mold the technological landscapes of
              today.{<br />}
              {<br />}As a versatile technologist, Skylar's commitment to
              excellence shines through in her adept understanding of data
              structures, algorithms, and agile methodologies. Skylar's
              technical acumen and leadership mark her as a standout
              professional in the rapidly evolving domains of AI and software
              development.{<br />}
              {<br />}Beyond being a tech professional, Skylar is a visionary at
              the intersection of software solutions and data. Her dedication to
              excellence and innovative problem-solving positions her as a
              trailblazer shaping the future of technology. Skylar's passion
              extends beyond the code, as she actively seeks to create
              meaningful impact through her work, setting her apart as a
              forward-thinking and impactful contributor to the tech community.
            </Typography>
          </Stack>
        </Box>
      </Box>

      {/* Mobile Version */}

      <Box
        sx={{
          display: { xs: "flex", sm: "flex", md: "none" },
          flexDirection: "column",
          paddingLeft: { xs: 3, sm: 3, md: 7 },
          paddingBottom: 10,
          alignItems: "flex-start",
          justifyContent: "flex-start",
          background: "linear-gradient( #FFFFFF, #52B1BB)",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <Stack
          direction="column"
          justifyContent="space-evenly"
          alignItems="center"
          sx={{
            paddingTop: "5%",
            margin: 3,
            paddingLeft: "3%",
          }}
        >
          <Typography
            variant="h3"
            sx={{
              fontWeight: "700",
              fontSize: "50px",
              marginY: 3,
            }}
          >
            Meet the Team
          </Typography>

          <Avatar
            src={jonathanheadshot}
            alt="jc"
            sx={{
              width: 300,
              height: 300,
              boxShadow: 1,
              // cursor: "pointer",
              "&:hover": {
                boxShadow: 3,
              },
            }}
          />
          <Typography
            variant="h3"
            sx={{
              fontWeight: "700",
              fontSize: "30px",
              marginY: 3,
            }}
          >
            Jonathan Cornelius
          </Typography>
          <Typography
            variant="h6"
            sx={{
              fontWeight: "500",
              color: "#16171B",
              paddingLeft: 0,
              marginBottom: 5,
              lineHeight: 2,
              letterSpacing: "-1.5%",
              fontSize: "20px",
            }}
          >
            Jonathan is a seasoned recruiter with a remarkable track record,
            having successfully placed over 30 individuals in various roles
            while building impactful partnerships with leadership at 15+
            prominent tech companies. His journey began with entrepreneurship
            during his senior year of college when he bootstrapped a recruiting
            firm, laying the foundation for what would later become Stratos.
            {<br />}
            {<br />}Drawing from his experiences and forging connections with HR
            leaders, Jonathan has cultivated valuable insights into the
            ever-evolving landscape of hiring needs. At Stratos, he channels
            this expertise into providing tailored recruitment solutions for
            both job seekers and employers.{<br />}
            {<br />}
            Jonathan is not just passionate about filling roles; he's driven by
            the art of connecting talent with opportunities that align with
            their aspirations and the strategic goals of the companies he
            partners with. His commitment to fostering meaningful professional
            connections sets him apart in the dynamic world of recruitment.
          </Typography>

          <Avatar
            src={skylarheadshot}
            alt="skylar"
            sx={{
              width: 300,
              height: 300,
              boxShadow: 1,
              // cursor: "pointer",
              "&:hover": {
                boxShadow: 3,
              },
            }}
          />
          <Typography
            variant="h3"
            sx={{
              fontWeight: "700",
              fontSize: "30px",
              marginY: 3,
            }}
          >
            Skylar Gordon
          </Typography>
          <Typography
            variant="h6"
            sx={{
              fontWeight: "500",
              color: "#16171B",
              paddingLeft: 0,
              marginBottom: 1,
              lineHeight: 2,
              letterSpacing: "-1.5%",
              fontSize: "20px",
            }}
          >
            Skylar Gordon emerges as a dynamic force in the tech landscape,
            armed with a Bachelors in Computation and Cognition from MIT and
            over four years of hands-on experience in AI and software
            development within startup realms. Since her youth, Skylar's
            fascination with technology and innovation has been a driving force,
            propelling her to not only embrace the future with enthusiasm but to
            actively mold the technological landscapes of today.{<br />}
            {<br />}As a versatile technologist, Skylar's commitment to
            excellence shines through in her adept understanding of data
            structures, algorithms, and agile methodologies. Skylar's technical
            acumen and leadership mark her as a standout professional in the
            rapidly evolving domains of AI and software development.{<br />}
            {<br />}Beyond being a tech professional, Skylar is a visionary at
            the intersection of software solutions and data. Her dedication to
            excellence and innovative problem-solving positions her as a
            trailblazer shaping the future of technology. Skylar's passion
            extends beyond the code, as she actively seeks to create meaningful
            impact through her work, setting her apart as a forward-thinking and
            impactful contributor to the tech community.
          </Typography>
        </Stack>
      </Box>
    </>
  );
};

export default About;
