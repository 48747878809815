export const convertDate = (input) => {
  let date = new Date(input);
  let options = { day: "2-digit", month: "short" };
  return date.toLocaleDateString("en-US", options) + ", " + date.getFullYear();
};

// degree image
export const degreeIllustration = (degree) => {
  switch (degree) {
    case "Associate's":
      return "https://firebasestorage.googleapis.com/v0/b/stratosv1.appspot.com/o/degree_images%2Fassociate.jpg?alt=media&token=b9994aec-8cd9-4e74-903d-dcbb0435b68b&_gl=1*bm3n9*_ga*NjU2OTExNzIzLjE2ODUzODE1MjQ.*_ga_CW55HF8NVT*MTY4NTYyMjEzNS45LjEuMTY4NTYyMjQ1My4wLjAuMA..";

    case "Bachelor's":
      return "https://firebasestorage.googleapis.com/v0/b/stratosv1.appspot.com/o/degree_images%2Fbachelor.jpg?alt=media&token=6742e495-b25e-468f-b6d4-82a066ad51d4&_gl=1*u7kc8j*_ga*NjU2OTExNzIzLjE2ODUzODE1MjQ.*_ga_CW55HF8NVT*MTY4NTYyMjEzNS45LjEuMTY4NTYyMjQ4My4wLjAuMA..";

    case "Doctorate's":
      return "https://firebasestorage.googleapis.com/v0/b/stratosv1.appspot.com/o/degree_images%2Fdoctoral.jpg?alt=media&token=415cd646-7b3c-41d9-8a0c-8810f76ca79e&_gl=1*17csi3i*_ga*NjU2OTExNzIzLjE2ODUzODE1MjQ.*_ga_CW55HF8NVT*MTY4NTYyMjEzNS45LjEuMTY4NTYyMjUyNS4wLjAuMA..";
    case "Graduate's":
      return "https://firebasestorage.googleapis.com/v0/b/stratosv1.appspot.com/o/degree_images%2Fgraduate.jpg?alt=media&token=8f8787a0-7085-4b8e-88b4-ff0c7dad63c4&_gl=1*12l5rpf*_ga*NjU2OTExNzIzLjE2ODUzODE1MjQ.*_ga_CW55HF8NVT*MTY4NTYyMjEzNS45LjEuMTY4NTYyMjUxNi4wLjAuMA..";
    default:
      break;
  }
};

// company image
// TODO there should be a test case for making sure every company in constants is here
export const companyIllustration = (company) => {
  switch (company) {
    case "Google":
      return "https://firebasestorage.googleapis.com/v0/b/stratosv1.appspot.com/o/company_logos%2Fgoogle_logo.png?alt=media&token=e4995401-da03-40d5-8a09-1facb583530d&_gl=1*1hv5lku*_ga*NjU2OTExNzIzLjE2ODUzODE1MjQ.*_ga_CW55HF8NVT*MTY4NTk2OTM1NC4xMy4xLjE2ODU5Njk0NjAuMC4wLjA.";

    case "Stratos":
      return "https://firebasestorage.googleapis.com/v0/b/stratosv1.appspot.com/o/company_logos%2Fstratos_logo_square.png?alt=media&token=ecbb8bcc-839e-4143-bca5-c109ce871e4f.";

    case "Sage":
      return "https://firebasestorage.googleapis.com/v0/b/stratosv1.appspot.com/o/company_logos%2Fsage_logo.png?alt=media&token=e056b961-56b7-491b-aa0a-d7eca9781963&_gl=1*1vxjd97*_ga*NjU2OTExNzIzLjE2ODUzODE1MjQ.*_ga_CW55HF8NVT*MTY4NTk2OTM1NC4xMy4xLjE2ODU5NzAwMjUuMC4wLjA.";

    case "Amazon":
      return "https://firebasestorage.googleapis.com/v0/b/stratosv1.appspot.com/o/company_logos%2Famazon_logo.png?alt=media&token=b9b760fc-a812-4fdc-9e14-729a248cd9a9.";

    case "Xactly":
      return "https://firebasestorage.googleapis.com/v0/b/stratosv1.appspot.com/o/company_logos%2Fxactly_logo.png?alt=media&token=1a68e307-4ab8-4c63-b102-4d46c540b8b3.";

    case "Patreon":
      return "https://firebasestorage.googleapis.com/v0/b/stratosv1.appspot.com/o/company_logos%2Fpatreon_logo.png?alt=media&token=69acb3c6-5331-4b75-a5e9-b6b6575fff9c.";

    case "Microsoft":
      return "https://firebasestorage.googleapis.com/v0/b/stratosv1.appspot.com/o/company_logos%2Fmicrosoft.png?alt=media&token=ccc76af4-3aeb-48b1-8ced-a5c8398d8942.";

    case "Nike":
      return "https://firebasestorage.googleapis.com/v0/b/stratosv1.appspot.com/o/company_logos%2Fnike.png?alt=media&token=7d868ab1-0adc-42d7-83f1-19c1b4d0ebae.";

    case "Youtube":
      return "https://firebasestorage.googleapis.com/v0/b/stratosv1.appspot.com/o/company_logos%2Fyoutube.png?alt=media&token=77551443-676e-4892-a034-b1a0eb0d9aab.";

    case "Apple":
      return "https://firebasestorage.googleapis.com/v0/b/stratosv1.appspot.com/o/company_logos%2Fapple_2.png?alt=media&token=0baf17f0-62e3-4b9a-95f8-e7b5725d6bbf.";

    case "Salesforce":
      return "https://firebasestorage.googleapis.com/v0/b/stratosv1.appspot.com/o/company_logos%2Fsalesforce_2.png?alt=media&token=3beb9cc0-c181-431c-971a-b00cbb764acd.";

    case "Snap":
      return "https://firebasestorage.googleapis.com/v0/b/stratosv1.appspot.com/o/company_logos%2Fsnap_logo.svg?alt=media&token=08246974-2937-470c-93c0-a9988d75dd03.";

    case "Asana":
      return "https://firebasestorage.googleapis.com/v0/b/stratosv1.appspot.com/o/company_logos%2Fasana_2.png?alt=media&token=1dbe059b-4493-4490-b289-075c7fcf0c5b.";

    case "Stealth":
      return "https://firebasestorage.googleapis.com/v0/b/stratosv1.appspot.com/o/company_logos%2Fstealth-startup-logo.png?alt=media&token=f80bb523-45a4-4a76-85ed-10f4a921053f.";

    case "Non-Partner Company":
      return "https://firebasestorage.googleapis.com/v0/b/stratosv1.appspot.com/o/company_logos%2Fstealth-startup-logo.png?alt=media&token=f80bb523-45a4-4a76-85ed-10f4a921053f.";

    case "Walmart":
      return "https://firebasestorage.googleapis.com/v0/b/stratosv1.appspot.com/o/company_logos%2Fwalmart-logo-2.png?alt=media&token=192bc6be-b11c-4865-a265-a063112f5aa6.";

    case "Paypal":
      return "https://firebasestorage.googleapis.com/v0/b/stratosv1.appspot.com/o/company_logos%2FPaypal_logo.png?alt=media&token=0571e0ac-480f-47d6-9ad5-b6c134fd6539.";

    case "DigitalOcean":
      return "https://firebasestorage.googleapis.com/v0/b/stratosv1.appspot.com/o/company_logos%2FDigitalOcean_icon.png?alt=media&token=b57a03aa-af83-4ff1-9e78-0e8934b9b2c8.";

    case "Pinterest":
      return "https://firebasestorage.googleapis.com/v0/b/stratosv1.appspot.com/o/company_logos%2Fpinterest_square.png?alt=media&token=67385215-aa46-4f18-862f-d5b7ea22dd48.";

    case "Crunchbase":
      return "https://firebasestorage.googleapis.com/v0/b/stratosv1.appspot.com/o/company_logos%2Fcrunchbase_favicon.png?alt=media&token=e29e6e1b-4cf6-408b-a150-567a185ec98c.";

    case "Yext":
      return "https://firebasestorage.googleapis.com/v0/b/stratosv1.appspot.com/o/company_logos%2Fyext_square.png?alt=media&token=ce80a246-7072-4973-90c6-e880c43ce059.";

    case "Lyft":
      return "https://firebasestorage.googleapis.com/v0/b/stratosv1.appspot.com/o/company_logos%2Flyft_square.png?alt=media&token=09d0f282-94ff-4d38-8ffe-b98e3fb936bf.";

    case "Palantir":
      return "https://firebasestorage.googleapis.com/v0/b/stratosv1.appspot.com/o/company_logos%2Fpalantir_square.png?alt=media&token=f564bca0-2e7c-4f82-93ce-d70b31bf4b4c.";

    case "Scale AI":
      return "https://firebasestorage.googleapis.com/v0/b/stratosv1.appspot.com/o/company_logos%2Fscale_square.png?alt=media&token=2e9960d4-ce2c-4b2d-b727-9526bcd4f367.";

    case "Instacart":
      return "https://firebasestorage.googleapis.com/v0/b/stratosv1.appspot.com/o/company_logos%2Finstacart_square.png?alt=media&token=086a55a2-fee3-4796-b366-01ab48dfc331.";

    case "Zocdoc":
      return "https://firebasestorage.googleapis.com/v0/b/stratosv1.appspot.com/o/company_logos%2FZocDoc_logo.png?alt=media&token=95fca9c6-8c55-4277-8502-993be802542a.";

    case "CaptivateIQ":
      return "https://firebasestorage.googleapis.com/v0/b/stratosv1.appspot.com/o/company_logos%2FCaptivateIQ_logo.png?alt=media&token=0fab23f4-2153-4318-b935-bf1c8efb1ab3.";

    case "Samsara":
      return "https://firebasestorage.googleapis.com/v0/b/stratosv1.appspot.com/o/company_logos%2Fsamsara.avif?alt=media&token=bf59ffd2-e2bf-40dd-8982-e451b460cda6.";

    case "Conduent":
      return "https://firebasestorage.googleapis.com/v0/b/stratosv1.appspot.com/o/company_logos%2FConduent_logo.png?alt=media&token=f2307626-dd98-40b1-84da-fda6c8c0a511.";

    case "Verizon Communications":
      return "https://firebasestorage.googleapis.com/v0/b/stratosv1.appspot.com/o/company_logos%2Fverizon-red-icon-black.png?alt=media&token=321ee1b7-8d64-4066-848d-ec574f518511.";

    case "Comcast":
      return "https://firebasestorage.googleapis.com/v0/b/stratosv1.appspot.com/o/company_logos%2FComcast-Logo.png?alt=media&token=323a7ab8-5a41-4076-bcac-50ed26dce415.";

    case "AT&T":
      return "https://firebasestorage.googleapis.com/v0/b/stratosv1.appspot.com/o/company_logos%2Fat%26t_logo.png?alt=media&token=f6e4f407-5bf0-4b6e-afe8-de7ce1e7f9fc.";

    case "Meta Platforms":
      return "https://firebasestorage.googleapis.com/v0/b/stratosv1.appspot.com/o/company_logos%2Fmeta_square.png?alt=media&token=fa01204c-617b-48bd-962a-4f9e2d3288c0.";

    case "Oracle":
      return "https://firebasestorage.googleapis.com/v0/b/stratosv1.appspot.com/o/company_logos%2Foracle_square.png?alt=media&token=74ad3ac2-05d0-49eb-8cdd-67eff47d135e.";

    case "Uber":
      return "https://firebasestorage.googleapis.com/v0/b/stratosv1.appspot.com/o/company_logos%2Fuber-icon-2048x2048-qbj0bfi1.png?alt=media&token=ecbed7e4-ae0e-4466-b713-6c3edaf58af5.";

    case "Netflix":
      return "https://firebasestorage.googleapis.com/v0/b/stratosv1.appspot.com/o/company_logos%2Fnetflix_logo.png?alt=media&token=da6e9c70-541c-4f17-a9af-1ec1d63321c6.";

    case "NVIDIA":
      return "https://firebasestorage.googleapis.com/v0/b/stratosv1.appspot.com/o/company_logos%2Fnvidia_square.png?alt=media&token=f421fbd8-3140-4bc3-82f8-2e878b0707f4.";

    case "Wayfair":
      return "https://firebasestorage.googleapis.com/v0/b/stratosv1.appspot.com/o/company_logos%2Fwayfair_square.png?alt=media&token=a3515d46-b11d-4dd6-af42-43e7cad2e04d.";

    case "Chewy":
      return "https://firebasestorage.googleapis.com/v0/b/stratosv1.appspot.com/o/company_logos%2Fchewy_square.png?alt=media&token=27e31efb-7dab-4f99-a426-f72d54b4ab36.";

    case "Airbnb":
      return "https://firebasestorage.googleapis.com/v0/b/stratosv1.appspot.com/o/company_logos%2Fairbnb_square.png?alt=media&token=a7b7a723-eddf-4f53-9c75-f3cacc749a5a.";

    case "Raytheon Technologies":
      return "https://firebasestorage.googleapis.com/v0/b/stratosv1.appspot.com/o/company_logos%2FRaytheon-Emblem.png?alt=media&token=3bb92f7c-e628-41bb-a160-b6e131b53d45.";

    default:
      break;
  }
};
