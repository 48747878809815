import AddCircleRounded from "@mui/icons-material/AddCircleRounded";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import SendIcon from "@mui/icons-material/Send";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { RiShareForwardLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { convertDate } from "../../../Assets/utilFunctions";
import {
  LogoutMixPanel,
  TrackComment,
  TrackLike,
  TrackUnLike,
} from "../../../MixPanel";
import Spinner from "../../../components/Spinner";
import { logout } from "../../../features/auth/authSlice";
import {
  createPostComment,
  getPosts,
  likePost,
  unLikePost,
} from "../../../features/posts/postSlice";
import ChooseLayers from "./ChooseLayers";
import CommentsCard from "./CommentsCard";
import HomeSkeleton from "./HomeSkeleton";
import NewPost from "./NewPost";
import TabPanel from "./TabPanel";

const Home = ({ displayNewPost, setDisplayNewPost }) => {
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const { notifications } = useSelector((state) => state.notifications);
  const { posts, isError, isSuccess, isLoading, message } = useSelector(
    (state) => state.posts
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // layers setup
  const [currentLayer, setCurrentLayer] = useState(0);
  const [currentLayerName, setCurrentLayerName] = useState("ALL");
  const [layers, setLayers] = useState(["ALL", ...user.layers]);
  // const [userLayers, setUserLayers] = useState([]); // for add button

  useEffect(() => {
    const userLayers = ["ALL", ...user.layers];
    const postKeys = [...Object.keys(posts)];
    const filteredLayers = postKeys
      .map((key) =>
        userLayers
          .map((layer) => layer.toUpperCase())
          .includes(key.toUpperCase())
          ? key
          : undefined
      )
      .filter((layer) => layer !== undefined);

    setLayers(filteredLayers);
  }, [posts]);

  const handleTabChange = (event, value) => {
    if (event.target.innerText != "+Add") {
      setCurrentLayer(value);
      console.log("event.target", event.target);
      setCurrentLayerName(event.target.innerText);
    }
  };
  const titleCase = (str) => {
    return str
      .toLowerCase()
      .split(" ")
      .map(function (word) {
        return word.replace(word[0], word[0].toUpperCase());
      })
      .join(" ");
  };
  // add layers dialog box code
  const [openAddLayers, setOpenAddLayers] = useState(false);
  const handleAddLayerClose = () => {
    setOpenAddLayers(false);
  };

  // Notification Code
  const { postid } = useParams();
  const [showNot, setShowNot] = useState(false);
  const [notPost, setNotPost] = useState({});
  useEffect(() => {
    if (postid && postid !== null) {
      notifications.filter((n) => {
        if (n.actionDetails.postId === postid) {
          console.log("notifcation", n.actionDetails.layer.toUpperCase());
          console.log(n);
          posts[n.actionDetails.layer.toUpperCase()].map((p) => {
            if (p.id === postid) {
              setShowNot(true);
              setNotPost(p);
            }
          });
        }
      });
    }
  }, [postid]);
  // post popup when showNot is false
  const NotificationPost = () => {
    const { comments, isCommentLoading } = useSelector((state) => state.posts);

    const [commentContent, setCommentContent] = useState("");

    const [like, setLike] = useState(false);
    const [likeCount, setLikeCount] = useState(0);

    const [tempComments, setTempComments] = useState([]);
    const [commentCount, setCommentCount] = useState(0);

    useEffect(() => {
      setLike(notPost.likedByCurrentUser ? notPost.likedByCurrentUser : false);
      setLikeCount(notPost.likesCount);
      setTempComments(comments);
      setCommentCount(notPost.commentsCount);
    }, []);

    useEffect(() => {
      setTempComments(comments);
      setCommentCount(notPost.commentsCount);
    }, [comments]);

    const handleWriteComment = () => {
      const commentData = {
        layer: notPost.layer,
        postId: notPost.id,
        commentContent: commentContent,
      };
      const tempCommentData = {
        layer: notPost.layer,
        postId: notPost.id,
        commentContent: commentContent,
        author: {
          name: user.name,
          profileImage: user.profileImage,
        },
      };
      TrackComment(
        user.email,
        notPost.id,
        notPost.author.name,
        notPost.author.id,
        notPost.author.jobTitle,
        notPost.author.currentCompany,
        user.jobTitle,
        user.currentCompany
      );
      setTempComments([...tempComments, tempCommentData]);
      const notificationData = {
        actionType: "Comment",
        content: notPost.content,
        postId: notPost.id,
        layer: notPost.layer,
        userToNotify: notPost.author.id,
      };
      dispatch(createPostComment({ commentData, notificationData }));

      setCommentContent("");
    };
    console.log("tempComments", tempComments);

    const handleLikeButton = (event) => {
      const likeData = { postId: notPost.id, layer: notPost.layer };

      if (notPost.likedByCurrentUser) {
        // unlike the post
        setLike(false);
        setLikeCount(likeCount - 1);
        dispatch(unLikePost(likeData));
        TrackUnLike(
          user.email,
          notPost.id,
          notPost.author.name,
          notPost.author.id,
          user.name,
          user.userId,
          notPost.layer,
          notPost.title,
          notPost.content
        );
      } else {
        // like the post
        setLike(true);
        setLikeCount(likeCount + 1);
        dispatch(likePost(likeData));
        TrackLike(
          user.email,
          notPost.id,
          notPost.author.name,
          notPost.author.id,
          user.name,
          user.userId,
          notPost.layer,
          notPost.title,
          notPost.content
        );
      }
    };

    return (
      <Dialog open={showNot} onClose={notClose} fullWidth>
        <DialogContent>
          <Stack direction="row" spacing={1}>
            <img
              src={notPost.author.profileImage}
              height={"35px"}
              width={"35px"}
              alt="author"
            />
            <Stack direction="column">
              <Typography>{notPost.author.name}</Typography>
              <Typography fontSize={10} color={"#BFBFBF"}>
                {convertDate(notPost.createdAt)}
              </Typography>
            </Stack>
          </Stack>
          <Typography mt={1} color="primary" fontSize={16}>
            {notPost.title}
          </Typography>
          <Typography mt={1} fontSize={12}>
            {notPost.content}
          </Typography>
        </DialogContent>
        <Box border={"1px solid #e2e2e2"}>
          <Button
            startIcon={like ? <ThumbUpIcon /> : <ThumbUpOutlinedIcon />}
            onClick={handleLikeButton}
          >
            {likeCount}
          </Button>
          <Button startIcon={<ChatOutlinedIcon />}>{commentCount}</Button>
          <Button startIcon={<RiShareForwardLine />}>
            {notPost.sharesCount}
          </Button>
        </Box>
        <Divider />
        <DialogContent
          dividers
          sx={{ paddingLeft: "5px", paddingRight: "0px" }}
        >
          <Typography
            variant="subtitle2"
            color="text.secondary"
            sx={{ fontSize: 14 }}
          >
            Responses ({commentCount})
          </Typography>

          {!isCommentLoading ? (
            tempComments.map((comment, index) => (
              <CommentsCard comment={comment} key={index} post={notPost} />
            ))
          ) : (
            <Spinner />
          )}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <TextField
              variant="outlined"
              multiline
              fullWidth
              minRows={1}
              maxRows={4}
              value={commentContent}
              sx={{ borderRadius: "30px" }}
              placeholder="Add to comment..."
              onChange={(e) => {
                e.preventDefault();
                setCommentContent(e.target.value);
              }}
            />
            <IconButton
              size="large"
              color="primary"
              onClick={handleWriteComment}
              disabled={
                !commentContent || commentContent.trim().length === 0
                  ? true
                  : false
              }
            >
              <SendIcon fontSize="30px" />
            </IconButton>
          </Box>
        </DialogContent>
      </Dialog>
    );
  };
  // close post popup
  const notClose = () => {
    setShowNot(false);
  };

  // get posts from BE
  useEffect(() => {
    const dispatchGetPosts = () => {
      dispatch(getPosts());
    };
    dispatchGetPosts();
  }, [user.layers]);

  // logout when error from BE
  useEffect(() => {
    if (
      message == "auth/argument-error" ||
      message == "auth/id-token-expired"
    ) {
      LogoutMixPanel(user.email);
      dispatch(logout());
      setShowErrorAlert(true);
    }
  }, [message]);

  // to set correct width of tabs
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const tabsWidth = Math.floor(windowWidth * 0.9);

  // new post code
  const handlePrompt = () => {
    setDisplayNewPost(!displayNewPost);
  };

  if (isLoading) {
    return <HomeSkeleton />;
  }

  return (
    <Box
      display="flex"
      flexDirection={"column"}
      alignItems={{ xs: "center", sm: "center", md: "flex-start" }}
      justifyContent={"flex-start"}
      margin={0}
      padding={0}
      sx={{ width: "100%" }}
    >
      <Helmet>
        <title>Home - Stratos</title>
      </Helmet>

      <Box width="100%">
        {showNot && <NotificationPost />}
        {showErrorAlert && (
          <Dialog
            open={showErrorAlert}
            onClose={() => {
              console.log("message", message);
              console.log("alert close");
              setShowErrorAlert(false);
              navigate("/Welcome");
            }}
          >
            <DialogTitle>{"Info"}</DialogTitle>
            <DialogContent>
              <DialogContentText>{"You have signed out! "}</DialogContentText>
            </DialogContent>
          </Dialog>
        )}

        <Box
          sx={{
            width: { xs: tabsWidth, sm: tabsWidth, md: "65%" },
            paddingX: -1,
          }}
        >
          {displayNewPost && <NewPost />}

          <Button
            variant="outlined"
            startIcon={
              <AddCircleRounded
                style={{
                  color: "#52B1BB",
                  fontSize: "32px",
                }}
              />
            }
            sx={{
              marginTop: 2,
              mb: 0,
              padding: 1,
              backgroundColor: "#F8F8F8",
              "&:hover": {
                backgroundColor: "promptBox.light",
                opacity: [0.9, 0.8, 0.7],
              },
              borderRadius: "30px",
              display: { xs: "flex", sm: "flex", md: "none" },
              justifyContent: "flex-start",
              borderColor: "#F8F8F8",
              textTransform: "none",
              color: "#9C9C9C",
              width: "100%",
            }}
            align="right"
            onClick={handlePrompt}
          >
            Post in the Stratosphere 🚀
          </Button>

          <Tabs
            variant="scrollable"
            value={currentLayer}
            onChange={handleTabChange}
            indicatorColor="transparent"
            scrollButtons="auto"
            sx={{
              display: "flex",
              overflow: "auto",
            }}
          >
            {layers.map((layer) => (
              <Tab
                label={titleCase(layer)}
                key={layer}
                disableRipple
                sx={{
                  display: "flex",
                  fontSize: 12,
                  backgroundColor: "white",
                  borderRadius: "50px",
                  // border: "1px solid #52B1BB",
                  // color: "#52B1BB",
                  paddingX: 1,
                  paddingY: 0.5,
                  minHeight: "auto",
                  minWidth: "auto",
                  margin: 1,
                  mt: 2,
                  color: "#16171B",
                  textTransform: "none",
                  "& .MuiTab-root": {
                    fontFamily: "Roboto",
                  },
                  "&.Mui-selected": {
                    backgroundColor: "#52B1BB",
                    color: "white",
                  },
                }}
              />
            ))}

            <Tab
              label={"+Add"}
              sx={{
                display: "flex",
                fontSize: 12,
                backgroundColor: "white",
                borderRadius: "50px",
                paddingX: 1,
                paddingY: 0.5,
                minHeight: "auto",
                minWidth: "auto",
                margin: 1,
                mt: 2,
                color: "#52B1BB",
                textTransform: "none",
                border: "1px solid #52B1BB",
                "& .MuiTab-root": {
                  fontFamily: "Roboto",
                },
                "&.Mui-selected": {
                  backgroundColor: "#52B1BB",
                  color: "white",
                },
              }}
              onClick={() => {
                setOpenAddLayers(true);
              }}
            />
          </Tabs>

          {posts && (
            <TabPanel
              key={currentLayer}
              currentLayerName={currentLayerName}
              setCurrentLayerName={setCurrentLayerName}
              setCurrentLayer={setCurrentLayer}
              layers={layers}
            />
          )}
        </Box>
      </Box>

      <Dialog onClose={handleAddLayerClose} open={openAddLayers} fullWidth>
        <DialogTitle
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            fontWeight: "bold",
            color: "#52B1BB",
            justifyContent: "center",
            pb: "0",
          }}
        >
          Add More Layers
        </DialogTitle>
        <DialogContent>
          <ChooseLayers handleAddLayerClose={handleAddLayerClose} />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default Home;
