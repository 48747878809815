import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import PlaceIcon from "@mui/icons-material/Place";
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import LinesEllipsis from "react-lines-ellipsis";
import responsiveHOC from "react-lines-ellipsis/lib/responsiveHOC";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  companyIllustration,
  degreeIllustration,
} from "../../../Assets/utilFunctions";
import { LogoutMixPanel } from "../../../MixPanel";
import { logout } from "../../../features/auth/authSlice";
import ChooseLayers from "../Home/ChooseLayers";
import EditProfile from "./EditProfile";

const Profile = () => {
  const { user, message } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const ResponsiveLinesEllipsis = responsiveHOC()(LinesEllipsis);
  const [expanded, setExpanded] = useState(false);

  // deal with cityState
  const city = user?.city ? user?.city : "";
  const state = user?.state ? user?.state : "";
  const cityState = city && state ? city + ", " + state : city;

  // add layers dialog box code
  const [openAddLayers, setOpenAddLayers] = useState(false);
  const handleAddLayerClose = () => {
    setOpenAddLayers(false);
  };

  // edit profile dialog box code
  const [openEditProfile, setOpenEditProfile] = useState(false);
  const handleEditProfile = () => {
    setOpenEditProfile(false);
  };

  // show error
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const navigate = useNavigate();
  // logout when error from BE
  useEffect(() => {
    if (message === "auth/id-token-expired") {
      LogoutMixPanel(user.email);
      dispatch(logout());
      setShowErrorAlert(true);
    }
  }, [message]);

  // get current job from experience
  const [currentJob, setCurrentJob] = useState({});

  useEffect(() => {
    if (user?.experience?.length > 0) {
      // const presentIndex = user?.experience.findIndex(
      //     (exp) => exp.endDate === "present"
      // );
      // setCurrentJob(user?.experience[presentIndex]);
      setCurrentJob(user?.experience[0]);
    }
  }, [user?.experience]);

  // sort experiences
  const [experience, setExperience] = useState(
    user?.experience ? user?.experience : []
  );
  useEffect(() => {
    const sortedExperience = [...experience].sort((a, b) => {
      const yearA = a.startDate.year;
      const yearB = b.startDate.year;
      const monthA = a.startDate.month;
      const monthB = b.startDate.month;

      // Compare years in descending order
      if (yearA !== yearB) {
        return yearB - yearA;
      }

      // If years are the same, compare months in descending order
      const monthOrder = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      return monthOrder.indexOf(monthB) - monthOrder.indexOf(monthA);
    });

    setExperience(sortedExperience);
  }, []);

  // extract birth date properly
  const [birthDate, setBirthDate] = useState(
    user?.birthDate ? user.birthDate : ""
  );
  useEffect(() => {
    if (user?.birthDate) {
      const isoString = user?.birthDate;
      const date = new Date(isoString);

      const options = { day: "numeric", month: "long", year: "numeric" };
      const formattedDate = date.toLocaleDateString("en-US", options);

      setBirthDate(formattedDate);
    }
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        backgroundColor: "#E9E9E9",
        width: "100%",
        height: "100%",
      }}
    >
      <Helmet>
        <title>Profile - Stratos</title>
      </Helmet>

      {showErrorAlert && (
        <Dialog
          open={showErrorAlert}
          onClose={() => {
            console.log("message", message);
            console.log("alert close");
            setShowErrorAlert(false);
            navigate("/Welcome");
          }}
        >
          <DialogTitle>{"Info"}</DialogTitle>
          <DialogContent>
            <DialogContentText>{"You have signed out! "}</DialogContentText>
          </DialogContent>
        </Dialog>
      )}

      <Box
        sx={{
          display: "flex",
          margin: { xs: 2, sm: 2, md: 5 },
          backgroundColor: "white",
          width: "100%",
          height: "100%",
          borderRadius: "10px",
          flexDirection: "column",
          padding: 2,
        }}
      >
        <Stack
          direction={{
            xs: "column",
            sm: "row",
            md: "row",
          }}
          justifyContent="space-between"
          alignItems="center"
          width={"100%"}
        >
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            {user.profileImage.url && user.profileImage.flag ? (
              <Avatar
                src={user.profileImage.url}
                sx={{ width: "100px", height: "100px" }}
              />
            ) : (
              <Avatar
                src={
                  "https://firebasestorage.googleapis.com/v0/b/stratosv1.appspot.com/o/default-user-profile.png?alt=media"
                }
                sx={{ width: "100px", height: "100px" }}
              />
            )}

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                margin: 1,
                justifyContent: "center",
              }}
            >
              <Typography
                sx={{
                  fontSize: { xs: 20, sm: 20, md: 25 },
                  fontWeight: {
                    xs: 600,
                    sm: 600,
                    md: 700,
                  },
                }}
              >
                {user.name}
              </Typography>
              <Typography
                sx={{
                  fontSize: 18,
                  fontWeight: "500",
                  color: "#52B1BB",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <PlaceIcon color="#52B1BB" />
                {cityState ? cityState : "location"}
              </Typography>
              {user?.pronouns && user?.pronouns.length > 0 && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 1,
                  }}
                >
                  {user?.pronouns &&
                    user?.pronouns
                      .slice(0, user?.pronouns.length - 1)
                      .map((p) => (
                        <Typography
                          key={p + "p"}
                          variant="body2"
                          color="#FFFFFF"
                          sx={{
                            color: "#52B1BB",
                            fontSize: 16,
                          }}
                        >
                          {p}/
                        </Typography>
                      ))}
                  <Typography
                    key={user?.pronouns[user?.pronouns.length - 1] + "p"}
                    variant="body2"
                    color="#FFFFFF"
                    sx={{
                      color: "#52B1BB",
                      fontSize: 16,
                    }}
                  >
                    {user?.pronouns[user?.pronouns.length - 1]}
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>

          <Box
            sx={{
              display: { xs: "none", sm: "none", md: "flex" },
            }}
          >
            <Button
              sx={{
                color: "white",
                backgroundColor: "#52B1BB",
                borderRadius: "4px",
                textTransform: "none",
                fontSize: "12",
                ":hover": {
                  color: "#52B1BB",
                  backgroundColor: "#D2E1E2",
                },
              }}
              onClick={() => setOpenEditProfile(true)}
            >
              Edit Profile
            </Button>
          </Box>
        </Stack>

        {currentJob && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Avatar
              src={companyIllustration(currentJob.company)}
              sx={{
                width: "32px",
                height: "32px",
                margin: 1,
                borderRadius: 0,
              }}
            />
            <Box
              sx={{
                margin: 1,
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Typography
                sx={{
                  color: "#16171B",
                  fontSize: "18",
                  fontWeight: 500,
                }}
              >
                {currentJob.position}
              </Typography>
              <Typography
                sx={{
                  color: "#767676",
                  fontSize: "18",
                  fontWeight: 500,
                }}
              >
                &nbsp;at&nbsp;
              </Typography>
              <Typography
                sx={{
                  color: "#16171B",
                  fontSize: "18",
                  fontWeight: 500,
                }}
              >
                {currentJob.company}
              </Typography>
            </Box>
          </Box>
        )}

        {/* {user?.pronouns && user?.pronouns.length > 0 && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              margin: 1,
              width: "60%",
            }}
          >
            {user?.pronouns &&
              user?.pronouns.slice(0, user?.pronouns.length - 1).map((p) => (
                <Typography
                  key={p + "p"}
                  variant="body2"
                  color="#FFFFFF"
                  sx={{
                    color: "#52B1BB",
                  }}
                >
                  {p}/
                </Typography>
              ))}
            <Typography
              key={user?.pronouns[user?.pronouns.length - 1] + "p"}
              variant="body2"
              color="#FFFFFF"
              sx={{
                color: "#52B1BB",
              }}
            >
              {user?.pronouns[user?.pronouns.length - 1]}
            </Typography>
          </Box>
        )} */}

        {/* {user?.linkedin !== "" && (
          <Box
            sx={{
              margin: 1,
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Typography
              sx={{
                color: "#16171B",
                fontSize: "18",
                fontWeight: 500,
              }}
            >
              LinkedIn: &nbsp;
            </Typography>

            <Link to={user.linkedin} underline="none" color="inherit">
              <Typography
                style={{
                  userSelect: "text",
                  overflowWrap: "anywhere",
                  color: "#767676",
                  fontSize: "18",
                  fontWeight: 500,
                }}
              >
                {user.linkedin}
              </Typography>
            </Link>
          </Box>
        )} */}

        {user?.bio.length > 0 && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              margin: 1,
              width: "60%",
            }}
          >
            <Typography
              sx={{
                color: "#52B1BB",
                fontSize: 25,
                fontWeight: 700,
              }}
            >
              Bio
            </Typography>
            {!expanded ? (
              <>
                <Typography
                  variant="body2"
                  color="#16171B"
                  sx={{
                    fontSize: "14px",
                    whiteSpace: "pre-wrap",
                    fontWeight: 400,
                  }}
                >
                  <ResponsiveLinesEllipsis
                    text={user.bio}
                    maxLine="3"
                    ellipsis={
                      <Button
                        onClick={() => setExpanded(true)}
                        sx={{
                          textTransform: "none",
                          display: "flex",
                          justifyContent: "flex-start",
                          paddingLeft: 0,
                          color: "#878787",
                          width: "fit-content",
                        }}
                      >
                        ...Show More
                      </Button>
                    }
                    trimRight
                    basedOn="words"
                  />
                </Typography>
              </>
            ) : (
              <>
                <Typography
                  variant="body2"
                  color="#16171B"
                  sx={{
                    fontSize: "14px",
                    whiteSpace: "pre-wrap",
                    fontWeight: 400,
                  }}
                >
                  {user?.bio}
                </Typography>
                <IconButton
                  onClick={() => setExpanded(false)}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    color: "#878787",
                    width: "fit-content",
                  }}
                >
                  <ExpandLessIcon />
                </IconButton>
              </>
            )}
          </Box>
        )}

        {user?.ergs && user?.ergs.length > 0 && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              margin: 1,
              width: "60%",
            }}
          >
            <Typography
              sx={{
                color: "#52B1BB",
                fontSize: 25,
                fontWeight: 700,
              }}
            >
              Groups
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                flexWrap: "wrap",
                marginTop: 1,
              }}
            >
              {user?.ergs &&
                user?.ergs.map((erg) => (
                  <Typography
                    key={erg + "erg"}
                    variant="body2"
                    color="#FFFFFF"
                    sx={{
                      fontSize: "15px",
                      whiteSpace: "nowrap",
                      fontWeight: 500,
                      marginRight: 3,
                      borderRadius: "27px",
                      paddingY: 0.5,
                      paddingX: 1.25,
                      marginBottom: 2,
                      backgroundColor: "#52B1BB",
                    }}
                  >
                    {erg}
                  </Typography>
                ))}
            </Box>
          </Box>
        )}

        {user?.interests && user?.interests.length > 0 && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              margin: 1,
              width: "60%",
            }}
          >
            <Typography
              sx={{
                color: "#52B1BB",
                fontSize: 25,
                fontWeight: 700,
              }}
            >
              Interests
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                flexWrap: "wrap",
                marginTop: 1,
              }}
            >
              {user?.interests &&
                user?.interests.map((interest) => (
                  <Typography
                    key={interest}
                    variant="body2"
                    color="#008997"
                    sx={{
                      fontSize: "14px",
                      whiteSpace: "nowrap",
                      fontWeight: 400,
                      marginRight: 3,
                      borderRadius: "4px",
                      padding: 0.5,
                      marginBottom: 2,
                      backgroundColor: "rgba(82, 177, 187, 0.2)",
                    }}
                  >
                    {interest}
                  </Typography>
                ))}
            </Box>
          </Box>
        )}

        {user?.layers.length > 0 && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              margin: 1,
              width: "60%",
            }}
          >
            <Typography
              sx={{
                color: "#52B1BB",
                fontSize: 25,
                fontWeight: 700,
              }}
            >
              My Layers
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                flexWrap: "wrap",
                marginTop: 1,
              }}
            >
              {user?.layers.map((layer) => (
                <Typography
                  key={layer}
                  variant="body2"
                  color="#16171B"
                  sx={{
                    fontSize: "15px",
                    whiteSpace: "nowrap",
                    fontWeight: 500,
                    marginRight: 3,
                    borderRadius: "27px",
                    paddingY: 0.5,
                    paddingX: 1.25,
                    marginBottom: 2,
                    backgroundColor: "#EBEBEB",
                  }}
                >
                  {layer}
                </Typography>
              ))}
              <Typography
                variant="body2"
                color="#52B1BB"
                sx={{
                  fontSize: "15px",
                  whiteSpace: "nowrap",
                  fontWeight: 500,
                  marginRight: 3,
                  borderRadius: "27px",
                  border: "1px solid #52B1BB",
                  paddingY: 0.5,
                  paddingX: 1.25,
                  marginBottom: 2,
                  backgroundColor: "#FFFFFF",
                }}
                onClick={() => {
                  setOpenAddLayers(true);
                }}
              >
                + Add
              </Typography>
            </Box>
          </Box>
        )}

        {experience && experience.length > 0 && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              margin: 1,
              width: "60%",
            }}
          >
            <Typography
              sx={{
                color: "#52B1BB",
                fontSize: 25,
                fontWeight: 700,
              }}
            >
              Experience
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                flexWrap: "wrap",
                marginTop: 1,
                alignItems: "flex-start",
              }}
            >
              {experience.length > 0 &&
                experience.map((exp) => (
                  <Stack
                    key={exp}
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <Avatar
                      src={companyIllustration(exp.company)}
                      sx={{
                        width: "40px",
                        height: "40px",
                        margin: 1,
                        borderRadius: 0,
                      }}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                        }}
                      >
                        <Typography
                          variant="body2"
                          color="#16171B"
                          sx={{
                            fontSize: "15px",
                            whiteSpace: "nowrap",
                            fontWeight: 500,
                          }}
                        >
                          {exp.company}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="#878787"
                          sx={{
                            fontSize: "12px",
                            whiteSpace: "nowrap",
                            fontWeight: 700,
                            marginLeft: 0.5,
                          }}
                        >
                          {exp.endDate != "present"
                            ? `(${exp.startDate.month}/${exp.startDate.year} - ${exp.endDate.month}/${exp.endDate.year})`
                            : `(${exp.startDate.month}/${exp.startDate.year} - ${exp.endDate})`}
                        </Typography>
                      </Box>

                      <Typography
                        variant="body2"
                        color="#878787"
                        sx={{
                          fontSize: "12px",
                          whiteSpace: "nowrap",
                          fontWeight: 700,
                        }}
                      >
                        {exp.position}
                      </Typography>
                    </Box>
                  </Stack>
                ))}
            </Box>
          </Box>
        )}

        {user.education && user?.education?.length > 0 && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              margin: 1,
              width: "60%",
            }}
          >
            <Typography
              sx={{
                color: "#52B1BB",
                fontSize: 25,
                fontWeight: 700,
              }}
            >
              School
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                flexWrap: "wrap",
                marginTop: 1,
                alignItems: "flex-start",
              }}
            >
              {user?.education?.map((sch) => (
                <Stack
                  key={sch}
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Avatar
                    src={degreeIllustration(sch.degree)}
                    sx={{
                      width: "40px",
                      height: "40px",
                      margin: 1,
                      borderRadius: 0,
                    }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                      }}
                    >
                      <Typography
                        variant="body2"
                        color="#16171B"
                        sx={{
                          fontSize: "15px",
                          whiteSpace: "nowrap",
                          fontWeight: 500,
                        }}
                      >
                        {sch.school}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="#878787"
                        sx={{
                          fontSize: "12px",
                          whiteSpace: "nowrap",
                          fontWeight: 700,
                          marginLeft: 0.5,
                        }}
                      >
                        {sch.endDate != "present"
                          ? `(${sch.startDate.month}/${sch.startDate.year} - ${sch.endDate.month}/${sch.endDate.year})`
                          : `(${sch.startDate.month}/${sch.startDate.year} - ${sch.endDate})`}
                      </Typography>
                    </Box>

                    <Typography
                      variant="body2"
                      color="#878787"
                      sx={{
                        fontSize: "12px",
                        whiteSpace: "nowrap",
                        fontWeight: 700,
                      }}
                    >
                      {sch.degree} degree, {sch.fieldOfStudy}
                    </Typography>
                  </Box>
                </Stack>
              ))}
            </Box>
          </Box>
        )}

        <Dialog onClose={handleAddLayerClose} open={openAddLayers} fullWidth>
          <DialogTitle
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              fontWeight: "bold",
              color: "#52B1BB",
              justifyContent: "center",
              pb: "0",
            }}
          >
            Add More Layers
          </DialogTitle>
          <DialogContent>
            <ChooseLayers handleAddLayerClose={handleAddLayerClose} />
          </DialogContent>
        </Dialog>

        <EditProfile
          handleEditProfile={handleEditProfile}
          openEditProfile={openEditProfile}
        />
      </Box>
    </Box>
  );
};

export default Profile;
