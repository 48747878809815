import { Box, Button, Chip, MenuItem, Select } from "@mui/material";
import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { updateUserErgs } from "../../../features/auth/authSlice";
import { ergs } from "../../constants";

const AddErgs = ({ handleAddErgsClose }) => {
  const { user } = useSelector((state) => state.auth);

  const [userErgs, setUserErgs] = useState(user.ergs);

  const dispatch = useDispatch();

  const handleChangeSelect = (event) => {
    const {
      target: { value },
    } = event;
    setUserErgs(value);
  };

  const handleConfirm = () => {
    handleAddErgsClose();
    dispatch(updateUserErgs(userErgs));
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        padding: 1,
        background: "white",
        justifyContent: "flex-start",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          padding: 1,
          height: "auto",
          alignItems: "center",
          mb: 20,
        }}
      >
        <Select
          sx={{
            width: "80%",
            height: "auto",
            color: "#52B1BB",
            border: "1px solid #52B1BB",
          }}
          placeholder="Select Groups"
          multiple
          value={userErgs ? userErgs : []}
          onChange={handleChangeSelect}
          renderValue={(selected) => (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 0.5,
              }}
            >
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
        >
          {ergs.map((erg) => (
            <MenuItem key={erg} value={erg}>
              {erg}
            </MenuItem>
          ))}
        </Select>
      </Box>

      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          variant="outlined"
          sx={{
            width: "20%",
            m: 1,
            color: "#FFFFFF",
            background: "#52B1BB",
            display: "block",
            borderRadius: "32px",
            paddingY: 1,
            paddingX: 3,
            textTransform: "none",
          }}
          onClick={() => handleAddErgsClose()}
        >
          Cancel
        </Button>
        <Button
          variant="outlined"
          // disabled={userErgs?.length < 1}
          sx={{
            width: "20%",
            m: 1,
            color: "#52B1BB",
            background: "#FFFFFF",
            display: "block",
            borderRadius: "32px",
            paddingY: 1,
            paddingX: 3,
            textTransform: "none",
          }}
          onClick={handleConfirm}
        >
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default AddErgs;
