import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { resetPassword } from "../../features/auth/authSlice";

const ForgotPassword = ({
  forgotPasswordEmail,
  setForgotPasswordEmail,
  showForgotPassword,
  setShowForgotPassword,
}) => {
  const dispatch = useDispatch();

  const handleSendPasswordReset = () => {
    dispatch(resetPassword({ email: forgotPasswordEmail }));
  };

  return (
    <Dialog
      variant="h3"
      align="center"
      open={showForgotPassword}
      onClose={() => {
        console.log("forgot password close");
        setShowForgotPassword(false);
        // navigate("/");
      }}
    >
      <DialogTitle>{"Forgot Password"}</DialogTitle>
      <DialogContent>
        <DialogContentText paddingBottom={3}>
          {"Enter your email to reset your password"}
        </DialogContentText>
        <TextField
          type="email"
          label="Email"
          name="email"
          value={forgotPasswordEmail}
          // onChange={setForgotPassEmail(email); e=>setUsername(e.target.value)}
          onChange={(e) => setForgotPasswordEmail(e.target.value)}
          required
          size="small"
          variant="outlined"
          fullWidth
          style={{ width: "100%" }}
          inputProps={{
            style: { fontSize: 10 },
          }} // font size of input text
          InputLabelProps={{
            shrink: true,
            style: { fontSize: 10 },
          }}
        />
        <Button
          onClick={handleSendPasswordReset}
          variant="contained"
          sx={{
            marginTop: 2,
          }}
        >
          Reset Password
        </Button>
      </DialogContent>
    </Dialog>
  );
};
export default ForgotPassword;
