import React from "react";
import { Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const Footer = () => {
    const linkStyle = {
        textDecoration: "none",
        color: "white",
        border: "none",
        margin: "8px",
        fontSize: "12px",
    };

    const titleStyle = {
        fontSize: "14px",
        fontWeight: "400",
        margin: "8px",
    };

    return (
        <Stack
            direction={"row"}
            justifyContent={"space-around"}
            alignContent={"center"}
            spacing={4}
            sx={{
                background: "transparent",
                width: "100%",
                padding: 4,
            }}
        >
            <Stack
                direction={"column"}
                justifyContent={"space-between"}
                alignItems={"flex-start"}
            >
                <Typography variant="body1" sx={titleStyle}>
                    Products
                </Typography>
                <Link to={"/"} style={linkStyle}>
                    Overview
                </Link>
                <Link href="#" to={"/"} style={linkStyle}>
                    Features
                </Link>
                <Link href="#" to={"/"} style={linkStyle}>
                    Solutions
                </Link>
                <Link to={"/"} style={linkStyle}>
                    Tutorials
                </Link>
                <Link href="#" to={"/"} style={linkStyle}>
                    Pricing
                </Link>
                <Link href="#" to={"/"} style={linkStyle}>
                    Releases
                </Link>
            </Stack>
            <Stack
                direction={"column"}
                justifyContent={"space-between"}
                alignItems={"flex-start"}
            >
                <Typography variant="body1" sx={titleStyle}>
                    Company
                </Typography>
                <Link href="#" to={"/about"} style={linkStyle}>
                    About us
                </Link>
                <Link href="#" to={"/"} style={linkStyle}>
                    Careers
                </Link>
                <Link href="#" to={"/"} style={linkStyle}>
                    Press
                </Link>
                <Link href="#" to={"/"} style={linkStyle}>
                    News
                </Link>
                <Link href="#" to={"/"} style={linkStyle}>
                    Media kit
                </Link>
                <Link href="#" to={"/contact"} style={linkStyle}>
                    Contact
                </Link>
            </Stack>
            <Stack
                direction={"column"}
                justifyContent={"space-between"}
                alignItems={"flex-start"}
            >
                <Typography variant="Typography" sx={titleStyle}>
                    Social
                </Typography>
                <Link to={"https://twitter.com/trystratos"} style={linkStyle}>
                    Twitter
                </Link>
                <Link
                    to={"https://www.linkedin.com/company/trystratos/"}
                    style={linkStyle}
                >
                    LinkedIn
                </Link>
                <Link href="#" to={"/"} style={linkStyle}>
                    Facebook
                </Link>
                <Link href="#" to={"/"} style={linkStyle}>
                    Github
                </Link>
                <Link href="#" to={"/"} style={linkStyle}>
                    AngelList
                </Link>
                <Link href="#" to={"/"} style={linkStyle}>
                    Dribble
                </Link>
            </Stack>
            <Stack
                direction={"column"}
                alignItems={"flex-start"}
                justifyContent={"space-between"}
            >
                <Typography variant="body1" sx={titleStyle}>
                    Legal
                </Typography>
                <Link href="#" to={"/"} style={linkStyle}>
                    Terms
                </Link>
                <Link href="#" to={"/"} style={linkStyle}>
                    Privacy
                </Link>
                <Link href="#" to={"/"} style={linkStyle}>
                    Cookies
                </Link>
                <Link href="#" to={"/"} style={linkStyle}>
                    Licenses
                </Link>
                <Link href="#" to={"/"} style={linkStyle}>
                    Settings
                </Link>
                <Link href="#" to={"/"} style={linkStyle}>
                    Contact
                </Link>
            </Stack>
        </Stack>
    );
};

export default Footer;
