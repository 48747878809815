import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import React from "react";
import { LocationOnOutlined, tiktok } from "@mui/icons-material";
import { FaInstagram, FaLinkedinIn, FaTiktok, FaTwitter } from "react-icons/fa";
import { useState } from "react";
const ContactUs = ({ iconColor, buttonColor }) => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phNo, setPhNo] = useState("");
    const [company, setCompany] = useState("");
    const [message, setMessage] = useState("");

    const handleSend = () => {
        if (name != "" && email != "" && message != "") {
            const learnRequest = { name, email, phNo, company, message };

            setName("");
            setEmail("");
            setPhNo("");
            setCompany("");
            setMessage("");
        }
    };

    return (
        <>
            <Box
                sx={{
                    width: "50%",
                    display: { xs: "none", sm: "none", md: "flex" },
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    flexDirection: "column",
                    padding: 4,
                }}
            >
                <Typography
                    variant="h4"
                    sx={{
                        fontWeight: "700",
                        fontSize: "50px",
                        textAlign: "left",
                        color: "#fff",
                        marginBottom: 2,
                    }}
                >
                    Learn How Stratos Works!
                </Typography>
                <TextField
                    label="Name"
                    variant="standard"
                    required
                    value={name}
                    InputLabelProps={{
                        style: {
                            color: "#FFFFFF",
                        },
                        shrink: true,
                    }}
                    InputProps={{
                        style: {
                            color: "#FFFFFF",
                            fontSize: "15px",
                            borderBottom: "solid rgba(255, 255, 255, 0.3)",
                        },
                        inputProps: {
                            style: {
                                color: "#FFFFFF",
                                fontSize: "15px",
                            },
                        },
                    }}
                    fullWidth
                    sx={{ margin: 1 }}
                    onChange={(event) => {
                        setName(event.target.value);
                    }}
                />
                <TextField
                    label="Email"
                    variant="standard"
                    required
                    InputLabelProps={{
                        style: {
                            color: "#FFFFFF",
                        },
                        shrink: true,
                    }}
                    InputProps={{
                        style: {
                            color: "#FFFFFF",
                            fontSize: "15px",
                            borderBottom: "solid rgba(255, 255, 255, 0.3)",
                        },
                        inputProps: {
                            style: {
                                color: "#FFFFFF",
                                fontSize: "15px",
                            },
                        },
                    }}
                    fullWidth
                    sx={{ margin: 1 }}
                    value={email}
                    onChange={(event) => {
                        setEmail(event.target.value);
                    }}
                />
                <TextField
                    label="Phone Number"
                    variant="standard"
                    InputLabelProps={{
                        style: {
                            color: "#FFFFFF",
                        },
                        shrink: true,
                    }}
                    InputProps={{
                        style: {
                            color: "#FFFFFF",
                            fontSize: "15px",
                            borderBottom: "solid rgba(255, 255, 255, 0.3)",
                        },
                        inputProps: {
                            style: {
                                color: "#FFFFFF",
                                fontSize: "15px",
                            },
                        },
                    }}
                    fullWidth
                    sx={{ margin: 1 }}
                    value={phNo}
                    onChange={(event) => {
                        setPhNo(event.target.value);
                    }}
                />
                <TextField
                    label="Company"
                    variant="standard"
                    InputLabelProps={{
                        style: {
                            color: "#FFFFFF",
                        },
                        shrink: true,
                    }}
                    InputProps={{
                        style: {
                            color: "#FFFFFF",
                            fontSize: "15px",
                            borderBottom: "solid rgba(255, 255, 255, 0.3)",
                        },
                        inputProps: {
                            style: {
                                color: "#FFFFFF",
                                fontSize: "15px",
                            },
                        },
                    }}
                    fullWidth
                    value={company}
                    sx={{ margin: 1 }}
                    onChange={(event) => {
                        setCompany(event.target.value);
                    }}
                />
                <TextField
                    label="Message"
                    variant="standard"
                    required
                    InputLabelProps={{
                        style: {
                            color: "#FFFFFF",
                        },
                        shrink: true,
                    }}
                    rows={4}
                    value={message}
                    multiline
                    InputProps={{
                        style: {
                            color: "#FFFFFF",
                            fontSize: "15px",
                            borderBottom: "solid rgba(255, 255, 255, 0.3)",
                        },
                        inputProps: {
                            style: {
                                color: "#FFFFFF",
                                fontSize: "15px",
                            },
                        },
                    }}
                    fullWidth
                    sx={{ margin: 1 }}
                    onChange={(event) => {
                        setMessage(event.target.value);
                    }}
                />
                <Button
                    sx={{
                        color: "#FFFFFF",
                        background: buttonColor,
                        borderRadius: "100px",
                        paddingY: 1,
                        paddingX: 3,
                        fontSize: "18px",
                        gap: "8px",
                        cursor: "pointer",
                        border: "none",
                        textTransform: "none",
                        mt: 1,
                    }}
                    variant="contained"
                    onClick={handleSend}
                    disabled={
                        name != "" && email != "" && message != ""
                            ? false
                            : true
                    }
                >
                    Send
                </Button>
            </Box>
            <Box
                sx={{
                    width: "30%",
                    height: "100%",
                    display: { xs: "none", sm: "none", md: "flex" },
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    flexDirection: "column",
                    padding: 3,
                }}
            >
                <Typography
                    variant="h4"
                    sx={{
                        fontWeight: "700",
                        fontSize: "50px",
                        textAlign: "left",
                        color: "#fff",
                        marginBottom: 4,
                    }}
                >
                    Find us here!
                </Typography>
                <Stack
                    direction="column"
                    justifyContent={"flex-start"}
                    spacing={8}
                    sx={{ width: "100%", height: "100%" }}
                >
                    <Typography
                        variant="h4"
                        sx={{
                            fontWeight: "700",
                            fontSize: "32px",
                            textAlign: "left",
                            color: "#fff",
                        }}
                    >
                        <LocationOnOutlined
                            style={{
                                verticalAlign: "middle",
                                fontSize: "34px",

                                color: iconColor,
                            }}
                        />
                        <span
                            style={{
                                verticalAlign: "middle",
                                marginLeft: 12,
                            }}
                        >
                            Atlanta, GA
                        </span>
                    </Typography>
                    <a
                        href="https://www.tiktok.com/@trystratos"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: "none", border: 0 }}
                    >
                        <Typography
                            variant="h4"
                            sx={{
                                fontWeight: "700",
                                fontSize: "32px",
                                textAlign: "left",
                                color: "#fff",
                                cursor: "pointer",
                                textDecoration: "none",
                            }}
                        >
                            <FaTiktok
                                style={{
                                    verticalAlign: "middle",
                                    fontSize: "32px",
                                    color: iconColor,
                                }}
                            />
                            <span
                                style={{
                                    verticalAlign: "middle",
                                    marginLeft: 12,
                                }}
                            >
                                TikTok
                            </span>
                        </Typography>
                    </a>
                    <a
                        href="https://www.linkedin.com/company/trystratos/"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: "none", border: 0 }}
                    >
                        <Typography
                            variant="h4"
                            sx={{
                                fontWeight: "700",
                                fontSize: "32px",
                                textAlign: "left",
                                color: "#fff",
                                cursor: "pointer",
                                textDecoration: "none",
                            }}
                        >
                            <FaLinkedinIn
                                style={{
                                    verticalAlign: "middle",
                                    fontSize: "32px",

                                    color: iconColor,
                                }}
                            />
                            <span
                                style={{
                                    verticalAlign: "middle",
                                    marginLeft: 12,
                                }}
                            >
                                Linkedin
                            </span>
                        </Typography>
                    </a>
                    <a
                        href="https://www.instagram.com/trystratos/"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: "none", border: 0 }}
                    >
                        <Typography
                            variant="h4"
                            sx={{
                                fontWeight: "700",
                                fontSize: "32px",
                                textAlign: "left",
                                color: "#fff",
                                cursor: "pointer",
                                textDecoration: "none",
                            }}
                        >
                            <FaInstagram
                                style={{
                                    verticalAlign: "middle",
                                    fontSize: "32px",

                                    color: iconColor,
                                }}
                            />
                            <span
                                style={{
                                    verticalAlign: "middle",
                                    marginLeft: 12,
                                }}
                            >
                                Instagram
                            </span>
                        </Typography>
                    </a>
                    <a
                        href="https://twitter.com/trystratos"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: "none", border: 0 }}
                    >
                        <Typography
                            variant="h4"
                            sx={{
                                fontWeight: "700",
                                fontSize: "32px",
                                textAlign: "left",
                                color: "#fff",
                                cursor: "pointer",
                                textDecoration: "none",
                            }}
                        >
                            <FaTwitter
                                style={{
                                    verticalAlign: "middle",
                                    fontSize: "32px",

                                    color: iconColor,
                                }}
                            />
                            <span
                                style={{
                                    verticalAlign: "middle",
                                    marginLeft: 12,
                                }}
                            >
                                Twitter
                            </span>
                        </Typography>
                    </a>
                </Stack>
            </Box>

            {/*mobile version*/}

            <Box
                sx={{
                    display: { xs: "flex", sm: "flex", md: "none" },
                    justifyContent: "flex-start",
                    alignItems: "center",
                    flexDirection: "column",
                    padding: 2,
                }}
            >
                <Typography
                    variant="h4"
                    sx={{
                        fontWeight: "700",
                        fontSize: "40px",
                        textAlign: "center",
                        color: "#fff",
                        marginBottom: 2,
                    }}
                >
                    Learn How Stratos Works!
                </Typography>
                <TextField
                    label="Name"
                    variant="standard"
                    required
                    value={name}
                    InputLabelProps={{
                        style: {
                            color: "#FFFFFF",
                        },
                        shrink: true,
                    }}
                    InputProps={{
                        style: {
                            color: "#FFFFFF",
                            fontSize: "15px",
                            borderBottom: "solid rgba(255, 255, 255, 0.3)",
                        },
                        inputProps: {
                            style: {
                                color: "#FFFFFF",
                                fontSize: "15px",
                            },
                        },
                    }}
                    fullWidth
                    sx={{ margin: 1 }}
                    onChange={(event) => {
                        setName(event.target.value);
                    }}
                />
                <TextField
                    label="Email"
                    variant="standard"
                    required
                    InputLabelProps={{
                        style: {
                            color: "#FFFFFF",
                        },
                        shrink: true,
                    }}
                    InputProps={{
                        style: {
                            color: "#FFFFFF",
                            fontSize: "15px",
                            borderBottom: "solid rgba(255, 255, 255, 0.3)",
                        },
                        inputProps: {
                            style: {
                                color: "#FFFFFF",
                                fontSize: "15px",
                            },
                        },
                    }}
                    fullWidth
                    sx={{ margin: 1 }}
                    value={email}
                    onChange={(event) => {
                        setEmail(event.target.value);
                    }}
                />
                <TextField
                    label="Phone Number"
                    variant="standard"
                    InputLabelProps={{
                        style: {
                            color: "#FFFFFF",
                        },
                        shrink: true,
                    }}
                    InputProps={{
                        style: {
                            color: "#FFFFFF",
                            fontSize: "15px",
                            borderBottom: "solid rgba(255, 255, 255, 0.3)",
                        },
                        inputProps: {
                            style: {
                                color: "#FFFFFF",
                                fontSize: "15px",
                            },
                        },
                    }}
                    fullWidth
                    sx={{ margin: 1 }}
                    value={phNo}
                    onChange={(event) => {
                        setPhNo(event.target.value);
                    }}
                />
                <TextField
                    label="Company"
                    variant="standard"
                    InputLabelProps={{
                        style: {
                            color: "#FFFFFF",
                        },
                        shrink: true,
                    }}
                    InputProps={{
                        style: {
                            color: "#FFFFFF",
                            fontSize: "15px",
                            borderBottom: "solid rgba(255, 255, 255, 0.3)",
                        },
                        inputProps: {
                            style: {
                                color: "#FFFFFF",
                                fontSize: "15px",
                            },
                        },
                    }}
                    fullWidth
                    value={company}
                    sx={{ margin: 1 }}
                    onChange={(event) => {
                        setCompany(event.target.value);
                    }}
                />
                <TextField
                    label="Message"
                    variant="standard"
                    required
                    InputLabelProps={{
                        style: {
                            color: "#FFFFFF",
                        },
                        shrink: true,
                    }}
                    rows={4}
                    value={message}
                    multiline
                    InputProps={{
                        style: {
                            color: "#FFFFFF",
                            fontSize: "15px",
                            borderBottom: "solid rgba(255, 255, 255, 0.3)",
                        },
                        inputProps: {
                            style: {
                                color: "#FFFFFF",
                                fontSize: "15px",
                            },
                        },
                    }}
                    fullWidth
                    sx={{ margin: 1 }}
                    onChange={(event) => {
                        setMessage(event.target.value);
                    }}
                />
                <Button
                    sx={{
                        color: "#FFFFFF",
                        background: buttonColor,
                        borderRadius: "100px",
                        paddingY: 1,
                        paddingX: 3,
                        fontSize: "18px",
                        gap: "8px",
                        cursor: "pointer",
                        border: "none",
                        textTransform: "none",
                        mt: 1,
                    }}
                    variant="contained"
                    onClick={handleSend}
                    disabled={
                        name != "" && email != "" && message != ""
                            ? false
                            : true
                    }
                >
                    Send
                </Button>
            </Box>
            <Box
                sx={{
                    display: { xs: "flex", sm: "flex", md: "none" },
                    justifyContent: "flex-start",
                    alignItems: "center",
                    flexDirection: "column",
                    padding: 2,
                    paddingBottom: 4,
                    width: "100%",
                }}
            >
                <Typography
                    variant="h4"
                    sx={{
                        fontWeight: "700",
                        fontSize: "40px",
                        textAlign: "center",
                        color: "#fff",
                        marginBottom: 3,
                    }}
                >
                    Find us here!
                </Typography>
                <Stack
                    direction="column"
                    justifyContent={"flex-start"}
                    spacing={8}
                >
                    <Typography
                        variant="h4"
                        sx={{
                            fontWeight: "700",
                            fontSize: "32px",
                            textAlign: "left",
                            color: "#fff",
                        }}
                    >
                        <LocationOnOutlined
                            style={{
                                verticalAlign: "middle",
                                fontSize: "34px",

                                color: iconColor,
                            }}
                        />
                        <span
                            style={{
                                verticalAlign: "middle",
                                marginLeft: 12,
                            }}
                        >
                            Atlanta, GA
                        </span>
                    </Typography>
                    <a
                        href="https://www.tiktok.com/@trystratos"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: "none", border: 0 }}
                    >
                        <Typography
                            variant="h4"
                            sx={{
                                fontWeight: "700",
                                fontSize: "32px",
                                textAlign: "left",
                                color: "#fff",
                                cursor: "pointer",
                                textDecoration: "none",
                            }}
                        >
                            <FaTiktok
                                style={{
                                    verticalAlign: "middle",
                                    fontSize: "32px",
                                    color: iconColor,
                                }}
                            />
                            <span
                                style={{
                                    verticalAlign: "middle",
                                    marginLeft: 12,
                                }}
                            >
                                TikTok
                            </span>
                        </Typography>
                    </a>
                    <a
                        href="https://www.linkedin.com/company/trystratos/"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: "none", border: 0 }}
                    >
                        <Typography
                            variant="h4"
                            sx={{
                                fontWeight: "700",
                                fontSize: "32px",
                                textAlign: "left",
                                color: "#fff",
                                cursor: "pointer",
                                textDecoration: "none",
                            }}
                        >
                            <FaLinkedinIn
                                style={{
                                    verticalAlign: "middle",
                                    fontSize: "32px",

                                    color: iconColor,
                                }}
                            />
                            <span
                                style={{
                                    verticalAlign: "middle",
                                    marginLeft: 12,
                                }}
                            >
                                Linkedin
                            </span>
                        </Typography>
                    </a>
                    <a
                        href="https://www.instagram.com/trystratos/"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: "none", border: 0 }}
                    >
                        <Typography
                            variant="h4"
                            sx={{
                                fontWeight: "700",
                                fontSize: "32px",
                                textAlign: "left",
                                color: "#fff",
                                cursor: "pointer",
                                textDecoration: "none",
                            }}
                        >
                            <FaInstagram
                                style={{
                                    verticalAlign: "middle",
                                    fontSize: "32px",

                                    color: iconColor,
                                }}
                            />
                            <span
                                style={{
                                    verticalAlign: "middle",
                                    marginLeft: 12,
                                }}
                            >
                                Instagram
                            </span>
                        </Typography>
                    </a>
                    <a
                        href="https://twitter.com/trystratos"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: "none", border: 0 }}
                    >
                        <Typography
                            variant="h4"
                            sx={{
                                fontWeight: "700",
                                fontSize: "32px",
                                textAlign: "left",
                                color: "#fff",
                                cursor: "pointer",
                                textDecoration: "none",
                            }}
                        >
                            <FaTwitter
                                style={{
                                    verticalAlign: "middle",
                                    fontSize: "32px",

                                    color: iconColor,
                                }}
                            />
                            <span
                                style={{
                                    verticalAlign: "middle",
                                    marginLeft: 12,
                                }}
                            >
                                Twitter
                            </span>
                        </Typography>
                    </a>
                </Stack>
            </Box>
        </>
    );
};

export default ContactUs;
