import React from 'react'
// import "../Pages/DirectMessaging/Home.scss";
import Messages from "./Messages";
import Input from "./Input";

const Chat = () => {
    return (
        <div className='chat'>
            <div className="chatInfo">
                <span>Jane</span>
            </div>
            <Messages/>
            <Input/>
        </div>
    )
}

export default Chat;