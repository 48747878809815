import axios from "axios";

const API = axios.create({
    baseURL: "https://us-central1-stratosv1.cloudfunctions.net/api",
});
// api url: https://us-central1-stratosv1.cloudfunctions.net/api

const getNotifications = async ({ token }) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };

    const response = await API.get("/notification/getNotifications", config, {
        headers: {
            Access: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        },
    });

    return response.data;
};

const createNotification = async (notificationData, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };

    const response = await API.post(
        "/notification/createNotification",
        notificationData,
        config
    );

    return response.data;
};

const notificationsService = { getNotifications, createNotification };

export default notificationsService;
