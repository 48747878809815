import { ArrowBack } from "@mui/icons-material";
import {
  AppBar,
  Box,
  Button,
  Divider,
  IconButton,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import stratosLogo from "../Assets/stratos-logo.png";

const TermsOfService = () => {
  const navigate = useNavigate();
  return (
    <Box>
      <AppBar position="static">
        <Toolbar
          disableGutters
          sx={{
            bgcolor: "#f2f2f2",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
          }}
        >
          <Button onClick={() => navigate("/")}>
            <img alt="stratos logo" src={stratosLogo} />
          </Button>
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBack color="primary" />
            <Typography marginLeft={1}>GO BACK</Typography>
          </IconButton>
        </Toolbar>
      </AppBar>

      <Stack
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
        pt={1}
      >
        <Box sx={{ width: "60%" }}>
          <Typography
            variant="h4"
            sx={{
              padding: 1,
              fontFamily:
                '"-apple-system", "BlinkMacSystemFont", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans"',
            }}
          >
            Stratos Terms of Services
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{
              padding: 1,
              color: "gray",
              fontSize: "12px",
              fontFamily:
                '"-apple-system", "BlinkMacSystemFont", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans"',
            }}
          >
            Last Updated: 6 April, 2023
          </Typography>

          <Divider />
          <Typography
            sx={{
              padding: 1,
              fontFamily: "Times New Roman",
              textAlign: "justify",
              fontSize: "18px",
            }}
          >
            Please read this terms of service agreement (the “terms of service”)
            carefully. These terms of service are an agreement between you and
            stratos solutions inc., a delaware corporation (“stratos,” “we,” or
            “us”), and govern your access and use of stratos’ website(s),
            stratos-branded apps and other stratos-related sites,
            communications, apps and other services to which these terms of
            service are linked, including the services and resources available
            on or enabled thereon (collectively, the “services”). Registered
            users of our services are “members”and unregistered users are
            “visitors”. By clicking “i accept” or similar, registering for an
            account (as defined below), accessing our services and/or using our
            services you represent that (1) you have read, understand, and agree
            to be bound by these terms of service, (2) you are of legal age to
            form a binding contract with stratos without parental consent
            (including using of your personal data), and (3) you have the right,
            authority, and capacity to enter into the terms of service (on
            behalf of yourself and, as applicable, the entity that you
            represent). If the individual entering into these terms or otherwise
            accessing or using the services is doing so on behalf of, or within
            his, her or their capacity as a representative, agent, or employee
            of an entity, such individual and such entity agree that: (i) “you”
            and “your” as used herein apply to such entity and, as applicable,
            such individual; and (ii) you represent and warrant that the
            individual entering into these terms has the power, right,
            authority, and capacity to enter into these terms on behalf of such
            entity. If you do not agree to be bound by the terms of service, you
            may not access or use the services.
          </Typography>
          <Typography
            sx={{
              padding: 1,
              fontFamily: "Times New Roman",
              textAlign: "justify",
              fontSize: "18px",
              fontWeight: "bold",
            }}
          >
            Please be aware that section 10 (dispute resolution) of this
            agreement, below, contains provisions governing how disputes that
            you and we have against each other are resolved, including, without
            limitation, any disputes that arose or were asserted prior to the
            effective date of this agreement. In particular, it contains an
            arbitration agreement which will, with limited exceptions, require
            disputes between us to be submitted to binding and final
            arbitration. Unless you opt out of the arbitration agreement: (1)
            you will only be permitted to pursue disputes or claims and seek
            relief against us on an individual basis, not as a plaintiff or
            class member in any class or representative action or proceeding;
            and (2) you are waiving your right to pursue disputes or claims and
            seek relief in a court of law and to have a jury trial.
          </Typography>
          <Typography
            sx={{
              padding: 1,
              fontFamily: "Times New Roman",
              textAlign: "justify",
              fontSize: "18px",
              fontWeight: "bold",
            }}
          >
            Any dispute, claim or request for relief relating in any way to your
            use of the services will be governed and interpreted by and under
            the laws of the state of delaware, consistent with the federal
            arbitration act, without giving effect to any principles that
            provide for the application of the law of any other jurisdiction.
          </Typography>
          <Typography
            sx={{
              padding: 1,
              fontFamily: "Times New Roman",
              textAlign: "justify",
              fontSize: "18px",
              fontWeight: "bold",
            }}
          >
            Please be aware that section 12.2 (stratos communications) of this
            agreement, below, contains your opt-in consent to receive
            communications from us, including via email, text message, calls and
            push notification.
          </Typography>
          <Typography
            sx={{
              padding: 1,
              fontFamily: "Times New Roman",
              textAlign: "justify",
              fontSize: "18px",
            }}
          >
            Your use of, and participation in, certain portions of the Services
            may be subject to additional terms (“Supplemental Terms”) and such
            Supplemental Terms will either be listed in the Terms of Service or
            will be presented to you for your acceptance when you sign up to use
            the supplemental portions of the Services. If the Terms of Service
            are inconsistent with the Supplemental Terms, the Supplemental Terms
            shall control with respect to such portions of the Services. The
            Terms of Service and any applicable Supplemental Terms are referred
            to herein as the “Agreement.”
          </Typography>
          <Typography
            sx={{
              padding: 1,
              fontFamily: "Times New Roman",
              textAlign: "justify",
              fontSize: "18px",
              fontWeight: "bold",
            }}
          >
            Please note that the agreement is subject to change by stratos in
            its sole discretion at any time in accordance with section 12.1.
            Please regularly check the terms of service to view the then-current
            terms.
          </Typography>
        </Box>
      </Stack>
      <Divider sx={{ marginTop: 4 }} />
    </Box>
  );
};

export default TermsOfService;
