import {
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
// import { LogoutMixPanel } from "../../../MixPanel";
import { logout } from "../../../features/auth/authSlice";
import { resetToDefault } from "../../../features/messages/messageSlice";
import ChatLists from "./ChatLists";
import ChatMessages from "./ChatMessages";

const DirectMessaging = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const { message } = useSelector((state) => state.messages);

  // logout if token error from BE
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  useEffect(() => {
    if (
      message == "auth/argument-error" ||
      message == "auth/id-token-expired"
    ) {
      // LogoutMixPanel(chatUserLocationState.email);
      dispatch(resetToDefault());
      dispatch(logout());
      setShowErrorAlert(true);
    }
  }, [message]);

  // check if user reached messages by clicking on message button from profile
  const [chatUserLocationState, setChatUserLocationState] = useState(null);
  useEffect(() => {
    if (location.state && location.state.chatUser) {
      setChatUserLocationState(location.state.chatUser);
    }
  }, []);

  const [currentChatUser, setCurrentChatUser] = useState(null);
  const [currentChatUserIndex, setCurrentChatUserIndex] = useState(0);

  // set the width of messages to be 25% of the window width
  const [containerWidth, setContainerWidth] = useState("25%");
  useEffect(() => {
    function handleResize() {
      const windowWidth = window.innerWidth;
      const newWidth =
        windowWidth <= 1200
          ? `${25 + ((1200 - windowWidth) / 100) * 5}%`
          : "25%";
      setContainerWidth(newWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Box
      sx={{
        paddingLeft: 5,
        paddingRight: 5,
        height: "90vh",
      }}
    >
      {showErrorAlert && (
        <Dialog
          open={showErrorAlert}
          onClose={() => {
            console.log("message", message);
            console.log("alert close");
            setShowErrorAlert(false);
            navigate("/Welcome");
          }}
        >
          <DialogTitle>{"Info"}</DialogTitle>
          <DialogContent>
            <DialogContentText>{"You have signed out! "}</DialogContentText>
          </DialogContent>
        </Dialog>
      )}
      <Toolbar />
      <Stack
        direction="row"
        spacing={0}
        padding={0}
        divider={<Divider orientation="vertical" flexItem />}
        sx={{
          backgroundColor: "#fff",
          borderRadius: 2,
          width: "100%",
          height: "90%",
        }}
      >
        <Box sx={{ width: "400px" }}>
          <Typography
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              paddingTop: 2,
              paddingLeft: 3,
              height: "11%",
              color: "#14213D",
              fontWeight: "700",
              fontSize: 20,
            }}
          >
            Messages
          </Typography>
          <ChatLists
            //setCurrentChatUser={setCurrentChatUser}
            setCurrentChatUserIndex={setCurrentChatUserIndex}
            currentChatUserIndex={currentChatUserIndex}
            chatUserLocationState={chatUserLocationState}
            setChatUserLocationState={setChatUserLocationState}
          />
        </Box>

        <ChatMessages
        //currentChatUser={currentChatUser}
        //setCurrentChatUser={setCurrentChatUser}
        />
      </Stack>
      {/*<Grid
                container
                component={Paper}
                elevation={3}
                sx={{ borderRadius: 2, overflow: "hidden" }}
            >
                <Grid item xs={3}>
                    <Typography
                        sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            paddingLeft: 1,
                            height: "10%",
                            color: "#14213D",
                            fontWeight: "700",
                            fontSize: 20,
                        }}
                    >
                        Messages
                    </Typography>
                    <Box sx={{ p: 2, height: "calc(100vh - 250px)" }}>
                        <ChatLists
                            //setCurrentChatUser={setCurrentChatUser}
                            setCurrentChatUserIndex={setCurrentChatUserIndex}
                            currentChatUserIndex={currentChatUserIndex}
                            chatUserLocationState={chatUserLocationState}
                            setChatUserLocationState={setChatUserLocationState}
                        />
                    </Box>
                </Grid>

                <Grid item xs={9} sx={{ borderLeft: "1px solid gray" }}>
                    <ChatMessages
                    //currentChatUser={currentChatUser}
                    //setCurrentChatUser={setCurrentChatUser}
                    />
                </Grid>
            </Grid>*/}
    </Box>
  );
};

export default DirectMessaging;
