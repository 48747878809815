import { Box, Divider, Typography } from "@mui/material";
import React, { memo } from "react";
import { useSelector } from "react-redux";
import MessagesComponent from "./MessagesComponent";
import UserDataCard from "./UserDataCard";

const ChatMessages = () => {
  const { currentChatUser } = useSelector((state) => state.messages);

  return currentChatUser ? (
    <Box sx={{ paddingLeft: "1", width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          width: "100%",
          height: "11%",
        }}
      >
        {currentChatUser !== null && <UserDataCard chat={currentChatUser} />}
      </Box>
      <Divider orientation="horizontal" flexItem />
      {currentChatUser !== null && (
        <MessagesComponent
          currentChatUser={currentChatUser}
          key={currentChatUser.id}
          //otherUserAvatar={
          //    currentChatUser.otherUserData.profileImage.url
          //}
          //setCurrentChatUser={setCurrentChatUser}
        />
      )}
    </Box>
  ) : (
    <Box sx={{ paddingLeft: "1", width: "63%" }}>
      <Typography
        variant="body2"
        color="#52B1BB"
        textAlign={"center"}
        sx={{
          fontSize: "15px",
          whiteSpace: "nowrap",
          fontWeight: 500,
          padding: 1,
          marginTop: 11,
        }}
      >
        No chat selected!
      </Typography>
    </Box>
  );
};

export default memo(ChatMessages);
