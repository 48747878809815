import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import messageService from "./messageService";

const initialState = {
    chatMessages: [],
    tempChats: [],
    chats: [],
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
    currentChatUser: null,
    unreadMessagesCount: 0,
};

export const getAllUserChats = createAsyncThunk(
    "messages/getAllUserChats",
    async (_, thunkAPI) => {

        try {
            const token = thunkAPI.getState().auth.token;

            const response = await messageService.getAllUserChats({
                token,
            });
            return response;
        } catch (error) {
            const message =
                error.response &&
                error.response.data &&
                error.response.data.error
                    ? error.response.data.error
                    : "";
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const getAllMessages = createAsyncThunk(
    "message/getAllMessages",
    async (otherUser, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.token;
            const response = await messageService.getAllMessages(
                otherUser,
                token
            );
            const chats = thunkAPI.getState().messages.tempChats;
            const index = chats.findIndex((obj) => obj.otherUser === otherUser);
            // get last message
            const lastMsg = response.messages[response.messages.length - 1];
            const lastMessage = {
                at: lastMsg.createdAt,
                by: lastMsg.author.name,
                by_ID: lastMsg.author.id,
                content: lastMsg.content,
            };
            return { response, index, lastMessage };
        } catch (error) {
            const message =
                error.response &&
                error.response.data &&
                error.response.data.error
                    ? error.response.data.error
                    : "";
            return thunkAPI.rejectWithValue(message);
        }
    }
);
export const createChat = createAsyncThunk(
    "message/createChat",
    async (otherUser, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.token;
            const authorId = thunkAPI.getState().auth.user.userId;
            const chats = thunkAPI.getState().messages.chats;
            let newChat = {};

            const index = chats.findIndex((obj) => obj.otherUser === otherUser);
            if (index === -1) {
                // create temp chat in front end
                // 1. get other user data
                const response = await messageService.getOtherUserData(
                    otherUser,
                    token
                );

                const chatID =
                    otherUser > authorId
                        ? otherUser + authorId
                        : authorId + otherUser;
                newChat = {
                    id: chatID,
                    lastMessage: { at: "", by: "", content: "" },
                    otherUser,
                    otherUserData: response,
                    unreadMessages: 0,
                };

                thunkAPI.dispatch(addTempChat(newChat));

            } else {
                newChat = chats[index];

            }

            return newChat;
        } catch (error) {

            const message =
                error.response &&
                error.response.data &&
                error.response.data.error
                    ? error.response.data.error
                    : "";
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const setAllMessagesRead = createAsyncThunk(
    "message/setAllMessagesRead",
    async (otherUser, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.token;

            await messageService.setAllMessagesRead(otherUser, token);
            // get index of other user in tempchats
            const chats = thunkAPI.getState().messages.tempChats;
            const index = chats.findIndex((obj) => obj.otherUser === otherUser);
            return index;
        } catch (error) {
            const message =
                error.response &&
                error.response.data &&
                error.response.data.error
                    ? error.response.data.error
                    : "";
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const getUnreadMessagesCount = createAsyncThunk(
    "message/getUnreadMessagesCount",
    async (_, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.token;
            const response = await messageService.getUnreadMessagesCount({
                token,
            });

            return response;
        } catch (error) {
            const message =
                error.response &&
                error.response.data &&
                error.response.data.error
                    ? error.response.data.error
                    : "";
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const createMessage = createAsyncThunk(
    "message/createMessage",
    async ({ otherUser, content }, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.token;

            const response = await messageService.createMessage(
                { otherUser, content },
                token
            );

            return response;
        } catch (error) {
            const message =
                error.response &&
                error.response.data &&
                error.response.data.error
                    ? error.response.data.error
                    : "";
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const setTempChats = createAsyncThunk(
    "message/setTempChats",
    async (_, thunkAPI) => {
        try {
            const chats = thunkAPI.getState().messages.chats;
            let tempChats =
                thunkAPI.getState().messages.tempChats.length > 0
                    ? [...thunkAPI.getState().messages.tempChats]
                    : [];

            chats.forEach((obj2) => {
                // Check if the otherUser value already exists in chats
                const exists = tempChats.some(
                    (obj1) => obj1.otherUser === obj2.otherUser
                );

                if (!exists) {
                    // If the otherUser value doesn't exist, add the object to tempChats
                    tempChats.push(obj2);
                }
            });
            return tempChats;
        } catch (error) {

            const message =
                error.response &&
                error.response.data &&
                error.response.data.error
                    ? error.response.data.error
                    : "";
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const messageSlice = createSlice({
    name: "messages",
    initialState,
    reducers: {
        addTempChat: (state, action) => {
            state.tempChats.unshift(action.payload);
        },
        resetChatMessages: (state) => {
            state.chatMessages = [];
        },
        resetToDefault: (state) => {
            state.isError = false;
            state.isSuccess = false;
            state.isLoading = false;
            state.message = "";
        },
        resetAll: (state) => {
            state.isError = false;
            state.isSuccess = false;
            state.isLoading = false;
            state.message = "";
            state.chatMessages = false;
            state.tempChats = false;
            state.chats = false;
        },
        updateLastMessage: (state, action) => {
            const index = state.tempChats.findIndex(
                (obj) => obj.id === action.payload.currentChatId
            );
            const lastMessage = {
                at: state.chatMessages[state.chatMessages.length - 1].createdAt,
                by: state.chatMessages[state.chatMessages.length - 1].author
                    .name,
                by_ID: state.chatMessages[state.chatMessages.length - 1].author
                    .id,
                content:
                    state.chatMessages[state.chatMessages.length - 1].content,
            };
            state.tempChats[index].lastMessage = lastMessage;
        },
        updateCurrentChatUser: (state, action) => {
            const chatId = action.payload;
            const index = state.tempChats.findIndex((obj) => obj.id === chatId);
            state.currentChatUser = state.tempChats[index];
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllUserChats.pending, (state) => {
                state.isLoading = true;
                state.isSuccess = false;
                state.isError = false;
            })
            .addCase(getAllUserChats.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.chats = action.payload.chats;
            })
            .addCase(getAllUserChats.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.payload;
            })
            .addCase(getAllMessages.pending, (state) => {
                state.isLoading = true;
                state.isSuccess = false;
                state.isError = false;
            })
            .addCase(getAllMessages.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.chatMessages = action.payload.response.messages;
                state.tempChats[action.payload.index].lastMessage =
                    action.payload.lastMessage;
            })
            .addCase(getAllMessages.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.payload;
            })
            .addCase(createChat.pending, (state) => {
                state.isLoading = true;
                state.isSuccess = false;
                state.isError = false;
            })
            .addCase(createChat.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
            })
            .addCase(createChat.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.payload;
            })
            .addCase(createMessage.pending, (state) => {
                state.isLoading = true;
                state.isSuccess = false;
                state.isError = false;
            })
            .addCase(createMessage.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.chatMessages.push(action.payload.message);
            })
            .addCase(createMessage.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.payload;
            })
            .addCase(setTempChats.pending, (state) => {
                state.isLoading = true;
                state.isSuccess = false;
                state.isError = false;
            })
            .addCase(setTempChats.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.tempChats = action.payload;
                state.currentChatUser = state.tempChats[0];
            })
            .addCase(setTempChats.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.payload;
            })
            .addCase(setAllMessagesRead.pending, (state) => {
                state.isLoading = true;
                state.isSuccess = false;
                state.isError = false;
            })
            .addCase(setAllMessagesRead.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                let unreadMsgs = state.tempChats[action.payload].unreadMessages;
                state.tempChats[action.payload].unreadMessages = 0;
                state.unreadMessagesCount =
                    state.unreadMessagesCount - unreadMsgs;
            })
            .addCase(setAllMessagesRead.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.payload;
            })
            .addCase(getUnreadMessagesCount.pending, (state) => {
                state.isLoading = true;
                state.isSuccess = false;
                state.isError = false;
            })
            .addCase(getUnreadMessagesCount.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.unreadMessagesCount = action.payload.totalUnreadMessages;
            })
            .addCase(getUnreadMessagesCount.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.payload;
            });
    },
});
export const {
    addTempChat,
    resetChatMessages,
    resetToDefault,
    updateLastMessage,
    updateCurrentChatUser,
    resetAll,
} = messageSlice.actions;
export default messageSlice.reducer;
