import React, { useState } from 'react';
import {collection, getDocs, query, where} from "firebase/firestore";

import {db} from "../firebase";

const Search = () => {
    const [username, setUsername] = useState("");
    const [user, setUser] = useState(null);
    const [err, setErr] = useState(false);


    const handleSearch =  async () => {
        const q = query(collection(db,  "users"), where("displayName", "==", user));


        try {

        
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            setUser(doc.data())
        });
        } catch(err) {
            setErr(true)
        }
    };

    const handleKey = (e) => {
        e.code === "Enter" && handleSearch();
    }
    return (
        <div className='search'>
            <div className='searchForm'>
                <input type="text" placeholder='Find a user' onKeyDown={handleKey} onChange={e=>setUsername(e.target.value)}/> 
            </div>
            <div className='userChat'>
                <img src="https://images.pexels.com/photos/15127334/pexels-photo-15127334.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt=""/>
                <div className="userChatInfo">
                    <span>Jane</span>
                </div>
            </div>

        </div>
    )
}

export default Search;