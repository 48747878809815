import axios from "axios";

const API = axios.create({
  baseURL: "https://us-central1-stratosv1.cloudfunctions.net/api",
});

// api url: https://us-central1-stratosv1.cloudfunctions.net/api
// localhost serving: http://127.0.0.1:5001/stratosv1/us-central1/api
// Emulator UI: http://127.0.0.1:4000
// Web App: http://localhost:8080/

// get layers db
const getLayers = async () => {
  const response = API.get("/layers");
  return (await response).data;
};

// create new layer
const createLayer = async (layerData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await API.post("/layers/create", layerData, config);
  let result = response.data;
  if (result.layer) {
    console.log("{ name, layer: result } --> ", {
      name: layerData.name,
      layer: result.layer,
    });
    return { name: layerData.name, layer: result.layer };
  }

  return result;
};

const layersService = { getLayers, createLayer };

export default layersService;
