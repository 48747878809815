import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogTitle,
  Paper,
  Popover,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  checkUser,
  login,
  register,
  reset,
} from "../../features/auth/authSlice";

import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import logo from "../../Assets/logo.png";
import { companyIllustration } from "../../Assets/utilFunctions";
import { TrackRegister } from "../../MixPanel";
import Spinner from "../../components/Spinner";
import { companies } from "../constants";
import CommunityGuidelines from "./CommunityGuidelines";
import "./Signup.css";
import TermsOfService from "./TermsOfService";

const SignUp = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const { isLoading, isSuccess, isError, message, user } = useSelector(
    (state) => state.auth
  );
  const [type, setType] = useState("text");
  const params = new URLSearchParams(location.search);
  const userEmail = params.get("email");
  const refCode = params.get("ref");
  console.log("refCode", refCode);

  useEffect(() => {
    if (userEmail) {
      console.log("userEmail", userEmail);
      dispatch(checkUser({ email: userEmail }));
    }
  }, [userEmail]);

  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [showNotApprovedAlert, setShowNotApprovedAlert] = useState(false);
  const [openCG, setOpenCG] = useState(false);
  const [openTOS, setOpenTOS] = useState(false);

  const [userData, setUserData] = useState({
    firstName: user?.firstName ? user.firstName : "",
    lastName: user?.lastName ? user.lastName : "",
    jobTitle: user?.jobTitle ? user.jobTitle : "",
    currentCompany: user?.currentCompany ? user.currentCompany : "",
    email: userEmail ? userEmail : "",
    password: "",
    confirmPassword: "",
  });

  const {
    firstName,
    lastName,
    jobTitle,
    currentCompany,
    email,
    password,
    confirmPassword,
  } = userData;

  const onChangeInput = (e) => {
    setUserData((inputTexts) => ({
      ...inputTexts,
      [e.target.name]: e.target.value,
    }));
  };

  //call the action here..
  const updateUsersDB = (e) => {
    e.preventDefault();

    const userData = {
      name: firstName + " " + lastName,
      firstName,
      jobTitle,
      currentCompany,
      email,
      password,
      confirmPassword,
    };

    const refCode = params.get("ref");
    console.log("refCode", refCode);

    dispatch(register({ user: userData, refCode }));
  };

  //regex validators
  const emailValidation =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
  const nameValidate = /^[a - zA - Z]+$/;
  const passwordValidate = /^.{7,}$/i;

  const validateInputsSignUp = () => {
    const { firstName, lastName, email, password, confirmPassword } = userData;
    console.log(userData);

    if (
      nameValidate.test(firstName) &&
      nameValidate.test(lastName) &&
      emailValidation.test(email) &&
      passwordValidate.test(password) &&
      passwordValidate.test(confirmPassword)
    ) {
      if (password !== confirmPassword) {
        // alert("Password and Confirm Password does not match!");
        return false;
      }

      return true;
    }

    return false;
  };

  useEffect(() => {
    // sign up is true, dispatch sign up

    const name = firstName + " " + lastName;
    if (isSuccess) {
      TrackRegister(name, email, jobTitle, currentCompany);
      dispatch(reset());
      navigate("/HomeScreen", {
        state: { showOnboarding: true },
      });
    }

    if (isError) {
      setShowErrorAlert(true);
      if (message.length === 27) {
        setShowNotApprovedAlert(true);
      } else {
        setShowNotApprovedAlert(false);
      }
    }
  }, [isSuccess, isError]);

  // update fields with db valid user data
  useEffect(() => {
    if (user) {
      setUserData((inputTexts) => ({
        ...inputTexts,
        firstName: user.firstName,
        lastName: user.lastName,
        currentCompany: user.currentCompany,
        jobTitle: user.jobTitle,
      }));
    }
  }, [user]);

  const signInUser = (event) => {
    event.preventDefault();
    const userData = { email, password };
    //dispatch signIn
    dispatch(login(userData));
  };

  if (isLoading) {
    return <Spinner />;
  }

  const isDisabled = () => {
    if (
      firstName != "" &&
      lastName != "" &&
      email != "" &&
      password != "" &&
      confirmPassword != "" &&
      password == confirmPassword
    ) {
      return false;
    } else {
      return true;
    }
  };

  const handleClickOpenCG = () => {
    setOpenCG(true);
  };

  const handleCloseCG = () => {
    setOpenCG(false);
  };
  const handleClickOpenTOS = () => {
    setOpenTOS(true);
  };

  const handleCloseTOS = () => {
    setOpenTOS(false);
  };

  return (
    <>
      {showErrorAlert && (
        <Dialog
          open={showErrorAlert}
          onClose={() => {
            console.log("alert close");
            dispatch(reset());
            setShowNotApprovedAlert(false);
            setShowErrorAlert(false);
            navigate("/Welcome");
          }}
        >
          <DialogTitle textAlign={"center"}>{"Error"}</DialogTitle>
          <DialogContent>
            <DialogContentText textAlign={"center"}>
              {"Error Details: " + message}
            </DialogContentText>{" "}
            {showNotApprovedAlert && (
              <>
                <DialogContentText
                  textAlign={"center"}
                  open={showNotApprovedAlert}
                >
                  We cannot auto-approve your account,{" "}
                  {/* <span
                    style={{
                      fontWeight: "bolder",
                      cursor: "pointer",
                    }}
                    aria-owns={open ? "mouse-over-popover" : undefined}
                    aria-haspopup="true"
                    onMouseEnter={handlePopoverOpen}
                    onMouseLeave={handlePopoverClose}
                  >
                    partner
                  </span>{" "} */}
                  submit your info{" "}
                  <span
                    style={{
                      fontWeight: "bolder",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      console.log("not approved error close");
                      dispatch(reset());
                      setShowNotApprovedAlert(false);
                      setShowErrorAlert(false);
                      navigate("/Request");
                    }}
                  >
                    here
                  </span>{" "}
                  to get your account approved.
                </DialogContentText>
                <Popover
                  id="mouse-over-popover"
                  sx={{
                    pointerEvents: "none",
                  }}
                  open={open}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  onClose={handlePopoverClose}
                  disableRestoreFocus
                >
                  <Stack direction={"row"}>
                    {companies.map(
                      (company) =>
                        !["Non-Partner Company", "Stealth"].includes(
                          company
                        ) && (
                          <Stack alignItems={"center"}>
                            <Avatar
                              src={companyIllustration(company)}
                              sx={{
                                width: "40px",
                                height: "40px",
                                margin: 1,
                                borderRadius: 0,
                              }}
                            />
                            <Typography sx={{ p: 1 }}>{company}</Typography>
                          </Stack>
                        )
                    )}
                  </Stack>
                </Popover>{" "}
              </>
            )}
          </DialogContent>
        </Dialog>
      )}

      <Box className="signup2-container">
        <img src={logo} alt="logo" />
        <Paper
          className="signup2-form-container"
          sx={{
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            className="form-heading"
            sx={{
              width: "80%",
              "@media(max-width: 780px)": {
                width: "74%",
              },
              "@media(max-width: 450px)": { width: "84%" },
              "@media(max-width: 375px)": { width: "86%" },
            }}
          >
            <IconButton size={"small"} onClick={() => navigate("/Welcome")}>
              <ArrowBackIcon className="arrow-icon" />
            </IconButton>
          </Box>
          <Typography variant="h6" className="form-heading-text">
            Create Account
          </Typography>
          {/* <Typography variant="h5" mt={1} className="form-enter-the-details">
            Enter the details below
          </Typography> */}
          {/* <placeholder for="Name"></placeholder> */}
          <Box className="signup-form" style={{ marginTop: "10px" }}>
            <Stack direction="column" spacing={2}>
              <TextField
                type={"text"}
                label="First Name"
                name="firstName"
                value={firstName}
                onChange={onChangeInput}
                required
                // error={isError && firstName.trim() === ""}
                // helperText={
                //   firstName.trim() === "" ? "First name is required" : ""
                // }
                size="small"
                variant="outlined"
                fullWidth
                inputProps={{
                  style: { fontSize: 10 },
                }} // font size of input text
                InputLabelProps={{
                  shrink: true,
                  style: { fontSize: 10 },
                }}
              />

              <TextField
                type="text"
                label="Last Name"
                name="lastName"
                value={lastName}
                onChange={onChangeInput}
                required
                // error={isError && lastName.trim() === ""}
                // helperText={
                //   lastName.trim() === "" ? "Last name is required" : ""
                // }
                size="small"
                variant="outlined"
                fullWidth
                inputProps={{
                  style: { fontSize: 10 },
                }} // font size of input text
                InputLabelProps={{
                  shrink: true,
                  style: { fontSize: 10 },
                }}
              />

              <TextField
                type="email"
                label="Email"
                name="email"
                value={email}
                onChange={onChangeInput}
                required
                // error={isError && email.trim() === ""}
                // helperText={email.trim() === "" ? "Email is required" : ""}
                size="small"
                variant="outlined"
                fullWidth
                style={{ width: "100%" }}
                inputProps={{
                  style: { fontSize: 10 },
                }} // font size of input text
                InputLabelProps={{
                  shrink: true,
                  style: { fontSize: 10 },
                }}
              />
              <TextField
                type="password"
                label="Password"
                name="password"
                value={password}
                onChange={onChangeInput}
                required
                // error={password.trim() === ""}
                size="small"
                variant="outlined"
                fullWidth
                inputProps={{
                  style: { fontSize: 10 },
                }} // font size of input text
                InputLabelProps={{
                  shrink: true,
                  style: { fontSize: 10 },
                }}
              />

              <TextField
                type="password"
                label="Confirm Password"
                name="confirmPassword"
                value={confirmPassword}
                onChange={onChangeInput}
                required
                error={password !== confirmPassword}
                helperText={
                  password !== confirmPassword ? "Passwords do not match." : ""
                }
                size="small"
                variant="outlined"
                fullWidth
                inputProps={{
                  style: { fontSize: 10 },
                }} // font size of input text
                InputLabelProps={{
                  shrink: true,
                  style: { fontSize: 10 },
                }}
              />

              {/* <input
                                style={{
                                    background: "#f3f9fb",
                                    color: "black",
                                    border: "1px solid hsl(184, 22%, 85%)",
                                    borderRadius: "3px",
                                    cursor:"pointer"
                                }}
                                name={isSignUp ? "Agree & Join" : "Login"}
                                type="submit"
                            /> */}
              <Button
                variant="contained"
                sx={{
                  color: "#fff",
                  backgroundColor: "#52B1BB",
                }}
                onClick={updateUsersDB}
                disabled={isDisabled()}
              >
                Agree & Join
              </Button>
              <Typography fontSize={12} fontWeight={400} textAlign={"center"}>
                By clicking Agree & Join, you agree to the{" "}
                <span
                  onClick={handleClickOpenCG}
                  style={{
                    color: "#52B1BB",
                    fontWeight: "bolder",
                    cursor: "pointer",
                  }}
                >
                  Stratos Community Guidelines
                </span>{" "}
                and{" "}
                <span
                  onClick={handleClickOpenTOS}
                  style={{
                    color: "#52B1BB",
                    fontWeight: "bolder",
                    cursor: "pointer",
                  }}
                >
                  our Terms of Service
                </span>
              </Typography>
              <CommunityGuidelines handleClose={handleCloseCG} open={openCG} />
              <TermsOfService handleClose={handleCloseTOS} open={openTOS} />
            </Stack>
          </Box>
          <a
            className="login-link"
            onClick={() => navigate("/login")}
            style={{
              color: "#52B1BB",
              fontWeight: "bolder",
              cursor: "pointer",
            }}
          >
            Have an Account? Login
          </a>
        </Paper>
      </Box>
    </>
  );
};

export default SignUp;
