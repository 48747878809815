import {  Box, Paper } from '@mui/material'
import React from 'react'
import Profile from '../../JobSettings/member.jpg'
import './MyPipeline.css'
import PipelineCard from './PipelineCard'

const MyPipeline = () => {
  return (
      <Box className='pipeline-card-container'>
          <PipelineCard Profile={Profile} />
          <PipelineCard Profile={Profile} />
          
    </Box>
  )
}

export default MyPipeline