import React, { useEffect } from "react";

import { Box, Stack } from "@mui/material";
import { City, Country, State } from "country-state-city";
import { useFormik } from "formik";
import Select from "react-select";

const ChooseYourCity = ({
  country,
  setCountry,
  state,
  setState,
  city,
  setCity,
}) => {
  const addressFromik = useFormik({
    initialValues: {
      country: country,
      state: state,
      city: city,
    },
    // onSubmit: (values) => {setCountry(values.country.name);
    //     setState(values.state.name);
    //     setCity(values.city.name);
    // }
  });

  const countries = Country.getAllCountries();

  const updatedCountries = countries.map((country) => ({
    label: country.name,
    value: country.isoCode,
    ...country,
  }));

  const updatedStates = (countryId) =>
    State.getStatesOfCountry(countryId).map((state) => ({
      label: state.name,
      value: state.isoCode,
      ...state,
    }));

  const updatedCities = (countryId, stateId) =>
    City.getCitiesOfState(countryId, stateId).map((city) => ({
      label: city.name,
      value: city.isoCode,
      ...city,
    }));

  const { values, setFieldValue } = addressFromik;
  useEffect(() => {}, [values]);

  return (
    <Stack
      direction={"row"}
      alignItems={"flex-start"}
      justifyContent={"center"}
      sx={{ m: 1 }}
    >
      <form>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            padding: 1,
            height: "auto",
            alignItems: "center",
          }}
        >
          <Select
            id="country"
            name="country"
            label="country"
            placeholder={
              values.country ? values.country : "select your country *"
            }
            options={updatedCountries}
            value={values.country}
            required
            onChange={(value) => {
              setFieldValue("country", value);
              setFieldValue("state", null);
              setFieldValue("city", null);
            }}
          />
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            padding: 1,
            height: "auto",
            alignItems: "center",
          }}
        >
          <Select
            id="state"
            name="state"
            placeholder={values.state ? values.state : "select your state *"}
            options={updatedStates(
              values.country ? values.country.value : null
            )}
            value={values.state}
            required
            onChange={(value) => {
              setFieldValue("state", value);
              setFieldValue("city", null);
            }}
          />
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            padding: 1,
            height: "auto",
            alignItems: "center",
          }}
        >
          <Select
            id="city"
            name="city"
            placeholder={values.city ? values.city : "select your city *"}
            options={updatedCities(
              values.country ? values.country.value : null,
              values.state ? values.state.value : null
            )}
            value={values.city}
            required
            onChange={(value) => {
              setFieldValue("city", value);
              setCountry(values.country.name);
              setState(values.state.name);
              setCity(value.name);
            }}
          />
        </Box>
      </form>
    </Stack>
  );
};
export default ChooseYourCity;
