import axios from "axios";

const API = axios.create({
    baseURL: "https://us-central1-stratosv1.cloudfunctions.net/api",
});
// api url: https://us-central1-stratosv1.cloudfunctions.net/api
// localhost serving: http://127.0.0.1:5001/stratosv1/us-central1/api
// Emulator UI: http://127.0.0.1:4000
// Web App: http://localhost:8080/

const getAllUserChats = async ({ token }) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
    const response = await API.get("/message/getAllUserChats", config, {
        headers: {
            Access: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        },
    });

    return response.data;
};

const getUnreadMessagesCount = async ({ token }) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
    const response = await API.get("/message/getUnreadMessagesCount", config);

    return response.data;
};

const getAllMessages = async (otherUser, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
    const response = await API.post(
        "/message/getAllMessages",
        { otherUser },
        config,
        {
            headers: {
                Access: "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
            },
        }
    );
    return response.data;
};

const createChat = async (otherUser, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
    const response = await API.post(
        "/message/createChat",
        { otherUser },
        config,
        {
            headers: {
                Access: "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
            },
        }
    );
    return response.data;
};

const getOtherUserData = async (otherUser, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
    const response = await API.post(
        "/message/getOtherUserData",
        { otherUser },
        config,
        {
            headers: {
                Access: "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
            },
        }
    );
    return response.data;
};

const setAllMessagesRead = async (otherUser, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
    const response = await API.post(
        "/message/setAllMessagesRead",
        { otherUser },
        config
    );
    return response.data;
};

const createMessage = async ({ otherUser, content }, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
    const response = await API.post(
        "/message/createMessage",
        { otherUser, content },
        config
    );
    return response.data;
};

const messageService = {
    getAllUserChats,
    setAllMessagesRead,
    getAllMessages,
    createChat,
    getOtherUserData,
    createMessage,
    getUnreadMessagesCount,
};

export default messageService;
