import React, { useRef, useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slider,
  Stack,
  TextField,
  Typography,
  alpha,
} from "@mui/material";
import AvatarEditor from "react-avatar-editor";
import { useDispatch, useSelector } from "react-redux";
import ChooseYourCity from "../../../components/Onboarding/ChooseYourCity";
import ChooseYourPronouns from "../../../components/Onboarding/ChooseYourPronouns";
import {
  removeImage,
  updateUserPersonal,
  uploadImage,
} from "../../../features/auth/authSlice";
import { sortExpriences } from "../../utils";
import ChooseLayers from "../Home/ChooseLayers";
import AddEducation from "../Profile/AddEducation";
import AddExperience from "./AddExperience";
import AddInterests from "./AddInterests";

const PersonalDetails = () => {
  const dispatch = useDispatch();

  const { user, editProfileImageLoading } = useSelector((state) => state.auth);
  const [profilePic, setProfilePic] = useState(user?.profileImage?.url);

  const fullName = user.name?.split(" ");
  const [firstName, setFirstName] = useState(fullName[0]);
  const [lastName, setLastName] = useState(fullName[1]);

  const [birthDate, setBirthDate] = useState(
    user.birthDate ? user.birthDate : ""
  );

  const [country, setCountry] = useState(user.country ? user.country : "");
  const [state, setState] = useState(user.state ? user.state : "");
  const [city, setCity] = useState(user.city ? user.city : "");
  const [bio, setBio] = useState(user.bio ? user.bio : "");
  const [linkedIn, setLinkedIn] = useState(user.linkedin ? user.linkedin : "");
  const [yourPronouns, setYourPronouns] = useState(
    user.pronouns ? user.pronouns : []
  );

  const [experience, setExperience] = useState(
    user.experience ? user.experience : []
  );

  const [education, setEducation] = useState(
    user.education ? user.education : []
  );

  const onClickSave = () => {
    const image = editor.current.getImageScaledToCanvas().toDataURL();
  };

  // to upload new profile pic
  const [openUploadProfilePicTab, setOpenUploadProfilePicTab] = useState(false);
  const [saveProfilePic, setSaveProfilePic] = useState("");
  const handleOpenUploadProfilePicTab = () => {
    setOpenUploadProfilePicTab(false);
  };
  const handleUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = (event) => {
      setProfilePic(event.target.result);
    };
    setSaveProfilePic(file);
  };
  const DataURIToBlob = (dataURI) => {
    const splitDataURI = dataURI.split(",");
    const byteString =
      splitDataURI[0].indexOf("base64") >= 0
        ? atob(splitDataURI[1])
        : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i);

    return new Blob([ia], { type: mimeString });
  };
  const handleSave = () => {
    // Get the cropped image data
    const image = editor.current.getImageScaledToCanvas().toDataURL();
    const file = DataURIToBlob(image);
    // Save the cropped image data to the state variable
    setProfilePic(file);
    let formData = new FormData();
    formData.append("file", file, "image.png");
    for (let [name, value] of formData) {
    }
    dispatch(uploadImage(formData));
    setOpenUploadProfilePicTab(false);
  };

  // for updating experience when any field in experience changes
  const handleFieldChange = (index, fieldName, newValue) => {
    setExperience((prevExperience) => {
      const updatedExperience = [...prevExperience];
      updatedExperience[index] = {
        ...updatedExperience[index],
        [fieldName]: newValue,
      };
      return updatedExperience;
    });
  };

  // for updating degree when any field in educatio changes
  const handleSchoolFieldChange = (index, fieldName, newValue) => {
    setEducation((prevEducation) => {
      const updatedEducation = [...prevEducation];
      updatedEducation[index] = {
        ...updatedEducation[index],
        [fieldName]: newValue,
      };
      return updatedEducation;
    });
  };

  // for avatar editor
  const editor = useRef(null);
  const [zoom, setZoom] = useState(1);
  const handleZoomChange = (event, newValue) => {
    setZoom(newValue);
  };

  // make save button disabled
  const disableSaveButton = () => {
    if (
      firstName.trim() === "" ||
      lastName.trim() === "" ||
      birthDate.trim() === "" ||
      city.trim() === ""
    ) {
      return true; // save button should remain disabled
    } else if (
      experience.some(
        (exp) =>
          exp.company.trim() === "" ||
          exp.position.trim() === "" ||
          exp.startDate === "" ||
          exp.endDate === ""
      )
    ) {
      return true; // save button should remain disabled
    } else if (
      education.some(
        (sch) =>
          sch.school.trim() === "" ||
          sch.fieldOfStudy.trim() === "" ||
          sch.degree.trim() === "" ||
          sch.startDate === "" ||
          sch.endDate === ""
      )
    ) {
      return true; // save button should remain disabled
    }

    return false; // save button can be enabled
  };

  // for save button
  const saveChanges = () => {
    const name = firstName + " " + lastName;
    const sortedExperience = sortExpriences(experience);
    const jobTitle =
      sortedExperience.length > 0 ? sortedExperience[0].position : "";
    const currentCompany =
      sortedExperience.length > 0 ? sortedExperience[0].company : "";
    const newUserData = {
      name,
      firstName,
      birthDate,
      country,
      state,
      city,
      jobTitle,
      currentCompany,
      bio,
      linkedin: linkedIn,
      pronouns: yourPronouns,
      experience: sortedExperience,
      education,
    };
    console.log("userData", newUserData);
    dispatch(updateUserPersonal({ newUserData }));
  };

  // for layout of textfields
  const sxForTextField = {
    "& .MuiFilledInput-root": {
      overflow: "hidden",
      borderRadius: "7px",
      backgroundColor: "#F3F9FB",
      border: "1px solid",
      borderColor: "#D2E1E2",
      transition: "border-color background-color box-shadow",
      "&:hover": {
        backgroundColor: "#F3F9FB",
      },
      "&.Mui-focused": {
        backgroundColor: "#F3F9FB",
        boxShadow: `${alpha("#000", 0.25)} 0 0 0 2px`,
        borderColor: "#D2E1E2",
      },
    },
  };
  const inputPropsForTextField = {
    style: {
      fontSize: { xs: 12, sm: 14, md: 16 },
      fontWeight: 300,
      color: "#2A2E33",
    },
  };

  const inputLabelPropsForTextfield = {
    style: {
      fontWeight: 400,
      fontSize: 12,
      color: "#16171B",
    },
  };

  // add layers dialog box code
  const [openAddLayers, setOpenAddLayers] = useState(false);
  const handleAddLayerClose = (event, reason) => {
    if (reason && reason == "backdropClick") return;
    setOpenAddLayers(false);
  };

  // add interests dialog box code
  const [openAddInterests, setOpenAddInterests] = useState(false);
  const handleAddInterestsClose = (event, reason) => {
    if (reason && reason == "backdropClick") return;
    setOpenAddInterests(false);
  };

  // upload image box code
  const [openCropImage, setOpenCropImage] = useState(false);
  const handleOpenCropImageClose = () => {
    setOpenCropImage(false);
  };

  const handleAddNewExperience = () => {
    let newExp = {
      company: "",
      position: "",
      startDate: "",
      endDate: "",
    };

    setExperience([...experience, newExp]);
  };

  const removeExperience = (index) => {
    setExperience((prevExperience) => {
      const newExperience = [...prevExperience];
      newExperience.splice(index, 1);
      return newExperience;
    });
  };

  const handleAddNewEducation = () => {
    let newEd = {
      school: "",
      fieldOfStudy: "",
      degree: "",
      startDate: "",
      endDate: "",
    };

    setEducation([...education, newEd]);
  };

  const removeEducation = (index) => {
    setEducation((prevEducation) => {
      const newEducation = [...prevEducation];
      newEducation.splice(index, 1);
      return newEducation;
    });
  };

  const handleRemovePhoto = () => {
    dispatch(removeImage());
  };

  return (
    <Stack
      direction="column"
      justifyContent={{
        xs: "flex-start",
        sm: "flex-star",
        md: "space-between",
      }}
      width={"90%"}
      sx={{ overflow: "hidden" }}
    >
      <Stack
        direction={{ xs: "column", sm: "column", md: "row" }}
        justifyContent={{
          xs: "flex-start",
          sm: "flex-start",
          md: "space-between",
        }}
        alignItems={{
          xs: "center",
          sm: "center",
          md: "flex-start",
        }}
      >
        <Stack
          sx={{
            width: { xs: "90%", sm: "90%", md: "55%" },
            marginX: 1.5,
          }}
          spacing={1}
          direction="column"
        >
          <Typography
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-start",
              m: 1,
              fontWeight: 700,
              fontSize: { xs: "16px", sm: "18px", md: "20px" },
              textAlign: "center",
            }}
            noWrap
          >
            Personal Information
          </Typography>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack
              direction="column"
              justifyContent={{
                xs: "flex-start",
                sm: "flex-star",
                md: "space-between",
              }}
              spacing={1}
            >
              <TextField
                name="firstName"
                required
                error={firstName.trim() === ""}
                helperText={
                  firstName.trim() === "" ? "First name is required" : ""
                }
                onChange={(e) => setFirstName(e.target.value)}
                size="small"
                value={firstName}
                label="first name"
                id="firstName"
                variant="filled"
                InputLabelProps={inputLabelPropsForTextfield}
                InputProps={{
                  disableUnderline: true,
                  classes: {
                    root: "MuiFilledInput-root",
                    focused: "Mui-focused",
                  },
                }}
                inputProps={inputPropsForTextField}
                sx={sxForTextField}
              />

              <TextField
                name="lastName"
                onChange={(e) => setLastName(e.target.value)}
                size="small"
                value={lastName}
                label="last name"
                id="lastName"
                variant="filled"
                required
                error={lastName.trim() === ""}
                helperText={
                  lastName.trim() === "" ? "Last name is required" : ""
                }
                InputLabelProps={inputLabelPropsForTextfield}
                InputProps={{
                  disableUnderline: true,
                  classes: {
                    root: "MuiFilledInput-root",
                    focused: "Mui-focused",
                  },
                }}
                inputProps={inputPropsForTextField}
                sx={sxForTextField}
              />

              <TextField
                name="birthDate"
                onChange={(e) => setBirthDate(e.target.value)}
                size="small"
                value={birthDate}
                label="birthdate"
                id="birthDate"
                variant="filled"
                required
                error={birthDate.trim() === ""}
                helperText={
                  birthDate.trim() === "" ? "Birthdate is required" : ""
                }
                InputLabelProps={inputLabelPropsForTextfield}
                InputProps={{
                  disableUnderline: true,
                  classes: {
                    root: "MuiFilledInput-root",
                    focused: "Mui-focused",
                  },
                }}
                inputProps={inputPropsForTextField}
                sx={sxForTextField}
                type="date"
              />

              <ChooseYourPronouns
                yourPronouns={yourPronouns}
                setYourPronouns={setYourPronouns}
              />
            </Stack>

            <ChooseYourCity
              country={country}
              setCountry={setCountry}
              state={state}
              setState={setState}
              city={city}
              setCity={setCity}
            />
          </Stack>

          {/* <TextField
            name="linkedIn"
            onChange={(e) => setLinkedIn(e.target.value)}
            size="small"
            value={linkedIn}
            label="linkedIn"
            id="linkedIn"
            variant="filled"
            InputLabelProps={inputLabelPropsForTextfield}
            InputProps={{
              disableUnderline: true,
              classes: {
                root: "MuiFilledInput-root",
                focused: "Mui-focused",
              },
            }}
            inputProps={inputPropsForTextField}
            sx={sxForTextField}
          /> */}
        </Stack>
        <Box
          sx={{
            width: { xs: "90%", sm: "90%", md: "45%" },
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginX: 1.5,
          }}
        >
          <Typography
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              m: 1,
              fontWeight: 700,
              fontSize: { xs: "16px", sm: "18px", md: "20px" },
            }}
          >
            Profile Photo
          </Typography>
          {editProfileImageLoading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress color="primary" />
            </Box>
          ) : (
            <>
              <Avatar
                src={user.profileImage.url}
                sx={{
                  width: { xs: 100, sm: 100, md: 160 },
                  height: { xs: 100, sm: 100, md: 160 },
                  m: 1,
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: {
                    xs: "column",
                    sm: "column",
                    md: "row",
                  },
                }}
              >
                <Button
                  variant="outlined"
                  sx={{
                    backgroundColor: "black",
                    color: "white",
                    border: "1px solid black",
                    textTransform: "none",
                    fontWeight: 700,
                    fontSize: "12px",
                    m: 0.5,
                    whiteSpace: "nowrap",
                    borderRadius: "15px",
                    "&:hover": {
                      backgroundColor: "grey",
                      border: "1px solid grey",
                    },
                  }}
                  onClick={handleRemovePhoto}
                >
                  Remove Photo
                </Button>
                <Button
                  variant="outlined"
                  sx={{
                    backgroundColor: "white",
                    color: "#52B1BB",
                    border: "1px solid #52B1BB",
                    textTransform: "none",
                    fontWeight: 700,
                    fontSize: "12px",
                    m: 0.5,
                    whiteSpace: "nowrap",
                    borderRadius: "15px",
                  }}
                  onClick={() => setOpenUploadProfilePicTab(true)}
                >
                  + Upload Image
                </Button>
              </Box>
            </>
          )}
          {/* <ChooseYourPronouns
            yourPronouns={yourPronouns}
            setYourPronouns={setYourPronouns}
          /> */}
        </Box>
      </Stack>

      <Box sx={{ paddingX: 1.5, marginY: 1 }}>
        <TextField
          name="bio"
          onChange={(e) => setBio(e.target.value)}
          size="small"
          value={bio}
          label="bio"
          id="bio"
          variant="filled"
          multiline
          fullWidth
          maxRows={4}
          InputLabelProps={inputLabelPropsForTextfield}
          InputProps={{
            disableUnderline: true,
            classes: {
              root: "MuiFilledInput-root",
              focused: "Mui-focused",
            },
          }}
          inputProps={inputPropsForTextField}
          sx={sxForTextField}
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          margin: { xs: 0, sm: 0, md: 1 },
          paddingX: { xs: 0.5, sm: 0.5, md: 1.5 },
        }}
      >
        <Typography
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            m: 1,
            fontWeight: 700,
            fontSize: { xs: "16px", sm: "18px", md: "20px" },
          }}
        >
          Experience
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            flexWrap: "wrap",
            marginTop: 1,
            alignItems: "flex-start",
          }}
        >
          <Button
            variant="text"
            startIcon={<AddIcon />}
            onClick={handleAddNewExperience}
            sx={{
              fontSize: { xs: "12px", sm: "14px", md: "16px" },
            }}
          >
            Add More Experience
          </Button>
          <Typography
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-start",
              marginLeft: 1,
              fontSize: "11px",
            }}
          >
            * only your most recent experience will be shown to others
          </Typography>

          {experience &&
            experience.map((exp, index) => (
              <Stack
                key={exp}
                direction={"row"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <AddExperience
                  exp={exp}
                  onFieldChange={(fieldName, newValue) =>
                    handleFieldChange(index, fieldName, newValue)
                  }
                  index={index}
                  removeExperience={removeExperience}
                />
              </Stack>
            ))}
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          margin: { xs: 0, sm: 0, md: 1 },
          paddingX: { xs: 0.5, sm: 0.5, md: 1.5 },
        }}
      >
        <Typography
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            m: 1,
            fontWeight: 700,
            fontSize: { xs: "16px", sm: "18px", md: "20px" },
          }}
        >
          School
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            flexWrap: "wrap",
            marginTop: 1,
            alignItems: "flex-start",
          }}
        >
          <Button
            variant="text"
            startIcon={<AddIcon />}
            onClick={handleAddNewEducation}
          >
            Add More Education
          </Button>

          {education.map((sch, index) => (
            <Stack
              key={index}
              direction={"row"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <AddEducation
                sch={sch}
                onSchoolFieldChange={(fieldName, newValue) =>
                  handleSchoolFieldChange(index, fieldName, newValue)
                }
                index={index}
                removeEducation={removeEducation}
              />
            </Stack>
          ))}
        </Box>
      </Box>

      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button
          onClick={saveChanges}
          disabled={disableSaveButton()}
          variant="outline"
          sx={{
            color: "#fff",
            backgroundColor: "#52B1BB",
            textTransform: "none",
            "&:hover": {
              backgroundColor: "#52B1BB",
            },
            width: { xs: "100%", sm: "100%", md: "auto" },
          }}
        >
          Save
        </Button>
      </Box>

      <Dialog onClose={handleAddLayerClose} open={openAddLayers} fullWidth>
        <DialogTitle
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            fontWeight: "bold",
            color: "#52B1BB",
            justifyContent: "center",
            pb: "0",
          }}
        >
          Add More Layers
        </DialogTitle>
        <DialogContent>
          <ChooseLayers handleAddLayerClose={handleAddLayerClose} />
        </DialogContent>
      </Dialog>

      <Dialog
        onClose={handleAddInterestsClose}
        open={openAddInterests}
        fullWidth
      >
        <DialogTitle
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            fontWeight: "bold",
            color: "#52B1BB",
            justifyContent: "center",
            pb: "0",
          }}
        >
          Add More Interests
        </DialogTitle>
        <DialogContent>
          <AddInterests handleAddInterestsClose={handleAddInterestsClose} />
        </DialogContent>
      </Dialog>

      <Dialog
        open={openUploadProfilePicTab}
        onClose={handleOpenUploadProfilePicTab}
      >
        <DialogTitle>Upload Profile Picture</DialogTitle>
        <DialogContent>
          <Stack
            direction={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            spacing={2}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <input type="file" onChange={handleUpload} />
            </Box>
            {profilePic && (
              <>
                <AvatarEditor
                  ref={editor}
                  image={profilePic}
                  width={200}
                  height={200}
                  borderRadius={100}
                  scale={zoom}
                />

                <Box sx={{ width: 200 }}>
                  <Stack spacing={2} direction="row">
                    <RemoveIcon />
                    <Slider
                      min={1}
                      max={3}
                      step={0.1}
                      value={zoom}
                      onChange={handleZoomChange}
                    />
                    <AddIcon />
                  </Stack>
                </Box>
              </>
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSave}>Save</Button>
          <Button onClick={handleOpenUploadProfilePicTab}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
};

export default PersonalDetails;
