import { Comment, ThumbUpAlt } from "@mui/icons-material";
import {
  Avatar,
  Badge,
  Box,
  Button,
  ButtonBase,
  Paper,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getNotifications } from "../../../features/notifications/notificationsSlice";
import { getPostComments } from "../../../features/posts/postSlice";

const Notifications = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { notifications, isSuccess, isLoading } = useSelector(
    (state) => state.notifications
  );

  console.log("notifications:", notifications);

  useEffect(() => {
    dispatch(getNotifications());

    const dispatchGetNotifications = () => {
      dispatch(getNotifications());
    };
    dispatchGetNotifications();
  }, []);

  return (
    <Box>
      <Toolbar />
      <Typography
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          paddingLeft: 1,
          paddingBottom: 1,
          height: "10%",
          color: "#14213D",
          fontWeight: "700",
          fontSize: 20,
        }}
      >
        Notifications
      </Typography>
      <Stack direction="column" justifyContent="center" alignItems="center">
        {notifications &&
          notifications.map((notif) => (
            <Paper
              key={notif.id}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "15px",
                marginBottom: "15px",
                width: "90%",
              }}
            >
              <ButtonBase
                onClick={() => {
                  const commentData = {
                    postId: notif.actionDetails.postId,
                    layer: notif.actionDetails.layer,
                  };
                  dispatch(getPostComments(commentData));
                  navigate(`/HomeScreen/post/${notif.actionDetails.postId}`);
                }}
              >
                <Stack direction={{ xs: "column", sm: "row" }} spacing={3}>
                  <Badge color="error" variant="dot" invisible={notif.read}>
                    <Avatar
                      src={
                        notif.userWhoPerformedAction.profileImage.url
                          ? notif.userWhoPerformedAction.profileImage.url
                          : ""
                      }
                      alt="User profile"
                    />
                  </Badge>
                  <Typography marginLeft={1}>
                    {notif.userWhoPerformedAction.name}
                  </Typography>
                  <Typography>
                    {notif.actionType === "Like" ? (
                      <ThumbUpAlt color="primary" />
                    ) : (
                      <Comment color="primary" />
                    )}
                  </Typography>
                  <Typography>
                    Your post on {notif.actionDetails.layer}:
                  </Typography>
                  <Typography>
                    {notif.actionDetails.content.length > 30
                      ? notif.actionDetails.content.substring(0, 30) + "..."
                      : notif.actionDetails.content}
                  </Typography>
                  <Typography variant="body2" sx={{ color: "#CACACA" }}>
                    {moment(new Date(notif.actionTime)).fromNow()}
                  </Typography>
                </Stack>
              </ButtonBase>
            </Paper>
          ))}
        {notifications && notifications.length < 1 && (
          <Box textAlign="center">
            <Typography
              variant="body2"
              color="#52B1BB"
              textAlign={"center"}
              sx={{
                fontSize: "17px",
                whiteSpace: "nowrap",
                fontWeight: 550,
                // padding: 0.5,
                m: 1,
              }}
            >
              🫣 No notifications for you at the moment
            </Typography>
            <Typography
              variant="body2"
              textAlign={"center"}
              sx={{
                fontSize: "16px",
                whiteSpace: "nowrap",
                fontWeight: 500,
                m: 1,
              }}
            >
              Get out there and start interacting with others!
            </Typography>
            <Button
              textAlign={"center"}
              alignSelf={"center"}
              sx={{
                fontWeight: 400,
                fontSize: "16px",
                borderRadius: "27px",
                color: "white",
                backgroundColor: "#52B1BB",
                textTransform: "none",
                m: 1,
              }}
              onClick={() => navigate("/HomeScreen")}
            >
              Head Over to a Layer 🌌
            </Button>
            {/* <Typography
              color="white"
              textAlign={"center"}
              alignSelf={"center"}
              onClick={() => navigate("/HomeScreen")}
              sx={{
                fontSize: "17px",
                whiteSpace: "nowrap",
                fontWeight: 550,
                borderRadius: "27px",
                padding: 0.5,
                marginTop: 2,
                backgroundColor: "#52B1BB",
                width: "200px",
              }}
            >
              head to the Layers!  🤠 🚀 🌟 🌠 🙄
            </Typography> */}
          </Box>
        )}
      </Stack>
    </Box>
  );
};

export default Notifications;
