import axios from "axios";

const API = axios.create({
    baseURL: "https://us-central1-stratosv1.cloudfunctions.net/api",
});
// api url: https://us-central1-stratosv1.cloudfunctions.net/api

const reportPost = async (
    {
        reportedPost,
        reportingReason,
        reportedPostLayer,
        reportedPostAuthorId,
        reportedPostAuthorName,
    },
    token
) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
    const reported = {
        reportedPost,
        reportingReason,
        reportedPostLayer,
        reportedPostAuthorId,
        reportedPostAuthorName,
    };
    const response = await API.post(
        `/blockReport/reportPost`,
        reported,
        config
    );
    return response.data;
};

//Report User
const reportUser = async (
    { reportedUser, reportingReason, reportedEmail },
    token
) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
    const reported = { reportedUser, reportingReason, reportedEmail };
    const response = await API.post(
        `/blockReport/reportUser`,
        reported,
        config
    );
    console.log("REPORT USER", response);
    return response.data;
};

const blockUser = async ({ blockedUser, blockedEmail }, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
    const blocked = { blockedUser, blockedEmail };
    const response = await API.post(`/blockReport/blockUser`, blocked, config);
    return response.data;
};

const getBlockedUsers = async ({ token }) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };

    const response = await API.get("/blockReport/getBlockedUsers", config);
    return response.data;
};

const reportComment = async (
    {
        reportedPost,
        reportedComment,
        reportingReason,
        reportedPostLayer,
        reportedCommentAuthorId,
        reportedCommentAuthorName,
    },
    token
) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
    const reported = {
        reportedPost,
        reportedComment,
        reportingReason,
        reportedPostLayer,
        reportedCommentAuthorId,
        reportedCommentAuthorName,
    };
    const response = await API.post(
        `/blockReport/reportComment`,
        reported,
        config
    );
    return response.data;
};

const reportServie = {
    reportPost,
    blockUser,
    reportUser,
    getBlockedUsers,
    reportComment,
};

export default reportServie;
